/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * StripeWebhookApi - axios parameter creator
 * @export
 */
export const StripeWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary The account update succeeded webhook EP for debug purposes only.
         * @param {string} [accountId] The connect account ID starting with acc_.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksAccountUpdatedDebugPost: async (accountId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/account-updated__debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Occurs when a connect account is updated (either status or property).  Stripe webhook: account.updated  https://stripe.com/docs/api/events/types#event_types-account.updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksAccountUpdatedPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/account-updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksChargeFailedPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/charge-failed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The payment intent failed webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {boolean} [isSepa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentFailedDebugPost: async (pi?: string, isSepa?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-failed__debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pi !== undefined) {
                localVarQueryParameter['pi'] = pi;
            }

            if (isSepa !== undefined) {
                localVarQueryParameter['isSepa'] = isSepa;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Occurs when a PaymentIntent has failed the attempt to create a payment method or a payment.  Stripe webhook: payment_intent.failed  https://stripe.com/docs/api/events/types#event_types-payment_intent.payment_failed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentFailedPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-failed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The payment intent processing webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentProcessingDebugPost: async (pi?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-processing__debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pi !== undefined) {
                localVarQueryParameter['pi'] = pi;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Payment intent is being processed.  Stripe webhook: payment_intent.processing  https://stripe.com/docs/api/events/types#event_types-payment_intent.processing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentProcessingPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-processing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The payment intent requires action webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost: async (pi?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-requires-action__debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pi !== undefined) {
                localVarQueryParameter['pi'] = pi;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Payment intent status changed.  Stripe webhook: payment_intent.requires_action  https://stripe.com/docs/api/events/types#event_types-payment_intent.requires_action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentRequiresActionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-requires-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The payment intent succeeded webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentSucceededDebugPost: async (pi?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-succeeded__debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pi !== undefined) {
                localVarQueryParameter['pi'] = pi;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentSucceededPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe-webhooks/payment-intent-succeeded`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeWebhookApi - functional programming interface
 * @export
 */
export const StripeWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary The account update succeeded webhook EP for debug purposes only.
         * @param {string} [accountId] The connect account ID starting with acc_.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksAccountUpdatedDebugPost(accountId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksAccountUpdatedDebugPost(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Occurs when a connect account is updated (either status or property).  Stripe webhook: account.updated  https://stripe.com/docs/api/events/types#event_types-account.updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksAccountUpdatedPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksAccountUpdatedPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksChargeFailedPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksChargeFailedPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The payment intent failed webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {boolean} [isSepa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi?: string, isSepa?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi, isSepa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Occurs when a PaymentIntent has failed the attempt to create a payment method or a payment.  Stripe webhook: payment_intent.failed  https://stripe.com/docs/api/events/types#event_types-payment_intent.payment_failed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentFailedPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentFailedPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The payment intent processing webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Payment intent is being processed.  Stripe webhook: payment_intent.processing  https://stripe.com/docs/api/events/types#event_types-payment_intent.processing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentProcessingPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentProcessingPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The payment intent requires action webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Payment intent status changed.  Stripe webhook: payment_intent.requires_action  https://stripe.com/docs/api/events/types#event_types-payment_intent.requires_action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentRequiresActionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentRequiresActionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The payment intent succeeded webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StripeWebhooksPaymentIntentSucceededPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StripeWebhooksPaymentIntentSucceededPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeWebhookApi - factory interface
 * @export
 */
export const StripeWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeWebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary The account update succeeded webhook EP for debug purposes only.
         * @param {string} [accountId] The connect account ID starting with acc_.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksAccountUpdatedDebugPost(accountId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksAccountUpdatedDebugPost(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Occurs when a connect account is updated (either status or property).  Stripe webhook: account.updated  https://stripe.com/docs/api/events/types#event_types-account.updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksAccountUpdatedPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksAccountUpdatedPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksChargeFailedPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksChargeFailedPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The payment intent failed webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {boolean} [isSepa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi?: string, isSepa?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi, isSepa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Occurs when a PaymentIntent has failed the attempt to create a payment method or a payment.  Stripe webhook: payment_intent.failed  https://stripe.com/docs/api/events/types#event_types-payment_intent.payment_failed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentFailedPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentFailedPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The payment intent processing webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Payment intent is being processed.  Stripe webhook: payment_intent.processing  https://stripe.com/docs/api/events/types#event_types-payment_intent.processing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentProcessingPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentProcessingPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The payment intent requires action webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Payment intent status changed.  Stripe webhook: payment_intent.requires_action  https://stripe.com/docs/api/events/types#event_types-payment_intent.requires_action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentRequiresActionPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentRequiresActionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The payment intent succeeded webhook EP for debug purposes only.
         * @param {string} [pi] The payment intent ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StripeWebhooksPaymentIntentSucceededPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1StripeWebhooksPaymentIntentSucceededPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeWebhookApi - interface
 * @export
 * @interface StripeWebhookApi
 */
export interface StripeWebhookApiInterface {
    /**
     * 
     * @summary The account update succeeded webhook EP for debug purposes only.
     * @param {string} [accountId] The connect account ID starting with acc_.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksAccountUpdatedDebugPost(accountId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Occurs when a connect account is updated (either status or property).  Stripe webhook: account.updated  https://stripe.com/docs/api/events/types#event_types-account.updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksAccountUpdatedPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksChargeFailedPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary The payment intent failed webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {boolean} [isSepa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi?: string, isSepa?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Occurs when a PaymentIntent has failed the attempt to create a payment method or a payment.  Stripe webhook: payment_intent.failed  https://stripe.com/docs/api/events/types#event_types-payment_intent.payment_failed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentFailedPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary The payment intent processing webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Payment intent is being processed.  Stripe webhook: payment_intent.processing  https://stripe.com/docs/api/events/types#event_types-payment_intent.processing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentProcessingPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary The payment intent requires action webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Payment intent status changed.  Stripe webhook: payment_intent.requires_action  https://stripe.com/docs/api/events/types#event_types-payment_intent.requires_action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentRequiresActionPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary The payment intent succeeded webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApiInterface
     */
    apiV1StripeWebhooksPaymentIntentSucceededPost(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * StripeWebhookApi - object-oriented interface
 * @export
 * @class StripeWebhookApi
 * @extends {BaseAPI}
 */
export class StripeWebhookApi extends BaseAPI implements StripeWebhookApiInterface {
    /**
     * 
     * @summary The account update succeeded webhook EP for debug purposes only.
     * @param {string} [accountId] The connect account ID starting with acc_.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksAccountUpdatedDebugPost(accountId?: string, options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksAccountUpdatedDebugPost(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Occurs when a connect account is updated (either status or property).  Stripe webhook: account.updated  https://stripe.com/docs/api/events/types#event_types-account.updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksAccountUpdatedPost(options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksAccountUpdatedPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksChargeFailedPost(options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksChargeFailedPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The payment intent failed webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {boolean} [isSepa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi?: string, isSepa?: boolean, options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentFailedDebugPost(pi, isSepa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Occurs when a PaymentIntent has failed the attempt to create a payment method or a payment.  Stripe webhook: payment_intent.failed  https://stripe.com/docs/api/events/types#event_types-payment_intent.payment_failed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentFailedPost(options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentFailedPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The payment intent processing webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi?: string, options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentProcessingDebugPost(pi, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Payment intent is being processed.  Stripe webhook: payment_intent.processing  https://stripe.com/docs/api/events/types#event_types-payment_intent.processing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentProcessingPost(options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentProcessingPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The payment intent requires action webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi?: string, options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentRequiresActionDebugPost(pi, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Payment intent status changed.  Stripe webhook: payment_intent.requires_action  https://stripe.com/docs/api/events/types#event_types-payment_intent.requires_action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentRequiresActionPost(options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentRequiresActionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The payment intent succeeded webhook EP for debug purposes only.
     * @param {string} [pi] The payment intent ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi?: string, options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentSucceededDebugPost(pi, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts the payment intent valid card attached.  Stripe webhook: payment_intent.succeeded  https://stripe.com/docs/api/events/types#event_types-payment_intent.succeeded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhookApi
     */
    public apiV1StripeWebhooksPaymentIntentSucceededPost(options?: AxiosRequestConfig) {
        return StripeWebhookApiFp(this.configuration).apiV1StripeWebhooksPaymentIntentSucceededPost(options).then((request) => request(this.axios, this.basePath));
    }
}

