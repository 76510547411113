/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { PoolCreateCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { VehiclePoolsListQueryResultPaginatedResult } from '../models';
/**
 * PoolOperatorApi - axios parameter creator
 * @export
 */
export const PoolOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets pools for logged in operator.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorPoolsGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1OperatorPoolsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1OperatorPoolsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/operator/pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorPoolsPost: async (poolCreateCommand?: PoolCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolOperatorApi - functional programming interface
 * @export
 */
export const PoolOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets pools for logged in operator.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclePoolsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorPoolsGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorPoolsPost(poolCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoolOperatorApi - factory interface
 * @export
 */
export const PoolOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets pools for logged in operator.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<VehiclePoolsListQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorPoolsGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1OperatorPoolsPost(poolCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoolOperatorApi - interface
 * @export
 * @interface PoolOperatorApi
 */
export interface PoolOperatorApiInterface {
    /**
     * 
     * @summary Gets pools for logged in operator.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolOperatorApiInterface
     */
    apiV1OperatorPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehiclePoolsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates a new vehicle pool entity from model provided.
     * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolOperatorApiInterface
     */
    apiV1OperatorPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * PoolOperatorApi - object-oriented interface
 * @export
 * @class PoolOperatorApi
 * @extends {BaseAPI}
 */
export class PoolOperatorApi extends BaseAPI implements PoolOperatorApiInterface {
    /**
     * 
     * @summary Gets pools for logged in operator.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolOperatorApi
     */
    public apiV1OperatorPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return PoolOperatorApiFp(this.configuration).apiV1OperatorPoolsGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new vehicle pool entity from model provided.
     * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolOperatorApi
     */
    public apiV1OperatorPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: AxiosRequestConfig) {
        return PoolOperatorApiFp(this.configuration).apiV1OperatorPoolsPost(poolCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

