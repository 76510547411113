/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Differentiates types of availability.
 * @export
 * @enum {string}
 */

export const RentalAvailabilityType = {
    NotSet: 'NotSet',
    Unavailable: 'Unavailable',
    Available: 'Available'
} as const;

export type RentalAvailabilityType = typeof RentalAvailabilityType[keyof typeof RentalAvailabilityType];



