/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCreateFleetOperatorStationCommand } from '../models';
// @ts-ignore
import { AdminUpdateFleetOperatorStationCommand } from '../models';
// @ts-ignore
import { CampiriProgramType } from '../models';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { FleetOperatorDocumentType } from '../models';
// @ts-ignore
import { FleetOperatorStationDetailQueryResult } from '../models';
// @ts-ignore
import { FleetOperatorStationStatus } from '../models';
// @ts-ignore
import { GetFleetOperatorPoliciesQueryResultListResult } from '../models';
// @ts-ignore
import { GetOperatorMemberQueryResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { GuidResultPaginatedResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ListFleetOperatorStationsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListOperatorMembersQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListingsByOperatorQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { OperatorAddressByIdQueryResult } from '../models';
// @ts-ignore
import { OperatorAddressUpdateCommand } from '../models';
// @ts-ignore
import { OperatorByIdQueryResult } from '../models';
// @ts-ignore
import { OperatorCreateCommand } from '../models';
// @ts-ignore
import { OperatorDocumentsAllQueryResult } from '../models';
// @ts-ignore
import { OperatorListingStatsResult } from '../models';
// @ts-ignore
import { OperatorMemberCreateCommand } from '../models';
// @ts-ignore
import { OperatorMemberUpdateCommand } from '../models';
// @ts-ignore
import { OperatorNameUpdateCommand } from '../models';
// @ts-ignore
import { OperatorPolicyType } from '../models';
// @ts-ignore
import { OperatorsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { SetFleetOperatorRentalPolicyCommand } from '../models';
// @ts-ignore
import { UpdateOperatorBillingInformationCommand } from '../models';
// @ts-ignore
import { UpdateOperatorContactInformationCommand } from '../models';
// @ts-ignore
import { UpdateOperatorContactPersonCommand } from '../models';
// @ts-ignore
import { UpdateOperatorDepositCommand } from '../models';
// @ts-ignore
import { UpdateOperatorInsuranceCommand } from '../models';
// @ts-ignore
import { UpdateOperatorProgramCommand } from '../models';
/**
 * OperatorAdminApi - axios parameter creator
 * @export
 */
export const OperatorAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a paginated list of operators.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isApproved] 
         * @param {string} [search] 
         * @param {CountryCode} [countryCode] 
         * @param {boolean} [isActive] 
         * @param {CampiriProgramType} [programType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGet: async (pageSize: number, pageNumber: number, isApproved?: boolean, search?: string, countryCode?: CountryCode, isActive?: boolean, programType?: CampiriProgramType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/operators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (isApproved !== undefined) {
                localVarQueryParameter['IsApproved'] = isApproved;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['CountryCode'] = countryCode;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (programType !== undefined) {
                localVarQueryParameter['ProgramType'] = programType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approves the operator
         * @param {string} guid The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidApprovePut: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidApprovePut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/approve`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s automatic payouts settings.
         * @param {string} guid The user identifier which is being updated.
         * @param {boolean} [enabled] Whether the payouts are enabled or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidAutomaticPayoutsPut: async (guid: string, enabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidAutomaticPayoutsPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/automatic-payouts`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorBillingInformationCommand} [updateOperatorBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidBillingInformationPut: async (guid: string, updateOperatorBillingInformationCommand?: UpdateOperatorBillingInformationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidBillingInformationPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/billing-information`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorBillingInformationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidCicoEnabledPut: async (guid: string, enabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidCicoEnabledPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/cico-enabled`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorContactInformationCommand} [updateOperatorContactInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidContactInformationPut: async (guid: string, updateOperatorContactInformationCommand?: UpdateOperatorContactInformationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidContactInformationPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/contact-information`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorContactInformationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorContactPersonCommand} [updateOperatorContactPersonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidContactPersonPut: async (guid: string, updateOperatorContactPersonCommand?: UpdateOperatorContactPersonCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidContactPersonPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/contact-person`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorContactPersonCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorDepositCommand} [updateOperatorDepositCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidDepositPut: async (guid: string, updateOperatorDepositCommand?: UpdateOperatorDepositCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidDepositPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/deposit`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorDepositCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorInsuranceCommand} [updateOperatorInsuranceCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidInsurancePut: async (guid: string, updateOperatorInsuranceCommand?: UpdateOperatorInsuranceCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidInsurancePut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/insurance`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorInsuranceCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s IsActive settings.
         * @param {string} guid The user identifier which is being updated.
         * @param {boolean} [enabled] Whether the opereator is active or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidIsActivePut: async (guid: string, enabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidIsActivePut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/is-active`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidKycEnabledPut: async (guid: string, enabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidKycEnabledPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/kyc-enabled`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated result of operator\'s listings.
         * @param {string} guid 
         * @param {number} pageSize Defines how many results will be returned.
         * @param {number} pageNumber Defines how many results will be skipped.
         * @param {string} [search] Fulltext search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidListingsGet: async (guid: string, pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidListingsGet', 'guid', guid)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidListingsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidListingsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/operators/{guid}/listings`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets stats about operator\'s listings.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidListingsStatsGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidListingsStatsGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/listings/stats`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the operators event logs.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidLogsGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidLogsGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/logs`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidMessagingEnabledPut: async (guid: string, enabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidMessagingEnabledPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/messaging-enabled`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorProgramCommand} [updateOperatorProgramCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidProgramPut: async (guid: string, updateOperatorProgramCommand?: UpdateOperatorProgramCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidProgramPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/program`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorProgramCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidTermsIncludedPut: async (guid: string, enabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsGuidTermsIncludedPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{guid}/terms-included`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the operator\'s info.
         * @param {number} id The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAddressGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAddressGet', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/address`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {number} id The user identifier which is being updated.
         * @param {OperatorAddressUpdateCommand} [operatorAddressUpdateCommand] The update operator command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAddressPut: async (id: number, operatorAddressUpdateCommand?: OperatorAddressUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAddressPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/address`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorAddressUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets allowed to add addons to bookings.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowAddingAddonsPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowAddingAddonsPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-adding-addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets allowed to create offers property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowCreatingOffersPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowCreatingOffersPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-creating-offers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets allowed to manage bookings property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowManagingBookingsPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowManagingBookingsPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-managing-bookings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets allowed to manage bookings custom items.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowManagingCustomItemsPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowManagingCustomItemsPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-managing-custom-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets allowed to manage damages and deposit property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-managing-damages-and-deposit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets allowed to modify requests property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowModifyRequestsPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowModifyRequestsPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-modify-requests`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/allow-register-payment-in-booking-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdContractsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdContractsGet', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/contracts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdContractsGuidGet: async (id: number, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdContractsGuidGet', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdContractsGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{id}/contracts/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FleetOperatorDocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdContractsPost: async (id: number, documentType?: FleetOperatorDocumentType, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdContractsPost', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/contracts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disables automatic synchronization of the operator.
         * @param {number} id The operator unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdDisableSynchronizationPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdDisableSynchronizationPost', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/disable-synchronization`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables automatic synchronization of the operator.
         * @param {number} id The operator unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdEnableSynchronizationPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdEnableSynchronizationPost', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/enable-synchronization`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the operator\'s info.
         * @param {number} id The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets insurance commission splitting for the operator.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdInsuranceSplittingEnabledPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdInsuranceSplittingEnabledPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/insurance-splitting-enabled`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of operator members
         * @param {number} id 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [searchRole] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGet: async (id: number, pageSize: number, pageNumber: number, searchRole?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/operators/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (searchRole !== undefined) {
                localVarQueryParameter['SearchRole'] = searchRole;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGuidDelete: async (id: number, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGuidDelete', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGuidDelete', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{id}/members/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGuidGet: async (id: number, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGuidGet', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{id}/members/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {OperatorMemberUpdateCommand} [operatorMemberUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGuidPut: async (id: number, guid: string, operatorMemberUpdateCommand?: OperatorMemberUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGuidPut', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/operators/{id}/members/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorMemberUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds new member to operator account
         * @param {number} id 
         * @param {OperatorMemberCreateCommand} [operatorMemberCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersPost: async (id: number, operatorMemberCreateCommand?: OperatorMemberCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdMembersPost', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorMemberCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the operator\'s name.
         * @param {number} id The user identifier which is being updated.
         * @param {OperatorNameUpdateCommand} [operatorNameUpdateCommand] The update operator&#x60;s name command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdNamePut: async (id: number, operatorNameUpdateCommand?: OperatorNameUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdNamePut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorNameUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of active rental policies for specified fleet operator account.
         * @param {number} id The Account GUID identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdRentalPoliciesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdRentalPoliciesGet', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/rental-policies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the fleet operator rental policy.
         * @param {number} id The operator identifier.
         * @param {SetFleetOperatorRentalPolicyCommand} [setFleetOperatorRentalPolicyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdRentalPoliciesPut: async (id: number, setFleetOperatorRentalPolicyCommand?: SetFleetOperatorRentalPolicyCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdRentalPoliciesPut', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/rental-policies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setFleetOperatorRentalPolicyCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unsets the fleet operator rental policy.
         * @param {number} id The operator identifier.
         * @param {OperatorPolicyType} type The rental policy Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdRentalPoliciesTypeDelete: async (id: number, type: OperatorPolicyType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdRentalPoliciesTypeDelete', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdRentalPoliciesTypeDelete', 'type', type)
            const localVarPath = `/api/v1/admin/operators/{id}/rental-policies/{type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} id The operator identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsGet: async (id: number, pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/operators/{id}/stations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new fleet operator station
         * @param {number} id The operator identifier.
         * @param {AdminCreateFleetOperatorStationCommand} [adminCreateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsPost: async (id: number, adminCreateFleetOperatorStationCommand?: AdminCreateFleetOperatorStationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsPost', 'id', id)
            const localVarPath = `/api/v1/admin/operators/{id}/stations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateFleetOperatorStationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes fleet operator station
         * @param {number} id The operator identifier.
         * @param {string} stationGuid The area guid
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsStationGuidDelete: async (id: number, stationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsStationGuidDelete', 'id', id)
            // verify required parameter 'stationGuid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsStationGuidDelete', 'stationGuid', stationGuid)
            const localVarPath = `/api/v1/admin/operators/{id}/stations/{stationGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"stationGuid"}}`, encodeURIComponent(String(stationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get station detail
         * @param {number} id The operator identifier.
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsStationGuidGet: async (id: number, stationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsStationGuidGet', 'id', id)
            // verify required parameter 'stationGuid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsStationGuidGet', 'stationGuid', stationGuid)
            const localVarPath = `/api/v1/admin/operators/{id}/stations/{stationGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"stationGuid"}}`, encodeURIComponent(String(stationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates fleet operator station
         * @param {number} id The operator identifier.
         * @param {string} stationGuid The area guid
         * @param {AdminUpdateFleetOperatorStationCommand} [adminUpdateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsStationGuidPut: async (id: number, stationGuid: string, adminUpdateFleetOperatorStationCommand?: AdminUpdateFleetOperatorStationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsStationGuidPut', 'id', id)
            // verify required parameter 'stationGuid' is not null or undefined
            assertParamExists('apiV1AdminOperatorsIdStationsStationGuidPut', 'stationGuid', stationGuid)
            const localVarPath = `/api/v1/admin/operators/{id}/stations/{stationGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"stationGuid"}}`, encodeURIComponent(String(stationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateFleetOperatorStationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new operator.
         * @param {OperatorCreateCommand} [operatorCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsPost: async (operatorCreateCommand?: OperatorCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/operators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorAdminApi - functional programming interface
 * @export
 */
export const OperatorAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatorAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of operators.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isApproved] 
         * @param {string} [search] 
         * @param {CountryCode} [countryCode] 
         * @param {boolean} [isActive] 
         * @param {CampiriProgramType} [programType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGet(pageSize: number, pageNumber: number, isApproved?: boolean, search?: string, countryCode?: CountryCode, isActive?: boolean, programType?: CampiriProgramType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGet(pageSize, pageNumber, isApproved, search, countryCode, isActive, programType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approves the operator
         * @param {string} guid The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidApprovePut(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidApprovePut(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s automatic payouts settings.
         * @param {string} guid The user identifier which is being updated.
         * @param {boolean} [enabled] Whether the payouts are enabled or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorBillingInformationCommand} [updateOperatorBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidBillingInformationPut(guid: string, updateOperatorBillingInformationCommand?: UpdateOperatorBillingInformationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidBillingInformationPut(guid, updateOperatorBillingInformationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidCicoEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidCicoEnabledPut(guid, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorContactInformationCommand} [updateOperatorContactInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidContactInformationPut(guid: string, updateOperatorContactInformationCommand?: UpdateOperatorContactInformationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidContactInformationPut(guid, updateOperatorContactInformationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorContactPersonCommand} [updateOperatorContactPersonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidContactPersonPut(guid: string, updateOperatorContactPersonCommand?: UpdateOperatorContactPersonCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidContactPersonPut(guid, updateOperatorContactPersonCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorDepositCommand} [updateOperatorDepositCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidDepositPut(guid: string, updateOperatorDepositCommand?: UpdateOperatorDepositCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidDepositPut(guid, updateOperatorDepositCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorInsuranceCommand} [updateOperatorInsuranceCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidInsurancePut(guid: string, updateOperatorInsuranceCommand?: UpdateOperatorInsuranceCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidInsurancePut(guid, updateOperatorInsuranceCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s IsActive settings.
         * @param {string} guid The user identifier which is being updated.
         * @param {boolean} [enabled] Whether the opereator is active or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidIsActivePut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidIsActivePut(guid, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidKycEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidKycEnabledPut(guid, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated result of operator\'s listings.
         * @param {string} guid 
         * @param {number} pageSize Defines how many results will be returned.
         * @param {number} pageNumber Defines how many results will be skipped.
         * @param {string} [search] Fulltext search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidListingsGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingsByOperatorQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidListingsGet(guid, pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets stats about operator\'s listings.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidListingsStatsGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorListingStatsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidListingsStatsGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the operators event logs.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidLogsGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidLogsGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidMessagingEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidMessagingEnabledPut(guid, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorProgramCommand} [updateOperatorProgramCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidProgramPut(guid: string, updateOperatorProgramCommand?: UpdateOperatorProgramCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidProgramPut(guid, updateOperatorProgramCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsGuidTermsIncludedPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsGuidTermsIncludedPut(guid, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the operator\'s info.
         * @param {number} id The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAddressGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorAddressByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAddressGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {number} id The user identifier which is being updated.
         * @param {OperatorAddressUpdateCommand} [operatorAddressUpdateCommand] The update operator command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAddressPut(id: number, operatorAddressUpdateCommand?: OperatorAddressUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAddressPut(id, operatorAddressUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets allowed to add addons to bookings.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowAddingAddonsPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowAddingAddonsPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets allowed to create offers property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowCreatingOffersPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowCreatingOffersPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets allowed to manage bookings property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowManagingBookingsPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowManagingBookingsPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets allowed to manage bookings custom items.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets allowed to manage damages and deposit property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets allowed to modify requests property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowModifyRequestsPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowModifyRequestsPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdContractsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OperatorDocumentsAllQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdContractsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdContractsGuidGet(id: number, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdContractsGuidGet(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FleetOperatorDocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdContractsPost(id: number, documentType?: FleetOperatorDocumentType, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdContractsPost(id, documentType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disables automatic synchronization of the operator.
         * @param {number} id The operator unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdDisableSynchronizationPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdDisableSynchronizationPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables automatic synchronization of the operator.
         * @param {number} id The operator unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdEnableSynchronizationPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdEnableSynchronizationPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the operator\'s info.
         * @param {number} id The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets insurance commission splitting for the operator.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of operator members
         * @param {number} id 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [searchRole] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdMembersGet(id: number, pageSize: number, pageNumber: number, searchRole?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOperatorMembersQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdMembersGet(id, pageSize, pageNumber, searchRole, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdMembersGuidDelete(id: number, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdMembersGuidDelete(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdMembersGuidGet(id: number, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorMemberQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdMembersGuidGet(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {OperatorMemberUpdateCommand} [operatorMemberUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdMembersGuidPut(id: number, guid: string, operatorMemberUpdateCommand?: OperatorMemberUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdMembersGuidPut(id, guid, operatorMemberUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds new member to operator account
         * @param {number} id 
         * @param {OperatorMemberCreateCommand} [operatorMemberCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdMembersPost(id: number, operatorMemberCreateCommand?: OperatorMemberCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdMembersPost(id, operatorMemberCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the operator\'s name.
         * @param {number} id The user identifier which is being updated.
         * @param {OperatorNameUpdateCommand} [operatorNameUpdateCommand] The update operator&#x60;s name command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdNamePut(id: number, operatorNameUpdateCommand?: OperatorNameUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdNamePut(id, operatorNameUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of active rental policies for specified fleet operator account.
         * @param {number} id The Account GUID identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdRentalPoliciesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFleetOperatorPoliciesQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdRentalPoliciesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the fleet operator rental policy.
         * @param {number} id The operator identifier.
         * @param {SetFleetOperatorRentalPolicyCommand} [setFleetOperatorRentalPolicyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdRentalPoliciesPut(id: number, setFleetOperatorRentalPolicyCommand?: SetFleetOperatorRentalPolicyCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdRentalPoliciesPut(id, setFleetOperatorRentalPolicyCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unsets the fleet operator rental policy.
         * @param {number} id The operator identifier.
         * @param {OperatorPolicyType} type The rental policy Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id: number, type: OperatorPolicyType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} id The operator identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdStationsGet(id: number, pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdStationsGet(id, pageSize, pageNumber, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new fleet operator station
         * @param {number} id The operator identifier.
         * @param {AdminCreateFleetOperatorStationCommand} [adminCreateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdStationsPost(id: number, adminCreateFleetOperatorStationCommand?: AdminCreateFleetOperatorStationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdStationsPost(id, adminCreateFleetOperatorStationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes fleet operator station
         * @param {number} id The operator identifier.
         * @param {string} stationGuid The area guid
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdStationsStationGuidDelete(id: number, stationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdStationsStationGuidDelete(id, stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get station detail
         * @param {number} id The operator identifier.
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdStationsStationGuidGet(id: number, stationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FleetOperatorStationDetailQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdStationsStationGuidGet(id, stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates fleet operator station
         * @param {number} id The operator identifier.
         * @param {string} stationGuid The area guid
         * @param {AdminUpdateFleetOperatorStationCommand} [adminUpdateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsIdStationsStationGuidPut(id: number, stationGuid: string, adminUpdateFleetOperatorStationCommand?: AdminUpdateFleetOperatorStationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsIdStationsStationGuidPut(id, stationGuid, adminUpdateFleetOperatorStationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new operator.
         * @param {OperatorCreateCommand} [operatorCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminOperatorsPost(operatorCreateCommand?: OperatorCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminOperatorsPost(operatorCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatorAdminApi - factory interface
 * @export
 */
export const OperatorAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatorAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of operators.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isApproved] 
         * @param {string} [search] 
         * @param {CountryCode} [countryCode] 
         * @param {boolean} [isActive] 
         * @param {CampiriProgramType} [programType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGet(pageSize: number, pageNumber: number, isApproved?: boolean, search?: string, countryCode?: CountryCode, isActive?: boolean, programType?: CampiriProgramType, options?: any): AxiosPromise<OperatorsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminOperatorsGet(pageSize, pageNumber, isApproved, search, countryCode, isActive, programType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approves the operator
         * @param {string} guid The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidApprovePut(guid: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminOperatorsGuidApprovePut(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s automatic payouts settings.
         * @param {string} guid The user identifier which is being updated.
         * @param {boolean} [enabled] Whether the payouts are enabled or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid: string, enabled?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorBillingInformationCommand} [updateOperatorBillingInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidBillingInformationPut(guid: string, updateOperatorBillingInformationCommand?: UpdateOperatorBillingInformationCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidBillingInformationPut(guid, updateOperatorBillingInformationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidCicoEnabledPut(guid: string, enabled?: boolean, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidCicoEnabledPut(guid, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorContactInformationCommand} [updateOperatorContactInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidContactInformationPut(guid: string, updateOperatorContactInformationCommand?: UpdateOperatorContactInformationCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidContactInformationPut(guid, updateOperatorContactInformationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorContactPersonCommand} [updateOperatorContactPersonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidContactPersonPut(guid: string, updateOperatorContactPersonCommand?: UpdateOperatorContactPersonCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidContactPersonPut(guid, updateOperatorContactPersonCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorDepositCommand} [updateOperatorDepositCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidDepositPut(guid: string, updateOperatorDepositCommand?: UpdateOperatorDepositCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidDepositPut(guid, updateOperatorDepositCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorInsuranceCommand} [updateOperatorInsuranceCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidInsurancePut(guid: string, updateOperatorInsuranceCommand?: UpdateOperatorInsuranceCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidInsurancePut(guid, updateOperatorInsuranceCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s IsActive settings.
         * @param {string} guid The user identifier which is being updated.
         * @param {boolean} [enabled] Whether the opereator is active or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidIsActivePut(guid: string, enabled?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsGuidIsActivePut(guid, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidKycEnabledPut(guid: string, enabled?: boolean, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidKycEnabledPut(guid, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated result of operator\'s listings.
         * @param {string} guid 
         * @param {number} pageSize Defines how many results will be returned.
         * @param {number} pageNumber Defines how many results will be skipped.
         * @param {string} [search] Fulltext search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidListingsGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ListingsByOperatorQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminOperatorsGuidListingsGet(guid, pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets stats about operator\'s listings.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidListingsStatsGet(guid: string, options?: any): AxiosPromise<OperatorListingStatsResult> {
            return localVarFp.apiV1AdminOperatorsGuidListingsStatsGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the operators event logs.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidLogsGet(guid: string, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidLogsGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidMessagingEnabledPut(guid: string, enabled?: boolean, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidMessagingEnabledPut(guid, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {UpdateOperatorProgramCommand} [updateOperatorProgramCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidProgramPut(guid: string, updateOperatorProgramCommand?: UpdateOperatorProgramCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidProgramPut(guid, updateOperatorProgramCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {string} guid 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsGuidTermsIncludedPut(guid: string, enabled?: boolean, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsGuidTermsIncludedPut(guid, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the operator\'s info.
         * @param {number} id The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAddressGet(id: number, options?: any): AxiosPromise<OperatorAddressByIdQueryResult> {
            return localVarFp.apiV1AdminOperatorsIdAddressGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s info.
         * @param {number} id The user identifier which is being updated.
         * @param {OperatorAddressUpdateCommand} [operatorAddressUpdateCommand] The update operator command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAddressPut(id: number, operatorAddressUpdateCommand?: OperatorAddressUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsIdAddressPut(id, operatorAddressUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets allowed to add addons to bookings.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowAddingAddonsPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowAddingAddonsPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets allowed to create offers property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowCreatingOffersPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowCreatingOffersPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets allowed to manage bookings property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowManagingBookingsPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowManagingBookingsPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets allowed to manage bookings custom items.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets allowed to manage damages and deposit property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets allowed to modify requests property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowModifyRequestsPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowModifyRequestsPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdContractsGet(id: number, options?: any): AxiosPromise<Array<OperatorDocumentsAllQueryResult>> {
            return localVarFp.apiV1AdminOperatorsIdContractsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdContractsGuidGet(id: number, guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdContractsGuidGet(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FleetOperatorDocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdContractsPost(id: number, documentType?: FleetOperatorDocumentType, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdContractsPost(id, documentType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disables automatic synchronization of the operator.
         * @param {number} id The operator unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdDisableSynchronizationPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdDisableSynchronizationPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables automatic synchronization of the operator.
         * @param {number} id The operator unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdEnableSynchronizationPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdEnableSynchronizationPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the operator\'s info.
         * @param {number} id The operator identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdGet(id: number, options?: any): AxiosPromise<OperatorByIdQueryResult> {
            return localVarFp.apiV1AdminOperatorsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets insurance commission splitting for the operator.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of operator members
         * @param {number} id 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [searchRole] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGet(id: number, pageSize: number, pageNumber: number, searchRole?: string, search?: string, options?: any): AxiosPromise<ListOperatorMembersQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminOperatorsIdMembersGet(id, pageSize, pageNumber, searchRole, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGuidDelete(id: number, guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdMembersGuidDelete(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGuidGet(id: number, guid: string, options?: any): AxiosPromise<GetOperatorMemberQueryResult> {
            return localVarFp.apiV1AdminOperatorsIdMembersGuidGet(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} guid 
         * @param {OperatorMemberUpdateCommand} [operatorMemberUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersGuidPut(id: number, guid: string, operatorMemberUpdateCommand?: OperatorMemberUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdMembersGuidPut(id, guid, operatorMemberUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds new member to operator account
         * @param {number} id 
         * @param {OperatorMemberCreateCommand} [operatorMemberCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdMembersPost(id: number, operatorMemberCreateCommand?: OperatorMemberCreateCommand, options?: any): AxiosPromise<GuidResultPaginatedResult> {
            return localVarFp.apiV1AdminOperatorsIdMembersPost(id, operatorMemberCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the operator\'s name.
         * @param {number} id The user identifier which is being updated.
         * @param {OperatorNameUpdateCommand} [operatorNameUpdateCommand] The update operator&#x60;s name command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdNamePut(id: number, operatorNameUpdateCommand?: OperatorNameUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsIdNamePut(id, operatorNameUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of active rental policies for specified fleet operator account.
         * @param {number} id The Account GUID identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdRentalPoliciesGet(id: number, options?: any): AxiosPromise<GetFleetOperatorPoliciesQueryResultListResult> {
            return localVarFp.apiV1AdminOperatorsIdRentalPoliciesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the fleet operator rental policy.
         * @param {number} id The operator identifier.
         * @param {SetFleetOperatorRentalPolicyCommand} [setFleetOperatorRentalPolicyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdRentalPoliciesPut(id: number, setFleetOperatorRentalPolicyCommand?: SetFleetOperatorRentalPolicyCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdRentalPoliciesPut(id, setFleetOperatorRentalPolicyCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unsets the fleet operator rental policy.
         * @param {number} id The operator identifier.
         * @param {OperatorPolicyType} type The rental policy Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id: number, type: OperatorPolicyType, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} id The operator identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsGet(id: number, pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: any): AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminOperatorsIdStationsGet(id, pageSize, pageNumber, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new fleet operator station
         * @param {number} id The operator identifier.
         * @param {AdminCreateFleetOperatorStationCommand} [adminCreateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsPost(id: number, adminCreateFleetOperatorStationCommand?: AdminCreateFleetOperatorStationCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminOperatorsIdStationsPost(id, adminCreateFleetOperatorStationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes fleet operator station
         * @param {number} id The operator identifier.
         * @param {string} stationGuid The area guid
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsStationGuidDelete(id: number, stationGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdStationsStationGuidDelete(id, stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get station detail
         * @param {number} id The operator identifier.
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsStationGuidGet(id: number, stationGuid: string, options?: any): AxiosPromise<FleetOperatorStationDetailQueryResult> {
            return localVarFp.apiV1AdminOperatorsIdStationsStationGuidGet(id, stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates fleet operator station
         * @param {number} id The operator identifier.
         * @param {string} stationGuid The area guid
         * @param {AdminUpdateFleetOperatorStationCommand} [adminUpdateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsIdStationsStationGuidPut(id: number, stationGuid: string, adminUpdateFleetOperatorStationCommand?: AdminUpdateFleetOperatorStationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminOperatorsIdStationsStationGuidPut(id, stationGuid, adminUpdateFleetOperatorStationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new operator.
         * @param {OperatorCreateCommand} [operatorCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminOperatorsPost(operatorCreateCommand?: OperatorCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminOperatorsPost(operatorCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatorAdminApi - interface
 * @export
 * @interface OperatorAdminApi
 */
export interface OperatorAdminApiInterface {
    /**
     * 
     * @summary Gets a paginated list of operators.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {boolean} [isApproved] 
     * @param {string} [search] 
     * @param {CountryCode} [countryCode] 
     * @param {boolean} [isActive] 
     * @param {CampiriProgramType} [programType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGet(pageSize: number, pageNumber: number, isApproved?: boolean, search?: string, countryCode?: CountryCode, isActive?: boolean, programType?: CampiriProgramType, options?: AxiosRequestConfig): AxiosPromise<OperatorsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Approves the operator
     * @param {string} guid The operator identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidApprovePut(guid: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Updates the operator\'s automatic payouts settings.
     * @param {string} guid The user identifier which is being updated.
     * @param {boolean} [enabled] Whether the payouts are enabled or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorBillingInformationCommand} [updateOperatorBillingInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidBillingInformationPut(guid: string, updateOperatorBillingInformationCommand?: UpdateOperatorBillingInformationCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidCicoEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorContactInformationCommand} [updateOperatorContactInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidContactInformationPut(guid: string, updateOperatorContactInformationCommand?: UpdateOperatorContactInformationCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorContactPersonCommand} [updateOperatorContactPersonCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidContactPersonPut(guid: string, updateOperatorContactPersonCommand?: UpdateOperatorContactPersonCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorDepositCommand} [updateOperatorDepositCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidDepositPut(guid: string, updateOperatorDepositCommand?: UpdateOperatorDepositCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorInsuranceCommand} [updateOperatorInsuranceCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidInsurancePut(guid: string, updateOperatorInsuranceCommand?: UpdateOperatorInsuranceCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s IsActive settings.
     * @param {string} guid The user identifier which is being updated.
     * @param {boolean} [enabled] Whether the opereator is active or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidIsActivePut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidKycEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets a paginated result of operator\'s listings.
     * @param {string} guid 
     * @param {number} pageSize Defines how many results will be returned.
     * @param {number} pageNumber Defines how many results will be skipped.
     * @param {string} [search] Fulltext search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidListingsGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ListingsByOperatorQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets stats about operator\'s listings.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidListingsStatsGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<OperatorListingStatsResult>;

    /**
     * 
     * @summary Gets the operators event logs.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidLogsGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidMessagingEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorProgramCommand} [updateOperatorProgramCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidProgramPut(guid: string, updateOperatorProgramCommand?: UpdateOperatorProgramCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsGuidTermsIncludedPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets the operator\'s info.
     * @param {number} id The operator identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAddressGet(id: number, options?: AxiosRequestConfig): AxiosPromise<OperatorAddressByIdQueryResult>;

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {number} id The user identifier which is being updated.
     * @param {OperatorAddressUpdateCommand} [operatorAddressUpdateCommand] The update operator command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAddressPut(id: number, operatorAddressUpdateCommand?: OperatorAddressUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Sets allowed to add addons to bookings.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowAddingAddonsPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets allowed to create offers property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowCreatingOffersPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets allowed to manage bookings property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowManagingBookingsPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets allowed to manage bookings custom items.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets allowed to manage damages and deposit property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets allowed to modify requests property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowModifyRequestsPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdContractsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Array<OperatorDocumentsAllQueryResult>>;

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdContractsGuidGet(id: number, guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {FleetOperatorDocumentType} [documentType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdContractsPost(id: number, documentType?: FleetOperatorDocumentType, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Disables automatic synchronization of the operator.
     * @param {number} id The operator unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdDisableSynchronizationPost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Enables automatic synchronization of the operator.
     * @param {number} id The operator unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdEnableSynchronizationPost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the operator\'s info.
     * @param {number} id The operator identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<OperatorByIdQueryResult>;

    /**
     * 
     * @summary Sets insurance commission splitting for the operator.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets list of operator members
     * @param {number} id 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [searchRole] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdMembersGet(id: number, pageSize: number, pageNumber: number, searchRole?: string, search?: string, options?: AxiosRequestConfig): AxiosPromise<ListOperatorMembersQueryResultPaginatedResult>;

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdMembersGuidDelete(id: number, guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdMembersGuidGet(id: number, guid: string, options?: AxiosRequestConfig): AxiosPromise<GetOperatorMemberQueryResult>;

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {OperatorMemberUpdateCommand} [operatorMemberUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdMembersGuidPut(id: number, guid: string, operatorMemberUpdateCommand?: OperatorMemberUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Adds new member to operator account
     * @param {number} id 
     * @param {OperatorMemberCreateCommand} [operatorMemberCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdMembersPost(id: number, operatorMemberCreateCommand?: OperatorMemberCreateCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResultPaginatedResult>;

    /**
     * 
     * @summary Updates the operator\'s name.
     * @param {number} id The user identifier which is being updated.
     * @param {OperatorNameUpdateCommand} [operatorNameUpdateCommand] The update operator&#x60;s name command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdNamePut(id: number, operatorNameUpdateCommand?: OperatorNameUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Returns a list of active rental policies for specified fleet operator account.
     * @param {number} id The Account GUID identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdRentalPoliciesGet(id: number, options?: AxiosRequestConfig): AxiosPromise<GetFleetOperatorPoliciesQueryResultListResult>;

    /**
     * 
     * @summary Sets the fleet operator rental policy.
     * @param {number} id The operator identifier.
     * @param {SetFleetOperatorRentalPolicyCommand} [setFleetOperatorRentalPolicyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdRentalPoliciesPut(id: number, setFleetOperatorRentalPolicyCommand?: SetFleetOperatorRentalPolicyCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Unsets the fleet operator rental policy.
     * @param {number} id The operator identifier.
     * @param {OperatorPolicyType} type The rental policy Type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id: number, type: OperatorPolicyType, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get stations of fleet operator
     * @param {number} id The operator identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {FleetOperatorStationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdStationsGet(id: number, pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig): AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates new fleet operator station
     * @param {number} id The operator identifier.
     * @param {AdminCreateFleetOperatorStationCommand} [adminCreateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdStationsPost(id: number, adminCreateFleetOperatorStationCommand?: AdminCreateFleetOperatorStationCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Deletes fleet operator station
     * @param {number} id The operator identifier.
     * @param {string} stationGuid The area guid
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdStationsStationGuidDelete(id: number, stationGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get station detail
     * @param {number} id The operator identifier.
     * @param {string} stationGuid Station identifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdStationsStationGuidGet(id: number, stationGuid: string, options?: AxiosRequestConfig): AxiosPromise<FleetOperatorStationDetailQueryResult>;

    /**
     * 
     * @summary Updates fleet operator station
     * @param {number} id The operator identifier.
     * @param {string} stationGuid The area guid
     * @param {AdminUpdateFleetOperatorStationCommand} [adminUpdateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsIdStationsStationGuidPut(id: number, stationGuid: string, adminUpdateFleetOperatorStationCommand?: AdminUpdateFleetOperatorStationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates new operator.
     * @param {OperatorCreateCommand} [operatorCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApiInterface
     */
    apiV1AdminOperatorsPost(operatorCreateCommand?: OperatorCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * OperatorAdminApi - object-oriented interface
 * @export
 * @class OperatorAdminApi
 * @extends {BaseAPI}
 */
export class OperatorAdminApi extends BaseAPI implements OperatorAdminApiInterface {
    /**
     * 
     * @summary Gets a paginated list of operators.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {boolean} [isApproved] 
     * @param {string} [search] 
     * @param {CountryCode} [countryCode] 
     * @param {boolean} [isActive] 
     * @param {CampiriProgramType} [programType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGet(pageSize: number, pageNumber: number, isApproved?: boolean, search?: string, countryCode?: CountryCode, isActive?: boolean, programType?: CampiriProgramType, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGet(pageSize, pageNumber, isApproved, search, countryCode, isActive, programType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approves the operator
     * @param {string} guid The operator identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidApprovePut(guid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidApprovePut(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s automatic payouts settings.
     * @param {string} guid The user identifier which is being updated.
     * @param {boolean} [enabled] Whether the payouts are enabled or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidAutomaticPayoutsPut(guid, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorBillingInformationCommand} [updateOperatorBillingInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidBillingInformationPut(guid: string, updateOperatorBillingInformationCommand?: UpdateOperatorBillingInformationCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidBillingInformationPut(guid, updateOperatorBillingInformationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidCicoEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidCicoEnabledPut(guid, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorContactInformationCommand} [updateOperatorContactInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidContactInformationPut(guid: string, updateOperatorContactInformationCommand?: UpdateOperatorContactInformationCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidContactInformationPut(guid, updateOperatorContactInformationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorContactPersonCommand} [updateOperatorContactPersonCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidContactPersonPut(guid: string, updateOperatorContactPersonCommand?: UpdateOperatorContactPersonCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidContactPersonPut(guid, updateOperatorContactPersonCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorDepositCommand} [updateOperatorDepositCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidDepositPut(guid: string, updateOperatorDepositCommand?: UpdateOperatorDepositCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidDepositPut(guid, updateOperatorDepositCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorInsuranceCommand} [updateOperatorInsuranceCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidInsurancePut(guid: string, updateOperatorInsuranceCommand?: UpdateOperatorInsuranceCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidInsurancePut(guid, updateOperatorInsuranceCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s IsActive settings.
     * @param {string} guid The user identifier which is being updated.
     * @param {boolean} [enabled] Whether the opereator is active or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidIsActivePut(guid: string, enabled?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidIsActivePut(guid, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidKycEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidKycEnabledPut(guid, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated result of operator\'s listings.
     * @param {string} guid 
     * @param {number} pageSize Defines how many results will be returned.
     * @param {number} pageNumber Defines how many results will be skipped.
     * @param {string} [search] Fulltext search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidListingsGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidListingsGet(guid, pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets stats about operator\'s listings.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidListingsStatsGet(guid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidListingsStatsGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the operators event logs.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidLogsGet(guid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidLogsGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidMessagingEnabledPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidMessagingEnabledPut(guid, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {UpdateOperatorProgramCommand} [updateOperatorProgramCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidProgramPut(guid: string, updateOperatorProgramCommand?: UpdateOperatorProgramCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidProgramPut(guid, updateOperatorProgramCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {string} guid 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsGuidTermsIncludedPut(guid: string, enabled?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsGuidTermsIncludedPut(guid, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the operator\'s info.
     * @param {number} id The operator identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAddressGet(id: number, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAddressGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s info.
     * @param {number} id The user identifier which is being updated.
     * @param {OperatorAddressUpdateCommand} [operatorAddressUpdateCommand] The update operator command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAddressPut(id: number, operatorAddressUpdateCommand?: OperatorAddressUpdateCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAddressPut(id, operatorAddressUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets allowed to add addons to bookings.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowAddingAddonsPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowAddingAddonsPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets allowed to create offers property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowCreatingOffersPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowCreatingOffersPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets allowed to manage bookings property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowManagingBookingsPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowManagingBookingsPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets allowed to manage bookings custom items.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowManagingCustomItemsPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets allowed to manage damages and deposit property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowManagingDamagesAndDepositPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets allowed to modify requests property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowModifyRequestsPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowModifyRequestsPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdAllowRegisterPaymentInBookingSummaryPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdContractsGet(id: number, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdContractsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdContractsGuidGet(id: number, guid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdContractsGuidGet(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FleetOperatorDocumentType} [documentType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdContractsPost(id: number, documentType?: FleetOperatorDocumentType, file?: File, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdContractsPost(id, documentType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disables automatic synchronization of the operator.
     * @param {number} id The operator unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdDisableSynchronizationPost(id: number, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdDisableSynchronizationPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables automatic synchronization of the operator.
     * @param {number} id The operator unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdEnableSynchronizationPost(id: number, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdEnableSynchronizationPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the operator\'s info.
     * @param {number} id The operator identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdGet(id: number, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets insurance commission splitting for the operator.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdInsuranceSplittingEnabledPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of operator members
     * @param {number} id 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [searchRole] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdMembersGet(id: number, pageSize: number, pageNumber: number, searchRole?: string, search?: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdMembersGet(id, pageSize, pageNumber, searchRole, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdMembersGuidDelete(id: number, guid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdMembersGuidDelete(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdMembersGuidGet(id: number, guid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdMembersGuidGet(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} guid 
     * @param {OperatorMemberUpdateCommand} [operatorMemberUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdMembersGuidPut(id: number, guid: string, operatorMemberUpdateCommand?: OperatorMemberUpdateCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdMembersGuidPut(id, guid, operatorMemberUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds new member to operator account
     * @param {number} id 
     * @param {OperatorMemberCreateCommand} [operatorMemberCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdMembersPost(id: number, operatorMemberCreateCommand?: OperatorMemberCreateCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdMembersPost(id, operatorMemberCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the operator\'s name.
     * @param {number} id The user identifier which is being updated.
     * @param {OperatorNameUpdateCommand} [operatorNameUpdateCommand] The update operator&#x60;s name command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdNamePut(id: number, operatorNameUpdateCommand?: OperatorNameUpdateCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdNamePut(id, operatorNameUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of active rental policies for specified fleet operator account.
     * @param {number} id The Account GUID identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdRentalPoliciesGet(id: number, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdRentalPoliciesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the fleet operator rental policy.
     * @param {number} id The operator identifier.
     * @param {SetFleetOperatorRentalPolicyCommand} [setFleetOperatorRentalPolicyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdRentalPoliciesPut(id: number, setFleetOperatorRentalPolicyCommand?: SetFleetOperatorRentalPolicyCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdRentalPoliciesPut(id, setFleetOperatorRentalPolicyCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unsets the fleet operator rental policy.
     * @param {number} id The operator identifier.
     * @param {OperatorPolicyType} type The rental policy Type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id: number, type: OperatorPolicyType, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdRentalPoliciesTypeDelete(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stations of fleet operator
     * @param {number} id The operator identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {FleetOperatorStationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdStationsGet(id: number, pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdStationsGet(id, pageSize, pageNumber, search, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new fleet operator station
     * @param {number} id The operator identifier.
     * @param {AdminCreateFleetOperatorStationCommand} [adminCreateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdStationsPost(id: number, adminCreateFleetOperatorStationCommand?: AdminCreateFleetOperatorStationCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdStationsPost(id, adminCreateFleetOperatorStationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes fleet operator station
     * @param {number} id The operator identifier.
     * @param {string} stationGuid The area guid
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdStationsStationGuidDelete(id: number, stationGuid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdStationsStationGuidDelete(id, stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get station detail
     * @param {number} id The operator identifier.
     * @param {string} stationGuid Station identifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdStationsStationGuidGet(id: number, stationGuid: string, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdStationsStationGuidGet(id, stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates fleet operator station
     * @param {number} id The operator identifier.
     * @param {string} stationGuid The area guid
     * @param {AdminUpdateFleetOperatorStationCommand} [adminUpdateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsIdStationsStationGuidPut(id: number, stationGuid: string, adminUpdateFleetOperatorStationCommand?: AdminUpdateFleetOperatorStationCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsIdStationsStationGuidPut(id, stationGuid, adminUpdateFleetOperatorStationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new operator.
     * @param {OperatorCreateCommand} [operatorCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorAdminApi
     */
    public apiV1AdminOperatorsPost(operatorCreateCommand?: OperatorCreateCommand, options?: AxiosRequestConfig) {
        return OperatorAdminApiFp(this.configuration).apiV1AdminOperatorsPost(operatorCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

