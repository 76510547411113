/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CalendarPriceListingByIdQueryResult } from '../models';
// @ts-ignore
import { CalendarPriceListingListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { CopyTermDiscountsCommand } from '../models';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ListingAvailableStationsQueryResult } from '../models';
// @ts-ignore
import { ListingAvailableVehiclesQueryResult } from '../models';
// @ts-ignore
import { ListingByIdQueryResult } from '../models';
// @ts-ignore
import { ListingCalendarPriceEditorDocumentsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListingCalendarPricesEditorQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListingPicturesSortCommand } from '../models';
// @ts-ignore
import { ListingRentalDefaultPriceUpdateCommand } from '../models';
// @ts-ignore
import { ListingStatus } from '../models';
// @ts-ignore
import { OperatorPartnerType } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { PriceChangeListingByIdQueryResult } from '../models';
// @ts-ignore
import { PriceChangeListingListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RentalPriceOverviewQueryResult } from '../models';
// @ts-ignore
import { TermDiscountByIdQueryResult } from '../models';
// @ts-ignore
import { TermDiscountListQueryResultListResult } from '../models';
// @ts-ignore
import { UnpublishedReasons } from '../models';
// @ts-ignore
import { VehicleListingsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { VehicleRentalCalendarPriceAddCommand } from '../models';
// @ts-ignore
import { VehicleRentalCalendarPriceUpdateCommand } from '../models';
// @ts-ignore
import { VehicleRentalListingAllocateCommand } from '../models';
// @ts-ignore
import { VehicleRentalListingCreateCommand } from '../models';
// @ts-ignore
import { VehicleRentalListingUpdateCommand } from '../models';
// @ts-ignore
import { VehicleRentalListingUpdateCommissionRateCommand } from '../models';
// @ts-ignore
import { VehicleRentalPriceChangeAddCommand } from '../models';
// @ts-ignore
import { VehicleRentalPriceChangeUpdateCommand } from '../models';
// @ts-ignore
import { VehicleRentalPriceChangesDeleteCommand } from '../models';
// @ts-ignore
import { VehicleRentalTermDiscountUpdateCommand } from '../models';
// @ts-ignore
import { VehicleType } from '../models';
/**
 * ListingAdminApi - axios parameter creator
 * @export
 */
export const ListingAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Allocates the unpublished rental listing.
         * @param {VehicleRentalListingAllocateCommand} [vehicleRentalListingAllocateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsAllocatePost: async (vehicleRentalListingAllocateCommand?: VehicleRentalListingAllocateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalListingAllocateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a listing available stations.
         * @param {number} [poolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsAvailableStationsGet: async (poolId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings/available-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copies calendar prices for next year for all listings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsCalendarPricesCopyToNextYearPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings/calendar-prices/copy-to-next-year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the listings.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [productGuid] 
         * @param {string} [operatorGuid] 
         * @param {Array<CountryCode>} [country] 
         * @param {Array<string>} [guids] 
         * @param {ListingStatus} [status] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGet: async (pageNumber?: number, pageSize?: number, search?: string, productGuid?: string, operatorGuid?: string, country?: Array<CountryCode>, guids?: Array<string>, status?: ListingStatus, stationGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (productGuid !== undefined) {
                localVarQueryParameter['ProductGuid'] = productGuid;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (country) {
                localVarQueryParameter['Country'] = country;
            }

            if (guids) {
                localVarQueryParameter['Guids'] = guids;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the listing picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGuidPicturesPictureGuidDelete: async (guid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminListingsGuidPicturesPictureGuidDelete', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/admin/listings/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the listing picture and returns the model.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGuidPicturesPost: async (guid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminListingsGuidPicturesPost', 'guid', guid)
            const localVarPath = `/api/v1/admin/listings/{guid}/pictures`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorts the listing pictures.
         * @param {string} guid 
         * @param {ListingPicturesSortCommand} [listingPicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGuidPicturesSortPost: async (guid: string, listingPicturesSortCommand?: ListingPicturesSortCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminListingsGuidPicturesSortPost', 'guid', guid)
            const localVarPath = `/api/v1/admin/listings/{guid}/pictures/sort`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingPicturesSortCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of available vehicles for listing at specific time frame.
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdAvailableVehiclesGet: async (id: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdAvailableVehiclesGet', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/available-vehicles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copies listing\'s calendar prices for next year.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesCopyToNextYearPut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesCopyToNextYearPut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/copy-to-next-year`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [filterFrom] 
         * @param {string} [filterUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesGet: async (id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['FilterFrom'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString() :
                    filterFrom;
            }

            if (filterUntil !== undefined) {
                localVarQueryParameter['FilterUntil'] = (filterUntil as any instanceof Date) ?
                    (filterUntil as any).toISOString() :
                    filterUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPost: async (id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPost', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalCalendarPriceAddCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing calendar price.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidGet: async (id: number, priceGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidGet', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidGet', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalCalendarPriceUpdateCommand} [vehicleRentalCalendarPriceUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidPut: async (id: number, priceGuid: string, vehicleRentalCalendarPriceUpdateCommand?: VehicleRentalCalendarPriceUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidPut', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidPut', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalCalendarPriceUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes listing\'s term discount.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} [quantity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete: async (id: number, priceGuid: string, quantity?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}/term-discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing term discounts.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet: async (id: number, priceGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}/term-discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the listing term discount by id, priceId, and quantity.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut: async (id: number, priceGuid: string, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}/term-discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalTermDiscountUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing term discount.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet: async (id: number, priceGuid: string, quantity: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet', 'priceGuid', priceGuid)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet', 'quantity', quantity)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}/term-discounts/{quantity}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)))
                .replace(`{${"quantity"}}`, encodeURIComponent(String(quantity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the listing term discount by id, priceId, and quantity.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} quantity The days quantity.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut: async (id: number, priceGuid: string, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut', 'priceGuid', priceGuid)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut', 'quantity', quantity)
            const localVarPath = `/api/v1/admin/listings/{id}/calendar-prices/{priceGuid}/term-discounts/{quantity}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)))
                .replace(`{${"quantity"}}`, encodeURIComponent(String(quantity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalTermDiscountUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a listing commission rate.
         * @param {number} id 
         * @param {VehicleRentalListingUpdateCommissionRateCommand} [vehicleRentalListingUpdateCommissionRateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCommissionRatePut: async (id: number, vehicleRentalListingUpdateCommissionRateCommand?: VehicleRentalListingUpdateCommissionRateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCommissionRatePut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/commission-rate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalListingUpdateCommissionRateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copies base price term discounts into calendar pricing term discounts
         * @param {number} id The listing identifier.
         * @param {CopyTermDiscountsCommand} [copyTermDiscountsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCopyDiscountsPost: async (id: number, copyTermDiscountsCommand?: CopyTermDiscountsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdCopyDiscountsPost', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/copy-discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyTermDiscountsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft deletes the unpublished rental listing.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdDelete', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a listing by its identifier.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a listing price change.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalPriceChangesDeleteCommand} [vehicleRentalPriceChangesDeleteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesDelete: async (id: number, vehicleRentalPriceChangesDeleteCommand?: VehicleRentalPriceChangesDeleteCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesDelete', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/price-changes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalPriceChangesDeleteCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {string} [dateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesGet: async (id: number, pageSize: number, pageNumber: number, search?: string, dateFrom?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/listings/{id}/price-changes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a listing price change.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalPriceChangeAddCommand} [vehicleRentalPriceChangeAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPost: async (id: number, vehicleRentalPriceChangeAddCommand?: VehicleRentalPriceChangeAddCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPost', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/price-changes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalPriceChangeAddCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPriceGuidDelete: async (id: number, priceGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPriceGuidDelete', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPriceGuidDelete', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/price-changes/{priceGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPriceGuidGet: async (id: number, priceGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPriceGuidGet', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPriceGuidGet', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/price-changes/{priceGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalPriceChangeUpdateCommand} [vehicleRentalPriceChangeUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPriceGuidPut: async (id: number, priceGuid: string, vehicleRentalPriceChangeUpdateCommand?: VehicleRentalPriceChangeUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPriceGuidPut', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPriceChangesPriceGuidPut', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/price-changes/{priceGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalPriceChangeUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the default listing\'s pricing.
         * @param {number} id The listing identifier.
         * @param {ListingRentalDefaultPriceUpdateCommand} [listingRentalDefaultPriceUpdateCommand] The command data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPricingsDefaultPut: async (id: number, listingRentalDefaultPriceUpdateCommand?: ListingRentalDefaultPriceUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPricingsDefaultPut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/pricings/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingRentalDefaultPriceUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an array of pricings linked to vehicle rental listing.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPricingsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPricingsGet', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/pricings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new pricing for listing.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The rental price guid identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPricingsPriceGuidDelete: async (id: number, priceGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPricingsPriceGuidDelete', 'id', id)
            // verify required parameter 'priceGuid' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPricingsPriceGuidDelete', 'priceGuid', priceGuid)
            const localVarPath = `/api/v1/admin/listings/{id}/pricings/{priceGuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"priceGuid"}}`, encodeURIComponent(String(priceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publishes the vehicle listing.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPublishPut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPublishPut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a listing.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalListingUpdateCommand} [vehicleRentalListingUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPut: async (id: number, vehicleRentalListingUpdateCommand?: VehicleRentalListingUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalListingUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing term discounts.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsGet', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/term-discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the listing term discount by id and quantity.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsPut: async (id: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsPut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/term-discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalTermDiscountUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes listing\'s term discount.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsQuantityDelete: async (id: number, quantity: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsQuantityDelete', 'id', id)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsQuantityDelete', 'quantity', quantity)
            const localVarPath = `/api/v1/admin/listings/{id}/term-discounts/{quantity}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"quantity"}}`, encodeURIComponent(String(quantity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets listing term discount.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsQuantityGet: async (id: number, quantity: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsQuantityGet', 'id', id)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsQuantityGet', 'quantity', quantity)
            const localVarPath = `/api/v1/admin/listings/{id}/term-discounts/{quantity}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"quantity"}}`, encodeURIComponent(String(quantity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the listing term discount by id and quantity.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsQuantityPut: async (id: number, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsQuantityPut', 'id', id)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('apiV1AdminListingsIdTermDiscountsQuantityPut', 'quantity', quantity)
            const localVarPath = `/api/v1/admin/listings/{id}/term-discounts/{quantity}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"quantity"}}`, encodeURIComponent(String(quantity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalTermDiscountUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unpublishes the vehicle listing.
         * @param {number} id The listing identifier.
         * @param {UnpublishedReasons} [unpublishedReasons] Unpublished reason for the listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdUnpublishPut: async (id: number, unpublishedReasons?: UnpublishedReasons, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminListingsIdUnpublishPut', 'id', id)
            const localVarPath = `/api/v1/admin/listings/{id}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (unpublishedReasons !== undefined) {
                localVarQueryParameter['unpublishedReasons'] = unpublishedReasons;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the unpublished rental listing.
         * @param {VehicleRentalListingCreateCommand} [vehicleRentalListingCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPost: async (vehicleRentalListingCreateCommand?: VehicleRentalListingCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalListingCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorDocumentsGet: async (pageSize: number, pageNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminListingsPriceEditorDocumentsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminListingsPriceEditorDocumentsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/listings/price-editor/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorDocumentsGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminListingsPriceEditorDocumentsGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/listings/price-editor/documents/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [search] 
         * @param {VehicleType} [vehicleType] 
         * @param {string} [productGuid] 
         * @param {string} [stationGuid] 
         * @param {CountryCode} [stationCountry] 
         * @param {string} [stationRegion] 
         * @param {string} [stationCity] 
         * @param {string} [operatorGuid] 
         * @param {OperatorPartnerType} [operatorType] 
         * @param {ListingStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorGet: async (pageSize: number, pageNumber: number, fromDate?: string, toDate?: string, search?: string, vehicleType?: VehicleType, productGuid?: string, stationGuid?: string, stationCountry?: CountryCode, stationRegion?: string, stationCity?: string, operatorGuid?: string, operatorType?: OperatorPartnerType, status?: ListingStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminListingsPriceEditorGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminListingsPriceEditorGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/listings/price-editor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['VehicleType'] = vehicleType;
            }

            if (productGuid !== undefined) {
                localVarQueryParameter['ProductGuid'] = productGuid;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (stationCountry !== undefined) {
                localVarQueryParameter['StationCountry'] = stationCountry;
            }

            if (stationRegion !== undefined) {
                localVarQueryParameter['StationRegion'] = stationRegion;
            }

            if (stationCity !== undefined) {
                localVarQueryParameter['StationCity'] = stationCity;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (operatorType !== undefined) {
                localVarQueryParameter['OperatorType'] = operatorType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorImportPut: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings/price-editor/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint is used to testing purposes only.
         * @param {string} [documentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorImportRunJobPut: async (documentGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings/price-editor/import/run-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentGuid !== undefined) {
                localVarQueryParameter['documentGuid'] = documentGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingAdminApi - functional programming interface
 * @export
 */
export const ListingAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Allocates the unpublished rental listing.
         * @param {VehicleRentalListingAllocateCommand} [vehicleRentalListingAllocateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand?: VehicleRentalListingAllocateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a listing available stations.
         * @param {number} [poolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsAvailableStationsGet(poolId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListingAvailableStationsQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsAvailableStationsGet(poolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Copies calendar prices for next year for all listings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsCalendarPricesCopyToNextYearPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsCalendarPricesCopyToNextYearPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the listings.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [productGuid] 
         * @param {string} [operatorGuid] 
         * @param {Array<CountryCode>} [country] 
         * @param {Array<string>} [guids] 
         * @param {ListingStatus} [status] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsGet(pageNumber?: number, pageSize?: number, search?: string, productGuid?: string, operatorGuid?: string, country?: Array<CountryCode>, guids?: Array<string>, status?: ListingStatus, stationGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleListingsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsGet(pageNumber, pageSize, search, productGuid, operatorGuid, country, guids, status, stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the listing picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsGuidPicturesPictureGuidDelete(guid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the listing picture and returns the model.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsGuidPicturesPost(guid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsGuidPicturesPost(guid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sorts the listing pictures.
         * @param {string} guid 
         * @param {ListingPicturesSortCommand} [listingPicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsGuidPicturesSortPost(guid: string, listingPicturesSortCommand?: ListingPicturesSortCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsGuidPicturesSortPost(guid, listingPicturesSortCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of available vehicles for listing at specific time frame.
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListingAvailableVehiclesQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdAvailableVehiclesGet(id, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Copies listing\'s calendar prices for next year.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [filterFrom] 
         * @param {string} [filterUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarPriceListingListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesGet(id, pageSize, pageNumber, filterFrom, filterUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPost(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPost(id, vehicleRentalCalendarPriceAddCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing calendar price.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidGet(id: number, priceGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarPriceListingByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidGet(id, priceGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalCalendarPriceUpdateCommand} [vehicleRentalCalendarPriceUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidPut(id: number, priceGuid: string, vehicleRentalCalendarPriceUpdateCommand?: VehicleRentalCalendarPriceUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidPut(id, priceGuid, vehicleRentalCalendarPriceUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes listing\'s term discount.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} [quantity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id: number, priceGuid: string, quantity?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id, priceGuid, quantity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing term discounts.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id: number, priceGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermDiscountListQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id, priceGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the listing term discount by id, priceId, and quantity.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id: number, priceGuid: string, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id, priceGuid, vehicleRentalTermDiscountUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing term discount.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id: number, priceGuid: string, quantity: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermDiscountByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id, priceGuid, quantity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the listing term discount by id, priceId, and quantity.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} quantity The days quantity.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id: number, priceGuid: string, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id, priceGuid, quantity, vehicleRentalTermDiscountUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a listing commission rate.
         * @param {number} id 
         * @param {VehicleRentalListingUpdateCommissionRateCommand} [vehicleRentalListingUpdateCommissionRateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCommissionRatePut(id: number, vehicleRentalListingUpdateCommissionRateCommand?: VehicleRentalListingUpdateCommissionRateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCommissionRatePut(id, vehicleRentalListingUpdateCommissionRateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Copies base price term discounts into calendar pricing term discounts
         * @param {number} id The listing identifier.
         * @param {CopyTermDiscountsCommand} [copyTermDiscountsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdCopyDiscountsPost(id: number, copyTermDiscountsCommand?: CopyTermDiscountsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdCopyDiscountsPost(id, copyTermDiscountsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft deletes the unpublished rental listing.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a listing by its identifier.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a listing price change.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalPriceChangesDeleteCommand} [vehicleRentalPriceChangesDeleteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPriceChangesDelete(id: number, vehicleRentalPriceChangesDeleteCommand?: VehicleRentalPriceChangesDeleteCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPriceChangesDelete(id, vehicleRentalPriceChangesDeleteCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {string} [dateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPriceChangesGet(id: number, pageSize: number, pageNumber: number, search?: string, dateFrom?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceChangeListingListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPriceChangesGet(id, pageSize, pageNumber, search, dateFrom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a listing price change.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalPriceChangeAddCommand} [vehicleRentalPriceChangeAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPriceChangesPost(id: number, vehicleRentalPriceChangeAddCommand?: VehicleRentalPriceChangeAddCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPriceChangesPost(id, vehicleRentalPriceChangeAddCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPriceChangesPriceGuidDelete(id: number, priceGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPriceChangesPriceGuidDelete(id, priceGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPriceChangesPriceGuidGet(id: number, priceGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceChangeListingByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPriceChangesPriceGuidGet(id, priceGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalPriceChangeUpdateCommand} [vehicleRentalPriceChangeUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPriceChangesPriceGuidPut(id: number, priceGuid: string, vehicleRentalPriceChangeUpdateCommand?: VehicleRentalPriceChangeUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPriceChangesPriceGuidPut(id, priceGuid, vehicleRentalPriceChangeUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the default listing\'s pricing.
         * @param {number} id The listing identifier.
         * @param {ListingRentalDefaultPriceUpdateCommand} [listingRentalDefaultPriceUpdateCommand] The command data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPricingsDefaultPut(id: number, listingRentalDefaultPriceUpdateCommand?: ListingRentalDefaultPriceUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPricingsDefaultPut(id, listingRentalDefaultPriceUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an array of pricings linked to vehicle rental listing.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPricingsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalPriceOverviewQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPricingsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new pricing for listing.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The rental price guid identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPricingsPriceGuidDelete(id: number, priceGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPricingsPriceGuidDelete(id, priceGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publishes the vehicle listing.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPublishPut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPublishPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a listing.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalListingUpdateCommand} [vehicleRentalListingUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdPut(id: number, vehicleRentalListingUpdateCommand?: VehicleRentalListingUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdPut(id, vehicleRentalListingUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing term discounts.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdTermDiscountsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermDiscountListQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdTermDiscountsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the listing term discount by id and quantity.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdTermDiscountsPut(id: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdTermDiscountsPut(id, vehicleRentalTermDiscountUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes listing\'s term discount.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdTermDiscountsQuantityDelete(id: number, quantity: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdTermDiscountsQuantityDelete(id, quantity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets listing term discount.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdTermDiscountsQuantityGet(id: number, quantity: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermDiscountByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdTermDiscountsQuantityGet(id, quantity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the listing term discount by id and quantity.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdTermDiscountsQuantityPut(id: number, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdTermDiscountsQuantityPut(id, quantity, vehicleRentalTermDiscountUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unpublishes the vehicle listing.
         * @param {number} id The listing identifier.
         * @param {UnpublishedReasons} [unpublishedReasons] Unpublished reason for the listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsIdUnpublishPut(id: number, unpublishedReasons?: UnpublishedReasons, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsIdUnpublishPut(id, unpublishedReasons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the unpublished rental listing.
         * @param {VehicleRentalListingCreateCommand} [vehicleRentalListingCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsPost(vehicleRentalListingCreateCommand?: VehicleRentalListingCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsPost(vehicleRentalListingCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsPriceEditorDocumentsGet(pageSize: number, pageNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingCalendarPriceEditorDocumentsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsPriceEditorDocumentsGet(pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsPriceEditorDocumentsGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsPriceEditorDocumentsGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [search] 
         * @param {VehicleType} [vehicleType] 
         * @param {string} [productGuid] 
         * @param {string} [stationGuid] 
         * @param {CountryCode} [stationCountry] 
         * @param {string} [stationRegion] 
         * @param {string} [stationCity] 
         * @param {string} [operatorGuid] 
         * @param {OperatorPartnerType} [operatorType] 
         * @param {ListingStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsPriceEditorGet(pageSize: number, pageNumber: number, fromDate?: string, toDate?: string, search?: string, vehicleType?: VehicleType, productGuid?: string, stationGuid?: string, stationCountry?: CountryCode, stationRegion?: string, stationCity?: string, operatorGuid?: string, operatorType?: OperatorPartnerType, status?: ListingStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingCalendarPricesEditorQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsPriceEditorGet(pageSize, pageNumber, fromDate, toDate, search, vehicleType, productGuid, stationGuid, stationCountry, stationRegion, stationCity, operatorGuid, operatorType, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsPriceEditorImportPut(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsPriceEditorImportPut(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint is used to testing purposes only.
         * @param {string} [documentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingAdminApi - factory interface
 * @export
 */
export const ListingAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Allocates the unpublished rental listing.
         * @param {VehicleRentalListingAllocateCommand} [vehicleRentalListingAllocateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand?: VehicleRentalListingAllocateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a listing available stations.
         * @param {number} [poolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsAvailableStationsGet(poolId?: number, options?: any): AxiosPromise<Array<ListingAvailableStationsQueryResult>> {
            return localVarFp.apiV1AdminListingsAvailableStationsGet(poolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copies calendar prices for next year for all listings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsCalendarPricesCopyToNextYearPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsCalendarPricesCopyToNextYearPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the listings.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [productGuid] 
         * @param {string} [operatorGuid] 
         * @param {Array<CountryCode>} [country] 
         * @param {Array<string>} [guids] 
         * @param {ListingStatus} [status] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGet(pageNumber?: number, pageSize?: number, search?: string, productGuid?: string, operatorGuid?: string, country?: Array<CountryCode>, guids?: Array<string>, status?: ListingStatus, stationGuid?: string, options?: any): AxiosPromise<VehicleListingsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminListingsGet(pageNumber, pageSize, search, productGuid, operatorGuid, country, guids, status, stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the listing picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminListingsGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the listing picture and returns the model.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGuidPicturesPost(guid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminListingsGuidPicturesPost(guid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorts the listing pictures.
         * @param {string} guid 
         * @param {ListingPicturesSortCommand} [listingPicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsGuidPicturesSortPost(guid: string, listingPicturesSortCommand?: ListingPicturesSortCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsGuidPicturesSortPost(guid, listingPicturesSortCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of available vehicles for listing at specific time frame.
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: any): AxiosPromise<Array<ListingAvailableVehiclesQueryResult>> {
            return localVarFp.apiV1AdminListingsIdAvailableVehiclesGet(id, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copies listing\'s calendar prices for next year.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [filterFrom] 
         * @param {string} [filterUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: any): AxiosPromise<CalendarPriceListingListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesGet(id, pageSize, pageNumber, filterFrom, filterUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPost(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPost(id, vehicleRentalCalendarPriceAddCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing calendar price.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidGet(id: number, priceGuid: string, options?: any): AxiosPromise<CalendarPriceListingByIdQueryResult> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidGet(id, priceGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalCalendarPriceUpdateCommand} [vehicleRentalCalendarPriceUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidPut(id: number, priceGuid: string, vehicleRentalCalendarPriceUpdateCommand?: VehicleRentalCalendarPriceUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidPut(id, priceGuid, vehicleRentalCalendarPriceUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes listing\'s term discount.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} [quantity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id: number, priceGuid: string, quantity?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id, priceGuid, quantity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing term discounts.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id: number, priceGuid: string, options?: any): AxiosPromise<TermDiscountListQueryResultListResult> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id, priceGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the listing term discount by id, priceId, and quantity.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id: number, priceGuid: string, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id, priceGuid, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing term discount.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id: number, priceGuid: string, quantity: number, options?: any): AxiosPromise<TermDiscountByIdQueryResult> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id, priceGuid, quantity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the listing term discount by id, priceId, and quantity.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {number} quantity The days quantity.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id: number, priceGuid: string, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id, priceGuid, quantity, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a listing commission rate.
         * @param {number} id 
         * @param {VehicleRentalListingUpdateCommissionRateCommand} [vehicleRentalListingUpdateCommissionRateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCommissionRatePut(id: number, vehicleRentalListingUpdateCommissionRateCommand?: VehicleRentalListingUpdateCommissionRateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdCommissionRatePut(id, vehicleRentalListingUpdateCommissionRateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copies base price term discounts into calendar pricing term discounts
         * @param {number} id The listing identifier.
         * @param {CopyTermDiscountsCommand} [copyTermDiscountsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdCopyDiscountsPost(id: number, copyTermDiscountsCommand?: CopyTermDiscountsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdCopyDiscountsPost(id, copyTermDiscountsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft deletes the unpublished rental listing.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a listing by its identifier.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdGet(id: number, options?: any): AxiosPromise<ListingByIdQueryResult> {
            return localVarFp.apiV1AdminListingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a listing price change.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalPriceChangesDeleteCommand} [vehicleRentalPriceChangesDeleteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesDelete(id: number, vehicleRentalPriceChangesDeleteCommand?: VehicleRentalPriceChangesDeleteCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsIdPriceChangesDelete(id, vehicleRentalPriceChangesDeleteCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {string} [dateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesGet(id: number, pageSize: number, pageNumber: number, search?: string, dateFrom?: string, options?: any): AxiosPromise<PriceChangeListingListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminListingsIdPriceChangesGet(id, pageSize, pageNumber, search, dateFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a listing price change.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalPriceChangeAddCommand} [vehicleRentalPriceChangeAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPost(id: number, vehicleRentalPriceChangeAddCommand?: VehicleRentalPriceChangeAddCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsIdPriceChangesPost(id, vehicleRentalPriceChangeAddCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPriceGuidDelete(id: number, priceGuid: string, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsIdPriceChangesPriceGuidDelete(id, priceGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPriceGuidGet(id: number, priceGuid: string, options?: any): AxiosPromise<PriceChangeListingByIdQueryResult> {
            return localVarFp.apiV1AdminListingsIdPriceChangesPriceGuidGet(id, priceGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a listing price change.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The price global identifier.
         * @param {VehicleRentalPriceChangeUpdateCommand} [vehicleRentalPriceChangeUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPriceChangesPriceGuidPut(id: number, priceGuid: string, vehicleRentalPriceChangeUpdateCommand?: VehicleRentalPriceChangeUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsIdPriceChangesPriceGuidPut(id, priceGuid, vehicleRentalPriceChangeUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the default listing\'s pricing.
         * @param {number} id The listing identifier.
         * @param {ListingRentalDefaultPriceUpdateCommand} [listingRentalDefaultPriceUpdateCommand] The command data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPricingsDefaultPut(id: number, listingRentalDefaultPriceUpdateCommand?: ListingRentalDefaultPriceUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdPricingsDefaultPut(id, listingRentalDefaultPriceUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an array of pricings linked to vehicle rental listing.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPricingsGet(id: number, options?: any): AxiosPromise<RentalPriceOverviewQueryResult> {
            return localVarFp.apiV1AdminListingsIdPricingsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new pricing for listing.
         * @param {number} id The listing identifier.
         * @param {string} priceGuid The rental price guid identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPricingsPriceGuidDelete(id: number, priceGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdPricingsPriceGuidDelete(id, priceGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publishes the vehicle listing.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPublishPut(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdPublishPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a listing.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalListingUpdateCommand} [vehicleRentalListingUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdPut(id: number, vehicleRentalListingUpdateCommand?: VehicleRentalListingUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsIdPut(id, vehicleRentalListingUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing term discounts.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsGet(id: number, options?: any): AxiosPromise<TermDiscountListQueryResultListResult> {
            return localVarFp.apiV1AdminListingsIdTermDiscountsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the listing term discount by id and quantity.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsPut(id: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdTermDiscountsPut(id, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes listing\'s term discount.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsQuantityDelete(id: number, quantity: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdTermDiscountsQuantityDelete(id, quantity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets listing term discount.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsQuantityGet(id: number, quantity: number, options?: any): AxiosPromise<TermDiscountByIdQueryResult> {
            return localVarFp.apiV1AdminListingsIdTermDiscountsQuantityGet(id, quantity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the listing term discount by id and quantity.
         * @param {number} id The listing identifier.
         * @param {number} quantity The days quantity.
         * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdTermDiscountsQuantityPut(id: number, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdTermDiscountsQuantityPut(id, quantity, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unpublishes the vehicle listing.
         * @param {number} id The listing identifier.
         * @param {UnpublishedReasons} [unpublishedReasons] Unpublished reason for the listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsIdUnpublishPut(id: number, unpublishedReasons?: UnpublishedReasons, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsIdUnpublishPut(id, unpublishedReasons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the unpublished rental listing.
         * @param {VehicleRentalListingCreateCommand} [vehicleRentalListingCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPost(vehicleRentalListingCreateCommand?: VehicleRentalListingCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsPost(vehicleRentalListingCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorDocumentsGet(pageSize: number, pageNumber: number, options?: any): AxiosPromise<ListingCalendarPriceEditorDocumentsQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminListingsPriceEditorDocumentsGet(pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorDocumentsGuidGet(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsPriceEditorDocumentsGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [search] 
         * @param {VehicleType} [vehicleType] 
         * @param {string} [productGuid] 
         * @param {string} [stationGuid] 
         * @param {CountryCode} [stationCountry] 
         * @param {string} [stationRegion] 
         * @param {string} [stationCity] 
         * @param {string} [operatorGuid] 
         * @param {OperatorPartnerType} [operatorType] 
         * @param {ListingStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorGet(pageSize: number, pageNumber: number, fromDate?: string, toDate?: string, search?: string, vehicleType?: VehicleType, productGuid?: string, stationGuid?: string, stationCountry?: CountryCode, stationRegion?: string, stationCity?: string, operatorGuid?: string, operatorType?: OperatorPartnerType, status?: ListingStatus, options?: any): AxiosPromise<ListingCalendarPricesEditorQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminListingsPriceEditorGet(pageSize, pageNumber, fromDate, toDate, search, vehicleType, productGuid, stationGuid, stationCountry, stationRegion, stationCity, operatorGuid, operatorType, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorImportPut(file?: File, options?: any): AxiosPromise<object> {
            return localVarFp.apiV1AdminListingsPriceEditorImportPut(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint is used to testing purposes only.
         * @param {string} [documentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid?: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingAdminApi - interface
 * @export
 * @interface ListingAdminApi
 */
export interface ListingAdminApiInterface {
    /**
     * 
     * @summary Allocates the unpublished rental listing.
     * @param {VehicleRentalListingAllocateCommand} [vehicleRentalListingAllocateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand?: VehicleRentalListingAllocateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets a listing available stations.
     * @param {number} [poolId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsAvailableStationsGet(poolId?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ListingAvailableStationsQueryResult>>;

    /**
     * 
     * @summary Copies calendar prices for next year for all listings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsCalendarPricesCopyToNextYearPut(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the listings.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [productGuid] 
     * @param {string} [operatorGuid] 
     * @param {Array<CountryCode>} [country] 
     * @param {Array<string>} [guids] 
     * @param {ListingStatus} [status] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsGet(pageNumber?: number, pageSize?: number, search?: string, productGuid?: string, operatorGuid?: string, country?: Array<CountryCode>, guids?: Array<string>, status?: ListingStatus, stationGuid?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleListingsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Removes the listing picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Uploads the listing picture and returns the model.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsGuidPicturesPost(guid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Sorts the listing pictures.
     * @param {string} guid 
     * @param {ListingPicturesSortCommand} [listingPicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsGuidPicturesSortPost(guid: string, listingPicturesSortCommand?: ListingPicturesSortCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets list of available vehicles for listing at specific time frame.
     * @param {number} id 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ListingAvailableVehiclesQueryResult>>;

    /**
     * 
     * @summary Copies listing\'s calendar prices for next year.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets listing calendar prices.
     * @param {number} id The listing identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [filterFrom] 
     * @param {string} [filterUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: AxiosRequestConfig): AxiosPromise<CalendarPriceListingListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Adds a listing calendar price.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPost(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Gets listing calendar price.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidGet(id: number, priceGuid: string, options?: AxiosRequestConfig): AxiosPromise<CalendarPriceListingByIdQueryResult>;

    /**
     * 
     * @summary Updates a listing calendar price.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {VehicleRentalCalendarPriceUpdateCommand} [vehicleRentalCalendarPriceUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidPut(id: number, priceGuid: string, vehicleRentalCalendarPriceUpdateCommand?: VehicleRentalCalendarPriceUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Deletes listing\'s term discount.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {number} [quantity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id: number, priceGuid: string, quantity?: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets listing term discounts.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id: number, priceGuid: string, options?: AxiosRequestConfig): AxiosPromise<TermDiscountListQueryResultListResult>;

    /**
     * 
     * @summary Creates the listing term discount by id, priceId, and quantity.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id: number, priceGuid: string, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets listing term discount.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {number} quantity The days quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id: number, priceGuid: string, quantity: number, options?: AxiosRequestConfig): AxiosPromise<TermDiscountByIdQueryResult>;

    /**
     * 
     * @summary Updates the listing term discount by id, priceId, and quantity.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {number} quantity The days quantity.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id: number, priceGuid: string, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a listing commission rate.
     * @param {number} id 
     * @param {VehicleRentalListingUpdateCommissionRateCommand} [vehicleRentalListingUpdateCommissionRateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCommissionRatePut(id: number, vehicleRentalListingUpdateCommissionRateCommand?: VehicleRentalListingUpdateCommissionRateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Copies base price term discounts into calendar pricing term discounts
     * @param {number} id The listing identifier.
     * @param {CopyTermDiscountsCommand} [copyTermDiscountsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdCopyDiscountsPost(id: number, copyTermDiscountsCommand?: CopyTermDiscountsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Soft deletes the unpublished rental listing.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a listing by its identifier.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<ListingByIdQueryResult>;

    /**
     * 
     * @summary Removes a listing price change.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalPriceChangesDeleteCommand} [vehicleRentalPriceChangesDeleteCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPriceChangesDelete(id: number, vehicleRentalPriceChangesDeleteCommand?: VehicleRentalPriceChangesDeleteCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets listing calendar prices.
     * @param {number} id The listing identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {string} [dateFrom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPriceChangesGet(id: number, pageSize: number, pageNumber: number, search?: string, dateFrom?: string, options?: AxiosRequestConfig): AxiosPromise<PriceChangeListingListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Adds a listing price change.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalPriceChangeAddCommand} [vehicleRentalPriceChangeAddCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPriceChangesPost(id: number, vehicleRentalPriceChangeAddCommand?: VehicleRentalPriceChangeAddCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Removes a listing price change.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPriceChangesPriceGuidDelete(id: number, priceGuid: string, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets listing price change.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPriceChangesPriceGuidGet(id: number, priceGuid: string, options?: AxiosRequestConfig): AxiosPromise<PriceChangeListingByIdQueryResult>;

    /**
     * 
     * @summary Updates a listing price change.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {VehicleRentalPriceChangeUpdateCommand} [vehicleRentalPriceChangeUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPriceChangesPriceGuidPut(id: number, priceGuid: string, vehicleRentalPriceChangeUpdateCommand?: VehicleRentalPriceChangeUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Updates the default listing\'s pricing.
     * @param {number} id The listing identifier.
     * @param {ListingRentalDefaultPriceUpdateCommand} [listingRentalDefaultPriceUpdateCommand] The command data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPricingsDefaultPut(id: number, listingRentalDefaultPriceUpdateCommand?: ListingRentalDefaultPriceUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets an array of pricings linked to vehicle rental listing.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPricingsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<RentalPriceOverviewQueryResult>;

    /**
     * 
     * @summary Creates a new pricing for listing.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The rental price guid identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPricingsPriceGuidDelete(id: number, priceGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Publishes the vehicle listing.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPublishPut(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a listing.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalListingUpdateCommand} [vehicleRentalListingUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdPut(id: number, vehicleRentalListingUpdateCommand?: VehicleRentalListingUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets listing term discounts.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdTermDiscountsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<TermDiscountListQueryResultListResult>;

    /**
     * 
     * @summary Creates the listing term discount by id and quantity.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdTermDiscountsPut(id: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes listing\'s term discount.
     * @param {number} id The listing identifier.
     * @param {number} quantity The days quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdTermDiscountsQuantityDelete(id: number, quantity: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets listing term discount.
     * @param {number} id The listing identifier.
     * @param {number} quantity The days quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdTermDiscountsQuantityGet(id: number, quantity: number, options?: AxiosRequestConfig): AxiosPromise<TermDiscountByIdQueryResult>;

    /**
     * 
     * @summary Updates the listing term discount by id and quantity.
     * @param {number} id The listing identifier.
     * @param {number} quantity The days quantity.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdTermDiscountsQuantityPut(id: number, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Unpublishes the vehicle listing.
     * @param {number} id The listing identifier.
     * @param {UnpublishedReasons} [unpublishedReasons] Unpublished reason for the listing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsIdUnpublishPut(id: number, unpublishedReasons?: UnpublishedReasons, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates the unpublished rental listing.
     * @param {VehicleRentalListingCreateCommand} [vehicleRentalListingCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsPost(vehicleRentalListingCreateCommand?: VehicleRentalListingCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsPriceEditorDocumentsGet(pageSize: number, pageNumber: number, options?: AxiosRequestConfig): AxiosPromise<ListingCalendarPriceEditorDocumentsQueryResultPaginatedResult>;

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsPriceEditorDocumentsGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [search] 
     * @param {VehicleType} [vehicleType] 
     * @param {string} [productGuid] 
     * @param {string} [stationGuid] 
     * @param {CountryCode} [stationCountry] 
     * @param {string} [stationRegion] 
     * @param {string} [stationCity] 
     * @param {string} [operatorGuid] 
     * @param {OperatorPartnerType} [operatorType] 
     * @param {ListingStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsPriceEditorGet(pageSize: number, pageNumber: number, fromDate?: string, toDate?: string, search?: string, vehicleType?: VehicleType, productGuid?: string, stationGuid?: string, stationCountry?: CountryCode, stationRegion?: string, stationCity?: string, operatorGuid?: string, operatorType?: OperatorPartnerType, status?: ListingStatus, options?: AxiosRequestConfig): AxiosPromise<ListingCalendarPricesEditorQueryResultPaginatedResult>;

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsPriceEditorImportPut(file?: File, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary This endpoint is used to testing purposes only.
     * @param {string} [documentGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApiInterface
     */
    apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid?: string, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * ListingAdminApi - object-oriented interface
 * @export
 * @class ListingAdminApi
 * @extends {BaseAPI}
 */
export class ListingAdminApi extends BaseAPI implements ListingAdminApiInterface {
    /**
     * 
     * @summary Allocates the unpublished rental listing.
     * @param {VehicleRentalListingAllocateCommand} [vehicleRentalListingAllocateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand?: VehicleRentalListingAllocateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsAllocatePost(vehicleRentalListingAllocateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a listing available stations.
     * @param {number} [poolId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsAvailableStationsGet(poolId?: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsAvailableStationsGet(poolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Copies calendar prices for next year for all listings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsCalendarPricesCopyToNextYearPut(options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsCalendarPricesCopyToNextYearPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the listings.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [productGuid] 
     * @param {string} [operatorGuid] 
     * @param {Array<CountryCode>} [country] 
     * @param {Array<string>} [guids] 
     * @param {ListingStatus} [status] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsGet(pageNumber?: number, pageSize?: number, search?: string, productGuid?: string, operatorGuid?: string, country?: Array<CountryCode>, guids?: Array<string>, status?: ListingStatus, stationGuid?: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsGet(pageNumber, pageSize, search, productGuid, operatorGuid, country, guids, status, stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the listing picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the listing picture and returns the model.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsGuidPicturesPost(guid: string, file?: File, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsGuidPicturesPost(guid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorts the listing pictures.
     * @param {string} guid 
     * @param {ListingPicturesSortCommand} [listingPicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsGuidPicturesSortPost(guid: string, listingPicturesSortCommand?: ListingPicturesSortCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsGuidPicturesSortPost(guid, listingPicturesSortCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of available vehicles for listing at specific time frame.
     * @param {number} id 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdAvailableVehiclesGet(id, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Copies listing\'s calendar prices for next year.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesCopyToNextYearPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing calendar prices.
     * @param {number} id The listing identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [filterFrom] 
     * @param {string} [filterUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesGet(id, pageSize, pageNumber, filterFrom, filterUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a listing calendar price.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPost(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPost(id, vehicleRentalCalendarPriceAddCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing calendar price.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidGet(id: number, priceGuid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidGet(id, priceGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a listing calendar price.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {VehicleRentalCalendarPriceUpdateCommand} [vehicleRentalCalendarPriceUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidPut(id: number, priceGuid: string, vehicleRentalCalendarPriceUpdateCommand?: VehicleRentalCalendarPriceUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidPut(id, priceGuid, vehicleRentalCalendarPriceUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes listing\'s term discount.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {number} [quantity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id: number, priceGuid: string, quantity?: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsDelete(id, priceGuid, quantity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing term discounts.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id: number, priceGuid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsGet(id, priceGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the listing term discount by id, priceId, and quantity.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id: number, priceGuid: string, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsPut(id, priceGuid, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing term discount.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {number} quantity The days quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id: number, priceGuid: string, quantity: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityGet(id, priceGuid, quantity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the listing term discount by id, priceId, and quantity.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {number} quantity The days quantity.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id: number, priceGuid: string, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCalendarPricesPriceGuidTermDiscountsQuantityPut(id, priceGuid, quantity, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a listing commission rate.
     * @param {number} id 
     * @param {VehicleRentalListingUpdateCommissionRateCommand} [vehicleRentalListingUpdateCommissionRateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCommissionRatePut(id: number, vehicleRentalListingUpdateCommissionRateCommand?: VehicleRentalListingUpdateCommissionRateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCommissionRatePut(id, vehicleRentalListingUpdateCommissionRateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Copies base price term discounts into calendar pricing term discounts
     * @param {number} id The listing identifier.
     * @param {CopyTermDiscountsCommand} [copyTermDiscountsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdCopyDiscountsPost(id: number, copyTermDiscountsCommand?: CopyTermDiscountsCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdCopyDiscountsPost(id, copyTermDiscountsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft deletes the unpublished rental listing.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdDelete(id: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a listing by its identifier.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdGet(id: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a listing price change.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalPriceChangesDeleteCommand} [vehicleRentalPriceChangesDeleteCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPriceChangesDelete(id: number, vehicleRentalPriceChangesDeleteCommand?: VehicleRentalPriceChangesDeleteCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPriceChangesDelete(id, vehicleRentalPriceChangesDeleteCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing calendar prices.
     * @param {number} id The listing identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {string} [dateFrom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPriceChangesGet(id: number, pageSize: number, pageNumber: number, search?: string, dateFrom?: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPriceChangesGet(id, pageSize, pageNumber, search, dateFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a listing price change.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalPriceChangeAddCommand} [vehicleRentalPriceChangeAddCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPriceChangesPost(id: number, vehicleRentalPriceChangeAddCommand?: VehicleRentalPriceChangeAddCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPriceChangesPost(id, vehicleRentalPriceChangeAddCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a listing price change.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPriceChangesPriceGuidDelete(id: number, priceGuid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPriceChangesPriceGuidDelete(id, priceGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing price change.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPriceChangesPriceGuidGet(id: number, priceGuid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPriceChangesPriceGuidGet(id, priceGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a listing price change.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The price global identifier.
     * @param {VehicleRentalPriceChangeUpdateCommand} [vehicleRentalPriceChangeUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPriceChangesPriceGuidPut(id: number, priceGuid: string, vehicleRentalPriceChangeUpdateCommand?: VehicleRentalPriceChangeUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPriceChangesPriceGuidPut(id, priceGuid, vehicleRentalPriceChangeUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the default listing\'s pricing.
     * @param {number} id The listing identifier.
     * @param {ListingRentalDefaultPriceUpdateCommand} [listingRentalDefaultPriceUpdateCommand] The command data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPricingsDefaultPut(id: number, listingRentalDefaultPriceUpdateCommand?: ListingRentalDefaultPriceUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPricingsDefaultPut(id, listingRentalDefaultPriceUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an array of pricings linked to vehicle rental listing.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPricingsGet(id: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPricingsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new pricing for listing.
     * @param {number} id The listing identifier.
     * @param {string} priceGuid The rental price guid identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPricingsPriceGuidDelete(id: number, priceGuid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPricingsPriceGuidDelete(id, priceGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publishes the vehicle listing.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPublishPut(id: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPublishPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a listing.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalListingUpdateCommand} [vehicleRentalListingUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdPut(id: number, vehicleRentalListingUpdateCommand?: VehicleRentalListingUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdPut(id, vehicleRentalListingUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing term discounts.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdTermDiscountsGet(id: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdTermDiscountsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the listing term discount by id and quantity.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdTermDiscountsPut(id: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdTermDiscountsPut(id, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes listing\'s term discount.
     * @param {number} id The listing identifier.
     * @param {number} quantity The days quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdTermDiscountsQuantityDelete(id: number, quantity: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdTermDiscountsQuantityDelete(id, quantity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets listing term discount.
     * @param {number} id The listing identifier.
     * @param {number} quantity The days quantity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdTermDiscountsQuantityGet(id: number, quantity: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdTermDiscountsQuantityGet(id, quantity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the listing term discount by id and quantity.
     * @param {number} id The listing identifier.
     * @param {number} quantity The days quantity.
     * @param {VehicleRentalTermDiscountUpdateCommand} [vehicleRentalTermDiscountUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdTermDiscountsQuantityPut(id: number, quantity: number, vehicleRentalTermDiscountUpdateCommand?: VehicleRentalTermDiscountUpdateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdTermDiscountsQuantityPut(id, quantity, vehicleRentalTermDiscountUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unpublishes the vehicle listing.
     * @param {number} id The listing identifier.
     * @param {UnpublishedReasons} [unpublishedReasons] Unpublished reason for the listing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsIdUnpublishPut(id: number, unpublishedReasons?: UnpublishedReasons, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsIdUnpublishPut(id, unpublishedReasons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the unpublished rental listing.
     * @param {VehicleRentalListingCreateCommand} [vehicleRentalListingCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsPost(vehicleRentalListingCreateCommand?: VehicleRentalListingCreateCommand, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsPost(vehicleRentalListingCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsPriceEditorDocumentsGet(pageSize: number, pageNumber: number, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsPriceEditorDocumentsGet(pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsPriceEditorDocumentsGuidGet(guid: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsPriceEditorDocumentsGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [search] 
     * @param {VehicleType} [vehicleType] 
     * @param {string} [productGuid] 
     * @param {string} [stationGuid] 
     * @param {CountryCode} [stationCountry] 
     * @param {string} [stationRegion] 
     * @param {string} [stationCity] 
     * @param {string} [operatorGuid] 
     * @param {OperatorPartnerType} [operatorType] 
     * @param {ListingStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsPriceEditorGet(pageSize: number, pageNumber: number, fromDate?: string, toDate?: string, search?: string, vehicleType?: VehicleType, productGuid?: string, stationGuid?: string, stationCountry?: CountryCode, stationRegion?: string, stationCity?: string, operatorGuid?: string, operatorType?: OperatorPartnerType, status?: ListingStatus, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsPriceEditorGet(pageSize, pageNumber, fromDate, toDate, search, vehicleType, productGuid, stationGuid, stationCountry, stationRegion, stationCity, operatorGuid, operatorType, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsPriceEditorImportPut(file?: File, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsPriceEditorImportPut(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint is used to testing purposes only.
     * @param {string} [documentGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingAdminApi
     */
    public apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid?: string, options?: AxiosRequestConfig) {
        return ListingAdminApiFp(this.configuration).apiV1AdminListingsPriceEditorImportRunJobPut(documentGuid, options).then((request) => request(this.axios, this.basePath));
    }
}

