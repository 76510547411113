/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DynamicSegmentVariationListResultPaginatedResult } from '../models';
// @ts-ignore
import { DynamicSegmentVariationResult } from '../models';
// @ts-ignore
import { LanguageCode } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * DynamicSegmentMarketplaceApi - axios parameter creator
 * @export
 */
export const DynamicSegmentMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a dynamic segment variation by language.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet', 'language', language)
            const localVarPath = `/api/v1/marketplace/dynamic-segments/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a dynamic segment variation by language.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet', 'language', language)
            const localVarPath = `/api/v1/marketplace/dynamic-segments/{id}/variations/{language}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of dynamic segment variations by language.
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {number} [dynamicSegmentId] 
         * @param {string} [search] Fulltext search
         * @param {LanguageCode} [language] Language filter
         * @param {boolean} [searchableOnly] Return only searchable variations
         * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceDynamicSegmentsVariationsGet: async (pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/dynamic-segments/variations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (dynamicSegmentId !== undefined) {
                localVarQueryParameter['DynamicSegmentId'] = dynamicSegmentId;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (language !== undefined) {
                localVarQueryParameter['Language'] = language;
            }

            if (searchableOnly !== undefined) {
                localVarQueryParameter['SearchableOnly'] = searchableOnly;
            }

            if (isIndexed !== undefined) {
                localVarQueryParameter['IsIndexed'] = isIndexed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicSegmentMarketplaceApi - functional programming interface
 * @export
 */
export const DynamicSegmentMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DynamicSegmentMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a dynamic segment variation by language.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentVariationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a dynamic segment variation by language.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentVariationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of dynamic segment variations by language.
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {number} [dynamicSegmentId] 
         * @param {string} [search] Fulltext search
         * @param {LanguageCode} [language] Language filter
         * @param {boolean} [searchableOnly] Return only searchable variations
         * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentVariationListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset, pageSize, dynamicSegmentId, search, language, searchableOnly, isIndexed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DynamicSegmentMarketplaceApi - factory interface
 * @export
 */
export const DynamicSegmentMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DynamicSegmentMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a dynamic segment variation by language.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: any): AxiosPromise<DynamicSegmentVariationResult> {
            return localVarFp.apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a dynamic segment variation by language.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id: number, language: LanguageCode, options?: any): AxiosPromise<DynamicSegmentVariationResult> {
            return localVarFp.apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of dynamic segment variations by language.
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {number} [dynamicSegmentId] 
         * @param {string} [search] Fulltext search
         * @param {LanguageCode} [language] Language filter
         * @param {boolean} [searchableOnly] Return only searchable variations
         * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: any): AxiosPromise<DynamicSegmentVariationListResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset, pageSize, dynamicSegmentId, search, language, searchableOnly, isIndexed, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DynamicSegmentMarketplaceApi - interface
 * @export
 * @interface DynamicSegmentMarketplaceApi
 */
export interface DynamicSegmentMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a dynamic segment variation by language.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentMarketplaceApiInterface
     */
    apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentVariationResult>;

    /**
     * 
     * @summary Gets a dynamic segment variation by language.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentMarketplaceApiInterface
     */
    apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentVariationResult>;

    /**
     * 
     * @summary Gets a list of dynamic segment variations by language.
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {number} [dynamicSegmentId] 
     * @param {string} [search] Fulltext search
     * @param {LanguageCode} [language] Language filter
     * @param {boolean} [searchableOnly] Return only searchable variations
     * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentMarketplaceApiInterface
     */
    apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentVariationListResultPaginatedResult>;

}

/**
 * DynamicSegmentMarketplaceApi - object-oriented interface
 * @export
 * @class DynamicSegmentMarketplaceApi
 * @extends {BaseAPI}
 */
export class DynamicSegmentMarketplaceApi extends BaseAPI implements DynamicSegmentMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a dynamic segment variation by language.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentMarketplaceApi
     */
    public apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return DynamicSegmentMarketplaceApiFp(this.configuration).apiV1MarketplaceDynamicSegmentsIdVariationsLanguageGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a dynamic segment variation by language.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentMarketplaceApi
     */
    public apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return DynamicSegmentMarketplaceApiFp(this.configuration).apiV1MarketplaceDynamicSegmentsIdVariationsLanguagePreviewGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of dynamic segment variations by language.
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {number} [dynamicSegmentId] 
     * @param {string} [search] Fulltext search
     * @param {LanguageCode} [language] Language filter
     * @param {boolean} [searchableOnly] Return only searchable variations
     * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentMarketplaceApi
     */
    public apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: AxiosRequestConfig) {
        return DynamicSegmentMarketplaceApiFp(this.configuration).apiV1MarketplaceDynamicSegmentsVariationsGet(pageOffset, pageSize, dynamicSegmentId, search, language, searchableOnly, isIndexed, options).then((request) => request(this.axios, this.basePath));
    }
}

