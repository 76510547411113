/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Differentiates states of a Campiri.Booking.BookingIntent
 * @export
 * @enum {string}
 */

export const BookingIntentStatus = {
    Open: 'Open',
    BookingRequested: 'BookingRequested',
    ReservationCreated: 'ReservationCreated',
    Cancelled: 'Cancelled',
    CounterOfferMade: 'CounterOfferMade',
    Incomplete: 'Incomplete'
} as const;

export type BookingIntentStatus = typeof BookingIntentStatus[keyof typeof BookingIntentStatus];



