/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const BookingAddonType = {
    BeddingSetSingle: 'BeddingSetSingle',
    BeddingSetDouble: 'BeddingSetDouble',
    CarpetUnderRoof: 'CarpetUnderRoof',
    VoltageConvertor: 'VoltageConvertor',
    Grill: 'Grill',
    Hammock: 'Hammock',
    BicycleCover: 'BicycleCover',
    SmokeDetector: 'SmokeDetector',
    PortableSpeaker: 'PortableSpeaker',
    BoardGames: 'BoardGames',
    InstantCamera: 'InstantCamera',
    InsectRepellent: 'InsectRepellent',
    ExtraKilometers: 'ExtraKilometers',
    UnlimitedKilometers: 'UnlimitedKilometers',
    AdvancedCoverage: 'AdvancedCoverage',
    CompleteCoverage: 'CompleteCoverage'
} as const;

export type BookingAddonType = typeof BookingAddonType[keyof typeof BookingAddonType];



