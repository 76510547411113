/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddVehicleDamageCommand } from '../models';
// @ts-ignore
import { CICOChecklistResult } from '../models';
// @ts-ignore
import { CheckOutFuelResult } from '../models';
// @ts-ignore
import { CheckOutOdometerReadingResult } from '../models';
// @ts-ignore
import { CheckOutOriginalDataResult } from '../models';
// @ts-ignore
import { CheckOutResult } from '../models';
// @ts-ignore
import { CicoProtocolDocumentFileResult } from '../models';
// @ts-ignore
import { CompleteCheckOutCommand } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { SetCheckOutChecklistItemsCommand } from '../models';
// @ts-ignore
import { SetCheckOutHandoverCommand } from '../models';
// @ts-ignore
import { SummaryCheckOutCommand } from '../models';
// @ts-ignore
import { UpdateCheckOutDataCommand } from '../models';
// @ts-ignore
import { UpdateCheckOutNoteCommand } from '../models';
// @ts-ignore
import { UpdateCheckOutProtocolSignedCommand } from '../models';
// @ts-ignore
import { VehicleCheckPictureType } from '../models';
/**
 * CheckOutOperatorApi - axios parameter creator
 * @export
 */
export const CheckOutOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates operator acknowledgment for no check-out damages.
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/acknowledge-no-damages`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes check-out billing picture.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidBillingReceiptDelete: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidBillingReceiptDelete', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/billing-receipt`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads check-out billing picture.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidBillingReceiptPut: async (guid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidBillingReceiptPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/billing-receipt`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates fuel difference
         * @param {string} guid Guid of the check-out
         * @param {number} [currentFuel] Current fuel in tank (percentage from 0 to 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCalculateFuelGet: async (guid: string, currentFuel?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidCalculateFuelGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/calculate-fuel`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currentFuel !== undefined) {
                localVarQueryParameter['currentFuel'] = currentFuel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates the mileage
         * @param {string} guid Guid of the check-out
         * @param {number} [currentMileage] Current mileage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCalculateMileageGet: async (guid: string, currentMileage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidCalculateMileageGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/calculate-mileage`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currentMileage !== undefined) {
                localVarQueryParameter['currentMileage'] = currentMileage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the checklist for a check-out
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidChecklistGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidChecklistGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/checklist`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in\'s checklist data.
         * @param {string} guid 
         * @param {SetCheckOutChecklistItemsCommand} [setCheckOutChecklistItemsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidChecklistPut: async (guid: string, setCheckOutChecklistItemsCommand?: SetCheckOutChecklistItemsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidChecklistPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/checklist`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCheckOutChecklistItemsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets check-out\'s damages evaluation finished.
         * @param {string} guid 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost: async (guid: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/complete-damages-evaluation`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets check-out\'s state
         * @param {string} guid 
         * @param {CompleteCheckOutCommand} [completeCheckOutCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCompletePost: async (guid: string, completeCheckOutCommand?: CompleteCheckOutCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidCompletePost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/complete`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeCheckOutCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns a vehicle damage to the check out
         * @param {string} guid Guid of the check-out
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidDamagesPost: async (guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidDamagesPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/damages`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVehicleDamageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the check-out data
         * @param {string} guid The check-out\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-out\'s handover data.
         * @param {string} guid Guid of the check-out.
         * @param {SetCheckOutHandoverCommand} [setCheckOutHandoverCommand] Handover data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidHandoverPut: async (guid: string, setCheckOutHandoverCommand?: SetCheckOutHandoverCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidHandoverPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/handover`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCheckOutHandoverCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates check-out note
         * @param {string} guid Guid of the check-out
         * @param {UpdateCheckOutNoteCommand} [updateCheckOutNoteCommand] Update check out note command data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidNotePut: async (guid: string, updateCheckOutNoteCommand?: UpdateCheckOutNoteCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidNotePut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/note`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckOutNoteCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a check-out\'s original data
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidOriginalDataGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidOriginalDataGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/original-data`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the vehicle check picture
         * @param {string} guid Guid of the check-out
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPicturesPictureGuidDelete: async (guid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPicturesPictureGuidDelete', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates check-out vehicle\'s picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPicturesPictureGuidPut: async (guid: string, pictureGuid: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPicturesPictureGuidPut', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPicturesPictureGuidPut', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a vehicle check picture
         * @param {string} guid Guid of the check-out
         * @param {VehicleCheckPictureType} type Picture type
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPicturesTypePost: async (guid: string, type: VehicleCheckPictureType, photo?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPicturesTypePost', 'guid', guid)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPicturesTypePost', 'type', type)
            const localVarPath = `/api/v1/operator/check-out/{guid}/pictures/{type}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes check-out protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolDocumentDelete: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidProtocolDocumentDelete', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/protocol/document`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns check-out protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolDocumentGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidProtocolDocumentGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/protocol/document`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads check-out protocol document.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolDocumentPut: async (guid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidProtocolDocumentPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/protocol/document`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the check out protocol
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidProtocolGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/protocol`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolHtmlGet: async (guid: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidProtocolHtmlGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/protocol/html`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets check-out\'s protocol signed.
         * @param {string} guid 
         * @param {UpdateCheckOutProtocolSignedCommand} [updateCheckOutProtocolSignedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolSignedPut: async (guid: string, updateCheckOutProtocolSignedCommand?: UpdateCheckOutProtocolSignedCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidProtocolSignedPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/protocol/signed`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckOutProtocolSignedCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates check-out data
         * @param {string} guid Guid of the check-out
         * @param {UpdateCheckOutDataCommand} [updateCheckOutDataCommand] Update check out data command data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPut: async (guid: string, updateCheckOutDataCommand?: UpdateCheckOutDataCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckOutDataCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in\'s billings data.
         * @param {string} guid Guid of the check-in
         * @param {SummaryCheckOutCommand} [summaryCheckOutCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidSummaryPut: async (guid: string, summaryCheckOutCommand?: SummaryCheckOutCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckOutGuidSummaryPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-out/{guid}/summary`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(summaryCheckOutCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initializes a check-out
         * @param {string} [referenceCode] The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutPost: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/check-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['referenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckOutOperatorApi - functional programming interface
 * @export
 */
export const CheckOutOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckOutOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates operator acknowledgment for no check-out damages.
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes check-out billing picture.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidBillingReceiptDelete(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidBillingReceiptDelete(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads check-out billing picture.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidBillingReceiptPut(guid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidBillingReceiptPut(guid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates fuel difference
         * @param {string} guid Guid of the check-out
         * @param {number} [currentFuel] Current fuel in tank (percentage from 0 to 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidCalculateFuelGet(guid: string, currentFuel?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOutFuelResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidCalculateFuelGet(guid, currentFuel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates the mileage
         * @param {string} guid Guid of the check-out
         * @param {number} [currentMileage] Current mileage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidCalculateMileageGet(guid: string, currentMileage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOutOdometerReadingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidCalculateMileageGet(guid, currentMileage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the checklist for a check-out
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidChecklistGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CICOChecklistResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidChecklistGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in\'s checklist data.
         * @param {string} guid 
         * @param {SetCheckOutChecklistItemsCommand} [setCheckOutChecklistItemsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidChecklistPut(guid: string, setCheckOutChecklistItemsCommand?: SetCheckOutChecklistItemsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidChecklistPut(guid, setCheckOutChecklistItemsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets check-out\'s damages evaluation finished.
         * @param {string} guid 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets check-out\'s state
         * @param {string} guid 
         * @param {CompleteCheckOutCommand} [completeCheckOutCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidCompletePost(guid: string, completeCheckOutCommand?: CompleteCheckOutCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidCompletePost(guid, completeCheckOutCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns a vehicle damage to the check out
         * @param {string} guid Guid of the check-out
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidDamagesPost(guid, addVehicleDamageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the check-out data
         * @param {string} guid The check-out\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOutResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-out\'s handover data.
         * @param {string} guid Guid of the check-out.
         * @param {SetCheckOutHandoverCommand} [setCheckOutHandoverCommand] Handover data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidHandoverPut(guid: string, setCheckOutHandoverCommand?: SetCheckOutHandoverCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidHandoverPut(guid, setCheckOutHandoverCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates check-out note
         * @param {string} guid Guid of the check-out
         * @param {UpdateCheckOutNoteCommand} [updateCheckOutNoteCommand] Update check out note command data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidNotePut(guid: string, updateCheckOutNoteCommand?: UpdateCheckOutNoteCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOutResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidNotePut(guid, updateCheckOutNoteCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a check-out\'s original data
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidOriginalDataGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOutOriginalDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidOriginalDataGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the vehicle check picture
         * @param {string} guid Guid of the check-out
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates check-out vehicle\'s picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid, pictureGuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a vehicle check picture
         * @param {string} guid Guid of the check-out
         * @param {VehicleCheckPictureType} type Picture type
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, photo?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidPicturesTypePost(guid, type, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes check-out protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns check-out protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidProtocolDocumentGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CicoProtocolDocumentFileResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidProtocolDocumentGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads check-out protocol document.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidProtocolDocumentPut(guid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidProtocolDocumentPut(guid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the check out protocol
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidProtocolGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidProtocolGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidProtocolHtmlGet(guid, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets check-out\'s protocol signed.
         * @param {string} guid 
         * @param {UpdateCheckOutProtocolSignedCommand} [updateCheckOutProtocolSignedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidProtocolSignedPut(guid: string, updateCheckOutProtocolSignedCommand?: UpdateCheckOutProtocolSignedCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidProtocolSignedPut(guid, updateCheckOutProtocolSignedCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates check-out data
         * @param {string} guid Guid of the check-out
         * @param {UpdateCheckOutDataCommand} [updateCheckOutDataCommand] Update check out data command data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidPut(guid: string, updateCheckOutDataCommand?: UpdateCheckOutDataCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOutResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidPut(guid, updateCheckOutDataCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in\'s billings data.
         * @param {string} guid Guid of the check-in
         * @param {SummaryCheckOutCommand} [summaryCheckOutCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutGuidSummaryPut(guid: string, summaryCheckOutCommand?: SummaryCheckOutCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutGuidSummaryPut(guid, summaryCheckOutCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initializes a check-out
         * @param {string} [referenceCode] The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckOutPost(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckOutPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckOutOperatorApi - factory interface
 * @export
 */
export const CheckOutOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckOutOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates operator acknowledgment for no check-out damages.
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes check-out billing picture.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidBillingReceiptDelete(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidBillingReceiptDelete(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads check-out billing picture.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidBillingReceiptPut(guid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckOutGuidBillingReceiptPut(guid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates fuel difference
         * @param {string} guid Guid of the check-out
         * @param {number} [currentFuel] Current fuel in tank (percentage from 0 to 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCalculateFuelGet(guid: string, currentFuel?: number, options?: any): AxiosPromise<CheckOutFuelResult> {
            return localVarFp.apiV1OperatorCheckOutGuidCalculateFuelGet(guid, currentFuel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates the mileage
         * @param {string} guid Guid of the check-out
         * @param {number} [currentMileage] Current mileage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCalculateMileageGet(guid: string, currentMileage?: number, options?: any): AxiosPromise<CheckOutOdometerReadingResult> {
            return localVarFp.apiV1OperatorCheckOutGuidCalculateMileageGet(guid, currentMileage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the checklist for a check-out
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidChecklistGet(guid: string, options?: any): AxiosPromise<CICOChecklistResult> {
            return localVarFp.apiV1OperatorCheckOutGuidChecklistGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in\'s checklist data.
         * @param {string} guid 
         * @param {SetCheckOutChecklistItemsCommand} [setCheckOutChecklistItemsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidChecklistPut(guid: string, setCheckOutChecklistItemsCommand?: SetCheckOutChecklistItemsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidChecklistPut(guid, setCheckOutChecklistItemsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets check-out\'s damages evaluation finished.
         * @param {string} guid 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets check-out\'s state
         * @param {string} guid 
         * @param {CompleteCheckOutCommand} [completeCheckOutCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidCompletePost(guid: string, completeCheckOutCommand?: CompleteCheckOutCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidCompletePost(guid, completeCheckOutCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns a vehicle damage to the check out
         * @param {string} guid Guid of the check-out
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorCheckOutGuidDamagesPost(guid, addVehicleDamageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the check-out data
         * @param {string} guid The check-out\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidGet(guid: string, options?: any): AxiosPromise<CheckOutResult> {
            return localVarFp.apiV1OperatorCheckOutGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-out\'s handover data.
         * @param {string} guid Guid of the check-out.
         * @param {SetCheckOutHandoverCommand} [setCheckOutHandoverCommand] Handover data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidHandoverPut(guid: string, setCheckOutHandoverCommand?: SetCheckOutHandoverCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidHandoverPut(guid, setCheckOutHandoverCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates check-out note
         * @param {string} guid Guid of the check-out
         * @param {UpdateCheckOutNoteCommand} [updateCheckOutNoteCommand] Update check out note command data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidNotePut(guid: string, updateCheckOutNoteCommand?: UpdateCheckOutNoteCommand, options?: any): AxiosPromise<CheckOutResult> {
            return localVarFp.apiV1OperatorCheckOutGuidNotePut(guid, updateCheckOutNoteCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a check-out\'s original data
         * @param {string} guid Guid of the check-out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidOriginalDataGet(guid: string, options?: any): AxiosPromise<CheckOutOriginalDataResult> {
            return localVarFp.apiV1OperatorCheckOutGuidOriginalDataGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the vehicle check picture
         * @param {string} guid Guid of the check-out
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates check-out vehicle\'s picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid, pictureGuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a vehicle check picture
         * @param {string} guid Guid of the check-out
         * @param {VehicleCheckPictureType} type Picture type
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, photo?: File, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorCheckOutGuidPicturesTypePost(guid, type, photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes check-out protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns check-out protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolDocumentGet(guid: string, options?: any): AxiosPromise<CicoProtocolDocumentFileResult> {
            return localVarFp.apiV1OperatorCheckOutGuidProtocolDocumentGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads check-out protocol document.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolDocumentPut(guid: string, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidProtocolDocumentPut(guid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the check out protocol
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolGet(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidProtocolGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidProtocolHtmlGet(guid, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets check-out\'s protocol signed.
         * @param {string} guid 
         * @param {UpdateCheckOutProtocolSignedCommand} [updateCheckOutProtocolSignedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidProtocolSignedPut(guid: string, updateCheckOutProtocolSignedCommand?: UpdateCheckOutProtocolSignedCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidProtocolSignedPut(guid, updateCheckOutProtocolSignedCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates check-out data
         * @param {string} guid Guid of the check-out
         * @param {UpdateCheckOutDataCommand} [updateCheckOutDataCommand] Update check out data command data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidPut(guid: string, updateCheckOutDataCommand?: UpdateCheckOutDataCommand, options?: any): AxiosPromise<CheckOutResult> {
            return localVarFp.apiV1OperatorCheckOutGuidPut(guid, updateCheckOutDataCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in\'s billings data.
         * @param {string} guid Guid of the check-in
         * @param {SummaryCheckOutCommand} [summaryCheckOutCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutGuidSummaryPut(guid: string, summaryCheckOutCommand?: SummaryCheckOutCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckOutGuidSummaryPut(guid, summaryCheckOutCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initializes a check-out
         * @param {string} [referenceCode] The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckOutPost(referenceCode?: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorCheckOutPost(referenceCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckOutOperatorApi - interface
 * @export
 * @interface CheckOutOperatorApi
 */
export interface CheckOutOperatorApiInterface {
    /**
     * 
     * @summary Updates operator acknowledgment for no check-out damages.
     * @param {string} guid Guid of the check-out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes check-out billing picture.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidBillingReceiptDelete(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads check-out billing picture.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidBillingReceiptPut(guid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Calculates fuel difference
     * @param {string} guid Guid of the check-out
     * @param {number} [currentFuel] Current fuel in tank (percentage from 0 to 1)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidCalculateFuelGet(guid: string, currentFuel?: number, options?: AxiosRequestConfig): AxiosPromise<CheckOutFuelResult>;

    /**
     * 
     * @summary Calculates the mileage
     * @param {string} guid Guid of the check-out
     * @param {number} [currentMileage] Current mileage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidCalculateMileageGet(guid: string, currentMileage?: number, options?: AxiosRequestConfig): AxiosPromise<CheckOutOdometerReadingResult>;

    /**
     * 
     * @summary Returns the checklist for a check-out
     * @param {string} guid Guid of the check-out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidChecklistGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CICOChecklistResult>;

    /**
     * 
     * @summary Updates the check-in\'s checklist data.
     * @param {string} guid 
     * @param {SetCheckOutChecklistItemsCommand} [setCheckOutChecklistItemsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidChecklistPut(guid: string, setCheckOutChecklistItemsCommand?: SetCheckOutChecklistItemsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets check-out\'s damages evaluation finished.
     * @param {string} guid 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid: string, body?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets check-out\'s state
     * @param {string} guid 
     * @param {CompleteCheckOutCommand} [completeCheckOutCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidCompletePost(guid: string, completeCheckOutCommand?: CompleteCheckOutCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Assigns a vehicle damage to the check out
     * @param {string} guid Guid of the check-out
     * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Returns all the check-out data
     * @param {string} guid The check-out\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CheckOutResult>;

    /**
     * 
     * @summary Updates the check-out\'s handover data.
     * @param {string} guid Guid of the check-out.
     * @param {SetCheckOutHandoverCommand} [setCheckOutHandoverCommand] Handover data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidHandoverPut(guid: string, setCheckOutHandoverCommand?: SetCheckOutHandoverCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates check-out note
     * @param {string} guid Guid of the check-out
     * @param {UpdateCheckOutNoteCommand} [updateCheckOutNoteCommand] Update check out note command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidNotePut(guid: string, updateCheckOutNoteCommand?: UpdateCheckOutNoteCommand, options?: AxiosRequestConfig): AxiosPromise<CheckOutResult>;

    /**
     * 
     * @summary Gets a check-out\'s original data
     * @param {string} guid Guid of the check-out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidOriginalDataGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CheckOutOriginalDataResult>;

    /**
     * 
     * @summary Removes the vehicle check picture
     * @param {string} guid Guid of the check-out
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates check-out vehicle\'s picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Adds a vehicle check picture
     * @param {string} guid Guid of the check-out
     * @param {VehicleCheckPictureType} type Picture type
     * @param {File} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, photo?: File, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Deletes check-out protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Returns check-out protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidProtocolDocumentGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CicoProtocolDocumentFileResult>;

    /**
     * 
     * @summary Uploads check-out protocol document.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidProtocolDocumentPut(guid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the check out protocol
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidProtocolGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the check in protocol
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets check-out\'s protocol signed.
     * @param {string} guid 
     * @param {UpdateCheckOutProtocolSignedCommand} [updateCheckOutProtocolSignedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidProtocolSignedPut(guid: string, updateCheckOutProtocolSignedCommand?: UpdateCheckOutProtocolSignedCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates check-out data
     * @param {string} guid Guid of the check-out
     * @param {UpdateCheckOutDataCommand} [updateCheckOutDataCommand] Update check out data command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidPut(guid: string, updateCheckOutDataCommand?: UpdateCheckOutDataCommand, options?: AxiosRequestConfig): AxiosPromise<CheckOutResult>;

    /**
     * 
     * @summary Updates the check-in\'s billings data.
     * @param {string} guid Guid of the check-in
     * @param {SummaryCheckOutCommand} [summaryCheckOutCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutGuidSummaryPut(guid: string, summaryCheckOutCommand?: SummaryCheckOutCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Initializes a check-out
     * @param {string} [referenceCode] The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApiInterface
     */
    apiV1OperatorCheckOutPost(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

}

/**
 * CheckOutOperatorApi - object-oriented interface
 * @export
 * @class CheckOutOperatorApi
 * @extends {BaseAPI}
 */
export class CheckOutOperatorApi extends BaseAPI implements CheckOutOperatorApiInterface {
    /**
     * 
     * @summary Updates operator acknowledgment for no check-out damages.
     * @param {string} guid Guid of the check-out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidAcknowledgeNoDamagesPost(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes check-out billing picture.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidBillingReceiptDelete(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidBillingReceiptDelete(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads check-out billing picture.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidBillingReceiptPut(guid: string, file?: File, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidBillingReceiptPut(guid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates fuel difference
     * @param {string} guid Guid of the check-out
     * @param {number} [currentFuel] Current fuel in tank (percentage from 0 to 1)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidCalculateFuelGet(guid: string, currentFuel?: number, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidCalculateFuelGet(guid, currentFuel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates the mileage
     * @param {string} guid Guid of the check-out
     * @param {number} [currentMileage] Current mileage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidCalculateMileageGet(guid: string, currentMileage?: number, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidCalculateMileageGet(guid, currentMileage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the checklist for a check-out
     * @param {string} guid Guid of the check-out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidChecklistGet(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidChecklistGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in\'s checklist data.
     * @param {string} guid 
     * @param {SetCheckOutChecklistItemsCommand} [setCheckOutChecklistItemsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidChecklistPut(guid: string, setCheckOutChecklistItemsCommand?: SetCheckOutChecklistItemsCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidChecklistPut(guid, setCheckOutChecklistItemsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets check-out\'s damages evaluation finished.
     * @param {string} guid 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid: string, body?: object, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidCompleteDamagesEvaluationPost(guid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets check-out\'s state
     * @param {string} guid 
     * @param {CompleteCheckOutCommand} [completeCheckOutCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidCompletePost(guid: string, completeCheckOutCommand?: CompleteCheckOutCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidCompletePost(guid, completeCheckOutCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns a vehicle damage to the check out
     * @param {string} guid Guid of the check-out
     * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidDamagesPost(guid, addVehicleDamageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the check-out data
     * @param {string} guid The check-out\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidGet(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-out\'s handover data.
     * @param {string} guid Guid of the check-out.
     * @param {SetCheckOutHandoverCommand} [setCheckOutHandoverCommand] Handover data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidHandoverPut(guid: string, setCheckOutHandoverCommand?: SetCheckOutHandoverCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidHandoverPut(guid, setCheckOutHandoverCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates check-out note
     * @param {string} guid Guid of the check-out
     * @param {UpdateCheckOutNoteCommand} [updateCheckOutNoteCommand] Update check out note command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidNotePut(guid: string, updateCheckOutNoteCommand?: UpdateCheckOutNoteCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidNotePut(guid, updateCheckOutNoteCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a check-out\'s original data
     * @param {string} guid Guid of the check-out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidOriginalDataGet(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidOriginalDataGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the vehicle check picture
     * @param {string} guid Guid of the check-out
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates check-out vehicle\'s picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidPicturesPictureGuidPut(guid, pictureGuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a vehicle check picture
     * @param {string} guid Guid of the check-out
     * @param {VehicleCheckPictureType} type Picture type
     * @param {File} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, photo?: File, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidPicturesTypePost(guid, type, photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes check-out protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidProtocolDocumentDelete(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns check-out protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidProtocolDocumentGet(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidProtocolDocumentGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads check-out protocol document.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidProtocolDocumentPut(guid: string, file?: File, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidProtocolDocumentPut(guid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the check out protocol
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidProtocolGet(guid: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidProtocolGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the check in protocol
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidProtocolHtmlGet(guid, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets check-out\'s protocol signed.
     * @param {string} guid 
     * @param {UpdateCheckOutProtocolSignedCommand} [updateCheckOutProtocolSignedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidProtocolSignedPut(guid: string, updateCheckOutProtocolSignedCommand?: UpdateCheckOutProtocolSignedCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidProtocolSignedPut(guid, updateCheckOutProtocolSignedCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates check-out data
     * @param {string} guid Guid of the check-out
     * @param {UpdateCheckOutDataCommand} [updateCheckOutDataCommand] Update check out data command data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidPut(guid: string, updateCheckOutDataCommand?: UpdateCheckOutDataCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidPut(guid, updateCheckOutDataCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in\'s billings data.
     * @param {string} guid Guid of the check-in
     * @param {SummaryCheckOutCommand} [summaryCheckOutCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutGuidSummaryPut(guid: string, summaryCheckOutCommand?: SummaryCheckOutCommand, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutGuidSummaryPut(guid, summaryCheckOutCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initializes a check-out
     * @param {string} [referenceCode] The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckOutOperatorApi
     */
    public apiV1OperatorCheckOutPost(referenceCode?: string, options?: AxiosRequestConfig) {
        return CheckOutOperatorApiFp(this.configuration).apiV1OperatorCheckOutPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }
}

