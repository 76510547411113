/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AllocateChargeTransactionIntentCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { TransactionChargeResult } from '../models';
/**
 * TransactionOpenCheckoutMarketplaceApi - axios parameter creator
 * @export
 */
export const TransactionOpenCheckoutMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates the charge transaction for a booking.
         * @param {string} [openCheckoutToken] 
         * @param {AllocateChargeTransactionIntentCommand} [allocateChargeTransactionIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost: async (openCheckoutToken?: string, allocateChargeTransactionIntentCommand?: AllocateChargeTransactionIntentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/transactions/charges/intent/using-open-checkout-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocateChargeTransactionIntentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a transaction by the transaction code
         * @param {string} referenceCode 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet: async (referenceCode: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/marketplace/transactions/charges/{referenceCode}/using-open-checkout-token`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a transaction by the provider id.
         * @param {string} [providerReferenceId] 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet: async (providerReferenceId?: string, openCheckoutToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/transactions/charges/using-open-checkout-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerReferenceId !== undefined) {
                localVarQueryParameter['providerReferenceId'] = providerReferenceId;
            }

            if (openCheckoutToken !== undefined) {
                localVarQueryParameter['openCheckoutToken'] = openCheckoutToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionOpenCheckoutMarketplaceApi - functional programming interface
 * @export
 */
export const TransactionOpenCheckoutMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionOpenCheckoutMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates the charge transaction for a booking.
         * @param {string} [openCheckoutToken] 
         * @param {AllocateChargeTransactionIntentCommand} [allocateChargeTransactionIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken?: string, allocateChargeTransactionIntentCommand?: AllocateChargeTransactionIntentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionChargeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken, allocateChargeTransactionIntentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a transaction by the transaction code
         * @param {string} referenceCode 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionChargeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a transaction by the provider id.
         * @param {string} [providerReferenceId] 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId?: string, openCheckoutToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionChargeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId, openCheckoutToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionOpenCheckoutMarketplaceApi - factory interface
 * @export
 */
export const TransactionOpenCheckoutMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionOpenCheckoutMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates the charge transaction for a booking.
         * @param {string} [openCheckoutToken] 
         * @param {AllocateChargeTransactionIntentCommand} [allocateChargeTransactionIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken?: string, allocateChargeTransactionIntentCommand?: AllocateChargeTransactionIntentCommand, options?: any): AxiosPromise<TransactionChargeResult> {
            return localVarFp.apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken, allocateChargeTransactionIntentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a transaction by the transaction code
         * @param {string} referenceCode 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode: string, openCheckoutToken?: string, options?: any): AxiosPromise<TransactionChargeResult> {
            return localVarFp.apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a transaction by the provider id.
         * @param {string} [providerReferenceId] 
         * @param {string} [openCheckoutToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId?: string, openCheckoutToken?: string, options?: any): AxiosPromise<TransactionChargeResult> {
            return localVarFp.apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId, openCheckoutToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionOpenCheckoutMarketplaceApi - interface
 * @export
 * @interface TransactionOpenCheckoutMarketplaceApi
 */
export interface TransactionOpenCheckoutMarketplaceApiInterface {
    /**
     * 
     * @summary Creates the charge transaction for a booking.
     * @param {string} [openCheckoutToken] 
     * @param {AllocateChargeTransactionIntentCommand} [allocateChargeTransactionIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOpenCheckoutMarketplaceApiInterface
     */
    apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken?: string, allocateChargeTransactionIntentCommand?: AllocateChargeTransactionIntentCommand, options?: AxiosRequestConfig): AxiosPromise<TransactionChargeResult>;

    /**
     * 
     * @summary Gets a transaction by the transaction code
     * @param {string} referenceCode 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOpenCheckoutMarketplaceApiInterface
     */
    apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<TransactionChargeResult>;

    /**
     * 
     * @summary Gets a transaction by the provider id.
     * @param {string} [providerReferenceId] 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOpenCheckoutMarketplaceApiInterface
     */
    apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId?: string, openCheckoutToken?: string, options?: AxiosRequestConfig): AxiosPromise<TransactionChargeResult>;

}

/**
 * TransactionOpenCheckoutMarketplaceApi - object-oriented interface
 * @export
 * @class TransactionOpenCheckoutMarketplaceApi
 * @extends {BaseAPI}
 */
export class TransactionOpenCheckoutMarketplaceApi extends BaseAPI implements TransactionOpenCheckoutMarketplaceApiInterface {
    /**
     * 
     * @summary Creates the charge transaction for a booking.
     * @param {string} [openCheckoutToken] 
     * @param {AllocateChargeTransactionIntentCommand} [allocateChargeTransactionIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOpenCheckoutMarketplaceApi
     */
    public apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken?: string, allocateChargeTransactionIntentCommand?: AllocateChargeTransactionIntentCommand, options?: AxiosRequestConfig) {
        return TransactionOpenCheckoutMarketplaceApiFp(this.configuration).apiV1MarketplaceTransactionsChargesIntentUsingOpenCheckoutTokenPost(openCheckoutToken, allocateChargeTransactionIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a transaction by the transaction code
     * @param {string} referenceCode 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOpenCheckoutMarketplaceApi
     */
    public apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return TransactionOpenCheckoutMarketplaceApiFp(this.configuration).apiV1MarketplaceTransactionsChargesReferenceCodeUsingOpenCheckoutTokenGet(referenceCode, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a transaction by the provider id.
     * @param {string} [providerReferenceId] 
     * @param {string} [openCheckoutToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOpenCheckoutMarketplaceApi
     */
    public apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId?: string, openCheckoutToken?: string, options?: AxiosRequestConfig) {
        return TransactionOpenCheckoutMarketplaceApiFp(this.configuration).apiV1MarketplaceTransactionsChargesUsingOpenCheckoutTokenGet(providerReferenceId, openCheckoutToken, options).then((request) => request(this.axios, this.basePath));
    }
}

