/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddFwcBookingAddonsCommand } from '../models';
// @ts-ignore
import { AvailableBookingAssigneesListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingAddCustomItemCommand } from '../models';
// @ts-ignore
import { BookingAssigneesQueryResultListResult } from '../models';
// @ts-ignore
import { BookingIntentConfirmCommand } from '../models';
// @ts-ignore
import { BookingStatusQueryResult } from '../models';
// @ts-ignore
import { CalculateOfferCustomPriceQuery } from '../models';
// @ts-ignore
import { CreateOfferCommand } from '../models';
// @ts-ignore
import { CustomerBookingReservationQueryResult } from '../models';
// @ts-ignore
import { ListingRentalCalendarPriceCalculateQuery } from '../models';
// @ts-ignore
import { ListingRentalCalendarPriceCalculateQueryResult } from '../models';
// @ts-ignore
import { ManagerBookingIntentQueryResult } from '../models';
// @ts-ignore
import { ManagerBookingOfferQueryResult } from '../models';
// @ts-ignore
import { ManagerBookingRequestQueryResult } from '../models';
// @ts-ignore
import { ManagerBookingReservationQueryResult } from '../models';
// @ts-ignore
import { ManagerReservationTransactionsQueryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ReservationSetCorrectiveInvoiceUploadedToSata } from '../models';
// @ts-ignore
import { ReservationSetInvoiceUploadedToSata } from '../models';
// @ts-ignore
import { UpdateBookingDropOffCommand } from '../models';
// @ts-ignore
import { UpdateBookingPickUpCommand } from '../models';
// @ts-ignore
import { UpdateBookingPickUpDropOffCommand } from '../models';
// @ts-ignore
import { UpdateBookingSecurityDepositCommand } from '../models';
/**
 * BookingManagerApi - axios parameter creator
 * @export
 */
export const BookingManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the available sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdAssigneesAvailableGet: async (id: string, pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdAssigneesAvailableGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdAssigneesAvailableGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdAssigneesAvailableGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/manager/bookings/{id}/assignees/available`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdAssigneesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdAssigneesGet', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/assignees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the sales assignee of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [assigneeGuid] The assignee identifier. Possible to be null to clear our the assignee.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdAssigneesPut: async (id: string, assigneeGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdAssigneesPut', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/assignees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assigneeGuid !== undefined) {
                localVarQueryParameter['assigneeGuid'] = assigneeGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdCustomItemsGuidDelete: async (id: string, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdCustomItemsGuidDelete', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdCustomItemsGuidDelete', 'guid', guid)
            const localVarPath = `/api/v1/manager/bookings/{id}/custom-items/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {BookingAddCustomItemCommand} [bookingAddCustomItemCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdCustomItemsPost: async (id: string, bookingAddCustomItemCommand?: BookingAddCustomItemCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdCustomItemsPost', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/custom-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingAddCustomItemCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdDropOffPut: async (id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdDropOffPut', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/drop-off`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingDropOffCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the custom internal note of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [note] The internal note to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdNotePut: async (id: string, note?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdNotePut', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the pick-up and drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdPickUpDropOffPut: async (id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdPickUpDropOffPut', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/pick-up-drop-off`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingPickUpDropOffCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the pick-up information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdPickUpPut: async (id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdPickUpPut', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/pick-up`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingPickUpCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the security deposit for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdSecurityDepositPut: async (id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdSecurityDepositPut', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/security-deposit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingSecurityDepositCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdStateGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerBookingsIdStateGet', 'id', id)
            const localVarPath = `/api/v1/manager/bookings/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels the booking intent.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdCancelPost: async (id: string, managerNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerIntentsIdCancelPost', 'id', id)
            const localVarPath = `/api/v1/manager/intents/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms the booking intent (instant bookable, no payment needed).
         * @param {string} id 
         * @param {string} [managerNote] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdConfirmPost: async (id: string, managerNote?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerIntentsIdConfirmPost', 'id', id)
            const localVarPath = `/api/v1/manager/intents/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms the booking intent (instant bookable, no payment needed).
         * @param {string} id 
         * @param {BookingIntentConfirmCommand} [bookingIntentConfirmCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdConfirmV2Post: async (id: string, bookingIntentConfirmCommand?: BookingIntentConfirmCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerIntentsIdConfirmV2Post', 'id', id)
            const localVarPath = `/api/v1/manager/intents/{id}/confirm-v2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingIntentConfirmCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerIntentsIdGet', 'id', id)
            const localVarPath = `/api/v1/manager/intents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates booking request from intent
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdRequestPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerIntentsIdRequestPost', 'id', id)
            const localVarPath = `/api/v1/manager/intents/{id}/request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates totals for custom pricing of offer.
         * @param {CalculateOfferCustomPriceQuery} [calculateOfferCustomPriceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersCalculateCustomPricePost: async (calculateOfferCustomPriceQuery?: CalculateOfferCustomPriceQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/manager/offers/calculate-custom-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateOfferCustomPriceQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an offer price.
         * @param {number} [listingId] 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersCalculatePricePost: async (listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/manager/offers/calculate-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (listingId !== undefined) {
                localVarQueryParameter['listingId'] = listingId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingRentalCalendarPriceCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels the booking offer.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdCancelPost: async (id: string, managerNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerOffersIdCancelPost', 'id', id)
            const localVarPath = `/api/v1/manager/offers/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms the booking offer (no payment needed).
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdConfirmPost: async (id: string, managerNote?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerOffersIdConfirmPost', 'id', id)
            const localVarPath = `/api/v1/manager/offers/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdExpirationPut: async (id: string, expiration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerOffersIdExpirationPut', 'id', id)
            const localVarPath = `/api/v1/manager/offers/{id}/expiration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = (expiration as any instanceof Date) ?
                    (expiration as any).toISOString() :
                    expiration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerOffersIdGet', 'id', id)
            const localVarPath = `/api/v1/manager/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new Booking offer
         * @param {CreateOfferCommand} [createOfferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersPost: async (createOfferCommand?: CreateOfferCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/manager/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Denies a booking offer
         * @param {string} referenceCode The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] The deny reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersReferenceCodeDenyPost: async (referenceCode: string, managerNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1ManagerOffersReferenceCodeDenyPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/manager/offers/{referenceCode}/deny`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the request state to approved.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdApprovePost: async (id: string, managerNote?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerRequestsIdApprovePost', 'id', id)
            const localVarPath = `/api/v1/manager/requests/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels the booking request.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdCancelPost: async (id: string, managerNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerRequestsIdCancelPost', 'id', id)
            const localVarPath = `/api/v1/manager/requests/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms the booking request (no payment needed).
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdConfirmPost: async (id: string, managerNote?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerRequestsIdConfirmPost', 'id', id)
            const localVarPath = `/api/v1/manager/requests/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdExpirationPut: async (id: string, expiration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerRequestsIdExpirationPut', 'id', id)
            const localVarPath = `/api/v1/manager/requests/{id}/expiration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = (expiration as any instanceof Date) ?
                    (expiration as any).toISOString() :
                    expiration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerRequestsIdGet', 'id', id)
            const localVarPath = `/api/v1/manager/requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the request state to rejected.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdRejectPost: async (id: string, managerNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerRequestsIdRejectPost', 'id', id)
            const localVarPath = `/api/v1/manager/requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds reservation addons
         * @param {string} id 
         * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdAddFwcAddonsPut: async (id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdAddFwcAddonsPut', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/add-fwc-addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFwcBookingAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels the reservation.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] The deny reason.
         * @param {boolean} [invalidateCancellationProtection] If true, the cancellation protection contract will be invalidated.  This should be set to true only, if the booking was cancelled due to Campiri error.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdCancelPost: async (id: string, managerNote?: string, reason?: string, invalidateCancellationProtection?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdCancelPost', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }

            if (invalidateCancellationProtection !== undefined) {
                localVarQueryParameter['invalidateCancellationProtection'] = invalidateCancellationProtection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets invoice as uploaded to SATA
         * @param {string} id 
         * @param {ReservationSetCorrectiveInvoiceUploadedToSata} [reservationSetCorrectiveInvoiceUploadedToSata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut: async (id: string, reservationSetCorrectiveInvoiceUploadedToSata?: ReservationSetCorrectiveInvoiceUploadedToSata, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/corrective-invoice-uploaded-to-sata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationSetCorrectiveInvoiceUploadedToSata, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a insurance uploaded document.
         * @param {string} id The reservation reference code.
         * @param {string} guid The document identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdDocumentsGuidGet: async (id: string, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdDocumentsGuidGet', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdDocumentsGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/manager/reservations/{id}/documents/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdGet', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets invoice as uploaded to SATA
         * @param {string} id 
         * @param {ReservationSetInvoiceUploadedToSata} [reservationSetInvoiceUploadedToSata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdInvoiceUploadedToSataPut: async (id: string, reservationSetInvoiceUploadedToSata?: ReservationSetInvoiceUploadedToSata, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdInvoiceUploadedToSataPut', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/invoice-uploaded-to-sata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationSetInvoiceUploadedToSata, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a corrective invoice for the reservation.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdInvoicesCorrectivePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdInvoicesCorrectivePost', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/invoices/corrective`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdTransactionsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdTransactionsGet', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingManagerApi - functional programming interface
 * @export
 */
export const BookingManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the available sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableBookingAssigneesListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdAssigneesAvailableGet(id, pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdAssigneesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingAssigneesQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdAssigneesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the sales assignee of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [assigneeGuid] The assignee identifier. Possible to be null to clear our the assignee.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdAssigneesPut(id: string, assigneeGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdAssigneesPut(id, assigneeGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdCustomItemsGuidDelete(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {BookingAddCustomItemCommand} [bookingAddCustomItemCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdCustomItemsPost(id: string, bookingAddCustomItemCommand?: BookingAddCustomItemCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdCustomItemsPost(id, bookingAddCustomItemCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdDropOffPut(id, updateBookingDropOffCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the custom internal note of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [note] The internal note to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdNotePut(id: string, note?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdNotePut(id, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the pick-up and drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdPickUpDropOffPut(id, updateBookingPickUpDropOffCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the pick-up information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdPickUpPut(id, updateBookingPickUpCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the security deposit for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdSecurityDepositPut(id, updateBookingSecurityDepositCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerBookingsIdStateGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStatusQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerBookingsIdStateGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels the booking intent.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerIntentsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerIntentsIdCancelPost(id, managerNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirms the booking intent (instant bookable, no payment needed).
         * @param {string} id 
         * @param {string} [managerNote] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1ManagerIntentsIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerIntentsIdConfirmPost(id, managerNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirms the booking intent (instant bookable, no payment needed).
         * @param {string} id 
         * @param {BookingIntentConfirmCommand} [bookingIntentConfirmCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerIntentsIdConfirmV2Post(id: string, bookingIntentConfirmCommand?: BookingIntentConfirmCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerIntentsIdConfirmV2Post(id, bookingIntentConfirmCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerIntentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerBookingIntentQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerIntentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates booking request from intent
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerIntentsIdRequestPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerIntentsIdRequestPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates totals for custom pricing of offer.
         * @param {CalculateOfferCustomPriceQuery} [calculateOfferCustomPriceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery?: CalculateOfferCustomPriceQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an offer price.
         * @param {number} [listingId] 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersCalculatePricePost(listingId, listingRentalCalendarPriceCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels the booking offer.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersIdCancelPost(id, managerNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirms the booking offer (no payment needed).
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersIdConfirmPost(id, managerNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersIdExpirationPut(id, expiration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerBookingOfferQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new Booking offer
         * @param {CreateOfferCommand} [createOfferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersPost(createOfferCommand?: CreateOfferCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersPost(createOfferCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Denies a booking offer
         * @param {string} referenceCode The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] The deny reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerOffersReferenceCodeDenyPost(referenceCode: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerOffersReferenceCodeDenyPost(referenceCode, managerNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the request state to approved.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerRequestsIdApprovePost(id: string, managerNote?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerRequestsIdApprovePost(id, managerNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels the booking request.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerRequestsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerRequestsIdCancelPost(id, managerNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirms the booking request (no payment needed).
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerRequestsIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerRequestsIdConfirmPost(id, managerNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerRequestsIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerRequestsIdExpirationPut(id, expiration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerRequestsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerBookingRequestQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the request state to rejected.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerRequestsIdRejectPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerRequestsIdRejectPost(id, managerNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds reservation addons
         * @param {string} id 
         * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdAddFwcAddonsPut(id, addFwcBookingAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels the reservation.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] The deny reason.
         * @param {boolean} [invalidateCancellationProtection] If true, the cancellation protection contract will be invalidated.  This should be set to true only, if the booking was cancelled due to Campiri error.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdCancelPost(id: string, managerNote?: string, reason?: string, invalidateCancellationProtection?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBookingReservationQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdCancelPost(id, managerNote, reason, invalidateCancellationProtection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets invoice as uploaded to SATA
         * @param {string} id 
         * @param {ReservationSetCorrectiveInvoiceUploadedToSata} [reservationSetCorrectiveInvoiceUploadedToSata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id: string, reservationSetCorrectiveInvoiceUploadedToSata?: ReservationSetCorrectiveInvoiceUploadedToSata, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id, reservationSetCorrectiveInvoiceUploadedToSata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a insurance uploaded document.
         * @param {string} id The reservation reference code.
         * @param {string} guid The document identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdDocumentsGuidGet(id: string, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdDocumentsGuidGet(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerBookingReservationQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets invoice as uploaded to SATA
         * @param {string} id 
         * @param {ReservationSetInvoiceUploadedToSata} [reservationSetInvoiceUploadedToSata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id: string, reservationSetInvoiceUploadedToSata?: ReservationSetInvoiceUploadedToSata, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id, reservationSetInvoiceUploadedToSata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a corrective invoice for the reservation.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdInvoicesCorrectivePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdInvoicesCorrectivePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdTransactionsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerReservationTransactionsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdTransactionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingManagerApi - factory interface
 * @export
 */
export const BookingManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingManagerApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the available sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<AvailableBookingAssigneesListQueryResultPaginatedResult> {
            return localVarFp.apiV1ManagerBookingsIdAssigneesAvailableGet(id, pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdAssigneesGet(id: string, options?: any): AxiosPromise<BookingAssigneesQueryResultListResult> {
            return localVarFp.apiV1ManagerBookingsIdAssigneesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the sales assignee of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [assigneeGuid] The assignee identifier. Possible to be null to clear our the assignee.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdAssigneesPut(id: string, assigneeGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdAssigneesPut(id, assigneeGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdCustomItemsGuidDelete(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {BookingAddCustomItemCommand} [bookingAddCustomItemCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdCustomItemsPost(id: string, bookingAddCustomItemCommand?: BookingAddCustomItemCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdCustomItemsPost(id, bookingAddCustomItemCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdDropOffPut(id, updateBookingDropOffCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the custom internal note of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [note] The internal note to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdNotePut(id: string, note?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdNotePut(id, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the pick-up and drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdPickUpDropOffPut(id, updateBookingPickUpDropOffCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the pick-up information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdPickUpPut(id, updateBookingPickUpCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the security deposit for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerBookingsIdSecurityDepositPut(id, updateBookingSecurityDepositCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerBookingsIdStateGet(id: string, options?: any): AxiosPromise<BookingStatusQueryResult> {
            return localVarFp.apiV1ManagerBookingsIdStateGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels the booking intent.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerIntentsIdCancelPost(id, managerNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms the booking intent (instant bookable, no payment needed).
         * @param {string} id 
         * @param {string} [managerNote] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdConfirmPost(id: string, managerNote?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerIntentsIdConfirmPost(id, managerNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms the booking intent (instant bookable, no payment needed).
         * @param {string} id 
         * @param {BookingIntentConfirmCommand} [bookingIntentConfirmCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdConfirmV2Post(id: string, bookingIntentConfirmCommand?: BookingIntentConfirmCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerIntentsIdConfirmV2Post(id, bookingIntentConfirmCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking intent information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdGet(id: string, options?: any): AxiosPromise<ManagerBookingIntentQueryResult> {
            return localVarFp.apiV1ManagerIntentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates booking request from intent
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerIntentsIdRequestPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerIntentsIdRequestPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates totals for custom pricing of offer.
         * @param {CalculateOfferCustomPriceQuery} [calculateOfferCustomPriceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery?: CalculateOfferCustomPriceQuery, options?: any): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult> {
            return localVarFp.apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an offer price.
         * @param {number} [listingId] 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: any): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult> {
            return localVarFp.apiV1ManagerOffersCalculatePricePost(listingId, listingRentalCalendarPriceCalculateQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels the booking offer.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdCancelPost(id: string, managerNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerOffersIdCancelPost(id, managerNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms the booking offer (no payment needed).
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdConfirmPost(id: string, managerNote?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerOffersIdConfirmPost(id, managerNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdExpirationPut(id: string, expiration?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerOffersIdExpirationPut(id, expiration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersIdGet(id: string, options?: any): AxiosPromise<ManagerBookingOfferQueryResult> {
            return localVarFp.apiV1ManagerOffersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new Booking offer
         * @param {CreateOfferCommand} [createOfferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersPost(createOfferCommand?: CreateOfferCommand, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1ManagerOffersPost(createOfferCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Denies a booking offer
         * @param {string} referenceCode The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] The deny reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerOffersReferenceCodeDenyPost(referenceCode: string, managerNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerOffersReferenceCodeDenyPost(referenceCode, managerNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the request state to approved.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdApprovePost(id: string, managerNote?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerRequestsIdApprovePost(id, managerNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels the booking request.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerRequestsIdCancelPost(id, managerNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms the booking request (no payment needed).
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdConfirmPost(id: string, managerNote?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerRequestsIdConfirmPost(id, managerNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdExpirationPut(id: string, expiration?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerRequestsIdExpirationPut(id, expiration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdGet(id: string, options?: any): AxiosPromise<ManagerBookingRequestQueryResult> {
            return localVarFp.apiV1ManagerRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the request state to rejected.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerRequestsIdRejectPost(id: string, managerNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerRequestsIdRejectPost(id, managerNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds reservation addons
         * @param {string} id 
         * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsIdAddFwcAddonsPut(id, addFwcBookingAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels the reservation.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] The deny reason.
         * @param {boolean} [invalidateCancellationProtection] If true, the cancellation protection contract will be invalidated.  This should be set to true only, if the booking was cancelled due to Campiri error.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdCancelPost(id: string, managerNote?: string, reason?: string, invalidateCancellationProtection?: boolean, options?: any): AxiosPromise<CustomerBookingReservationQueryResult> {
            return localVarFp.apiV1ManagerReservationsIdCancelPost(id, managerNote, reason, invalidateCancellationProtection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets invoice as uploaded to SATA
         * @param {string} id 
         * @param {ReservationSetCorrectiveInvoiceUploadedToSata} [reservationSetCorrectiveInvoiceUploadedToSata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id: string, reservationSetCorrectiveInvoiceUploadedToSata?: ReservationSetCorrectiveInvoiceUploadedToSata, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id, reservationSetCorrectiveInvoiceUploadedToSata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a insurance uploaded document.
         * @param {string} id The reservation reference code.
         * @param {string} guid The document identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdDocumentsGuidGet(id: string, guid: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1ManagerReservationsIdDocumentsGuidGet(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdGet(id: string, options?: any): AxiosPromise<ManagerBookingReservationQueryResult> {
            return localVarFp.apiV1ManagerReservationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets invoice as uploaded to SATA
         * @param {string} id 
         * @param {ReservationSetInvoiceUploadedToSata} [reservationSetInvoiceUploadedToSata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id: string, reservationSetInvoiceUploadedToSata?: ReservationSetInvoiceUploadedToSata, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id, reservationSetInvoiceUploadedToSata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a corrective invoice for the reservation.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdInvoicesCorrectivePost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsIdInvoicesCorrectivePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdTransactionsGet(id: string, options?: any): AxiosPromise<ManagerReservationTransactionsQueryResult> {
            return localVarFp.apiV1ManagerReservationsIdTransactionsGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingManagerApi - interface
 * @export
 * @interface BookingManagerApi
 */
export interface BookingManagerApiInterface {
    /**
     * 
     * @summary Gets the available sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<AvailableBookingAssigneesListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets the sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdAssigneesGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingAssigneesQueryResultListResult>;

    /**
     * 
     * @summary Updates the sales assignee of the booking.
     * @param {string} id The booking reference code.
     * @param {string} [assigneeGuid] The assignee identifier. Possible to be null to clear our the assignee.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdAssigneesPut(id: string, assigneeGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {BookingAddCustomItemCommand} [bookingAddCustomItemCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdCustomItemsPost(id: string, bookingAddCustomItemCommand?: BookingAddCustomItemCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the custom internal note of the booking.
     * @param {string} id The booking reference code.
     * @param {string} [note] The internal note to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdNotePut(id: string, note?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the pick-up and drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the pick-up information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the security deposit for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking status information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerBookingsIdStateGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingStatusQueryResult>;

    /**
     * 
     * @summary Cancels the booking intent.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerIntentsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Confirms the booking intent (instant bookable, no payment needed).
     * @param {string} id 
     * @param {string} [managerNote] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerIntentsIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Confirms the booking intent (instant bookable, no payment needed).
     * @param {string} id 
     * @param {BookingIntentConfirmCommand} [bookingIntentConfirmCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerIntentsIdConfirmV2Post(id: string, bookingIntentConfirmCommand?: BookingIntentConfirmCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking intent information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerIntentsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ManagerBookingIntentQueryResult>;

    /**
     * 
     * @summary Creates booking request from intent
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerIntentsIdRequestPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Calculates totals for custom pricing of offer.
     * @param {CalculateOfferCustomPriceQuery} [calculateOfferCustomPriceQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery?: CalculateOfferCustomPriceQuery, options?: AxiosRequestConfig): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>;

    /**
     * 
     * @summary Gets an offer price.
     * @param {number} [listingId] 
     * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>;

    /**
     * 
     * @summary Cancels the booking offer.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Confirms the booking offer (no payment needed).
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes the booking\'s expiration date.
     * @param {string} id The booking reference code identifier.
     * @param {string} [expiration] New expiration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking offer information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ManagerBookingOfferQueryResult>;

    /**
     * 
     * @summary Creates new Booking offer
     * @param {CreateOfferCommand} [createOfferCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersPost(createOfferCommand?: CreateOfferCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Denies a booking offer
     * @param {string} referenceCode The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] The deny reason.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerOffersReferenceCodeDenyPost(referenceCode: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes the request state to approved.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerRequestsIdApprovePost(id: string, managerNote?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Cancels the booking request.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerRequestsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Confirms the booking request (no payment needed).
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerRequestsIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes the booking\'s expiration date.
     * @param {string} id The booking reference code identifier.
     * @param {string} [expiration] New expiration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerRequestsIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking request information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerRequestsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ManagerBookingRequestQueryResult>;

    /**
     * 
     * @summary Changes the request state to rejected.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerRequestsIdRejectPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Adds reservation addons
     * @param {string} id 
     * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Cancels the reservation.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] The deny reason.
     * @param {boolean} [invalidateCancellationProtection] If true, the cancellation protection contract will be invalidated.  This should be set to true only, if the booking was cancelled due to Campiri error.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdCancelPost(id: string, managerNote?: string, reason?: string, invalidateCancellationProtection?: boolean, options?: AxiosRequestConfig): AxiosPromise<CustomerBookingReservationQueryResult>;

    /**
     * 
     * @summary Sets invoice as uploaded to SATA
     * @param {string} id 
     * @param {ReservationSetCorrectiveInvoiceUploadedToSata} [reservationSetCorrectiveInvoiceUploadedToSata] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id: string, reservationSetCorrectiveInvoiceUploadedToSata?: ReservationSetCorrectiveInvoiceUploadedToSata, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a insurance uploaded document.
     * @param {string} id The reservation reference code.
     * @param {string} guid The document identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdDocumentsGuidGet(id: string, guid: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ManagerBookingReservationQueryResult>;

    /**
     * 
     * @summary Sets invoice as uploaded to SATA
     * @param {string} id 
     * @param {ReservationSetInvoiceUploadedToSata} [reservationSetInvoiceUploadedToSata] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id: string, reservationSetInvoiceUploadedToSata?: ReservationSetInvoiceUploadedToSata, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Generates a corrective invoice for the reservation.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdInvoicesCorrectivePost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApiInterface
     */
    apiV1ManagerReservationsIdTransactionsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ManagerReservationTransactionsQueryResult>;

}

/**
 * BookingManagerApi - object-oriented interface
 * @export
 * @class BookingManagerApi
 * @extends {BaseAPI}
 */
export class BookingManagerApi extends BaseAPI implements BookingManagerApiInterface {
    /**
     * 
     * @summary Gets the available sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdAssigneesAvailableGet(id, pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdAssigneesGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdAssigneesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the sales assignee of the booking.
     * @param {string} id The booking reference code.
     * @param {string} [assigneeGuid] The assignee identifier. Possible to be null to clear our the assignee.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdAssigneesPut(id: string, assigneeGuid?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdAssigneesPut(id, assigneeGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdCustomItemsGuidDelete(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {BookingAddCustomItemCommand} [bookingAddCustomItemCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdCustomItemsPost(id: string, bookingAddCustomItemCommand?: BookingAddCustomItemCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdCustomItemsPost(id, bookingAddCustomItemCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdDropOffPut(id, updateBookingDropOffCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the custom internal note of the booking.
     * @param {string} id The booking reference code.
     * @param {string} [note] The internal note to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdNotePut(id: string, note?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdNotePut(id, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the pick-up and drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdPickUpDropOffPut(id, updateBookingPickUpDropOffCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the pick-up information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdPickUpPut(id, updateBookingPickUpCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the security deposit for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdSecurityDepositPut(id, updateBookingSecurityDepositCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking status information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerBookingsIdStateGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerBookingsIdStateGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels the booking intent.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerIntentsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerIntentsIdCancelPost(id, managerNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms the booking intent (instant bookable, no payment needed).
     * @param {string} id 
     * @param {string} [managerNote] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerIntentsIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerIntentsIdConfirmPost(id, managerNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms the booking intent (instant bookable, no payment needed).
     * @param {string} id 
     * @param {BookingIntentConfirmCommand} [bookingIntentConfirmCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerIntentsIdConfirmV2Post(id: string, bookingIntentConfirmCommand?: BookingIntentConfirmCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerIntentsIdConfirmV2Post(id, bookingIntentConfirmCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking intent information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerIntentsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerIntentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates booking request from intent
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerIntentsIdRequestPost(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerIntentsIdRequestPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates totals for custom pricing of offer.
     * @param {CalculateOfferCustomPriceQuery} [calculateOfferCustomPriceQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery?: CalculateOfferCustomPriceQuery, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersCalculateCustomPricePost(calculateOfferCustomPriceQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an offer price.
     * @param {number} [listingId] 
     * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersCalculatePricePost(listingId, listingRentalCalendarPriceCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels the booking offer.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersIdCancelPost(id, managerNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms the booking offer (no payment needed).
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersIdConfirmPost(id, managerNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the booking\'s expiration date.
     * @param {string} id The booking reference code identifier.
     * @param {string} [expiration] New expiration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersIdExpirationPut(id, expiration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking offer information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new Booking offer
     * @param {CreateOfferCommand} [createOfferCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersPost(createOfferCommand?: CreateOfferCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersPost(createOfferCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Denies a booking offer
     * @param {string} referenceCode The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] The deny reason.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerOffersReferenceCodeDenyPost(referenceCode: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerOffersReferenceCodeDenyPost(referenceCode, managerNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the request state to approved.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerRequestsIdApprovePost(id: string, managerNote?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerRequestsIdApprovePost(id, managerNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels the booking request.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerRequestsIdCancelPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerRequestsIdCancelPost(id, managerNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms the booking request (no payment needed).
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerRequestsIdConfirmPost(id: string, managerNote?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerRequestsIdConfirmPost(id, managerNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the booking\'s expiration date.
     * @param {string} id The booking reference code identifier.
     * @param {string} [expiration] New expiration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerRequestsIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerRequestsIdExpirationPut(id, expiration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking request information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerRequestsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the request state to rejected.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerRequestsIdRejectPost(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerRequestsIdRejectPost(id, managerNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds reservation addons
     * @param {string} id 
     * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdAddFwcAddonsPut(id, addFwcBookingAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels the reservation.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] The deny reason.
     * @param {boolean} [invalidateCancellationProtection] If true, the cancellation protection contract will be invalidated.  This should be set to true only, if the booking was cancelled due to Campiri error.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdCancelPost(id: string, managerNote?: string, reason?: string, invalidateCancellationProtection?: boolean, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdCancelPost(id, managerNote, reason, invalidateCancellationProtection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets invoice as uploaded to SATA
     * @param {string} id 
     * @param {ReservationSetCorrectiveInvoiceUploadedToSata} [reservationSetCorrectiveInvoiceUploadedToSata] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id: string, reservationSetCorrectiveInvoiceUploadedToSata?: ReservationSetCorrectiveInvoiceUploadedToSata, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdCorrectiveInvoiceUploadedToSataPut(id, reservationSetCorrectiveInvoiceUploadedToSata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a insurance uploaded document.
     * @param {string} id The reservation reference code.
     * @param {string} guid The document identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdDocumentsGuidGet(id: string, guid: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdDocumentsGuidGet(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets invoice as uploaded to SATA
     * @param {string} id 
     * @param {ReservationSetInvoiceUploadedToSata} [reservationSetInvoiceUploadedToSata] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id: string, reservationSetInvoiceUploadedToSata?: ReservationSetInvoiceUploadedToSata, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdInvoiceUploadedToSataPut(id, reservationSetInvoiceUploadedToSata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a corrective invoice for the reservation.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdInvoicesCorrectivePost(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdInvoicesCorrectivePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingManagerApi
     */
    public apiV1ManagerReservationsIdTransactionsGet(id: string, options?: AxiosRequestConfig) {
        return BookingManagerApiFp(this.configuration).apiV1ManagerReservationsIdTransactionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

