/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateChargeTransactionPaymentCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { TransactionChargeResult } from '../models';
/**
 * TransactionOperatorApi - axios parameter creator
 * @export
 */
export const TransactionOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Records a successful off-system payment (valid for requests and reservations).
         * @param {CreateChargeTransactionPaymentCommand} [createChargeTransactionPaymentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorTransactionsChargesPaymentPost: async (createChargeTransactionPaymentCommand?: CreateChargeTransactionPaymentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/transactions/charges/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChargeTransactionPaymentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a transaction by the transaction code
         * @param {string} referenceCode Transaction reference code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorTransactionsChargesReferenceCodeGet: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1OperatorTransactionsChargesReferenceCodeGet', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/operator/transactions/charges/{referenceCode}`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionOperatorApi - functional programming interface
 * @export
 */
export const TransactionOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Records a successful off-system payment (valid for requests and reservations).
         * @param {CreateChargeTransactionPaymentCommand} [createChargeTransactionPaymentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand?: CreateChargeTransactionPaymentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionChargeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a transaction by the transaction code
         * @param {string} referenceCode Transaction reference code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionChargeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionOperatorApi - factory interface
 * @export
 */
export const TransactionOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Records a successful off-system payment (valid for requests and reservations).
         * @param {CreateChargeTransactionPaymentCommand} [createChargeTransactionPaymentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand?: CreateChargeTransactionPaymentCommand, options?: any): AxiosPromise<TransactionChargeResult> {
            return localVarFp.apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a transaction by the transaction code
         * @param {string} referenceCode Transaction reference code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode: string, options?: any): AxiosPromise<TransactionChargeResult> {
            return localVarFp.apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionOperatorApi - interface
 * @export
 * @interface TransactionOperatorApi
 */
export interface TransactionOperatorApiInterface {
    /**
     * 
     * @summary Records a successful off-system payment (valid for requests and reservations).
     * @param {CreateChargeTransactionPaymentCommand} [createChargeTransactionPaymentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOperatorApiInterface
     */
    apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand?: CreateChargeTransactionPaymentCommand, options?: AxiosRequestConfig): AxiosPromise<TransactionChargeResult>;

    /**
     * 
     * @summary Gets a transaction by the transaction code
     * @param {string} referenceCode Transaction reference code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOperatorApiInterface
     */
    apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<TransactionChargeResult>;

}

/**
 * TransactionOperatorApi - object-oriented interface
 * @export
 * @class TransactionOperatorApi
 * @extends {BaseAPI}
 */
export class TransactionOperatorApi extends BaseAPI implements TransactionOperatorApiInterface {
    /**
     * 
     * @summary Records a successful off-system payment (valid for requests and reservations).
     * @param {CreateChargeTransactionPaymentCommand} [createChargeTransactionPaymentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOperatorApi
     */
    public apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand?: CreateChargeTransactionPaymentCommand, options?: AxiosRequestConfig) {
        return TransactionOperatorApiFp(this.configuration).apiV1OperatorTransactionsChargesPaymentPost(createChargeTransactionPaymentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a transaction by the transaction code
     * @param {string} referenceCode Transaction reference code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionOperatorApi
     */
    public apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode: string, options?: AxiosRequestConfig) {
        return TransactionOperatorApiFp(this.configuration).apiV1OperatorTransactionsChargesReferenceCodeGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }
}

