/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckResult } from '../models';
// @ts-ignore
import { DocumentSide } from '../models';
// @ts-ignore
import { DocumentType } from '../models';
// @ts-ignore
import { KycRequestListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { KycRequestsByGuidQueryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RequestStatus } from '../models';
// @ts-ignore
import { UpdateAndApproveKycRequestCommand } from '../models';
/**
 * KycAdminApi - axios parameter creator
 * @export
 */
export const KycAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates Kyc request applicant data, then approves the request.
         * @param {string} guid 
         * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidApprovePut: async (guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminKycRequestGuidApprovePut', 'guid', guid)
            const localVarPath = `/api/v1/admin/kyc/request/{guid}/approve`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAndApproveKycRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Denies Kyc Request and unverifies campiri account
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidDenyPut: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminKycRequestGuidDenyPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/kyc/request/{guid}/deny`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {DocumentType} [type] 
         * @param {DocumentSide} [side] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidDocumentsGet: async (guid: string, type?: DocumentType, side?: DocumentSide, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminKycRequestGuidDocumentsGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/kyc/request/{guid}/documents`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (side !== undefined) {
                localVarQueryParameter['side'] = side;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets kyc request by its identifier.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminKycRequestGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/kyc/request/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of Kyc requests in requestet state
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {RequestStatus} [status] 
         * @param {CheckResult} [checkResult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestsGet: async (pageNumber: number, pageSize: number, search?: string, status?: RequestStatus, checkResult?: CheckResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminKycRequestsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminKycRequestsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/kyc/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (checkResult !== undefined) {
                localVarQueryParameter['CheckResult'] = checkResult;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KycAdminApi - functional programming interface
 * @export
 */
export const KycAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KycAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates Kyc request applicant data, then approves the request.
         * @param {string} guid 
         * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminKycRequestGuidApprovePut(guid, updateAndApproveKycRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Denies Kyc Request and unverifies campiri account
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminKycRequestGuidDenyPut(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminKycRequestGuidDenyPut(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {DocumentType} [type] 
         * @param {DocumentSide} [side] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminKycRequestGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminKycRequestGuidDocumentsGet(guid, type, side, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets kyc request by its identifier.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminKycRequestGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRequestsByGuidQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminKycRequestGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of Kyc requests in requestet state
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {RequestStatus} [status] 
         * @param {CheckResult} [checkResult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminKycRequestsGet(pageNumber: number, pageSize: number, search?: string, status?: RequestStatus, checkResult?: CheckResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRequestListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminKycRequestsGet(pageNumber, pageSize, search, status, checkResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KycAdminApi - factory interface
 * @export
 */
export const KycAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KycAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates Kyc request applicant data, then approves the request.
         * @param {string} guid 
         * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminKycRequestGuidApprovePut(guid, updateAndApproveKycRequestCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Denies Kyc Request and unverifies campiri account
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidDenyPut(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminKycRequestGuidDenyPut(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {DocumentType} [type] 
         * @param {DocumentSide} [side] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminKycRequestGuidDocumentsGet(guid, type, side, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets kyc request by its identifier.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestGuidGet(guid: string, options?: any): AxiosPromise<KycRequestsByGuidQueryResult> {
            return localVarFp.apiV1AdminKycRequestGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of Kyc requests in requestet state
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {RequestStatus} [status] 
         * @param {CheckResult} [checkResult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminKycRequestsGet(pageNumber: number, pageSize: number, search?: string, status?: RequestStatus, checkResult?: CheckResult, options?: any): AxiosPromise<KycRequestListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminKycRequestsGet(pageNumber, pageSize, search, status, checkResult, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KycAdminApi - interface
 * @export
 * @interface KycAdminApi
 */
export interface KycAdminApiInterface {
    /**
     * 
     * @summary Updates Kyc request applicant data, then approves the request.
     * @param {string} guid 
     * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApiInterface
     */
    apiV1AdminKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Denies Kyc Request and unverifies campiri account
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApiInterface
     */
    apiV1AdminKycRequestGuidDenyPut(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a KYC uploaded document.
     * @param {string} guid 
     * @param {DocumentType} [type] 
     * @param {DocumentSide} [side] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApiInterface
     */
    apiV1AdminKycRequestGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets kyc request by its identifier.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApiInterface
     */
    apiV1AdminKycRequestGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<KycRequestsByGuidQueryResult>;

    /**
     * 
     * @summary Gets list of Kyc requests in requestet state
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {RequestStatus} [status] 
     * @param {CheckResult} [checkResult] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApiInterface
     */
    apiV1AdminKycRequestsGet(pageNumber: number, pageSize: number, search?: string, status?: RequestStatus, checkResult?: CheckResult, options?: AxiosRequestConfig): AxiosPromise<KycRequestListQueryResultPaginatedResult>;

}

/**
 * KycAdminApi - object-oriented interface
 * @export
 * @class KycAdminApi
 * @extends {BaseAPI}
 */
export class KycAdminApi extends BaseAPI implements KycAdminApiInterface {
    /**
     * 
     * @summary Updates Kyc request applicant data, then approves the request.
     * @param {string} guid 
     * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApi
     */
    public apiV1AdminKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: AxiosRequestConfig) {
        return KycAdminApiFp(this.configuration).apiV1AdminKycRequestGuidApprovePut(guid, updateAndApproveKycRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Denies Kyc Request and unverifies campiri account
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApi
     */
    public apiV1AdminKycRequestGuidDenyPut(guid: string, options?: AxiosRequestConfig) {
        return KycAdminApiFp(this.configuration).apiV1AdminKycRequestGuidDenyPut(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a KYC uploaded document.
     * @param {string} guid 
     * @param {DocumentType} [type] 
     * @param {DocumentSide} [side] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApi
     */
    public apiV1AdminKycRequestGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: AxiosRequestConfig) {
        return KycAdminApiFp(this.configuration).apiV1AdminKycRequestGuidDocumentsGet(guid, type, side, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets kyc request by its identifier.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApi
     */
    public apiV1AdminKycRequestGuidGet(guid: string, options?: AxiosRequestConfig) {
        return KycAdminApiFp(this.configuration).apiV1AdminKycRequestGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of Kyc requests in requestet state
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {RequestStatus} [status] 
     * @param {CheckResult} [checkResult] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycAdminApi
     */
    public apiV1AdminKycRequestsGet(pageNumber: number, pageSize: number, search?: string, status?: RequestStatus, checkResult?: CheckResult, options?: AxiosRequestConfig) {
        return KycAdminApiFp(this.configuration).apiV1AdminKycRequestsGet(pageNumber, pageSize, search, status, checkResult, options).then((request) => request(this.axios, this.basePath));
    }
}

