/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PipeDriveOperation } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary This EP reenqueues the cancellation protection job in hangfire.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/utils/bookings/{referenceCode}/register-cancel-cancellation-protection-job`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP reenqueues the cancellation protection job in hangfire.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/utils/bookings/{referenceCode}/register-cancellation-protection-job`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} referenceCode 
         * @param {PipeDriveOperation} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut: async (referenceCode: string, action?: PipeDriveOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/utils/bookings/{referenceCode}/update-pipedrive`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP reenqueues the mailer to customer for reservation cancelled event.
         * @param {string} [reservationGuid] 
         * @param {string} [userGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsSendReservationCancelledMailerPost: async (reservationGuid?: string, userGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils/bookings/send-reservation-cancelled-mailer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reservationGuid !== undefined) {
                localVarQueryParameter['reservationGuid'] = reservationGuid;
            }

            if (userGuid !== undefined) {
                localVarQueryParameter['userGuid'] = userGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsListingsGuidTriggerEventsPut: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1UtilsListingsGuidTriggerEventsPut', 'guid', guid)
            const localVarPath = `/api/v1/utils/listings/{guid}/trigger-events`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsListingsTriggerEventsPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils/listings/trigger-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsModelsGuidTriggerEventsPut: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1UtilsModelsGuidTriggerEventsPut', 'guid', guid)
            const localVarPath = `/api/v1/utils/models/{guid}/trigger-events`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsModelsTriggerEventsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils/models/trigger-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsPricingsIdTriggerEventsPut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UtilsPricingsIdTriggerEventsPut', 'id', id)
            const localVarPath = `/api/v1/utils/pricings/{id}/trigger-events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP regenerates the rental listing prices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsPricingsTriggerEventsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils/pricings/trigger-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This EP reenqueues the mailer to customer for reservation cancelled event.  NOTE: keep in mind that setting the reusability to true, will remove tracking of codes usage - therefore if set back to false, all codes will be unused (and applicable).
         * @param {number} id 
         * @param {boolean} [reusable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost: async (id: number, reusable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost', 'id', id)
            const localVarPath = `/api/v1/utils/promo-campaigns/{id}/force-campaign-reusability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reusable !== undefined) {
                localVarQueryParameter['reusable'] = reusable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary This EP reenqueues the cancellation protection job in hangfire.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP reenqueues the cancellation protection job in hangfire.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} referenceCode 
         * @param {PipeDriveOperation} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode: string, action?: PipeDriveOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP reenqueues the mailer to customer for reservation cancelled event.
         * @param {string} [reservationGuid] 
         * @param {string} [userGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid?: string, userGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsListingsGuidTriggerEventsPut(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsListingsGuidTriggerEventsPut(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsListingsTriggerEventsPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsListingsTriggerEventsPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsModelsGuidTriggerEventsPut(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsModelsGuidTriggerEventsPut(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsModelsTriggerEventsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsModelsTriggerEventsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsPricingsIdTriggerEventsPut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsPricingsIdTriggerEventsPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP regenerates the rental listing prices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsPricingsTriggerEventsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsPricingsTriggerEventsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This EP reenqueues the mailer to customer for reservation cancelled event.  NOTE: keep in mind that setting the reusability to true, will remove tracking of codes usage - therefore if set back to false, all codes will be unused (and applicable).
         * @param {number} id 
         * @param {boolean} [reusable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id: number, reusable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id, reusable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary This EP reenqueues the cancellation protection job in hangfire.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP reenqueues the cancellation protection job in hangfire.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} referenceCode 
         * @param {PipeDriveOperation} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode: string, action?: PipeDriveOperation, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode, action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP reenqueues the mailer to customer for reservation cancelled event.
         * @param {string} [reservationGuid] 
         * @param {string} [userGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid?: string, userGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsListingsGuidTriggerEventsPut(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsListingsGuidTriggerEventsPut(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsListingsTriggerEventsPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsListingsTriggerEventsPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsModelsGuidTriggerEventsPut(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsModelsGuidTriggerEventsPut(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsModelsTriggerEventsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsModelsTriggerEventsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP retriggers the events, that updates the proxy entities.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsPricingsIdTriggerEventsPut(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsPricingsIdTriggerEventsPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP regenerates the rental listing prices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsPricingsTriggerEventsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsPricingsTriggerEventsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This EP reenqueues the mailer to customer for reservation cancelled event.  NOTE: keep in mind that setting the reusability to true, will remove tracking of codes usage - therefore if set back to false, all codes will be unused (and applicable).
         * @param {number} id 
         * @param {boolean} [reusable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id: number, reusable?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id, reusable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitiesApi - interface
 * @export
 * @interface UtilitiesApi
 */
export interface UtilitiesApiInterface {
    /**
     * 
     * @summary This EP reenqueues the cancellation protection job in hangfire.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP reenqueues the cancellation protection job in hangfire.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {string} referenceCode 
     * @param {PipeDriveOperation} [action] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode: string, action?: PipeDriveOperation, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP reenqueues the mailer to customer for reservation cancelled event.
     * @param {string} [reservationGuid] 
     * @param {string} [userGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid?: string, userGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsListingsGuidTriggerEventsPut(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsListingsTriggerEventsPut(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsModelsGuidTriggerEventsPut(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsModelsTriggerEventsGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsPricingsIdTriggerEventsPut(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP regenerates the rental listing prices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsPricingsTriggerEventsGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This EP reenqueues the mailer to customer for reservation cancelled event.  NOTE: keep in mind that setting the reusability to true, will remove tracking of codes usage - therefore if set back to false, all codes will be unused (and applicable).
     * @param {number} id 
     * @param {boolean} [reusable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApiInterface
     */
    apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id: number, reusable?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI implements UtilitiesApiInterface {
    /**
     * 
     * @summary This EP reenqueues the cancellation protection job in hangfire.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsBookingsReferenceCodeRegisterCancelCancellationProtectionJobPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP reenqueues the cancellation protection job in hangfire.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsBookingsReferenceCodeRegisterCancellationProtectionJobPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {string} referenceCode 
     * @param {PipeDriveOperation} [action] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode: string, action?: PipeDriveOperation, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsBookingsReferenceCodeUpdatePipedrivePut(referenceCode, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP reenqueues the mailer to customer for reservation cancelled event.
     * @param {string} [reservationGuid] 
     * @param {string} [userGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid?: string, userGuid?: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsBookingsSendReservationCancelledMailerPost(reservationGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsListingsGuidTriggerEventsPut(guid: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsListingsGuidTriggerEventsPut(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsListingsTriggerEventsPut(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsListingsTriggerEventsPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsModelsGuidTriggerEventsPut(guid: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsModelsGuidTriggerEventsPut(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsModelsTriggerEventsGet(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsModelsTriggerEventsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP retriggers the events, that updates the proxy entities.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsPricingsIdTriggerEventsPut(id: number, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsPricingsIdTriggerEventsPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP regenerates the rental listing prices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsPricingsTriggerEventsGet(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsPricingsTriggerEventsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This EP reenqueues the mailer to customer for reservation cancelled event.  NOTE: keep in mind that setting the reusability to true, will remove tracking of codes usage - therefore if set back to false, all codes will be unused (and applicable).
     * @param {number} id 
     * @param {boolean} [reusable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id: number, reusable?: boolean, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).apiV1UtilsPromoCampaignsIdForceCampaignReusabilityPost(id, reusable, options).then((request) => request(this.axios, this.basePath));
    }
}

