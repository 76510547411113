/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptAlterationAsOperatorCommand } from '../models';
// @ts-ignore
import { AlterationCalendarDaysQueryResultListResult } from '../models';
// @ts-ignore
import { AlterationState } from '../models';
// @ts-ignore
import { CreateAlterationAsOperatorCommand } from '../models';
// @ts-ignore
import { CreateAlterationAsOperatorCommandResult } from '../models';
// @ts-ignore
import { DeclineAlterationAsOperatorCommand } from '../models';
// @ts-ignore
import { GetOperatorBookingAlterationsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { WithdrawAlterationAsOperatorCommand } from '../models';
/**
 * AlterationOperatorApi - axios parameter creator
 * @export
 */
export const AlterationOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
         * @param {string} id 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAlterationsCalendarDaysGet: async (id: string, start?: string, end?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorReservationsIdAlterationsCalendarDaysGet', 'id', id)
            const localVarPath = `/api/v1/operator/reservations/{id}/alterations/calendar_days`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAlterationsGet: async (id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorReservationsIdAlterationsGet', 'id', id)
            const localVarPath = `/api/v1/operator/reservations/{id}/alterations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsOperatorCommand} [createAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAlterationsPost: async (id: string, createAlterationAsOperatorCommand?: CreateAlterationAsOperatorCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorReservationsIdAlterationsPost', 'id', id)
            const localVarPath = `/api/v1/operator/reservations/{id}/alterations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAlterationAsOperatorCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsOperatorCommand} [acceptAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut: async (reservationId: string, alterationId: number, acceptAlterationAsOperatorCommand?: AcceptAlterationAsOperatorCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/operator/reservations/{reservationId}/alterations/{alterationId}/accept`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptAlterationAsOperatorCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Declines an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsOperatorCommand} [declineAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut: async (reservationId: string, alterationId: number, declineAlterationAsOperatorCommand?: DeclineAlterationAsOperatorCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/operator/reservations/{reservationId}/alterations/{alterationId}/decline`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(declineAlterationAsOperatorCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut: async (reservationId: string, alterationId: number, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/operator/reservations/{reservationId}/alterations/{alterationId}/dismiss-result-notification`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Withdraws an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsOperatorCommand} [withdrawAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut: async (reservationId: string, alterationId: number, withdrawAlterationAsOperatorCommand?: WithdrawAlterationAsOperatorCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/operator/reservations/{reservationId}/alterations/{alterationId}/withdraw`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawAlterationAsOperatorCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlterationOperatorApi - functional programming interface
 * @export
 */
export const AlterationOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlterationOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
         * @param {string} id 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlterationCalendarDaysQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorBookingAlterationsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsOperatorCommand} [createAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsIdAlterationsPost(id: string, createAlterationAsOperatorCommand?: CreateAlterationAsOperatorCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAlterationAsOperatorCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsIdAlterationsPost(id, createAlterationAsOperatorCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsOperatorCommand} [acceptAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsOperatorCommand?: AcceptAlterationAsOperatorCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsOperatorCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Declines an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsOperatorCommand} [declineAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsOperatorCommand?: DeclineAlterationAsOperatorCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsOperatorCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Withdraws an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsOperatorCommand} [withdrawAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsOperatorCommand?: WithdrawAlterationAsOperatorCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsOperatorCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlterationOperatorApi - factory interface
 * @export
 */
export const AlterationOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlterationOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
         * @param {string} id 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: any): AxiosPromise<AlterationCalendarDaysQueryResultListResult> {
            return localVarFp.apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: any): AxiosPromise<GetOperatorBookingAlterationsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsOperatorCommand} [createAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAlterationsPost(id: string, createAlterationAsOperatorCommand?: CreateAlterationAsOperatorCommand, options?: any): AxiosPromise<CreateAlterationAsOperatorCommandResult> {
            return localVarFp.apiV1OperatorReservationsIdAlterationsPost(id, createAlterationAsOperatorCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsOperatorCommand} [acceptAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsOperatorCommand?: AcceptAlterationAsOperatorCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsOperatorCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Declines an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsOperatorCommand} [declineAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsOperatorCommand?: DeclineAlterationAsOperatorCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsOperatorCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Withdraws an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsOperatorCommand} [withdrawAlterationAsOperatorCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsOperatorCommand?: WithdrawAlterationAsOperatorCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsOperatorCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlterationOperatorApi - interface
 * @export
 * @interface AlterationOperatorApi
 */
export interface AlterationOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
     * @param {string} id 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: AxiosRequestConfig): AxiosPromise<AlterationCalendarDaysQueryResultListResult>;

    /**
     * 
     * @summary Gets a list of all related alterations and available actions.
     * @param {string} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {AlterationState} [state] Optional alteration state to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig): AxiosPromise<GetOperatorBookingAlterationsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates a new reservation alteration request.
     * @param {string} id 
     * @param {CreateAlterationAsOperatorCommand} [createAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsIdAlterationsPost(id: string, createAlterationAsOperatorCommand?: CreateAlterationAsOperatorCommand, options?: AxiosRequestConfig): AxiosPromise<CreateAlterationAsOperatorCommandResult>;

    /**
     * 
     * @summary Accepts an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {AcceptAlterationAsOperatorCommand} [acceptAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsOperatorCommand?: AcceptAlterationAsOperatorCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Declines an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {DeclineAlterationAsOperatorCommand} [declineAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsOperatorCommand?: DeclineAlterationAsOperatorCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Marks the result notification as seen.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Withdraws an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {WithdrawAlterationAsOperatorCommand} [withdrawAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApiInterface
     */
    apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsOperatorCommand?: WithdrawAlterationAsOperatorCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AlterationOperatorApi - object-oriented interface
 * @export
 * @class AlterationOperatorApi
 * @extends {BaseAPI}
 */
export class AlterationOperatorApi extends BaseAPI implements AlterationOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
     * @param {string} id 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsIdAlterationsCalendarDaysGet(id, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of all related alterations and available actions.
     * @param {string} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {AlterationState} [state] Optional alteration state to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new reservation alteration request.
     * @param {string} id 
     * @param {CreateAlterationAsOperatorCommand} [createAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsIdAlterationsPost(id: string, createAlterationAsOperatorCommand?: CreateAlterationAsOperatorCommand, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsIdAlterationsPost(id, createAlterationAsOperatorCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {AcceptAlterationAsOperatorCommand} [acceptAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsOperatorCommand?: AcceptAlterationAsOperatorCommand, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsOperatorCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Declines an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {DeclineAlterationAsOperatorCommand} [declineAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsOperatorCommand?: DeclineAlterationAsOperatorCommand, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsOperatorCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks the result notification as seen.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Withdraws an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {WithdrawAlterationAsOperatorCommand} [withdrawAlterationAsOperatorCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationOperatorApi
     */
    public apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsOperatorCommand?: WithdrawAlterationAsOperatorCommand, options?: AxiosRequestConfig) {
        return AlterationOperatorApiFp(this.configuration).apiV1OperatorReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsOperatorCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

