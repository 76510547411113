/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Vehicle shower facility.
 * @export
 * @enum {string}
 */

export const ShowerOption = {
    NotSet: 'NotSet',
    OutdoorShower: 'OutdoorShower',
    IndoorShower: 'IndoorShower',
    NoShower: 'NoShower',
    OutdoorAndIndoorShower: 'OutdoorAndIndoorShower'
} as const;

export type ShowerOption = typeof ShowerOption[keyof typeof ShowerOption];



