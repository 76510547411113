/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityDepositPaymentMethod = {
    NotSet: 'NotSet',
    CreditCardFreezed: 'CreditCardFreezed',
    CreditCardCharged: 'CreditCardCharged',
    DebitCard: 'DebitCard',
    Paypal: 'Paypal',
    Unzer: 'Unzer',
    BankTransferCommerzbank: 'BankTransferCommerzbank',
    BankTransferBPM: 'BankTransferBPM',
    BankTransfer: 'BankTransfer'
} as const;

export type SecurityDepositPaymentMethod = typeof SecurityDepositPaymentMethod[keyof typeof SecurityDepositPaymentMethod];



