/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HelpCenterCategoryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * HelpCenterCategoryMarketplaceApi - axios parameter creator
 * @export
 */
export const HelpCenterCategoryMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of categories.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterCategoriesGet: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/help-center/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpCenterCategoryMarketplaceApi - functional programming interface
 * @export
 */
export const HelpCenterCategoryMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpCenterCategoryMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of categories.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceHelpCenterCategoriesGet(query?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HelpCenterCategoryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceHelpCenterCategoriesGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpCenterCategoryMarketplaceApi - factory interface
 * @export
 */
export const HelpCenterCategoryMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpCenterCategoryMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of categories.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterCategoriesGet(query?: object, options?: any): AxiosPromise<Array<HelpCenterCategoryResult>> {
            return localVarFp.apiV1MarketplaceHelpCenterCategoriesGet(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpCenterCategoryMarketplaceApi - interface
 * @export
 * @interface HelpCenterCategoryMarketplaceApi
 */
export interface HelpCenterCategoryMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of categories.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterCategoryMarketplaceApiInterface
     */
    apiV1MarketplaceHelpCenterCategoriesGet(query?: object, options?: AxiosRequestConfig): AxiosPromise<Array<HelpCenterCategoryResult>>;

}

/**
 * HelpCenterCategoryMarketplaceApi - object-oriented interface
 * @export
 * @class HelpCenterCategoryMarketplaceApi
 * @extends {BaseAPI}
 */
export class HelpCenterCategoryMarketplaceApi extends BaseAPI implements HelpCenterCategoryMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of categories.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterCategoryMarketplaceApi
     */
    public apiV1MarketplaceHelpCenterCategoriesGet(query?: object, options?: AxiosRequestConfig) {
        return HelpCenterCategoryMarketplaceApiFp(this.configuration).apiV1MarketplaceHelpCenterCategoriesGet(query, options).then((request) => request(this.axios, this.basePath));
    }
}

