/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ModelCreateCommand } from '../models';
// @ts-ignore
import { ModelUpdateCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { VehicleModelByIdQueryResult } from '../models';
// @ts-ignore
import { VehicleModelListByManufacturerIdQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { VehicleModelListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { VehicleModelUniqueListQueryResultListResult } from '../models';
/**
 * ModelAdminApi - axios parameter creator
 * @export
 */
export const ModelAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminModelsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminModelsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminModelsIdDelete', 'id', id)
            const localVarPath = `/api/v1/admin/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminModelsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {ModelUpdateCommand} [modelUpdateCommand] The vehicle model data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsIdPut: async (id: number, modelUpdateCommand?: ModelUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminModelsIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of vehicle manufacturers by manufacturer identifier.
         * @param {string} manufacturerGuid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsManufacturerManufacturerGuidGet: async (manufacturerGuid: string, pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerGuid' is not null or undefined
            assertParamExists('apiV1AdminModelsManufacturerManufacturerGuidGet', 'manufacturerGuid', manufacturerGuid)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminModelsManufacturerManufacturerGuidGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminModelsManufacturerManufacturerGuidGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/models/manufacturer/{manufacturerGuid}`
                .replace(`{${"manufacturerGuid"}}`, encodeURIComponent(String(manufacturerGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new vehicle model entity from model provided.
         * @param {ModelCreateCommand} [modelCreateCommand] The vehicle model data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsPost: async (modelCreateCommand?: ModelCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsUniqueGet: async (manufacturerGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/models/unique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerGuid !== undefined) {
                localVarQueryParameter['manufacturerGuid'] = manufacturerGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelAdminApi - functional programming interface
 * @export
 */
export const ModelAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {ModelUpdateCommand} [modelUpdateCommand] The vehicle model data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsIdPut(id: number, modelUpdateCommand?: ModelUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsIdPut(id, modelUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of vehicle manufacturers by manufacturer identifier.
         * @param {string} manufacturerGuid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelListByManufacturerIdQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid, pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new vehicle model entity from model provided.
         * @param {ModelCreateCommand} [modelCreateCommand] The vehicle model data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsPost(modelCreateCommand?: ModelCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsPost(modelCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminModelsUniqueGet(manufacturerGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelUniqueListQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminModelsUniqueGet(manufacturerGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelAdminApi - factory interface
 * @export
 */
export const ModelAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<VehicleModelListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminModelsGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminModelsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsIdGet(id: number, options?: any): AxiosPromise<VehicleModelByIdQueryResult> {
            return localVarFp.apiV1AdminModelsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {ModelUpdateCommand} [modelUpdateCommand] The vehicle model data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsIdPut(id: number, modelUpdateCommand?: ModelUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminModelsIdPut(id, modelUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of vehicle manufacturers by manufacturer identifier.
         * @param {string} manufacturerGuid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid: string, pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<VehicleModelListByManufacturerIdQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid, pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new vehicle model entity from model provided.
         * @param {ModelCreateCommand} [modelCreateCommand] The vehicle model data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsPost(modelCreateCommand?: ModelCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminModelsPost(modelCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminModelsUniqueGet(manufacturerGuid?: string, options?: any): AxiosPromise<VehicleModelUniqueListQueryResultListResult> {
            return localVarFp.apiV1AdminModelsUniqueGet(manufacturerGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelAdminApi - interface
 * @export
 * @interface ModelAdminApi
 */
export interface ModelAdminApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleModelListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Deletes an existing vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsIdDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<VehicleModelByIdQueryResult>;

    /**
     * 
     * @summary Deletes an existing vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {ModelUpdateCommand} [modelUpdateCommand] The vehicle model data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsIdPut(id: number, modelUpdateCommand?: ModelUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets a list of vehicle manufacturers by manufacturer identifier.
     * @param {string} manufacturerGuid 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleModelListByManufacturerIdQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates a new vehicle model entity from model provided.
     * @param {ModelCreateCommand} [modelCreateCommand] The vehicle model data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsPost(modelCreateCommand?: ModelCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApiInterface
     */
    apiV1AdminModelsUniqueGet(manufacturerGuid?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleModelUniqueListQueryResultListResult>;

}

/**
 * ModelAdminApi - object-oriented interface
 * @export
 * @class ModelAdminApi
 * @extends {BaseAPI}
 */
export class ModelAdminApi extends BaseAPI implements ModelAdminApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsIdDelete(id: number, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsIdGet(id: number, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {ModelUpdateCommand} [modelUpdateCommand] The vehicle model data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsIdPut(id: number, modelUpdateCommand?: ModelUpdateCommand, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsIdPut(id, modelUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of vehicle manufacturers by manufacturer identifier.
     * @param {string} manufacturerGuid 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsManufacturerManufacturerGuidGet(manufacturerGuid, pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new vehicle model entity from model provided.
     * @param {ModelCreateCommand} [modelCreateCommand] The vehicle model data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsPost(modelCreateCommand?: ModelCreateCommand, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsPost(modelCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAdminApi
     */
    public apiV1AdminModelsUniqueGet(manufacturerGuid?: string, options?: AxiosRequestConfig) {
        return ModelAdminApiFp(this.configuration).apiV1AdminModelsUniqueGet(manufacturerGuid, options).then((request) => request(this.axios, this.basePath));
    }
}

