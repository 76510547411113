/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetListingBasePriceQueryResult } from '../models';
// @ts-ignore
import { ListingAvailableVehiclesQueryResult } from '../models';
// @ts-ignore
import { ListingSearchQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { OperatorListingRentalCalendarPriceCalculateQuery } from '../models';
// @ts-ignore
import { OperatorListingRentalCalendarPriceCalculateQueryResult } from '../models';
// @ts-ignore
import { OperatorListingSearchQuery } from '../models';
// @ts-ignore
import { OperatorListingsCountQueryResult } from '../models';
// @ts-ignore
import { OperatorListingsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ListingOperatorApi - axios parameter creator
 * @export
 */
export const ListingOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a count of listings by its visibility status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of available vehicles for listing at specific time frame.
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdAvailableVehiclesGet: async (id: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdAvailableVehiclesGet', 'id', id)
            const localVarPath = `/api/v1/operator/listings/{id}/available-vehicles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a listing base price.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdBasePriceGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdBasePriceGet', 'id', id)
            const localVarPath = `/api/v1/operator/listings/{id}/base-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a listing price.
         * @param {number} id 
         * @param {OperatorListingRentalCalendarPriceCalculateQuery} [operatorListingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdCalculatePricePost: async (id: number, operatorListingRentalCalendarPriceCalculateQuery?: OperatorListingRentalCalendarPriceCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdCalculatePricePost', 'id', id)
            const localVarPath = `/api/v1/operator/listings/{id}/calculate-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorListingRentalCalendarPriceCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdInstantBookablePost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdInstantBookablePost', 'id', id)
            const localVarPath = `/api/v1/operator/listings/{id}/instant-bookable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logged operator.
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsPublishedGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logger operator.
         * @param {OperatorListingSearchQuery} [operatorListingSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsSearchPost: async (operatorListingSearchQuery?: OperatorListingSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorListingSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logged operator.
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsUnpublishedGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/unpublished`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingOperatorApi - functional programming interface
 * @export
 */
export const ListingOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a count of listings by its visibility status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorListingsCountQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of available vehicles for listing at specific time frame.
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListingAvailableVehiclesQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsIdAvailableVehiclesGet(id, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a listing base price.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsIdBasePriceGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingBasePriceQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsIdBasePriceGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a listing price.
         * @param {number} id 
         * @param {OperatorListingRentalCalendarPriceCalculateQuery} [operatorListingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsIdCalculatePricePost(id: number, operatorListingRentalCalendarPriceCalculateQuery?: OperatorListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorListingRentalCalendarPriceCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsIdCalculatePricePost(id, operatorListingRentalCalendarPriceCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsIdInstantBookablePost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsIdInstantBookablePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logged operator.
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsPublishedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorListingsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsPublishedGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logger operator.
         * @param {OperatorListingSearchQuery} [operatorListingSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsSearchPost(operatorListingSearchQuery?: OperatorListingSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingSearchQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsSearchPost(operatorListingSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logged operator.
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsUnpublishedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorListingsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsUnpublishedGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingOperatorApi - factory interface
 * @export
 */
export const ListingOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a count of listings by its visibility status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsCountGet(options?: any): AxiosPromise<OperatorListingsCountQueryResult> {
            return localVarFp.apiV1OperatorListingsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of available vehicles for listing at specific time frame.
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: any): AxiosPromise<Array<ListingAvailableVehiclesQueryResult>> {
            return localVarFp.apiV1OperatorListingsIdAvailableVehiclesGet(id, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a listing base price.
         * @param {number} id The listing identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdBasePriceGet(id: number, options?: any): AxiosPromise<GetListingBasePriceQueryResult> {
            return localVarFp.apiV1OperatorListingsIdBasePriceGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a listing price.
         * @param {number} id 
         * @param {OperatorListingRentalCalendarPriceCalculateQuery} [operatorListingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdCalculatePricePost(id: number, operatorListingRentalCalendarPriceCalculateQuery?: OperatorListingRentalCalendarPriceCalculateQuery, options?: any): AxiosPromise<OperatorListingRentalCalendarPriceCalculateQueryResult> {
            return localVarFp.apiV1OperatorListingsIdCalculatePricePost(id, operatorListingRentalCalendarPriceCalculateQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdInstantBookablePost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsIdInstantBookablePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logged operator.
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsPublishedGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<OperatorListingsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsPublishedGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logger operator.
         * @param {OperatorListingSearchQuery} [operatorListingSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsSearchPost(operatorListingSearchQuery?: OperatorListingSearchQuery, options?: any): AxiosPromise<ListingSearchQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsSearchPost(operatorListingSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of listings for currently logged operator.
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsUnpublishedGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<OperatorListingsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsUnpublishedGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingOperatorApi - interface
 * @export
 * @interface ListingOperatorApi
 */
export interface ListingOperatorApiInterface {
    /**
     * 
     * @summary Gets a count of listings by its visibility status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsCountGet(options?: AxiosRequestConfig): AxiosPromise<OperatorListingsCountQueryResult>;

    /**
     * 
     * @summary Gets list of available vehicles for listing at specific time frame.
     * @param {number} id 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ListingAvailableVehiclesQueryResult>>;

    /**
     * 
     * @summary Gets a listing base price.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsIdBasePriceGet(id: number, options?: AxiosRequestConfig): AxiosPromise<GetListingBasePriceQueryResult>;

    /**
     * 
     * @summary Gets a listing price.
     * @param {number} id 
     * @param {OperatorListingRentalCalendarPriceCalculateQuery} [operatorListingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsIdCalculatePricePost(id: number, operatorListingRentalCalendarPriceCalculateQuery?: OperatorListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): AxiosPromise<OperatorListingRentalCalendarPriceCalculateQueryResult>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsIdInstantBookablePost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a paginated list of listings for currently logged operator.
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsPublishedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<OperatorListingsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a paginated list of listings for currently logger operator.
     * @param {OperatorListingSearchQuery} [operatorListingSearchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsSearchPost(operatorListingSearchQuery?: OperatorListingSearchQuery, options?: AxiosRequestConfig): AxiosPromise<ListingSearchQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a paginated list of listings for currently logged operator.
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApiInterface
     */
    apiV1OperatorListingsUnpublishedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<OperatorListingsQueryResultPaginatedResult>;

}

/**
 * ListingOperatorApi - object-oriented interface
 * @export
 * @class ListingOperatorApi
 * @extends {BaseAPI}
 */
export class ListingOperatorApi extends BaseAPI implements ListingOperatorApiInterface {
    /**
     * 
     * @summary Gets a count of listings by its visibility status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsCountGet(options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of available vehicles for listing at specific time frame.
     * @param {number} id 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsIdAvailableVehiclesGet(id: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsIdAvailableVehiclesGet(id, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a listing base price.
     * @param {number} id The listing identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsIdBasePriceGet(id: number, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsIdBasePriceGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a listing price.
     * @param {number} id 
     * @param {OperatorListingRentalCalendarPriceCalculateQuery} [operatorListingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsIdCalculatePricePost(id: number, operatorListingRentalCalendarPriceCalculateQuery?: OperatorListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsIdCalculatePricePost(id, operatorListingRentalCalendarPriceCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsIdInstantBookablePost(id: number, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsIdInstantBookablePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of listings for currently logged operator.
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsPublishedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsPublishedGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of listings for currently logger operator.
     * @param {OperatorListingSearchQuery} [operatorListingSearchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsSearchPost(operatorListingSearchQuery?: OperatorListingSearchQuery, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsSearchPost(operatorListingSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of listings for currently logged operator.
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingOperatorApi
     */
    public apiV1OperatorListingsUnpublishedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ListingOperatorApiFp(this.configuration).apiV1OperatorListingsUnpublishedGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}

