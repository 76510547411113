/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateOperatorReviewTrackingRecordCommand } from '../models';
// @ts-ignore
import { FillInOperatorReviewCommand } from '../models';
// @ts-ignore
import { GetOperatorReviewResult } from '../models';
// @ts-ignore
import { GetPendingOperatorReviewsCountQueryResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ReviewsMarketplaceApi - axios parameter creator
 * @export
 */
export const ReviewsMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the booking review detail based on booking reference code.
         * @param {string} [referenceCode] The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsGet: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['referenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the number of pending reviews.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsPendingCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/reviews/pending/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Posts the booking review linked to booking by its reference code.
         * @param {FillInOperatorReviewCommand} [fillInOperatorReviewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsPut: async (fillInOperatorReviewCommand?: FillInOperatorReviewCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fillInOperatorReviewCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Records a review event.
         * @param {CreateOperatorReviewTrackingRecordCommand} [createOperatorReviewTrackingRecordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsTrackingRecordsPost: async (createOperatorReviewTrackingRecordCommand?: CreateOperatorReviewTrackingRecordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/reviews/tracking-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOperatorReviewTrackingRecordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Records a review event.
         * @param {string} [referenceCode] 
         * @param {number} [notificationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet: async (referenceCode?: string, notificationNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/reviews/tracking-records/tracking-pixel.jpg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['referenceCode'] = referenceCode;
            }

            if (notificationNumber !== undefined) {
                localVarQueryParameter['notificationNumber'] = notificationNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewsMarketplaceApi - functional programming interface
 * @export
 */
export const ReviewsMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewsMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the booking review detail based on booking reference code.
         * @param {string} [referenceCode] The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReviewsGet(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorReviewResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReviewsGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the number of pending reviews.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReviewsPendingCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPendingOperatorReviewsCountQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReviewsPendingCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Posts the booking review linked to booking by its reference code.
         * @param {FillInOperatorReviewCommand} [fillInOperatorReviewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand?: FillInOperatorReviewCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Records a review event.
         * @param {CreateOperatorReviewTrackingRecordCommand} [createOperatorReviewTrackingRecordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand?: CreateOperatorReviewTrackingRecordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Records a review event.
         * @param {string} [referenceCode] 
         * @param {number} [notificationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode, notificationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewsMarketplaceApi - factory interface
 * @export
 */
export const ReviewsMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewsMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the booking review detail based on booking reference code.
         * @param {string} [referenceCode] The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsGet(referenceCode?: string, options?: any): AxiosPromise<GetOperatorReviewResult> {
            return localVarFp.apiV1MarketplaceReviewsGet(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the number of pending reviews.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsPendingCountGet(options?: any): AxiosPromise<GetPendingOperatorReviewsCountQueryResult> {
            return localVarFp.apiV1MarketplaceReviewsPendingCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Posts the booking review linked to booking by its reference code.
         * @param {FillInOperatorReviewCommand} [fillInOperatorReviewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand?: FillInOperatorReviewCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Records a review event.
         * @param {CreateOperatorReviewTrackingRecordCommand} [createOperatorReviewTrackingRecordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand?: CreateOperatorReviewTrackingRecordCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Records a review event.
         * @param {string} [referenceCode] 
         * @param {number} [notificationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode, notificationNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewsMarketplaceApi - interface
 * @export
 * @interface ReviewsMarketplaceApi
 */
export interface ReviewsMarketplaceApiInterface {
    /**
     * 
     * @summary Gets the booking review detail based on booking reference code.
     * @param {string} [referenceCode] The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApiInterface
     */
    apiV1MarketplaceReviewsGet(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<GetOperatorReviewResult>;

    /**
     * 
     * @summary Gets the number of pending reviews.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApiInterface
     */
    apiV1MarketplaceReviewsPendingCountGet(options?: AxiosRequestConfig): AxiosPromise<GetPendingOperatorReviewsCountQueryResult>;

    /**
     * 
     * @summary Posts the booking review linked to booking by its reference code.
     * @param {FillInOperatorReviewCommand} [fillInOperatorReviewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApiInterface
     */
    apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand?: FillInOperatorReviewCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Records a review event.
     * @param {CreateOperatorReviewTrackingRecordCommand} [createOperatorReviewTrackingRecordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApiInterface
     */
    apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand?: CreateOperatorReviewTrackingRecordCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Records a review event.
     * @param {string} [referenceCode] 
     * @param {number} [notificationNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApiInterface
     */
    apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ReviewsMarketplaceApi - object-oriented interface
 * @export
 * @class ReviewsMarketplaceApi
 * @extends {BaseAPI}
 */
export class ReviewsMarketplaceApi extends BaseAPI implements ReviewsMarketplaceApiInterface {
    /**
     * 
     * @summary Gets the booking review detail based on booking reference code.
     * @param {string} [referenceCode] The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApi
     */
    public apiV1MarketplaceReviewsGet(referenceCode?: string, options?: AxiosRequestConfig) {
        return ReviewsMarketplaceApiFp(this.configuration).apiV1MarketplaceReviewsGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the number of pending reviews.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApi
     */
    public apiV1MarketplaceReviewsPendingCountGet(options?: AxiosRequestConfig) {
        return ReviewsMarketplaceApiFp(this.configuration).apiV1MarketplaceReviewsPendingCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Posts the booking review linked to booking by its reference code.
     * @param {FillInOperatorReviewCommand} [fillInOperatorReviewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApi
     */
    public apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand?: FillInOperatorReviewCommand, options?: AxiosRequestConfig) {
        return ReviewsMarketplaceApiFp(this.configuration).apiV1MarketplaceReviewsPut(fillInOperatorReviewCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Records a review event.
     * @param {CreateOperatorReviewTrackingRecordCommand} [createOperatorReviewTrackingRecordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApi
     */
    public apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand?: CreateOperatorReviewTrackingRecordCommand, options?: AxiosRequestConfig) {
        return ReviewsMarketplaceApiFp(this.configuration).apiV1MarketplaceReviewsTrackingRecordsPost(createOperatorReviewTrackingRecordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Records a review event.
     * @param {string} [referenceCode] 
     * @param {number} [notificationNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsMarketplaceApi
     */
    public apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: AxiosRequestConfig) {
        return ReviewsMarketplaceApiFp(this.configuration).apiV1MarketplaceReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode, notificationNumber, options).then((request) => request(this.axios, this.basePath));
    }
}

