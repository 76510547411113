/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptAlterationAsManagerCommand } from '../models';
// @ts-ignore
import { AlterationState } from '../models';
// @ts-ignore
import { CreateAlterationAsManagerCommand } from '../models';
// @ts-ignore
import { CreateAlterationAsManagerCommandResult } from '../models';
// @ts-ignore
import { DeclineAlterationAsManagerCommand } from '../models';
// @ts-ignore
import { GetOperatorBookingAlterationsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { WithdrawAlterationAsManagerCommand } from '../models';
/**
 * AlterationManagerApi - axios parameter creator
 * @export
 */
export const AlterationManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list  of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdAlterationsGet: async (id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdAlterationsGet', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/alterations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsManagerCommand} [createAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdAlterationsPost: async (id: string, createAlterationAsManagerCommand?: CreateAlterationAsManagerCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerReservationsIdAlterationsPost', 'id', id)
            const localVarPath = `/api/v1/manager/reservations/{id}/alterations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAlterationAsManagerCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsManagerCommand} [acceptAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut: async (reservationId: string, alterationId: number, acceptAlterationAsManagerCommand?: AcceptAlterationAsManagerCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/manager/reservations/{reservationId}/alterations/{alterationId}/accept`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptAlterationAsManagerCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Declines an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsManagerCommand} [declineAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut: async (reservationId: string, alterationId: number, declineAlterationAsManagerCommand?: DeclineAlterationAsManagerCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/manager/reservations/{reservationId}/alterations/{alterationId}/decline`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(declineAlterationAsManagerCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut: async (reservationId: string, alterationId: number, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/manager/reservations/{reservationId}/alterations/{alterationId}/dismiss-result-notification`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Withdraws an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsManagerCommand} [withdrawAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut: async (reservationId: string, alterationId: number, withdrawAlterationAsManagerCommand?: WithdrawAlterationAsManagerCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/manager/reservations/{reservationId}/alterations/{alterationId}/withdraw`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawAlterationAsManagerCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlterationManagerApi - functional programming interface
 * @export
 */
export const AlterationManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlterationManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list  of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorBookingAlterationsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsManagerCommand} [createAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsIdAlterationsPost(id: string, createAlterationAsManagerCommand?: CreateAlterationAsManagerCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAlterationAsManagerCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsIdAlterationsPost(id, createAlterationAsManagerCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsManagerCommand} [acceptAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsManagerCommand?: AcceptAlterationAsManagerCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsManagerCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Declines an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsManagerCommand} [declineAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsManagerCommand?: DeclineAlterationAsManagerCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsManagerCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Withdraws an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsManagerCommand} [withdrawAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsManagerCommand?: WithdrawAlterationAsManagerCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsManagerCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlterationManagerApi - factory interface
 * @export
 */
export const AlterationManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlterationManagerApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list  of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: any): AxiosPromise<GetOperatorBookingAlterationsQueryResultPaginatedResult> {
            return localVarFp.apiV1ManagerReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsManagerCommand} [createAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsIdAlterationsPost(id: string, createAlterationAsManagerCommand?: CreateAlterationAsManagerCommand, options?: any): AxiosPromise<CreateAlterationAsManagerCommandResult> {
            return localVarFp.apiV1ManagerReservationsIdAlterationsPost(id, createAlterationAsManagerCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsManagerCommand} [acceptAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsManagerCommand?: AcceptAlterationAsManagerCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsManagerCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Declines an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsManagerCommand} [declineAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsManagerCommand?: DeclineAlterationAsManagerCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsManagerCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Withdraws an active alteration request as Manager.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsManagerCommand} [withdrawAlterationAsManagerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsManagerCommand?: WithdrawAlterationAsManagerCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsManagerCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlterationManagerApi - interface
 * @export
 * @interface AlterationManagerApi
 */
export interface AlterationManagerApiInterface {
    /**
     * 
     * @summary Gets a list  of all related alterations and available actions.
     * @param {string} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {AlterationState} [state] Optional alteration state to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApiInterface
     */
    apiV1ManagerReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig): AxiosPromise<GetOperatorBookingAlterationsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates a new reservation alteration request.
     * @param {string} id 
     * @param {CreateAlterationAsManagerCommand} [createAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApiInterface
     */
    apiV1ManagerReservationsIdAlterationsPost(id: string, createAlterationAsManagerCommand?: CreateAlterationAsManagerCommand, options?: AxiosRequestConfig): AxiosPromise<CreateAlterationAsManagerCommandResult>;

    /**
     * 
     * @summary Accepts an active alteration request as Manager.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {AcceptAlterationAsManagerCommand} [acceptAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApiInterface
     */
    apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsManagerCommand?: AcceptAlterationAsManagerCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Declines an active alteration request as Manager.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {DeclineAlterationAsManagerCommand} [declineAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApiInterface
     */
    apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsManagerCommand?: DeclineAlterationAsManagerCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Marks the result notification as seen.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApiInterface
     */
    apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Withdraws an active alteration request as Manager.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {WithdrawAlterationAsManagerCommand} [withdrawAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApiInterface
     */
    apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsManagerCommand?: WithdrawAlterationAsManagerCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AlterationManagerApi - object-oriented interface
 * @export
 * @class AlterationManagerApi
 * @extends {BaseAPI}
 */
export class AlterationManagerApi extends BaseAPI implements AlterationManagerApiInterface {
    /**
     * 
     * @summary Gets a list  of all related alterations and available actions.
     * @param {string} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {AlterationState} [state] Optional alteration state to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApi
     */
    public apiV1ManagerReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig) {
        return AlterationManagerApiFp(this.configuration).apiV1ManagerReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new reservation alteration request.
     * @param {string} id 
     * @param {CreateAlterationAsManagerCommand} [createAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApi
     */
    public apiV1ManagerReservationsIdAlterationsPost(id: string, createAlterationAsManagerCommand?: CreateAlterationAsManagerCommand, options?: AxiosRequestConfig) {
        return AlterationManagerApiFp(this.configuration).apiV1ManagerReservationsIdAlterationsPost(id, createAlterationAsManagerCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts an active alteration request as Manager.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {AcceptAlterationAsManagerCommand} [acceptAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApi
     */
    public apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsManagerCommand?: AcceptAlterationAsManagerCommand, options?: AxiosRequestConfig) {
        return AlterationManagerApiFp(this.configuration).apiV1ManagerReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsManagerCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Declines an active alteration request as Manager.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {DeclineAlterationAsManagerCommand} [declineAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApi
     */
    public apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsManagerCommand?: DeclineAlterationAsManagerCommand, options?: AxiosRequestConfig) {
        return AlterationManagerApiFp(this.configuration).apiV1ManagerReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsManagerCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks the result notification as seen.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApi
     */
    public apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig) {
        return AlterationManagerApiFp(this.configuration).apiV1ManagerReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Withdraws an active alteration request as Manager.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {WithdrawAlterationAsManagerCommand} [withdrawAlterationAsManagerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationManagerApi
     */
    public apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsManagerCommand?: WithdrawAlterationAsManagerCommand, options?: AxiosRequestConfig) {
        return AlterationManagerApiFp(this.configuration).apiV1ManagerReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsManagerCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

