/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Options of vehicle\'s winter operation availability.
 * @export
 * @enum {string}
 */

export const WinterOperationOption = {
    NotSet: 'NotSet',
    NotAllowed: 'NotAllowed',
    Limited: 'Limited',
    Allowed: 'Allowed'
} as const;

export type WinterOperationOption = typeof WinterOperationOption[keyof typeof WinterOperationOption];



