/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AddonLevel = {
    NotSet: 'NotSet',
    Listing: 'Listing',
    Station: 'Station'
} as const;

export type AddonLevel = typeof AddonLevel[keyof typeof AddonLevel];



