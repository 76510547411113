/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RenterReviewTrackingType = {
    EmailSent: 'EmailSent',
    EmailOpened: 'EmailOpened',
    CtaClicked: 'CtaClicked',
    OneStarClicked: 'OneStarClicked',
    TwoStarsClicked: 'TwoStarsClicked',
    ThreeStarsClicked: 'ThreeStarsClicked',
    FourStarsClicked: 'FourStarsClicked',
    FiveStarsClicked: 'FiveStarsClicked',
    ReviewSubmitted: 'ReviewSubmitted'
} as const;

export type RenterReviewTrackingType = typeof RenterReviewTrackingType[keyof typeof RenterReviewTrackingType];



