/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddVehicleDamageCommand } from '../models';
// @ts-ignore
import { CalculateInsurancePricePreviewQuery } from '../models';
// @ts-ignore
import { GetVehicleByGuidAdminQueryResult } from '../models';
// @ts-ignore
import { GetVehicleStationPresenceListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ListVehicleDamagesQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListVehiclesByOperatorAndStationQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RentalCalendarAvailabilityByGuidQueryResult } from '../models';
// @ts-ignore
import { StringPaginatedResult } from '../models';
// @ts-ignore
import { UpdateVehicleDamageCommand } from '../models';
// @ts-ignore
import { VehicleCreateCommand } from '../models';
// @ts-ignore
import { VehicleCreateCommandResult } from '../models';
// @ts-ignore
import { VehicleCreateInPoolCommand } from '../models';
// @ts-ignore
import { VehicleCreateTransferCommand } from '../models';
// @ts-ignore
import { VehicleDamageByGuidQueryResult } from '../models';
// @ts-ignore
import { VehicleEventAddCommand } from '../models';
// @ts-ignore
import { VehicleEventUpdateCommand } from '../models';
// @ts-ignore
import { VehicleListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { VehiclePictureEditCommand } from '../models';
// @ts-ignore
import { VehiclePicturesSortCommand } from '../models';
// @ts-ignore
import { VehicleStationPresenceOverrideCommand } from '../models';
// @ts-ignore
import { VehicleStationPresenceOverrideCommandResult } from '../models';
// @ts-ignore
import { VehicleStatus } from '../models';
// @ts-ignore
import { VehicleUpdateCommand } from '../models';
// @ts-ignore
import { VehicleUpdateCommandResult } from '../models';
/**
 * VehicleAdminApi - axios parameter creator
 * @export
 */
export const VehicleAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a vehicle to pool.
         * @param {number} poolId 
         * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsPoolIdVehiclesPost: async (poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1AdminPoolsPoolIdVehiclesPost', 'poolId', poolId)
            const localVarPath = `/api/v1/admin/pools/{poolId}/vehicles`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateInPoolCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicles filtered by station.
         * @param {string} [stationGuid] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesByStationGet: async (stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/vehicles/by-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stationGuid !== undefined) {
                localVarQueryParameter['stationGuid'] = stationGuid;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesEventsEventGuidDelete: async (eventGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesEventsEventGuidDelete', 'eventGuid', eventGuid)
            const localVarPath = `/api/v1/admin/vehicles/events/{eventGuid}`
                .replace(`{${"eventGuid"}}`, encodeURIComponent(String(eventGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesEventsEventGuidGet: async (eventGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesEventsEventGuidGet', 'eventGuid', eventGuid)
            const localVarPath = `/api/v1/admin/vehicles/events/{eventGuid}`
                .replace(`{${"eventGuid"}}`, encodeURIComponent(String(eventGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesEventsEventGuidPut: async (eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesEventsEventGuidPut', 'eventGuid', eventGuid)
            const localVarPath = `/api/v1/admin/vehicles/events/{eventGuid}`
                .replace(`{${"eventGuid"}}`, encodeURIComponent(String(eventGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleEventUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [stationGuid] 
         * @param {string} [operatorGuid] 
         * @param {string} [productGuid] 
         * @param {VehicleStatus} [status] 
         * @param {string} [licensePlate] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGet: async (pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (productGuid !== undefined) {
                localVarQueryParameter['ProductGuid'] = productGuid;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (licensePlate !== undefined) {
                localVarQueryParameter['LicensePlate'] = licensePlate;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approves a vehicle (sent for review by an operator).
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidApprovePost: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidApprovePost', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/approve`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid GUID of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidDelete: async (guid: string, damageGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidDelete', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidDelete', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages/{damageGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidGet: async (guid: string, damageGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidGet', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidGet', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages/{damageGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes an image from the vehicle damage object.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {string} pictureGuid Picture GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete: async (guid: string, damageGuid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete', 'damageGuid', damageGuid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages/{damageGuid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add an image to the vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {File} [picture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost: async (guid: string, damageGuid: string, picture?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages/{damageGuid}/pictures`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (picture !== undefined) { 
                localVarFormParams.append('picture', picture as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidPut: async (guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPut', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesDamageGuidPut', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages/{damageGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleDamageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicle damages.
         * @param {string} guid Global identifier of the vehicle
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesGet: async (guid: string, pageNumber: number, pageSize: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesGet', 'guid', guid)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesPost: async (guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDamagesPost', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/damages`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVehicleDamageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDelete: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidDelete', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates listing insurance price preview.
         * @param {string} guid 
         * @param {CalculateInsurancePricePreviewQuery} [calculateInsurancePricePreviewQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidPreviewInsurancePricePost: async (guid: string, calculateInsurancePricePreviewQuery?: CalculateInsurancePricePreviewQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidPreviewInsurancePricePost', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/preview-insurance-price`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateInsurancePricePreviewQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} [productGuid] 
         * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidPut: async (guid: string, productGuid?: string, vehicleUpdateCommand?: VehicleUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productGuid !== undefined) {
                localVarQueryParameter['productGuid'] = productGuid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets station presence list.
         * @param {string} guid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidStationPresenceGet: async (guid: string, pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidStationPresenceGet', 'guid', guid)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidStationPresenceGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidStationPresenceGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/station-presence`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {VehicleStationPresenceOverrideCommand} [vehicleStationPresenceOverrideCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidStationPresencePut: async (guid: string, vehicleStationPresenceOverrideCommand?: VehicleStationPresenceOverrideCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidStationPresencePut', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/station-presence`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleStationPresenceOverrideCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {string} stationPresenceGuid 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete: async (guid: string, stationPresenceGuid: string, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete', 'guid', guid)
            // verify required parameter 'stationPresenceGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete', 'stationPresenceGuid', stationPresenceGuid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/station-presence/{stationPresenceGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"stationPresenceGuid"}}`, encodeURIComponent(String(stationPresenceGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidTransfersPut: async (guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesGuidTransfersPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/{guid}/transfers`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateTransferCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesLicensePlatesGet: async (search?: string, pageSize?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/vehicles/license-plates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {number} poolId The vehicle pool idnetifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [stationGuid] 
         * @param {string} [operatorGuid] 
         * @param {string} [productGuid] 
         * @param {VehicleStatus} [status] 
         * @param {string} [licensePlate] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesPoolIdVehiclesGet: async (poolId: number, pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1AdminVehiclesPoolIdVehiclesGet', 'poolId', poolId)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminVehiclesPoolIdVehiclesGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminVehiclesPoolIdVehiclesGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/vehicles/{poolId}/vehicles`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (productGuid !== undefined) {
                localVarQueryParameter['ProductGuid'] = productGuid;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (licensePlate !== undefined) {
                localVarQueryParameter['LicensePlate'] = licensePlate;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a vehicle.
         * @param {number} [operatorAccountId] 
         * @param {string} [productGuid] 
         * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesPost: async (operatorAccountId?: number, productGuid?: string, vehicleCreateCommand?: VehicleCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (operatorAccountId !== undefined) {
                localVarQueryParameter['operatorAccountId'] = operatorAccountId;
            }

            if (productGuid !== undefined) {
                localVarQueryParameter['productGuid'] = productGuid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesStationPresenceGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesStationPresenceGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/vehicles/station-presence/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add the listing\'s calendar event.
         * @param {string} vehicleGuid 
         * @param {VehicleEventAddCommand} [vehicleEventAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidEventsPost: async (vehicleGuid: string, vehicleEventAddCommand?: VehicleEventAddCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidEventsPost', 'vehicleGuid', vehicleGuid)
            const localVarPath = `/api/v1/admin/vehicles/{vehicleGuid}/events`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleEventAddCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the vehicle pool picture.
         * @param {string} vehicleGuid 
         * @param {string} pictureGuid THe picture identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete: async (vehicleGuid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete', 'vehicleGuid', vehicleGuid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/admin/vehicles/{vehicleGuid}/pictures/{pictureGuid}`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits the vehicle pool picture.
         * @param {string} vehicleGuid 
         * @param {string} pictureGuid 
         * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut: async (vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut', 'vehicleGuid', vehicleGuid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/admin/vehicles/{vehicleGuid}/pictures/{pictureGuid}`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePictureEditCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the vehicle picture and returns the model.
         * @param {string} vehicleGuid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesPost: async (vehicleGuid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidPicturesPost', 'vehicleGuid', vehicleGuid)
            const localVarPath = `/api/v1/admin/vehicles/{vehicleGuid}/pictures`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorts the vehicle pool pictures.
         * @param {string} vehicleGuid 
         * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesSortPost: async (vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidPicturesSortPost', 'vehicleGuid', vehicleGuid)
            const localVarPath = `/api/v1/admin/vehicles/{vehicleGuid}/pictures/sort`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePicturesSortCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the square thumbnail picture and returns the model.
         * @param {string} vehicleGuid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidSquarethumbnailPost: async (vehicleGuid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1AdminVehiclesVehicleGuidSquarethumbnailPost', 'vehicleGuid', vehicleGuid)
            const localVarPath = `/api/v1/admin/vehicles/{vehicleGuid}/squarethumbnail`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleAdminApi - functional programming interface
 * @export
 */
export const VehicleAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a vehicle to pool.
         * @param {number} poolId 
         * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsPoolIdVehiclesPost(poolId, vehicleCreateInPoolCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicles filtered by station.
         * @param {string} [stationGuid] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVehiclesByOperatorAndStationQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesByStationGet(stationGuid, from, until, search, pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesEventsEventGuidDelete(eventGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesEventsEventGuidDelete(eventGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesEventsEventGuidGet(eventGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalCalendarAvailabilityByGuidQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesEventsEventGuidGet(eventGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesEventsEventGuidPut(eventGuid, vehicleEventUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [stationGuid] 
         * @param {string} [operatorGuid] 
         * @param {string} [productGuid] 
         * @param {VehicleStatus} [status] 
         * @param {string} [licensePlate] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGet(pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGet(pageSize, pageNumber, stationGuid, operatorGuid, productGuid, status, licensePlate, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approves a vehicle (sent for review by an operator).
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidApprovePost(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidApprovePost(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid GUID of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid, damageGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageByGuidQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid, damageGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes an image from the vehicle damage object.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {string} pictureGuid Picture GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid, damageGuid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add an image to the vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {File} [picture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid, damageGuid, picture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid, damageGuid, updateVehicleDamageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicle damages.
         * @param {string} guid Global identifier of the vehicle
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesGet(guid: string, pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVehicleDamagesQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesGet(guid, pageNumber, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDamagesPost(guid, addVehicleDamageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidDelete(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidDelete(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleByGuidAdminQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates listing insurance price preview.
         * @param {string} guid 
         * @param {CalculateInsurancePricePreviewQuery} [calculateInsurancePricePreviewQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid: string, calculateInsurancePricePreviewQuery?: CalculateInsurancePricePreviewQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid, calculateInsurancePricePreviewQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} [productGuid] 
         * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidPut(guid: string, productGuid?: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleUpdateCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidPut(guid, productGuid, vehicleUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets station presence list.
         * @param {string} guid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidStationPresenceGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleStationPresenceListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidStationPresenceGet(guid, pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {VehicleStationPresenceOverrideCommand} [vehicleStationPresenceOverrideCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidStationPresencePut(guid: string, vehicleStationPresenceOverrideCommand?: VehicleStationPresenceOverrideCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleStationPresenceOverrideCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidStationPresencePut(guid, vehicleStationPresenceOverrideCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {string} stationPresenceGuid 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid: string, stationPresenceGuid: string, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid, stationPresenceGuid, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesGuidTransfersPut(guid, vehicleCreateTransferCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesLicensePlatesGet(search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesLicensePlatesGet(search, pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {number} poolId The vehicle pool idnetifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [stationGuid] 
         * @param {string} [operatorGuid] 
         * @param {string} [productGuid] 
         * @param {VehicleStatus} [status] 
         * @param {string} [licensePlate] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesPoolIdVehiclesGet(poolId: number, pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesPoolIdVehiclesGet(poolId, pageSize, pageNumber, stationGuid, operatorGuid, productGuid, status, licensePlate, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a vehicle.
         * @param {number} [operatorAccountId] 
         * @param {string} [productGuid] 
         * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesPost(operatorAccountId?: number, productGuid?: string, vehicleCreateCommand?: VehicleCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleCreateCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesPost(operatorAccountId, productGuid, vehicleCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesStationPresenceGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleStationPresenceListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesStationPresenceGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add the listing\'s calendar event.
         * @param {string} vehicleGuid 
         * @param {VehicleEventAddCommand} [vehicleEventAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid: string, vehicleEventAddCommand?: VehicleEventAddCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid, vehicleEventAddCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the vehicle pool picture.
         * @param {string} vehicleGuid 
         * @param {string} pictureGuid THe picture identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edits the vehicle pool picture.
         * @param {string} vehicleGuid 
         * @param {string} pictureGuid 
         * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid, pictureGuid, vehiclePictureEditCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the vehicle picture and returns the model.
         * @param {string} vehicleGuid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sorts the vehicle pool pictures.
         * @param {string} vehicleGuid 
         * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid, vehiclePicturesSortCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the square thumbnail picture and returns the model.
         * @param {string} vehicleGuid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleAdminApi - factory interface
 * @export
 */
export const VehicleAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a vehicle to pool.
         * @param {number} poolId 
         * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminPoolsPoolIdVehiclesPost(poolId, vehicleCreateInPoolCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicles filtered by station.
         * @param {string} [stationGuid] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: any): AxiosPromise<ListVehiclesByOperatorAndStationQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesByStationGet(stationGuid, from, until, search, pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesEventsEventGuidDelete(eventGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesEventsEventGuidDelete(eventGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesEventsEventGuidGet(eventGuid: string, options?: any): AxiosPromise<RentalCalendarAvailabilityByGuidQueryResult> {
            return localVarFp.apiV1AdminVehiclesEventsEventGuidGet(eventGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the listing\'s calendar event.
         * @param {string} eventGuid 
         * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesEventsEventGuidPut(eventGuid, vehicleEventUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [stationGuid] 
         * @param {string} [operatorGuid] 
         * @param {string} [productGuid] 
         * @param {VehicleStatus} [status] 
         * @param {string} [licensePlate] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGet(pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: any): AxiosPromise<VehicleListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesGet(pageSize, pageNumber, stationGuid, operatorGuid, productGuid, status, licensePlate, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approves a vehicle (sent for review by an operator).
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidApprovePost(guid: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminVehiclesGuidApprovePost(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid GUID of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid, damageGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: any): AxiosPromise<VehicleDamageByGuidQueryResult> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid, damageGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes an image from the vehicle damage object.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {string} pictureGuid Picture GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid, damageGuid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add an image to the vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {File} [picture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid, damageGuid, picture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid, damageGuid, updateVehicleDamageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicle damages.
         * @param {string} guid Global identifier of the vehicle
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesGet(guid: string, pageNumber: number, pageSize: number, search?: string, options?: any): AxiosPromise<ListVehicleDamagesQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesGet(guid, pageNumber, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminVehiclesGuidDamagesPost(guid, addVehicleDamageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidDelete(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesGuidDelete(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidGet(guid: string, options?: any): AxiosPromise<GetVehicleByGuidAdminQueryResult> {
            return localVarFp.apiV1AdminVehiclesGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates listing insurance price preview.
         * @param {string} guid 
         * @param {CalculateInsurancePricePreviewQuery} [calculateInsurancePricePreviewQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid: string, calculateInsurancePricePreviewQuery?: CalculateInsurancePricePreviewQuery, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid, calculateInsurancePricePreviewQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} [productGuid] 
         * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidPut(guid: string, productGuid?: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: any): AxiosPromise<VehicleUpdateCommandResult> {
            return localVarFp.apiV1AdminVehiclesGuidPut(guid, productGuid, vehicleUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets station presence list.
         * @param {string} guid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidStationPresenceGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<GetVehicleStationPresenceListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesGuidStationPresenceGet(guid, pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {VehicleStationPresenceOverrideCommand} [vehicleStationPresenceOverrideCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidStationPresencePut(guid: string, vehicleStationPresenceOverrideCommand?: VehicleStationPresenceOverrideCommand, options?: any): AxiosPromise<VehicleStationPresenceOverrideCommandResult> {
            return localVarFp.apiV1AdminVehiclesGuidStationPresencePut(guid, vehicleStationPresenceOverrideCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {string} stationPresenceGuid 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid: string, stationPresenceGuid: string, force?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid, stationPresenceGuid, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesGuidTransfersPut(guid, vehicleCreateTransferCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesLicensePlatesGet(search?: string, pageSize?: number, page?: number, options?: any): AxiosPromise<StringPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesLicensePlatesGet(search, pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the vehicles from the pool.
         * @param {number} poolId The vehicle pool idnetifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [stationGuid] 
         * @param {string} [operatorGuid] 
         * @param {string} [productGuid] 
         * @param {VehicleStatus} [status] 
         * @param {string} [licensePlate] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesPoolIdVehiclesGet(poolId: number, pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: any): AxiosPromise<VehicleListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesPoolIdVehiclesGet(poolId, pageSize, pageNumber, stationGuid, operatorGuid, productGuid, status, licensePlate, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a vehicle.
         * @param {number} [operatorAccountId] 
         * @param {string} [productGuid] 
         * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesPost(operatorAccountId?: number, productGuid?: string, vehicleCreateCommand?: VehicleCreateCommand, options?: any): AxiosPromise<VehicleCreateCommandResult> {
            return localVarFp.apiV1AdminVehiclesPost(operatorAccountId, productGuid, vehicleCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets station presence.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesStationPresenceGuidGet(guid: string, options?: any): AxiosPromise<GetVehicleStationPresenceListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminVehiclesStationPresenceGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add the listing\'s calendar event.
         * @param {string} vehicleGuid 
         * @param {VehicleEventAddCommand} [vehicleEventAddCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid: string, vehicleEventAddCommand?: VehicleEventAddCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid, vehicleEventAddCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the vehicle pool picture.
         * @param {string} vehicleGuid 
         * @param {string} pictureGuid THe picture identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits the vehicle pool picture.
         * @param {string} vehicleGuid 
         * @param {string} pictureGuid 
         * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid, pictureGuid, vehiclePictureEditCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the vehicle picture and returns the model.
         * @param {string} vehicleGuid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorts the vehicle pool pictures.
         * @param {string} vehicleGuid 
         * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid, vehiclePicturesSortCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the square thumbnail picture and returns the model.
         * @param {string} vehicleGuid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleAdminApi - interface
 * @export
 * @interface VehicleAdminApi
 */
export interface VehicleAdminApiInterface {
    /**
     * 
     * @summary Create a vehicle to pool.
     * @param {number} poolId 
     * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminPoolsPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Gets vehicles filtered by station.
     * @param {string} [stationGuid] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {string} [search] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<ListVehiclesByOperatorAndStationQueryResultPaginatedResult>;

    /**
     * 
     * @summary Deletes the listing\'s calendar event.
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesEventsEventGuidDelete(eventGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the listing\'s calendar event.
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesEventsEventGuidGet(eventGuid: string, options?: AxiosRequestConfig): AxiosPromise<RentalCalendarAvailabilityByGuidQueryResult>;

    /**
     * 
     * @summary Updates the listing\'s calendar event.
     * @param {string} eventGuid 
     * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the vehicles from the pool.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [stationGuid] 
     * @param {string} [operatorGuid] 
     * @param {string} [productGuid] 
     * @param {VehicleStatus} [status] 
     * @param {string} [licensePlate] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGet(pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Approves a vehicle (sent for review by an operator).
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidApprovePost(guid: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Remove vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid GUID of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: AxiosRequestConfig): AxiosPromise<VehicleDamageByGuidQueryResult>;

    /**
     * 
     * @summary Removes an image from the vehicle damage object.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {string} pictureGuid Picture GUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Add an image to the vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {File} [picture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Update vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Gets vehicle damages.
     * @param {string} guid Global identifier of the vehicle
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesGet(guid: string, pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ListVehicleDamagesQueryResultPaginatedResult>;

    /**
     * 
     * @summary Add vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Deletes the vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidDelete(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<GetVehicleByGuidAdminQueryResult>;

    /**
     * 
     * @summary Calculates listing insurance price preview.
     * @param {string} guid 
     * @param {CalculateInsurancePricePreviewQuery} [calculateInsurancePricePreviewQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid: string, calculateInsurancePricePreviewQuery?: CalculateInsurancePricePreviewQuery, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Updates the vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} [productGuid] 
     * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidPut(guid: string, productGuid?: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<VehicleUpdateCommandResult>;

    /**
     * 
     * @summary Gets station presence list.
     * @param {string} guid 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidStationPresenceGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<GetVehicleStationPresenceListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets station presence.
     * @param {string} guid 
     * @param {VehicleStationPresenceOverrideCommand} [vehicleStationPresenceOverrideCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidStationPresencePut(guid: string, vehicleStationPresenceOverrideCommand?: VehicleStationPresenceOverrideCommand, options?: AxiosRequestConfig): AxiosPromise<VehicleStationPresenceOverrideCommandResult>;

    /**
     * 
     * @summary Gets station presence.
     * @param {string} guid 
     * @param {string} stationPresenceGuid 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid: string, stationPresenceGuid: string, force?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} guid 
     * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the vehicles from the pool.
     * @param {string} [search] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesLicensePlatesGet(search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<StringPaginatedResult>;

    /**
     * 
     * @summary Gets the vehicles from the pool.
     * @param {number} poolId The vehicle pool idnetifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [stationGuid] 
     * @param {string} [operatorGuid] 
     * @param {string} [productGuid] 
     * @param {VehicleStatus} [status] 
     * @param {string} [licensePlate] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesPoolIdVehiclesGet(poolId: number, pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Create a vehicle.
     * @param {number} [operatorAccountId] 
     * @param {string} [productGuid] 
     * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesPost(operatorAccountId?: number, productGuid?: string, vehicleCreateCommand?: VehicleCreateCommand, options?: AxiosRequestConfig): AxiosPromise<VehicleCreateCommandResult>;

    /**
     * 
     * @summary Gets station presence.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesStationPresenceGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<GetVehicleStationPresenceListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Add the listing\'s calendar event.
     * @param {string} vehicleGuid 
     * @param {VehicleEventAddCommand} [vehicleEventAddCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid: string, vehicleEventAddCommand?: VehicleEventAddCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Removes the vehicle pool picture.
     * @param {string} vehicleGuid 
     * @param {string} pictureGuid THe picture identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Edits the vehicle pool picture.
     * @param {string} vehicleGuid 
     * @param {string} pictureGuid 
     * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the vehicle picture and returns the model.
     * @param {string} vehicleGuid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Sorts the vehicle pool pictures.
     * @param {string} vehicleGuid 
     * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the square thumbnail picture and returns the model.
     * @param {string} vehicleGuid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApiInterface
     */
    apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

}

/**
 * VehicleAdminApi - object-oriented interface
 * @export
 * @class VehicleAdminApi
 * @extends {BaseAPI}
 */
export class VehicleAdminApi extends BaseAPI implements VehicleAdminApiInterface {
    /**
     * 
     * @summary Create a vehicle to pool.
     * @param {number} poolId 
     * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminPoolsPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminPoolsPoolIdVehiclesPost(poolId, vehicleCreateInPoolCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicles filtered by station.
     * @param {string} [stationGuid] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {string} [search] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesByStationGet(stationGuid, from, until, search, pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the listing\'s calendar event.
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesEventsEventGuidDelete(eventGuid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesEventsEventGuidDelete(eventGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the listing\'s calendar event.
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesEventsEventGuidGet(eventGuid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesEventsEventGuidGet(eventGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the listing\'s calendar event.
     * @param {string} eventGuid 
     * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesEventsEventGuidPut(eventGuid, vehicleEventUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the vehicles from the pool.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [stationGuid] 
     * @param {string} [operatorGuid] 
     * @param {string} [productGuid] 
     * @param {VehicleStatus} [status] 
     * @param {string} [licensePlate] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGet(pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGet(pageSize, pageNumber, stationGuid, operatorGuid, productGuid, status, licensePlate, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approves a vehicle (sent for review by an operator).
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidApprovePost(guid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidApprovePost(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid GUID of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesDamageGuidDelete(guid, damageGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesDamageGuidGet(guid, damageGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes an image from the vehicle damage object.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {string} pictureGuid Picture GUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid, damageGuid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add an image to the vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {File} [picture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesDamageGuidPicturesPost(guid, damageGuid, picture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesDamageGuidPut(guid, damageGuid, updateVehicleDamageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicle damages.
     * @param {string} guid Global identifier of the vehicle
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesGet(guid: string, pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesGet(guid, pageNumber, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDamagesPost(guid, addVehicleDamageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidDelete(guid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidDelete(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidGet(guid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates listing insurance price preview.
     * @param {string} guid 
     * @param {CalculateInsurancePricePreviewQuery} [calculateInsurancePricePreviewQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid: string, calculateInsurancePricePreviewQuery?: CalculateInsurancePricePreviewQuery, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidPreviewInsurancePricePost(guid, calculateInsurancePricePreviewQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} [productGuid] 
     * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidPut(guid: string, productGuid?: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidPut(guid, productGuid, vehicleUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets station presence list.
     * @param {string} guid 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidStationPresenceGet(guid: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidStationPresenceGet(guid, pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets station presence.
     * @param {string} guid 
     * @param {VehicleStationPresenceOverrideCommand} [vehicleStationPresenceOverrideCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidStationPresencePut(guid: string, vehicleStationPresenceOverrideCommand?: VehicleStationPresenceOverrideCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidStationPresencePut(guid, vehicleStationPresenceOverrideCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets station presence.
     * @param {string} guid 
     * @param {string} stationPresenceGuid 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid: string, stationPresenceGuid: string, force?: boolean, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidStationPresenceStationPresenceGuidDelete(guid, stationPresenceGuid, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesGuidTransfersPut(guid, vehicleCreateTransferCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the vehicles from the pool.
     * @param {string} [search] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesLicensePlatesGet(search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesLicensePlatesGet(search, pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the vehicles from the pool.
     * @param {number} poolId The vehicle pool idnetifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [stationGuid] 
     * @param {string} [operatorGuid] 
     * @param {string} [productGuid] 
     * @param {VehicleStatus} [status] 
     * @param {string} [licensePlate] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesPoolIdVehiclesGet(poolId: number, pageSize: number, pageNumber: number, stationGuid?: string, operatorGuid?: string, productGuid?: string, status?: VehicleStatus, licensePlate?: string, search?: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesPoolIdVehiclesGet(poolId, pageSize, pageNumber, stationGuid, operatorGuid, productGuid, status, licensePlate, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a vehicle.
     * @param {number} [operatorAccountId] 
     * @param {string} [productGuid] 
     * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesPost(operatorAccountId?: number, productGuid?: string, vehicleCreateCommand?: VehicleCreateCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesPost(operatorAccountId, productGuid, vehicleCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets station presence.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesStationPresenceGuidGet(guid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesStationPresenceGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add the listing\'s calendar event.
     * @param {string} vehicleGuid 
     * @param {VehicleEventAddCommand} [vehicleEventAddCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid: string, vehicleEventAddCommand?: VehicleEventAddCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesVehicleGuidEventsPost(vehicleGuid, vehicleEventAddCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the vehicle pool picture.
     * @param {string} vehicleGuid 
     * @param {string} pictureGuid THe picture identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits the vehicle pool picture.
     * @param {string} vehicleGuid 
     * @param {string} pictureGuid 
     * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid, pictureGuid, vehiclePictureEditCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the vehicle picture and returns the model.
     * @param {string} vehicleGuid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesVehicleGuidPicturesPost(vehicleGuid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorts the vehicle pool pictures.
     * @param {string} vehicleGuid 
     * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesVehicleGuidPicturesSortPost(vehicleGuid, vehiclePicturesSortCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the square thumbnail picture and returns the model.
     * @param {string} vehicleGuid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleAdminApi
     */
    public apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig) {
        return VehicleAdminApiFp(this.configuration).apiV1AdminVehiclesVehicleGuidSquarethumbnailPost(vehicleGuid, file, options).then((request) => request(this.axios, this.basePath));
    }
}

