/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const TireBrand = {
    NotSet: 'NotSet',
    ContinentalVanContact4Season: 'ContinentalVanContact4Season',
    MichelinAgilisCamping: 'MichelinAgilisCamping',
    ContinentalVancoCamper: 'ContinentalVancoCamper',
    BridgestoneWeatherControl: 'BridgestoneWeatherControl',
    HankookVantraStAS2: 'HankookVantraStAS2',
    ContinentalVanContactEco: 'ContinentalVanContactEco',
    ContinentalPremiumContact6: 'ContinentalPremiumContact6',
    ContinentalContiVanContact200: 'ContinentalContiVanContact200'
} as const;

export type TireBrand = typeof TireBrand[keyof typeof TireBrand];



