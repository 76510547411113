/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const TimeZoneCode = {
    NotSet: 'NotSet',
    DatelineStandardTime: 'DatelineStandardTime',
    UTC11: 'UTC11',
    AleutianStandardTime: 'AleutianStandardTime',
    HawaiianStandardTime: 'HawaiianStandardTime',
    MarquesasStandardTime: 'MarquesasStandardTime',
    AlaskanStandardTime: 'AlaskanStandardTime',
    UTC09: 'UTC09',
    PacificStandardTimeMexico: 'PacificStandardTimeMexico',
    UTC08: 'UTC08',
    PacificStandardTime: 'PacificStandardTime',
    USMountainStandardTime: 'USMountainStandardTime',
    MountainStandardTimeMexico: 'MountainStandardTimeMexico',
    MountainStandardTime: 'MountainStandardTime',
    CentralAmericaStandardTime: 'CentralAmericaStandardTime',
    CentralStandardTime: 'CentralStandardTime',
    EasterIslandStandardTime: 'EasterIslandStandardTime',
    CentralStandardTimeMexico: 'CentralStandardTimeMexico',
    CanadaCentralStandardTime: 'CanadaCentralStandardTime',
    SAPacificStandardTime: 'SAPacificStandardTime',
    EasternStandardTimeMexico: 'EasternStandardTimeMexico',
    EasternStandardTime: 'EasternStandardTime',
    HaitiStandardTime: 'HaitiStandardTime',
    CubaStandardTime: 'CubaStandardTime',
    USEasternStandardTime: 'USEasternStandardTime',
    ParaguayStandardTime: 'ParaguayStandardTime',
    AtlanticStandardTime: 'AtlanticStandardTime',
    VenezuelaStandardTime: 'VenezuelaStandardTime',
    CentralBrazilianStandardTime: 'CentralBrazilianStandardTime',
    SAWesternStandardTime: 'SAWesternStandardTime',
    PacificSAStandardTime: 'PacificSAStandardTime',
    TurksAndCaicosStandardTime: 'TurksAndCaicosStandardTime',
    NewfoundlandStandardTime: 'NewfoundlandStandardTime',
    TocantinsStandardTime: 'TocantinsStandardTime',
    EeasternSouthAmericaStandardTime: 'EeasternSouthAmericaStandardTime',
    SAEasternStandardTime: 'SAEasternStandardTime',
    ArgentinaStandardTime: 'ArgentinaStandardTime',
    GreenlandStandardTime: 'GreenlandStandardTime',
    MontevideoStandardTime: 'MontevideoStandardTime',
    SaintPierreStandardTime: 'SaintPierreStandardTime',
    BahiaStandardTime: 'BahiaStandardTime',
    UTC02: 'UTC02',
    MidAtlanticStandardTime: 'MidAtlanticStandardTime',
    AzoresStandardTime: 'AzoresStandardTime',
    CapeVerdeStandardTime: 'CapeVerdeStandardTime',
    UTC: 'UTC',
    MoroccoStandardTime: 'MoroccoStandardTime',
    GMTStandardTime: 'GMTStandardTime',
    GreenwichStandardTime: 'GreenwichStandardTime',
    WesternEuropeStandardTime: 'WesternEuropeStandardTime',
    RomanceStandardTime: 'RomanceStandardTime',
    CentralEuropeanStandardTime: 'CentralEuropeanStandardTime',
    WesternCentralAfricaStandardTime: 'WesternCentralAfricaStandardTime',
    NamibiaStandardTime: 'NamibiaStandardTime',
    JordanStandardTime: 'JordanStandardTime',
    GTBStandardTime: 'GTBStandardTime',
    MiddleEastStandardTime: 'MiddleEastStandardTime',
    EgyptStandardTime: 'EgyptStandardTime',
    EeasternEuropeStandardTime: 'EeasternEuropeStandardTime',
    SyriaStandardTime: 'SyriaStandardTime',
    WestBankStandardTime: 'WestBankStandardTime',
    SouthAfricaStandardTime: 'SouthAfricaStandardTime',
    FLEStandardTime: 'FLEStandardTime',
    IsraelStandardTime: 'IsraelStandardTime',
    KaliningradStandardTime: 'KaliningradStandardTime',
    LibyaStandardTime: 'LibyaStandardTime',
    ArabicStandardTime: 'ArabicStandardTime',
    TurkeyStandardTime: 'TurkeyStandardTime',
    ArabStandardTime: 'ArabStandardTime',
    BelarusStandardTime: 'BelarusStandardTime',
    RussianStandardTime: 'RussianStandardTime',
    EeasternAfricaStandardTime: 'EeasternAfricaStandardTime',
    IranStandardTime: 'IranStandardTime',
    ArabianStandardTime: 'ArabianStandardTime',
    AstrakhanStandardTime: 'AstrakhanStandardTime',
    AzerbaijanStandardTime: 'AzerbaijanStandardTime',
    RussiaTimeZone3: 'RussiaTimeZone3',
    MauritiusStandardTime: 'MauritiusStandardTime',
    SaratovStandardTime: 'SaratovStandardTime',
    GeorgianStandardTime: 'GeorgianStandardTime',
    CaucasusStandardTime: 'CaucasusStandardTime',
    AfghanistanStandardTime: 'AfghanistanStandardTime',
    WestAsiaStandardTime: 'WestAsiaStandardTime',
    EkaterinburgStandardTime: 'EkaterinburgStandardTime',
    PakistanStandardTime: 'PakistanStandardTime',
    IndiaStandardTime: 'IndiaStandardTime',
    SriLankaStandardTime: 'SriLankaStandardTime',
    NepalStandardTime: 'NepalStandardTime',
    CentralAsiaStandardTime: 'CentralAsiaStandardTime',
    BangladeshStandardTime: 'BangladeshStandardTime',
    OmskStandardTime: 'OmskStandardTime',
    MyanmarStandardTime: 'MyanmarStandardTime',
    SouthEastAsiaStandardTime: 'SouthEastAsiaStandardTime',
    AltaiStandardTime: 'AltaiStandardTime',
    WMongoliaStandardTime: 'WMongoliaStandardTime',
    NorthAsiaStandardTime: 'NorthAsiaStandardTime',
    NorthernCentralAsiaStandardTime: 'NorthernCentralAsiaStandardTime',
    TomskStandardTime: 'TomskStandardTime',
    ChinaStandardTime: 'ChinaStandardTime',
    NorthAsiaEastStandardTime: 'NorthAsiaEastStandardTime',
    SingaporeStandardTime: 'SingaporeStandardTime',
    WesternAustraliaStandardTime: 'WesternAustraliaStandardTime',
    TaipeiStandardTime: 'TaipeiStandardTime',
    UlaanbaatarStandardTime: 'UlaanbaatarStandardTime',
    NorthKoreaStandardTime: 'NorthKoreaStandardTime',
    AusCentralWStandardTime: 'AusCentralWStandardTime',
    TransbaikalStandardTime: 'TransbaikalStandardTime',
    TokyoStandardTime: 'TokyoStandardTime',
    KoreaStandardTime: 'KoreaStandardTime',
    YakutskStandardTime: 'YakutskStandardTime',
    CentralAustraliaStandardTime: 'CentralAustraliaStandardTime',
    AustralianCentralStandardTime: 'AustralianCentralStandardTime',
    EeasternAustraliaStandardTime: 'EeasternAustraliaStandardTime',
    AustralianEasternStandardTime: 'AustralianEasternStandardTime',
    WestPacificStandardTime: 'WestPacificStandardTime',
    TasmaniaStandardTime: 'TasmaniaStandardTime',
    VladivostokStandardTime: 'VladivostokStandardTime',
    LordHoweStandardTime: 'LordHoweStandardTime',
    BougainvilleStandardTime: 'BougainvilleStandardTime',
    RussiaTimeZone10: 'RussiaTimeZone10',
    MagadanStandardTime: 'MagadanStandardTime',
    NorfolkStandardTime: 'NorfolkStandardTime',
    SakhalinStandardTime: 'SakhalinStandardTime',
    CentralPacificStandardTime: 'CentralPacificStandardTime',
    RussiaTimeZone11: 'RussiaTimeZone11',
    NewZealandStandardTime: 'NewZealandStandardTime',
    UTC12: 'UTC12',
    FijiStandardTime: 'FijiStandardTime',
    KamchatkaStandardTime: 'KamchatkaStandardTime',
    ChathamIslandsStandardTime: 'ChathamIslandsStandardTime',
    UTC13: 'UTC13',
    TongaStandardTime: 'TongaStandardTime',
    SamoaStandardTime: 'SamoaStandardTime',
    LineIslandsStandardTime: 'LineIslandsStandardTime',
    MalayPeninsulaStandardTime: 'MalayPeninsulaStandardTime',
    SudanStandardTime: 'SudanStandardTime',
    SaoTomeStandardTime: 'SaoTomeStandardTime',
    YukonStandardTime: 'YukonStandardTime',
    TurksandCaicosStandardTime: 'TurksandCaicosStandardTime',
    MagallanesStandardTime: 'MagallanesStandardTime',
    WestBankGazaStandardTime: 'WestBankGazaStandardTime',
    JerusalemStandardTime: 'JerusalemStandardTime',
    NovosibirskStandardTime: 'NovosibirskStandardTime',
    QyzylordaStandardTime: 'QyzylordaStandardTime',
    CaboVerdeStandardTime: 'CaboVerdeStandardTime',
    VolgogradStandardTime: 'VolgogradStandardTime',
    RussiaTZ1StandardTime: 'RussiaTZ1StandardTime',
    RussiaTZ2StandardTime: 'RussiaTZ2StandardTime',
    RussiaTZ3StandardTime: 'RussiaTZ3StandardTime',
    RussiaTZ4StandardTime: 'RussiaTZ4StandardTime',
    RussiaTZ5StandardTime: 'RussiaTZ5StandardTime',
    RussiaTZ6StandardTime: 'RussiaTZ6StandardTime',
    RussiaTZ7StandardTime: 'RussiaTZ7StandardTime',
    RussiaTZ8StandardTime: 'RussiaTZ8StandardTime',
    RussiaTZ9StandardTime: 'RussiaTZ9StandardTime',
    RussiaTZ10StandardTime: 'RussiaTZ10StandardTime',
    RussiaTZ11StandardTime: 'RussiaTZ11StandardTime'
} as const;

export type TimeZoneCode = typeof TimeZoneCode[keyof typeof TimeZoneCode];



