/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { VehicleModelByIdQueryResult } from '../models';
// @ts-ignore
import { VehicleModelListQueryPaginatedResult } from '../models';
// @ts-ignore
import { VehicleModelUniqueListQueryResultListResult } from '../models';
/**
 * ModelOperatorApi - axios parameter creator
 * @export
 */
export const ModelOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of vehicle models.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorModelsGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1OperatorModelsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1OperatorModelsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/operator/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorModelsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorModelsIdGet', 'id', id)
            const localVarPath = `/api/v1/operator/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorModelsUniqueGet: async (manufacturerGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/models/unique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerGuid !== undefined) {
                localVarQueryParameter['manufacturerGuid'] = manufacturerGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelOperatorApi - functional programming interface
 * @export
 */
export const ModelOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle models.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorModelsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelListQueryPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorModelsGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorModelsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorModelsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorModelsUniqueGet(manufacturerGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelUniqueListQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorModelsUniqueGet(manufacturerGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelOperatorApi - factory interface
 * @export
 */
export const ModelOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle models.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorModelsGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<VehicleModelListQueryPaginatedResult> {
            return localVarFp.apiV1OperatorModelsGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorModelsIdGet(id: number, options?: any): AxiosPromise<VehicleModelByIdQueryResult> {
            return localVarFp.apiV1OperatorModelsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorModelsUniqueGet(manufacturerGuid?: string, options?: any): AxiosPromise<VehicleModelUniqueListQueryResultListResult> {
            return localVarFp.apiV1OperatorModelsUniqueGet(manufacturerGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelOperatorApi - interface
 * @export
 * @interface ModelOperatorApi
 */
export interface ModelOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle models.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelOperatorApiInterface
     */
    apiV1OperatorModelsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleModelListQueryPaginatedResult>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelOperatorApiInterface
     */
    apiV1OperatorModelsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<VehicleModelByIdQueryResult>;

    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelOperatorApiInterface
     */
    apiV1OperatorModelsUniqueGet(manufacturerGuid?: string, options?: AxiosRequestConfig): AxiosPromise<VehicleModelUniqueListQueryResultListResult>;

}

/**
 * ModelOperatorApi - object-oriented interface
 * @export
 * @class ModelOperatorApi
 * @extends {BaseAPI}
 */
export class ModelOperatorApi extends BaseAPI implements ModelOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle models.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelOperatorApi
     */
    public apiV1OperatorModelsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ModelOperatorApiFp(this.configuration).apiV1OperatorModelsGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelOperatorApi
     */
    public apiV1OperatorModelsIdGet(id: number, options?: AxiosRequestConfig) {
        return ModelOperatorApiFp(this.configuration).apiV1OperatorModelsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelOperatorApi
     */
    public apiV1OperatorModelsUniqueGet(manufacturerGuid?: string, options?: AxiosRequestConfig) {
        return ModelOperatorApiFp(this.configuration).apiV1OperatorModelsUniqueGet(manufacturerGuid, options).then((request) => request(this.axios, this.basePath));
    }
}

