/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateKycRequestCommand } from '../models';
// @ts-ignore
import { CreateKycRequestCommandResult } from '../models';
// @ts-ignore
import { DocumentSide } from '../models';
// @ts-ignore
import { DocumentType } from '../models';
// @ts-ignore
import { KycRequestQueryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateApplicantKycRequestDataCommand } from '../models';
/**
 * KycMarketplaceApi - axios parameter creator
 * @export
 */
export const KycMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Uploads document to the request.
         * @param {string} guid 
         * @param {DocumentSide} [documentSide] 
         * @param {DocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycGuidDocumentsPost: async (guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1MarketplaceKycGuidDocumentsPost', 'guid', guid)
            const localVarPath = `/api/v1/marketplace/kyc/{guid}/documents`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentSide !== undefined) {
                localVarQueryParameter['documentSide'] = documentSide;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the kyc request.
         * @param {string} guid 
         * @param {UpdateApplicantKycRequestDataCommand} [updateApplicantKycRequestDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycGuidPut: async (guid: string, updateApplicantKycRequestDataCommand?: UpdateApplicantKycRequestDataCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1MarketplaceKycGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/marketplace/kyc/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicantKycRequestDataCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets latest kyc request of user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycLatestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/kyc/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates applicants data of kyc request
         * @param {string} [userGuid] 
         * @param {string} [bookingReferenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycPost: async (userGuid?: string, bookingReferenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/kyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userGuid !== undefined) {
                localVarQueryParameter['userGuid'] = userGuid;
            }

            if (bookingReferenceCode !== undefined) {
                localVarQueryParameter['bookingReferenceCode'] = bookingReferenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates kyc request - for debug purposes
         * @param {CreateKycRequestCommand} [createKycRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycRequestPost: async (createKycRequestCommand?: CreateKycRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/kyc/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createKycRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KycMarketplaceApi - functional programming interface
 * @export
 */
export const KycMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KycMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Uploads document to the request.
         * @param {string} guid 
         * @param {DocumentSide} [documentSide] 
         * @param {DocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceKycGuidDocumentsPost(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceKycGuidDocumentsPost(guid, documentSide, documentType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the kyc request.
         * @param {string} guid 
         * @param {UpdateApplicantKycRequestDataCommand} [updateApplicantKycRequestDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceKycGuidPut(guid: string, updateApplicantKycRequestDataCommand?: UpdateApplicantKycRequestDataCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceKycGuidPut(guid, updateApplicantKycRequestDataCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets latest kyc request of user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceKycLatestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRequestQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceKycLatestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates applicants data of kyc request
         * @param {string} [userGuid] 
         * @param {string} [bookingReferenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceKycPost(userGuid?: string, bookingReferenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateKycRequestCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceKycPost(userGuid, bookingReferenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates kyc request - for debug purposes
         * @param {CreateKycRequestCommand} [createKycRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceKycRequestPost(createKycRequestCommand?: CreateKycRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceKycRequestPost(createKycRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KycMarketplaceApi - factory interface
 * @export
 */
export const KycMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KycMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Uploads document to the request.
         * @param {string} guid 
         * @param {DocumentSide} [documentSide] 
         * @param {DocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycGuidDocumentsPost(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceKycGuidDocumentsPost(guid, documentSide, documentType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the kyc request.
         * @param {string} guid 
         * @param {UpdateApplicantKycRequestDataCommand} [updateApplicantKycRequestDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycGuidPut(guid: string, updateApplicantKycRequestDataCommand?: UpdateApplicantKycRequestDataCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceKycGuidPut(guid, updateApplicantKycRequestDataCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets latest kyc request of user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycLatestGet(options?: any): AxiosPromise<KycRequestQueryResult> {
            return localVarFp.apiV1MarketplaceKycLatestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates applicants data of kyc request
         * @param {string} [userGuid] 
         * @param {string} [bookingReferenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycPost(userGuid?: string, bookingReferenceCode?: string, options?: any): AxiosPromise<CreateKycRequestCommandResult> {
            return localVarFp.apiV1MarketplaceKycPost(userGuid, bookingReferenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates kyc request - for debug purposes
         * @param {CreateKycRequestCommand} [createKycRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceKycRequestPost(createKycRequestCommand?: CreateKycRequestCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceKycRequestPost(createKycRequestCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KycMarketplaceApi - interface
 * @export
 * @interface KycMarketplaceApi
 */
export interface KycMarketplaceApiInterface {
    /**
     * 
     * @summary Uploads document to the request.
     * @param {string} guid 
     * @param {DocumentSide} [documentSide] 
     * @param {DocumentType} [documentType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApiInterface
     */
    apiV1MarketplaceKycGuidDocumentsPost(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the kyc request.
     * @param {string} guid 
     * @param {UpdateApplicantKycRequestDataCommand} [updateApplicantKycRequestDataCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApiInterface
     */
    apiV1MarketplaceKycGuidPut(guid: string, updateApplicantKycRequestDataCommand?: UpdateApplicantKycRequestDataCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets latest kyc request of user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApiInterface
     */
    apiV1MarketplaceKycLatestGet(options?: AxiosRequestConfig): AxiosPromise<KycRequestQueryResult>;

    /**
     * 
     * @summary Updates applicants data of kyc request
     * @param {string} [userGuid] 
     * @param {string} [bookingReferenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApiInterface
     */
    apiV1MarketplaceKycPost(userGuid?: string, bookingReferenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<CreateKycRequestCommandResult>;

    /**
     * 
     * @summary Creates kyc request - for debug purposes
     * @param {CreateKycRequestCommand} [createKycRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApiInterface
     */
    apiV1MarketplaceKycRequestPost(createKycRequestCommand?: CreateKycRequestCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * KycMarketplaceApi - object-oriented interface
 * @export
 * @class KycMarketplaceApi
 * @extends {BaseAPI}
 */
export class KycMarketplaceApi extends BaseAPI implements KycMarketplaceApiInterface {
    /**
     * 
     * @summary Uploads document to the request.
     * @param {string} guid 
     * @param {DocumentSide} [documentSide] 
     * @param {DocumentType} [documentType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApi
     */
    public apiV1MarketplaceKycGuidDocumentsPost(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: AxiosRequestConfig) {
        return KycMarketplaceApiFp(this.configuration).apiV1MarketplaceKycGuidDocumentsPost(guid, documentSide, documentType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the kyc request.
     * @param {string} guid 
     * @param {UpdateApplicantKycRequestDataCommand} [updateApplicantKycRequestDataCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApi
     */
    public apiV1MarketplaceKycGuidPut(guid: string, updateApplicantKycRequestDataCommand?: UpdateApplicantKycRequestDataCommand, options?: AxiosRequestConfig) {
        return KycMarketplaceApiFp(this.configuration).apiV1MarketplaceKycGuidPut(guid, updateApplicantKycRequestDataCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets latest kyc request of user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApi
     */
    public apiV1MarketplaceKycLatestGet(options?: AxiosRequestConfig) {
        return KycMarketplaceApiFp(this.configuration).apiV1MarketplaceKycLatestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates applicants data of kyc request
     * @param {string} [userGuid] 
     * @param {string} [bookingReferenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApi
     */
    public apiV1MarketplaceKycPost(userGuid?: string, bookingReferenceCode?: string, options?: AxiosRequestConfig) {
        return KycMarketplaceApiFp(this.configuration).apiV1MarketplaceKycPost(userGuid, bookingReferenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates kyc request - for debug purposes
     * @param {CreateKycRequestCommand} [createKycRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycMarketplaceApi
     */
    public apiV1MarketplaceKycRequestPost(createKycRequestCommand?: CreateKycRequestCommand, options?: AxiosRequestConfig) {
        return KycMarketplaceApiFp(this.configuration).apiV1MarketplaceKycRequestPost(createKycRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

