/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ManufacturerListResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ManufacturerOperatorApi - axios parameter creator
 * @export
 */
export const ManufacturerOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorManufacturersGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1OperatorManufacturersGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1OperatorManufacturersGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/operator/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerOperatorApi - functional programming interface
 * @export
 */
export const ManufacturerOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorManufacturersGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerOperatorApi - factory interface
 * @export
 */
export const ManufacturerOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ManufacturerListResultPaginatedResult> {
            return localVarFp.apiV1OperatorManufacturersGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerOperatorApi - interface
 * @export
 * @interface ManufacturerOperatorApi
 */
export interface ManufacturerOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerOperatorApiInterface
     */
    apiV1OperatorManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ManufacturerListResultPaginatedResult>;

}

/**
 * ManufacturerOperatorApi - object-oriented interface
 * @export
 * @class ManufacturerOperatorApi
 * @extends {BaseAPI}
 */
export class ManufacturerOperatorApi extends BaseAPI implements ManufacturerOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerOperatorApi
     */
    public apiV1OperatorManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ManufacturerOperatorApiFp(this.configuration).apiV1OperatorManufacturersGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }
}

