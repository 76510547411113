/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ManufacturerByIdResult } from '../models';
// @ts-ignore
import { ManufacturerCreateCommand } from '../models';
// @ts-ignore
import { ManufacturerListResultPaginatedResult } from '../models';
// @ts-ignore
import { ManufacturerUpdateCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ManufacturerAdminApi - axios parameter creator
 * @export
 */
export const ManufacturerAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminManufacturersGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminManufacturersGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminManufacturersIdDelete', 'id', id)
            const localVarPath = `/api/v1/admin/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminManufacturersIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {ManufacturerUpdateCommand} [manufacturerUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersIdPut: async (id: number, manufacturerUpdateCommand?: ManufacturerUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminManufacturersIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manufacturerUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersModelsCsvGet: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/manufacturers/models/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new vehicle manufacturer entity from model provided.
         * @param {ManufacturerCreateCommand} [manufacturerCreateCommand] The vehicle manufacturer data model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersPost: async (manufacturerCreateCommand?: ManufacturerCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manufacturerCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerAdminApi - functional programming interface
 * @export
 */
export const ManufacturerAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminManufacturersGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminManufacturersIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminManufacturersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminManufacturersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerByIdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminManufacturersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an existing vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {ManufacturerUpdateCommand} [manufacturerUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminManufacturersIdPut(id: number, manufacturerUpdateCommand?: ManufacturerUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminManufacturersIdPut(id, manufacturerUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminManufacturersModelsCsvGet(query?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminManufacturersModelsCsvGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new vehicle manufacturer entity from model provided.
         * @param {ManufacturerCreateCommand} [manufacturerCreateCommand] The vehicle manufacturer data model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminManufacturersPost(manufacturerCreateCommand?: ManufacturerCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminManufacturersPost(manufacturerCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerAdminApi - factory interface
 * @export
 */
export const ManufacturerAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ManufacturerListResultPaginatedResult> {
            return localVarFp.apiV1AdminManufacturersGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminManufacturersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersIdGet(id: number, options?: any): AxiosPromise<ManufacturerByIdResult> {
            return localVarFp.apiV1AdminManufacturersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing vehicle manufacturer entity based on its identifier.
         * @param {number} id The vehicle manufacturer unique identifier.
         * @param {ManufacturerUpdateCommand} [manufacturerUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersIdPut(id: number, manufacturerUpdateCommand?: ManufacturerUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminManufacturersIdPut(id, manufacturerUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersModelsCsvGet(query?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminManufacturersModelsCsvGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new vehicle manufacturer entity from model provided.
         * @param {ManufacturerCreateCommand} [manufacturerCreateCommand] The vehicle manufacturer data model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminManufacturersPost(manufacturerCreateCommand?: ManufacturerCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminManufacturersPost(manufacturerCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerAdminApi - interface
 * @export
 * @interface ManufacturerAdminApi
 */
export interface ManufacturerAdminApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApiInterface
     */
    apiV1AdminManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ManufacturerListResultPaginatedResult>;

    /**
     * 
     * @summary Deletes an existing vehicle manufacturer entity based on its identifier.
     * @param {number} id The vehicle manufacturer unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApiInterface
     */
    apiV1AdminManufacturersIdDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the vehicle manufacturer entity based on its identifier.
     * @param {number} id The vehicle manufacturer unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApiInterface
     */
    apiV1AdminManufacturersIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<ManufacturerByIdResult>;

    /**
     * 
     * @summary Updates an existing vehicle manufacturer entity based on its identifier.
     * @param {number} id The vehicle manufacturer unique identifier.
     * @param {ManufacturerUpdateCommand} [manufacturerUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApiInterface
     */
    apiV1AdminManufacturersIdPut(id: number, manufacturerUpdateCommand?: ManufacturerUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApiInterface
     */
    apiV1AdminManufacturersModelsCsvGet(query?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new vehicle manufacturer entity from model provided.
     * @param {ManufacturerCreateCommand} [manufacturerCreateCommand] The vehicle manufacturer data model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApiInterface
     */
    apiV1AdminManufacturersPost(manufacturerCreateCommand?: ManufacturerCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * ManufacturerAdminApi - object-oriented interface
 * @export
 * @class ManufacturerAdminApi
 * @extends {BaseAPI}
 */
export class ManufacturerAdminApi extends BaseAPI implements ManufacturerAdminApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApi
     */
    public apiV1AdminManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ManufacturerAdminApiFp(this.configuration).apiV1AdminManufacturersGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing vehicle manufacturer entity based on its identifier.
     * @param {number} id The vehicle manufacturer unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApi
     */
    public apiV1AdminManufacturersIdDelete(id: number, options?: AxiosRequestConfig) {
        return ManufacturerAdminApiFp(this.configuration).apiV1AdminManufacturersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the vehicle manufacturer entity based on its identifier.
     * @param {number} id The vehicle manufacturer unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApi
     */
    public apiV1AdminManufacturersIdGet(id: number, options?: AxiosRequestConfig) {
        return ManufacturerAdminApiFp(this.configuration).apiV1AdminManufacturersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing vehicle manufacturer entity based on its identifier.
     * @param {number} id The vehicle manufacturer unique identifier.
     * @param {ManufacturerUpdateCommand} [manufacturerUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApi
     */
    public apiV1AdminManufacturersIdPut(id: number, manufacturerUpdateCommand?: ManufacturerUpdateCommand, options?: AxiosRequestConfig) {
        return ManufacturerAdminApiFp(this.configuration).apiV1AdminManufacturersIdPut(id, manufacturerUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApi
     */
    public apiV1AdminManufacturersModelsCsvGet(query?: object, options?: AxiosRequestConfig) {
        return ManufacturerAdminApiFp(this.configuration).apiV1AdminManufacturersModelsCsvGet(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new vehicle manufacturer entity from model provided.
     * @param {ManufacturerCreateCommand} [manufacturerCreateCommand] The vehicle manufacturer data model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerAdminApi
     */
    public apiV1AdminManufacturersPost(manufacturerCreateCommand?: ManufacturerCreateCommand, options?: AxiosRequestConfig) {
        return ManufacturerAdminApiFp(this.configuration).apiV1AdminManufacturersPost(manufacturerCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

