/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Type of vehicle/camper.
 * @export
 * @enum {string}
 */

export const VehicleType = {
    NotSet: 'NotSet',
    Campervan: 'Campervan',
    BuiltIn: 'BuiltIn',
    SemiIntegrated: 'SemiIntegrated',
    Alcove: 'Alcove',
    Integrated: 'Integrated',
    Trailer: 'Trailer',
    CarWithRooftopTent: 'CarWithRooftopTent',
    Van: 'Van',
    RV: 'RV',
    Other: 'Other'
} as const;

export type VehicleType = typeof VehicleType[keyof typeof VehicleType];



