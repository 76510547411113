/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Vehicle stove type.
 * @export
 * @enum {string}
 */

export const StoveType = {
    NotSet: 'NotSet',
    Electric: 'Electric',
    Gas: 'Gas',
    Propane: 'Propane',
    Portable: 'Portable',
    NoStove: 'NoStove'
} as const;

export type StoveType = typeof StoveType[keyof typeof StoveType];



