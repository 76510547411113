/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AlterationState } from '../models';
// @ts-ignore
import { AvailableBookingAssigneesListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingAssigneesQueryResultListResult } from '../models';
// @ts-ignore
import { BookingEventsLogQueryResult } from '../models';
// @ts-ignore
import { BookingIntentListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingOfferStatus } from '../models';
// @ts-ignore
import { BookingOffersListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingPayoutsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingRequestListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingRequestStatus } from '../models';
// @ts-ignore
import { BookingReservationListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingReservationStatus } from '../models';
// @ts-ignore
import { BookingsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { OpenInquiryListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * BookingAdminApi - axios parameter creator
 * @export
 */
export const BookingAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of cancelled bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsCancelledGet: async (pageNumber: number, pageSize: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsCancelledGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsCancelledGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings/cancelled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of  bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsGet: async (pageNumber: number, pageSize: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {string} [vehicleGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdAssignVehiclePut: async (id: string, vehicleGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdAssignVehiclePut', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/{id}/assign-vehicle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleGuid !== undefined) {
                localVarQueryParameter['vehicleGuid'] = vehicleGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the available sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdAssigneesAvailableGet: async (id: string, pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdAssigneesAvailableGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdAssigneesAvailableGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdAssigneesAvailableGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/bookings/{id}/assignees/available`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdAssigneesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdAssigneesGet', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/{id}/assignees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Immediatelly expires the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdExpirePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdExpirePost', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/{id}/expire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a booking change logs.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdLogsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdLogsGet', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassigns booking vehicle.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdUnassignVehiclePut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIdUnassignVehiclePut', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/{id}/unassign-vehicle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of open inquiries.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsInquiriesGet: async (pageNumber: number, pageSize: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsInquiriesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsInquiriesGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of booking intents.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIntentsGet: async (pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsIntentsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsIntentsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings/intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (assigneeGuids) {
                localVarQueryParameter['AssigneeGuids'] = assigneeGuids;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (listingGuid !== undefined) {
                localVarQueryParameter['ListingGuid'] = listingGuid;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (agent !== undefined) {
                localVarQueryParameter['Agent'] = agent;
            }

            if (licensePlate !== undefined) {
                localVarQueryParameter['LicensePlate'] = licensePlate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIntentsIdCompletePost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsIntentsIdCompletePost', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/intents/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of booking offers.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {Array<BookingOfferStatus>} [statuses] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsOffersGet: async (pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingOfferStatus>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsOffersGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsOffersGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (assigneeGuids) {
                localVarQueryParameter['AssigneeGuids'] = assigneeGuids;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (listingGuid !== undefined) {
                localVarQueryParameter['ListingGuid'] = listingGuid;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (agent !== undefined) {
                localVarQueryParameter['Agent'] = agent;
            }

            if (licensePlate !== undefined) {
                localVarQueryParameter['LicensePlate'] = licensePlate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceCode] 
         * @param {string} [reservedOnFrom] 
         * @param {string} [reservedOnUntil] 
         * @param {string} [bookingStartFrom] 
         * @param {string} [bookingStartUntil] 
         * @param {string} [bookingEndFrom] 
         * @param {string} [bookingEndUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [operatorSurname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsPayoutsCsvGet: async (referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/bookings/payouts/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['ReferenceCode'] = referenceCode;
            }

            if (reservedOnFrom !== undefined) {
                localVarQueryParameter['ReservedOnFrom'] = (reservedOnFrom as any instanceof Date) ?
                    (reservedOnFrom as any).toISOString() :
                    reservedOnFrom;
            }

            if (reservedOnUntil !== undefined) {
                localVarQueryParameter['ReservedOnUntil'] = (reservedOnUntil as any instanceof Date) ?
                    (reservedOnUntil as any).toISOString() :
                    reservedOnUntil;
            }

            if (bookingStartFrom !== undefined) {
                localVarQueryParameter['BookingStartFrom'] = (bookingStartFrom as any instanceof Date) ?
                    (bookingStartFrom as any).toISOString() :
                    bookingStartFrom;
            }

            if (bookingStartUntil !== undefined) {
                localVarQueryParameter['BookingStartUntil'] = (bookingStartUntil as any instanceof Date) ?
                    (bookingStartUntil as any).toISOString() :
                    bookingStartUntil;
            }

            if (bookingEndFrom !== undefined) {
                localVarQueryParameter['BookingEndFrom'] = (bookingEndFrom as any instanceof Date) ?
                    (bookingEndFrom as any).toISOString() :
                    bookingEndFrom;
            }

            if (bookingEndUntil !== undefined) {
                localVarQueryParameter['BookingEndUntil'] = (bookingEndUntil as any instanceof Date) ?
                    (bookingEndUntil as any).toISOString() :
                    bookingEndUntil;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (operatorSurname !== undefined) {
                localVarQueryParameter['OperatorSurname'] = operatorSurname;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the payouts data for bookings.
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [referenceCode] 
         * @param {string} [reservedOnFrom] 
         * @param {string} [reservedOnUntil] 
         * @param {string} [bookingStartFrom] 
         * @param {string} [bookingStartUntil] 
         * @param {string} [bookingEndFrom] 
         * @param {string} [bookingEndUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [operatorSurname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsPayoutsGet: async (pageSize?: number, pageNumber?: number, referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/bookings/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (referenceCode !== undefined) {
                localVarQueryParameter['ReferenceCode'] = referenceCode;
            }

            if (reservedOnFrom !== undefined) {
                localVarQueryParameter['ReservedOnFrom'] = (reservedOnFrom as any instanceof Date) ?
                    (reservedOnFrom as any).toISOString() :
                    reservedOnFrom;
            }

            if (reservedOnUntil !== undefined) {
                localVarQueryParameter['ReservedOnUntil'] = (reservedOnUntil as any instanceof Date) ?
                    (reservedOnUntil as any).toISOString() :
                    reservedOnUntil;
            }

            if (bookingStartFrom !== undefined) {
                localVarQueryParameter['BookingStartFrom'] = (bookingStartFrom as any instanceof Date) ?
                    (bookingStartFrom as any).toISOString() :
                    bookingStartFrom;
            }

            if (bookingStartUntil !== undefined) {
                localVarQueryParameter['BookingStartUntil'] = (bookingStartUntil as any instanceof Date) ?
                    (bookingStartUntil as any).toISOString() :
                    bookingStartUntil;
            }

            if (bookingEndFrom !== undefined) {
                localVarQueryParameter['BookingEndFrom'] = (bookingEndFrom as any instanceof Date) ?
                    (bookingEndFrom as any).toISOString() :
                    bookingEndFrom;
            }

            if (bookingEndUntil !== undefined) {
                localVarQueryParameter['BookingEndUntil'] = (bookingEndUntil as any instanceof Date) ?
                    (bookingEndUntil as any).toISOString() :
                    bookingEndUntil;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (operatorSurname !== undefined) {
                localVarQueryParameter['OperatorSurname'] = operatorSurname;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of unresolved requested bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {Array<BookingRequestStatus>} [statuses] 
         * @param {boolean} [rejectedByOperator] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsRequestsGet: async (pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingRequestStatus>, rejectedByOperator?: boolean, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsRequestsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsRequestsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (assigneeGuids) {
                localVarQueryParameter['AssigneeGuids'] = assigneeGuids;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (rejectedByOperator !== undefined) {
                localVarQueryParameter['RejectedByOperator'] = rejectedByOperator;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (listingGuid !== undefined) {
                localVarQueryParameter['ListingGuid'] = listingGuid;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (agent !== undefined) {
                localVarQueryParameter['Agent'] = agent;
            }

            if (licensePlate !== undefined) {
                localVarQueryParameter['LicensePlate'] = licensePlate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsRequestsIdConfirmPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsRequestsIdConfirmPost', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/requests/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejects the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsRequestsIdRejectPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBookingsRequestsIdRejectPost', 'id', id)
            const localVarPath = `/api/v1/admin/bookings/requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of reservations.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<BookingReservationStatus>} [statuses] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {string} [operatorGuid] 
         * @param {AlterationState} [alterationState] 
         * @param {string} [createdFrom] 
         * @param {string} [createdUntil] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsReservationsGet: async (pageNumber: number, pageSize: number, search?: string, statuses?: Array<BookingReservationStatus>, assigneeGuids?: Array<string>, operatorGuid?: string, alterationState?: AlterationState, createdFrom?: string, createdUntil?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBookingsReservationsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBookingsReservationsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/bookings/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (assigneeGuids) {
                localVarQueryParameter['AssigneeGuids'] = assigneeGuids;
            }

            if (operatorGuid !== undefined) {
                localVarQueryParameter['OperatorGuid'] = operatorGuid;
            }

            if (alterationState !== undefined) {
                localVarQueryParameter['AlterationState'] = alterationState;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['CreatedFrom'] = (createdFrom as any instanceof Date) ?
                    (createdFrom as any).toISOString() :
                    createdFrom;
            }

            if (createdUntil !== undefined) {
                localVarQueryParameter['CreatedUntil'] = (createdUntil as any instanceof Date) ?
                    (createdUntil as any).toISOString() :
                    createdUntil;
            }

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }

            if (listingGuid !== undefined) {
                localVarQueryParameter['ListingGuid'] = listingGuid;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (agent !== undefined) {
                localVarQueryParameter['Agent'] = agent;
            }

            if (licensePlate !== undefined) {
                localVarQueryParameter['LicensePlate'] = licensePlate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recalculates subtotals for a reservation.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost: async (referenceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            assertParamExists('apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost', 'referenceCode', referenceCode)
            const localVarPath = `/api/v1/admin/bookings/reservations/{referenceCode}/fix-subtotals`
                .replace(`{${"referenceCode"}}`, encodeURIComponent(String(referenceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of reservations with incorrect total price.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsReservationsWithIncorrectTotalGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/bookings/reservations/with-incorrect-total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingAdminApi - functional programming interface
 * @export
 */
export const BookingAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of cancelled bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsCancelledGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsCancelledGet(pageNumber, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of  bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsGet(pageNumber, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {string} [vehicleGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIdAssignVehiclePut(id, vehicleGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the available sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableBookingAssigneesListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIdAssigneesAvailableGet(id, pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIdAssigneesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingAssigneesQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIdAssigneesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Immediatelly expires the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIdExpirePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIdExpirePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a booking change logs.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIdLogsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingEventsLogQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIdLogsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unassigns booking vehicle.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIdUnassignVehiclePut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIdUnassignVehiclePut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of open inquiries.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsInquiriesGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenInquiryListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsInquiriesGet(pageNumber, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of booking intents.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIntentsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingIntentListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIntentsGet(pageNumber, pageSize, search, assigneeGuids, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirms the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsIntentsIdCompletePost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsIntentsIdCompletePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of booking offers.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {Array<BookingOfferStatus>} [statuses] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsOffersGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingOfferStatus>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingOffersListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsOffersGet(pageNumber, pageSize, search, assigneeGuids, statuses, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [referenceCode] 
         * @param {string} [reservedOnFrom] 
         * @param {string} [reservedOnUntil] 
         * @param {string} [bookingStartFrom] 
         * @param {string} [bookingStartUntil] 
         * @param {string} [bookingEndFrom] 
         * @param {string} [bookingEndUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [operatorSurname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsPayoutsCsvGet(referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsPayoutsCsvGet(referenceCode, reservedOnFrom, reservedOnUntil, bookingStartFrom, bookingStartUntil, bookingEndFrom, bookingEndUntil, stationGuid, operatorSurname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the payouts data for bookings.
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [referenceCode] 
         * @param {string} [reservedOnFrom] 
         * @param {string} [reservedOnUntil] 
         * @param {string} [bookingStartFrom] 
         * @param {string} [bookingStartUntil] 
         * @param {string} [bookingEndFrom] 
         * @param {string} [bookingEndUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [operatorSurname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsPayoutsGet(pageSize?: number, pageNumber?: number, referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingPayoutsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsPayoutsGet(pageSize, pageNumber, referenceCode, reservedOnFrom, reservedOnUntil, bookingStartFrom, bookingStartUntil, bookingEndFrom, bookingEndUntil, stationGuid, operatorSurname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of unresolved requested bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {Array<BookingRequestStatus>} [statuses] 
         * @param {boolean} [rejectedByOperator] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsRequestsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingRequestStatus>, rejectedByOperator?: boolean, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRequestListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsRequestsGet(pageNumber, pageSize, search, assigneeGuids, statuses, rejectedByOperator, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirms the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsRequestsIdConfirmPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsRequestsIdConfirmPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rejects the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsRequestsIdRejectPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsRequestsIdRejectPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of reservations.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<BookingReservationStatus>} [statuses] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {string} [operatorGuid] 
         * @param {AlterationState} [alterationState] 
         * @param {string} [createdFrom] 
         * @param {string} [createdUntil] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsReservationsGet(pageNumber: number, pageSize: number, search?: string, statuses?: Array<BookingReservationStatus>, assigneeGuids?: Array<string>, operatorGuid?: string, alterationState?: AlterationState, createdFrom?: string, createdUntil?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingReservationListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsReservationsGet(pageNumber, pageSize, search, statuses, assigneeGuids, operatorGuid, alterationState, createdFrom, createdUntil, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recalculates subtotals for a reservation.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of reservations with incorrect total price.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBookingsReservationsWithIncorrectTotalGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBookingsReservationsWithIncorrectTotalGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingAdminApi - factory interface
 * @export
 */
export const BookingAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of cancelled bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsCancelledGet(pageNumber: number, pageSize: number, search?: string, options?: any): AxiosPromise<BookingsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsCancelledGet(pageNumber, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of  bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsGet(pageNumber: number, pageSize: number, search?: string, options?: any): AxiosPromise<BookingsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsGet(pageNumber, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {string} [vehicleGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsIdAssignVehiclePut(id, vehicleGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the available sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<AvailableBookingAssigneesListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsIdAssigneesAvailableGet(id, pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the sales assignees for the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdAssigneesGet(id: string, options?: any): AxiosPromise<BookingAssigneesQueryResultListResult> {
            return localVarFp.apiV1AdminBookingsIdAssigneesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Immediatelly expires the booking.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdExpirePost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsIdExpirePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a booking change logs.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdLogsGet(id: string, options?: any): AxiosPromise<Array<BookingEventsLogQueryResult>> {
            return localVarFp.apiV1AdminBookingsIdLogsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassigns booking vehicle.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIdUnassignVehiclePut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsIdUnassignVehiclePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of open inquiries.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsInquiriesGet(pageNumber: number, pageSize: number, search?: string, options?: any): AxiosPromise<OpenInquiryListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsInquiriesGet(pageNumber, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of booking intents.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIntentsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: any): AxiosPromise<BookingIntentListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsIntentsGet(pageNumber, pageSize, search, assigneeGuids, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsIntentsIdCompletePost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsIntentsIdCompletePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of booking offers.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {Array<BookingOfferStatus>} [statuses] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsOffersGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingOfferStatus>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: any): AxiosPromise<BookingOffersListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsOffersGet(pageNumber, pageSize, search, assigneeGuids, statuses, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [referenceCode] 
         * @param {string} [reservedOnFrom] 
         * @param {string} [reservedOnUntil] 
         * @param {string} [bookingStartFrom] 
         * @param {string} [bookingStartUntil] 
         * @param {string} [bookingEndFrom] 
         * @param {string} [bookingEndUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [operatorSurname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsPayoutsCsvGet(referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsPayoutsCsvGet(referenceCode, reservedOnFrom, reservedOnUntil, bookingStartFrom, bookingStartUntil, bookingEndFrom, bookingEndUntil, stationGuid, operatorSurname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the payouts data for bookings.
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [referenceCode] 
         * @param {string} [reservedOnFrom] 
         * @param {string} [reservedOnUntil] 
         * @param {string} [bookingStartFrom] 
         * @param {string} [bookingStartUntil] 
         * @param {string} [bookingEndFrom] 
         * @param {string} [bookingEndUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [operatorSurname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsPayoutsGet(pageSize?: number, pageNumber?: number, referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: any): AxiosPromise<BookingPayoutsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsPayoutsGet(pageSize, pageNumber, referenceCode, reservedOnFrom, reservedOnUntil, bookingStartFrom, bookingStartUntil, bookingEndFrom, bookingEndUntil, stationGuid, operatorSurname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of unresolved requested bookings.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {Array<BookingRequestStatus>} [statuses] 
         * @param {boolean} [rejectedByOperator] 
         * @param {string} [operatorGuid] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsRequestsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingRequestStatus>, rejectedByOperator?: boolean, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: any): AxiosPromise<BookingRequestListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsRequestsGet(pageNumber, pageSize, search, assigneeGuids, statuses, rejectedByOperator, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsRequestsIdConfirmPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsRequestsIdConfirmPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rejects the booking request.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsRequestsIdRejectPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsRequestsIdRejectPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of reservations.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<BookingReservationStatus>} [statuses] 
         * @param {Array<string>} [assigneeGuids] 
         * @param {string} [operatorGuid] 
         * @param {AlterationState} [alterationState] 
         * @param {string} [createdFrom] 
         * @param {string} [createdUntil] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [stationGuid] 
         * @param {string} [listingGuid] 
         * @param {CountryCode} [country] 
         * @param {string} [agent] 
         * @param {string} [licensePlate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsReservationsGet(pageNumber: number, pageSize: number, search?: string, statuses?: Array<BookingReservationStatus>, assigneeGuids?: Array<string>, operatorGuid?: string, alterationState?: AlterationState, createdFrom?: string, createdUntil?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: any): AxiosPromise<BookingReservationListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBookingsReservationsGet(pageNumber, pageSize, search, statuses, assigneeGuids, operatorGuid, alterationState, createdFrom, createdUntil, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recalculates subtotals for a reservation.
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of reservations with incorrect total price.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBookingsReservationsWithIncorrectTotalGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1AdminBookingsReservationsWithIncorrectTotalGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingAdminApi - interface
 * @export
 * @interface BookingAdminApi
 */
export interface BookingAdminApiInterface {
    /**
     * 
     * @summary Gets a list of cancelled bookings.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsCancelledGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<BookingsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a list of  bookings.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<BookingsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Assigns booking to specific physical vehicle.
     * @param {string} id The booking reference code.
     * @param {string} [vehicleGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the available sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<AvailableBookingAssigneesListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets the sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIdAssigneesGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingAssigneesQueryResultListResult>;

    /**
     * 
     * @summary Immediatelly expires the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIdExpirePost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a booking change logs.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIdLogsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<BookingEventsLogQueryResult>>;

    /**
     * 
     * @summary Unassigns booking vehicle.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIdUnassignVehiclePut(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of open inquiries.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsInquiriesGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<OpenInquiryListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a list of booking intents.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {string} [operatorGuid] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIntentsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): AxiosPromise<BookingIntentListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Confirms the booking request.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsIntentsIdCompletePost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of booking offers.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {Array<BookingOfferStatus>} [statuses] 
     * @param {string} [operatorGuid] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsOffersGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingOfferStatus>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): AxiosPromise<BookingOffersListQueryResultPaginatedResult>;

    /**
     * 
     * @param {string} [referenceCode] 
     * @param {string} [reservedOnFrom] 
     * @param {string} [reservedOnUntil] 
     * @param {string} [bookingStartFrom] 
     * @param {string} [bookingStartUntil] 
     * @param {string} [bookingEndFrom] 
     * @param {string} [bookingEndUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [operatorSurname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsPayoutsCsvGet(referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the payouts data for bookings.
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [referenceCode] 
     * @param {string} [reservedOnFrom] 
     * @param {string} [reservedOnUntil] 
     * @param {string} [bookingStartFrom] 
     * @param {string} [bookingStartUntil] 
     * @param {string} [bookingEndFrom] 
     * @param {string} [bookingEndUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [operatorSurname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsPayoutsGet(pageSize?: number, pageNumber?: number, referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: AxiosRequestConfig): AxiosPromise<BookingPayoutsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a list of unresolved requested bookings.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {Array<BookingRequestStatus>} [statuses] 
     * @param {boolean} [rejectedByOperator] 
     * @param {string} [operatorGuid] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsRequestsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingRequestStatus>, rejectedByOperator?: boolean, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): AxiosPromise<BookingRequestListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Confirms the booking request.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsRequestsIdConfirmPost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Rejects the booking request.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsRequestsIdRejectPost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of reservations.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<BookingReservationStatus>} [statuses] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {string} [operatorGuid] 
     * @param {AlterationState} [alterationState] 
     * @param {string} [createdFrom] 
     * @param {string} [createdUntil] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsReservationsGet(pageNumber: number, pageSize: number, search?: string, statuses?: Array<BookingReservationStatus>, assigneeGuids?: Array<string>, operatorGuid?: string, alterationState?: AlterationState, createdFrom?: string, createdUntil?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig): AxiosPromise<BookingReservationListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Recalculates subtotals for a reservation.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of reservations with incorrect total price.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApiInterface
     */
    apiV1AdminBookingsReservationsWithIncorrectTotalGet(options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

}

/**
 * BookingAdminApi - object-oriented interface
 * @export
 * @class BookingAdminApi
 * @extends {BaseAPI}
 */
export class BookingAdminApi extends BaseAPI implements BookingAdminApiInterface {
    /**
     * 
     * @summary Gets a list of cancelled bookings.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsCancelledGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsCancelledGet(pageNumber, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of  bookings.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsGet(pageNumber, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns booking to specific physical vehicle.
     * @param {string} id The booking reference code.
     * @param {string} [vehicleGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIdAssignVehiclePut(id, vehicleGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the available sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIdAssigneesAvailableGet(id: string, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIdAssigneesAvailableGet(id, pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the sales assignees for the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIdAssigneesGet(id: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIdAssigneesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Immediatelly expires the booking.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIdExpirePost(id: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIdExpirePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a booking change logs.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIdLogsGet(id: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIdLogsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassigns booking vehicle.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIdUnassignVehiclePut(id: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIdUnassignVehiclePut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of open inquiries.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsInquiriesGet(pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsInquiriesGet(pageNumber, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of booking intents.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {string} [operatorGuid] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIntentsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIntentsGet(pageNumber, pageSize, search, assigneeGuids, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms the booking request.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsIntentsIdCompletePost(id: number, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsIntentsIdCompletePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of booking offers.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {Array<BookingOfferStatus>} [statuses] 
     * @param {string} [operatorGuid] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsOffersGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingOfferStatus>, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsOffersGet(pageNumber, pageSize, search, assigneeGuids, statuses, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [referenceCode] 
     * @param {string} [reservedOnFrom] 
     * @param {string} [reservedOnUntil] 
     * @param {string} [bookingStartFrom] 
     * @param {string} [bookingStartUntil] 
     * @param {string} [bookingEndFrom] 
     * @param {string} [bookingEndUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [operatorSurname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsPayoutsCsvGet(referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsPayoutsCsvGet(referenceCode, reservedOnFrom, reservedOnUntil, bookingStartFrom, bookingStartUntil, bookingEndFrom, bookingEndUntil, stationGuid, operatorSurname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the payouts data for bookings.
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [referenceCode] 
     * @param {string} [reservedOnFrom] 
     * @param {string} [reservedOnUntil] 
     * @param {string} [bookingStartFrom] 
     * @param {string} [bookingStartUntil] 
     * @param {string} [bookingEndFrom] 
     * @param {string} [bookingEndUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [operatorSurname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsPayoutsGet(pageSize?: number, pageNumber?: number, referenceCode?: string, reservedOnFrom?: string, reservedOnUntil?: string, bookingStartFrom?: string, bookingStartUntil?: string, bookingEndFrom?: string, bookingEndUntil?: string, stationGuid?: string, operatorSurname?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsPayoutsGet(pageSize, pageNumber, referenceCode, reservedOnFrom, reservedOnUntil, bookingStartFrom, bookingStartUntil, bookingEndFrom, bookingEndUntil, stationGuid, operatorSurname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of unresolved requested bookings.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {Array<BookingRequestStatus>} [statuses] 
     * @param {boolean} [rejectedByOperator] 
     * @param {string} [operatorGuid] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsRequestsGet(pageNumber: number, pageSize: number, search?: string, assigneeGuids?: Array<string>, statuses?: Array<BookingRequestStatus>, rejectedByOperator?: boolean, operatorGuid?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsRequestsGet(pageNumber, pageSize, search, assigneeGuids, statuses, rejectedByOperator, operatorGuid, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms the booking request.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsRequestsIdConfirmPost(id: number, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsRequestsIdConfirmPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rejects the booking request.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsRequestsIdRejectPost(id: number, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsRequestsIdRejectPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of reservations.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<BookingReservationStatus>} [statuses] 
     * @param {Array<string>} [assigneeGuids] 
     * @param {string} [operatorGuid] 
     * @param {AlterationState} [alterationState] 
     * @param {string} [createdFrom] 
     * @param {string} [createdUntil] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [stationGuid] 
     * @param {string} [listingGuid] 
     * @param {CountryCode} [country] 
     * @param {string} [agent] 
     * @param {string} [licensePlate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsReservationsGet(pageNumber: number, pageSize: number, search?: string, statuses?: Array<BookingReservationStatus>, assigneeGuids?: Array<string>, operatorGuid?: string, alterationState?: AlterationState, createdFrom?: string, createdUntil?: string, termStartFrom?: string, termStartUntil?: string, stationGuid?: string, listingGuid?: string, country?: CountryCode, agent?: string, licensePlate?: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsReservationsGet(pageNumber, pageSize, search, statuses, assigneeGuids, operatorGuid, alterationState, createdFrom, createdUntil, termStartFrom, termStartUntil, stationGuid, listingGuid, country, agent, licensePlate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recalculates subtotals for a reservation.
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode: string, options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsReservationsReferenceCodeFixSubtotalsPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of reservations with incorrect total price.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingAdminApi
     */
    public apiV1AdminBookingsReservationsWithIncorrectTotalGet(options?: AxiosRequestConfig) {
        return BookingAdminApiFp(this.configuration).apiV1AdminBookingsReservationsWithIncorrectTotalGet(options).then((request) => request(this.axios, this.basePath));
    }
}

