/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HelpCenterArticleCategoryType } from '../models';
// @ts-ignore
import { HelpCenterArticleProductType } from '../models';
// @ts-ignore
import { HelpCenterArticleQueryResult } from '../models';
// @ts-ignore
import { HelpCenterArticlesListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { MediaImageResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * HelpCenterArticleMarketplaceApi - axios parameter creator
 * @export
 */
export const HelpCenterArticleMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageOffset 
         * @param {number} pageSize 
         * @param {HelpCenterArticleProductType} [productType] 
         * @param {HelpCenterArticleCategoryType} [category] 
         * @param {Array<number>} [ids] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesGet: async (pageOffset: number, pageSize: number, productType?: HelpCenterArticleProductType, category?: HelpCenterArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageOffset' is not null or undefined
            assertParamExists('apiV1MarketplaceHelpCenterArticlesGet', 'pageOffset', pageOffset)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1MarketplaceHelpCenterArticlesGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/marketplace/help-center/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (productType !== undefined) {
                localVarQueryParameter['ProductType'] = productType;
            }

            if (category !== undefined) {
                localVarQueryParameter['Category'] = category;
            }

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (guids) {
                localVarQueryParameter['Guids'] = guids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceHelpCenterArticlesIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/help-center/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesIdPreviewGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceHelpCenterArticlesIdPreviewGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/help-center/articles/{id}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesPicturesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceHelpCenterArticlesPicturesIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/help-center/articles/pictures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpCenterArticleMarketplaceApi - functional programming interface
 * @export
 */
export const HelpCenterArticleMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpCenterArticleMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageOffset 
         * @param {number} pageSize 
         * @param {HelpCenterArticleProductType} [productType] 
         * @param {HelpCenterArticleCategoryType} [category] 
         * @param {Array<number>} [ids] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceHelpCenterArticlesGet(pageOffset: number, pageSize: number, productType?: HelpCenterArticleProductType, category?: HelpCenterArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCenterArticlesListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceHelpCenterArticlesGet(pageOffset, pageSize, productType, category, ids, guids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceHelpCenterArticlesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCenterArticleQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceHelpCenterArticlesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCenterArticleQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpCenterArticleMarketplaceApi - factory interface
 * @export
 */
export const HelpCenterArticleMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpCenterArticleMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageOffset 
         * @param {number} pageSize 
         * @param {HelpCenterArticleProductType} [productType] 
         * @param {HelpCenterArticleCategoryType} [category] 
         * @param {Array<number>} [ids] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesGet(pageOffset: number, pageSize: number, productType?: HelpCenterArticleProductType, category?: HelpCenterArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: any): AxiosPromise<HelpCenterArticlesListQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceHelpCenterArticlesGet(pageOffset, pageSize, productType, category, ids, guids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesIdGet(id: number, options?: any): AxiosPromise<HelpCenterArticleQueryResult> {
            return localVarFp.apiV1MarketplaceHelpCenterArticlesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id: number, options?: any): AxiosPromise<HelpCenterArticleQueryResult> {
            return localVarFp.apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id: number, options?: any): AxiosPromise<MediaImageResponse> {
            return localVarFp.apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpCenterArticleMarketplaceApi - interface
 * @export
 * @interface HelpCenterArticleMarketplaceApi
 */
export interface HelpCenterArticleMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageOffset 
     * @param {number} pageSize 
     * @param {HelpCenterArticleProductType} [productType] 
     * @param {HelpCenterArticleCategoryType} [category] 
     * @param {Array<number>} [ids] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApiInterface
     */
    apiV1MarketplaceHelpCenterArticlesGet(pageOffset: number, pageSize: number, productType?: HelpCenterArticleProductType, category?: HelpCenterArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<HelpCenterArticlesListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApiInterface
     */
    apiV1MarketplaceHelpCenterArticlesIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<HelpCenterArticleQueryResult>;

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApiInterface
     */
    apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id: number, options?: AxiosRequestConfig): AxiosPromise<HelpCenterArticleQueryResult>;

    /**
     * 
     * @summary Gets the media picture by its identifier.
     * @param {number} id Media identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApiInterface
     */
    apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<MediaImageResponse>;

}

/**
 * HelpCenterArticleMarketplaceApi - object-oriented interface
 * @export
 * @class HelpCenterArticleMarketplaceApi
 * @extends {BaseAPI}
 */
export class HelpCenterArticleMarketplaceApi extends BaseAPI implements HelpCenterArticleMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageOffset 
     * @param {number} pageSize 
     * @param {HelpCenterArticleProductType} [productType] 
     * @param {HelpCenterArticleCategoryType} [category] 
     * @param {Array<number>} [ids] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApi
     */
    public apiV1MarketplaceHelpCenterArticlesGet(pageOffset: number, pageSize: number, productType?: HelpCenterArticleProductType, category?: HelpCenterArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: AxiosRequestConfig) {
        return HelpCenterArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceHelpCenterArticlesGet(pageOffset, pageSize, productType, category, ids, guids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApi
     */
    public apiV1MarketplaceHelpCenterArticlesIdGet(id: number, options?: AxiosRequestConfig) {
        return HelpCenterArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceHelpCenterArticlesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApi
     */
    public apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id: number, options?: AxiosRequestConfig) {
        return HelpCenterArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceHelpCenterArticlesIdPreviewGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the media picture by its identifier.
     * @param {number} id Media identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleMarketplaceApi
     */
    public apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id: number, options?: AxiosRequestConfig) {
        return HelpCenterArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceHelpCenterArticlesPicturesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

