/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ReportAdminApi - axios parameter creator
 * @export
 */
export const ReportAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CountryCode>} [countries] 
         * @param {string} [startDateLocal] 
         * @param {string} [endDateLocal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsCsDepositsToReturnCsvGet: async (countries?: Array<CountryCode>, startDateLocal?: string, endDateLocal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/cs/deposits-to-return/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (countries) {
                localVarQueryParameter['Countries'] = countries;
            }

            if (startDateLocal !== undefined) {
                localVarQueryParameter['StartDateLocal'] = (startDateLocal as any instanceof Date) ?
                    (startDateLocal as any).toISOString() :
                    startDateLocal;
            }

            if (endDateLocal !== undefined) {
                localVarQueryParameter['EndDateLocal'] = (endDateLocal as any instanceof Date) ?
                    (endDateLocal as any).toISOString() :
                    endDateLocal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CountryCode>} [countries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsCsInvoicesToIssueCsvGet: async (countries?: Array<CountryCode>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/cs/invoices-to-issue/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (countries) {
                localVarQueryParameter['Countries'] = countries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDateLocal] 
         * @param {string} [endDateLocal] 
         * @param {boolean} [franchiseOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsCsMissingPaymentsCsvGet: async (startDateLocal?: string, endDateLocal?: string, franchiseOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/cs/missing-payments/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDateLocal !== undefined) {
                localVarQueryParameter['StartDateLocal'] = (startDateLocal as any instanceof Date) ?
                    (startDateLocal as any).toISOString() :
                    startDateLocal;
            }

            if (endDateLocal !== undefined) {
                localVarQueryParameter['EndDateLocal'] = (endDateLocal as any instanceof Date) ?
                    (endDateLocal as any).toISOString() :
                    endDateLocal;
            }

            if (franchiseOnly !== undefined) {
                localVarQueryParameter['FranchiseOnly'] = franchiseOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevBookingsCsvGet: async (termStartFrom?: string, termStartUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/bookings/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [evaluatedFrom] 
         * @param {string} [evaluatedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevDamagesCsvGet: async (evaluatedFrom?: string, evaluatedUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/damages/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluatedFrom !== undefined) {
                localVarQueryParameter['EvaluatedFrom'] = (evaluatedFrom as any instanceof Date) ?
                    (evaluatedFrom as any).toISOString() :
                    evaluatedFrom;
            }

            if (evaluatedUntil !== undefined) {
                localVarQueryParameter['EvaluatedUntil'] = (evaluatedUntil as any instanceof Date) ?
                    (evaluatedUntil as any).toISOString() :
                    evaluatedUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevPaymentsCsvGet: async (paymentDateFrom?: string, paymentDateUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/payments/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['PaymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString() :
                    paymentDateFrom;
            }

            if (paymentDateUntil !== undefined) {
                localVarQueryParameter['PaymentDateUntil'] = (paymentDateUntil as any instanceof Date) ?
                    (paymentDateUntil as any).toISOString() :
                    paymentDateUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevPaymentsItalyCsvGet: async (paymentDateFrom?: string, paymentDateUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/payments/italy/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['PaymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString() :
                    paymentDateFrom;
            }

            if (paymentDateUntil !== undefined) {
                localVarQueryParameter['PaymentDateUntil'] = (paymentDateUntil as any instanceof Date) ?
                    (paymentDateUntil as any).toISOString() :
                    paymentDateUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cancelledFrom] 
         * @param {string} [cancelledUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevVouchersIssuedCsvGet: async (cancelledFrom?: string, cancelledUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/vouchers/issued/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cancelledFrom !== undefined) {
                localVarQueryParameter['CancelledFrom'] = (cancelledFrom as any instanceof Date) ?
                    (cancelledFrom as any).toISOString() :
                    cancelledFrom;
            }

            if (cancelledUntil !== undefined) {
                localVarQueryParameter['CancelledUntil'] = (cancelledUntil as any instanceof Date) ?
                    (cancelledUntil as any).toISOString() :
                    cancelledUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [codeAppliedFrom] 
         * @param {string} [codeAppliedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevVouchersRevenueCsvGet: async (termStartFrom?: string, termStartUntil?: string, codeAppliedFrom?: string, codeAppliedUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/vouchers/revenue/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }

            if (codeAppliedFrom !== undefined) {
                localVarQueryParameter['CodeAppliedFrom'] = (codeAppliedFrom as any instanceof Date) ?
                    (codeAppliedFrom as any).toISOString() :
                    codeAppliedFrom;
            }

            if (codeAppliedUntil !== undefined) {
                localVarQueryParameter['CodeAppliedUntil'] = (codeAppliedUntil as any instanceof Date) ?
                    (codeAppliedUntil as any).toISOString() :
                    codeAppliedUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [codeAppliedFrom] 
         * @param {string} [codeAppliedUntil] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevVouchersUsedCsvGet: async (codeAppliedFrom?: string, codeAppliedUntil?: string, termStartFrom?: string, termStartUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/datev/vouchers/used/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeAppliedFrom !== undefined) {
                localVarQueryParameter['CodeAppliedFrom'] = (codeAppliedFrom as any instanceof Date) ?
                    (codeAppliedFrom as any).toISOString() :
                    codeAppliedFrom;
            }

            if (codeAppliedUntil !== undefined) {
                localVarQueryParameter['CodeAppliedUntil'] = (codeAppliedUntil as any instanceof Date) ?
                    (codeAppliedUntil as any).toISOString() :
                    codeAppliedUntil;
            }

            if (termStartFrom !== undefined) {
                localVarQueryParameter['TermStartFrom'] = (termStartFrom as any instanceof Date) ?
                    (termStartFrom as any).toISOString() :
                    termStartFrom;
            }

            if (termStartUntil !== undefined) {
                localVarQueryParameter['TermStartUntil'] = (termStartUntil as any instanceof Date) ?
                    (termStartUntil as any).toISOString() :
                    termStartUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [invoiceIssuedFrom] 
         * @param {string} [invoiceIssuedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsInvoicingInvoicesCsvGet: async (invoiceIssuedFrom?: string, invoiceIssuedUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/invoicing/invoices/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invoiceIssuedFrom !== undefined) {
                localVarQueryParameter['InvoiceIssuedFrom'] = (invoiceIssuedFrom as any instanceof Date) ?
                    (invoiceIssuedFrom as any).toISOString() :
                    invoiceIssuedFrom;
            }

            if (invoiceIssuedUntil !== undefined) {
                localVarQueryParameter['InvoiceIssuedUntil'] = (invoiceIssuedUntil as any instanceof Date) ?
                    (invoiceIssuedUntil as any).toISOString() :
                    invoiceIssuedUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [termEndFrom] 
         * @param {string} [termEndUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsInvoicingNotUploadedToSataCsvGet: async (termEndFrom?: string, termEndUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/invoicing/not-uploaded-to-sata/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (termEndFrom !== undefined) {
                localVarQueryParameter['TermEndFrom'] = (termEndFrom as any instanceof Date) ?
                    (termEndFrom as any).toISOString() :
                    termEndFrom;
            }

            if (termEndUntil !== undefined) {
                localVarQueryParameter['TermEndUntil'] = (termEndUntil as any instanceof Date) ?
                    (termEndUntil as any).toISOString() :
                    termEndUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateStartLocal] 
         * @param {string} [dateEndLocal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOpsFleetCsvGet: async (dateStartLocal?: string, dateEndLocal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/ops/fleet/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateStartLocal !== undefined) {
                localVarQueryParameter['DateStartLocal'] = (dateStartLocal as any instanceof Date) ?
                    (dateStartLocal as any).toISOString() :
                    dateStartLocal;
            }

            if (dateEndLocal !== undefined) {
                localVarQueryParameter['DateEndLocal'] = (dateEndLocal as any instanceof Date) ?
                    (dateEndLocal as any).toISOString() :
                    dateEndLocal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportAdminApi - functional programming interface
 * @export
 */
export const ReportAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<CountryCode>} [countries] 
         * @param {string} [startDateLocal] 
         * @param {string} [endDateLocal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsCsDepositsToReturnCsvGet(countries?: Array<CountryCode>, startDateLocal?: string, endDateLocal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsCsDepositsToReturnCsvGet(countries, startDateLocal, endDateLocal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CountryCode>} [countries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsCsInvoicesToIssueCsvGet(countries?: Array<CountryCode>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsCsInvoicesToIssueCsvGet(countries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDateLocal] 
         * @param {string} [endDateLocal] 
         * @param {boolean} [franchiseOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal?: string, endDateLocal?: string, franchiseOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal, endDateLocal, franchiseOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevBookingsCsvGet(termStartFrom?: string, termStartUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevBookingsCsvGet(termStartFrom, termStartUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [evaluatedFrom] 
         * @param {string} [evaluatedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom?: string, evaluatedUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom, evaluatedUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom, paymentDateUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom, paymentDateUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [cancelledFrom] 
         * @param {string} [cancelledUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom?: string, cancelledUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom, cancelledUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [codeAppliedFrom] 
         * @param {string} [codeAppliedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom?: string, termStartUntil?: string, codeAppliedFrom?: string, codeAppliedUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom, termStartUntil, codeAppliedFrom, codeAppliedUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [codeAppliedFrom] 
         * @param {string} [codeAppliedUntil] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom?: string, codeAppliedUntil?: string, termStartFrom?: string, termStartUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom, codeAppliedUntil, termStartFrom, termStartUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [invoiceIssuedFrom] 
         * @param {string} [invoiceIssuedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom?: string, invoiceIssuedUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom, invoiceIssuedUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [termEndFrom] 
         * @param {string} [termEndUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom?: string, termEndUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom, termEndUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateStartLocal] 
         * @param {string} [dateEndLocal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsOpsFleetCsvGet(dateStartLocal?: string, dateEndLocal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsOpsFleetCsvGet(dateStartLocal, dateEndLocal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportAdminApi - factory interface
 * @export
 */
export const ReportAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<CountryCode>} [countries] 
         * @param {string} [startDateLocal] 
         * @param {string} [endDateLocal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsCsDepositsToReturnCsvGet(countries?: Array<CountryCode>, startDateLocal?: string, endDateLocal?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsCsDepositsToReturnCsvGet(countries, startDateLocal, endDateLocal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CountryCode>} [countries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsCsInvoicesToIssueCsvGet(countries?: Array<CountryCode>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsCsInvoicesToIssueCsvGet(countries, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDateLocal] 
         * @param {string} [endDateLocal] 
         * @param {boolean} [franchiseOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal?: string, endDateLocal?: string, franchiseOnly?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal, endDateLocal, franchiseOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevBookingsCsvGet(termStartFrom?: string, termStartUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevBookingsCsvGet(termStartFrom, termStartUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [evaluatedFrom] 
         * @param {string} [evaluatedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom?: string, evaluatedUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom, evaluatedUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom, paymentDateUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom, paymentDateUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [cancelledFrom] 
         * @param {string} [cancelledUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom?: string, cancelledUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom, cancelledUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {string} [codeAppliedFrom] 
         * @param {string} [codeAppliedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom?: string, termStartUntil?: string, codeAppliedFrom?: string, codeAppliedUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom, termStartUntil, codeAppliedFrom, codeAppliedUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [codeAppliedFrom] 
         * @param {string} [codeAppliedUntil] 
         * @param {string} [termStartFrom] 
         * @param {string} [termStartUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom?: string, codeAppliedUntil?: string, termStartFrom?: string, termStartUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom, codeAppliedUntil, termStartFrom, termStartUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [invoiceIssuedFrom] 
         * @param {string} [invoiceIssuedUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom?: string, invoiceIssuedUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom, invoiceIssuedUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [termEndFrom] 
         * @param {string} [termEndUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom?: string, termEndUntil?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom, termEndUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateStartLocal] 
         * @param {string} [dateEndLocal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOpsFleetCsvGet(dateStartLocal?: string, dateEndLocal?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsOpsFleetCsvGet(dateStartLocal, dateEndLocal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportAdminApi - interface
 * @export
 * @interface ReportAdminApi
 */
export interface ReportAdminApiInterface {
    /**
     * 
     * @param {Array<CountryCode>} [countries] 
     * @param {string} [startDateLocal] 
     * @param {string} [endDateLocal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsCsDepositsToReturnCsvGet(countries?: Array<CountryCode>, startDateLocal?: string, endDateLocal?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<CountryCode>} [countries] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsCsInvoicesToIssueCsvGet(countries?: Array<CountryCode>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [startDateLocal] 
     * @param {string} [endDateLocal] 
     * @param {boolean} [franchiseOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal?: string, endDateLocal?: string, franchiseOnly?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevBookingsCsvGet(termStartFrom?: string, termStartUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [evaluatedFrom] 
     * @param {string} [evaluatedUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom?: string, evaluatedUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [paymentDateFrom] 
     * @param {string} [paymentDateUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [paymentDateFrom] 
     * @param {string} [paymentDateUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [cancelledFrom] 
     * @param {string} [cancelledUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom?: string, cancelledUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [codeAppliedFrom] 
     * @param {string} [codeAppliedUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom?: string, termStartUntil?: string, codeAppliedFrom?: string, codeAppliedUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [codeAppliedFrom] 
     * @param {string} [codeAppliedUntil] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom?: string, codeAppliedUntil?: string, termStartFrom?: string, termStartUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [invoiceIssuedFrom] 
     * @param {string} [invoiceIssuedUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom?: string, invoiceIssuedUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [termEndFrom] 
     * @param {string} [termEndUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom?: string, termEndUntil?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [dateStartLocal] 
     * @param {string} [dateEndLocal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApiInterface
     */
    apiV1AdminReportsOpsFleetCsvGet(dateStartLocal?: string, dateEndLocal?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ReportAdminApi - object-oriented interface
 * @export
 * @class ReportAdminApi
 * @extends {BaseAPI}
 */
export class ReportAdminApi extends BaseAPI implements ReportAdminApiInterface {
    /**
     * 
     * @param {Array<CountryCode>} [countries] 
     * @param {string} [startDateLocal] 
     * @param {string} [endDateLocal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsCsDepositsToReturnCsvGet(countries?: Array<CountryCode>, startDateLocal?: string, endDateLocal?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsCsDepositsToReturnCsvGet(countries, startDateLocal, endDateLocal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CountryCode>} [countries] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsCsInvoicesToIssueCsvGet(countries?: Array<CountryCode>, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsCsInvoicesToIssueCsvGet(countries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDateLocal] 
     * @param {string} [endDateLocal] 
     * @param {boolean} [franchiseOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal?: string, endDateLocal?: string, franchiseOnly?: boolean, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsCsMissingPaymentsCsvGet(startDateLocal, endDateLocal, franchiseOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevBookingsCsvGet(termStartFrom?: string, termStartUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevBookingsCsvGet(termStartFrom, termStartUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [evaluatedFrom] 
     * @param {string} [evaluatedUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom?: string, evaluatedUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevDamagesCsvGet(evaluatedFrom, evaluatedUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [paymentDateFrom] 
     * @param {string} [paymentDateUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevPaymentsCsvGet(paymentDateFrom, paymentDateUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [paymentDateFrom] 
     * @param {string} [paymentDateUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom?: string, paymentDateUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevPaymentsItalyCsvGet(paymentDateFrom, paymentDateUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [cancelledFrom] 
     * @param {string} [cancelledUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom?: string, cancelledUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevVouchersIssuedCsvGet(cancelledFrom, cancelledUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {string} [codeAppliedFrom] 
     * @param {string} [codeAppliedUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom?: string, termStartUntil?: string, codeAppliedFrom?: string, codeAppliedUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevVouchersRevenueCsvGet(termStartFrom, termStartUntil, codeAppliedFrom, codeAppliedUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [codeAppliedFrom] 
     * @param {string} [codeAppliedUntil] 
     * @param {string} [termStartFrom] 
     * @param {string} [termStartUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom?: string, codeAppliedUntil?: string, termStartFrom?: string, termStartUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsDatevVouchersUsedCsvGet(codeAppliedFrom, codeAppliedUntil, termStartFrom, termStartUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [invoiceIssuedFrom] 
     * @param {string} [invoiceIssuedUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom?: string, invoiceIssuedUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsInvoicingInvoicesCsvGet(invoiceIssuedFrom, invoiceIssuedUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [termEndFrom] 
     * @param {string} [termEndUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom?: string, termEndUntil?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsInvoicingNotUploadedToSataCsvGet(termEndFrom, termEndUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateStartLocal] 
     * @param {string} [dateEndLocal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAdminApi
     */
    public apiV1AdminReportsOpsFleetCsvGet(dateStartLocal?: string, dateEndLocal?: string, options?: AxiosRequestConfig) {
        return ReportAdminApiFp(this.configuration).apiV1AdminReportsOpsFleetCsvGet(dateStartLocal, dateEndLocal, options).then((request) => request(this.axios, this.basePath));
    }
}

