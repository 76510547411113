/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CancelChargeCommand } from '../models';
// @ts-ignore
import { ChargeListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * TransactionAdminApi - axios parameter creator
 * @export
 */
export const TransactionAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets list of Kyc requests in requestet state
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTransactionsChargesGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminTransactionsChargesGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminTransactionsChargesGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/transactions/charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancells a charge
         * @param {string} transactionCode 
         * @param {CancelChargeCommand} [cancelChargeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTransactionsChargesTransactionCodeCancelPost: async (transactionCode: string, cancelChargeCommand?: CancelChargeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionCode' is not null or undefined
            assertParamExists('apiV1AdminTransactionsChargesTransactionCodeCancelPost', 'transactionCode', transactionCode)
            const localVarPath = `/api/v1/admin/transactions/charges/{transactionCode}/cancel`
                .replace(`{${"transactionCode"}}`, encodeURIComponent(String(transactionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelChargeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionAdminApi - functional programming interface
 * @export
 */
export const TransactionAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets list of Kyc requests in requestet state
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTransactionsChargesGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargeListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTransactionsChargesGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancells a charge
         * @param {string} transactionCode 
         * @param {CancelChargeCommand} [cancelChargeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode: string, cancelChargeCommand?: CancelChargeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode, cancelChargeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionAdminApi - factory interface
 * @export
 */
export const TransactionAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets list of Kyc requests in requestet state
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTransactionsChargesGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ChargeListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminTransactionsChargesGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancells a charge
         * @param {string} transactionCode 
         * @param {CancelChargeCommand} [cancelChargeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode: string, cancelChargeCommand?: CancelChargeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode, cancelChargeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionAdminApi - interface
 * @export
 * @interface TransactionAdminApi
 */
export interface TransactionAdminApiInterface {
    /**
     * 
     * @summary Gets list of Kyc requests in requestet state
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionAdminApiInterface
     */
    apiV1AdminTransactionsChargesGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ChargeListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Cancells a charge
     * @param {string} transactionCode 
     * @param {CancelChargeCommand} [cancelChargeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionAdminApiInterface
     */
    apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode: string, cancelChargeCommand?: CancelChargeCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TransactionAdminApi - object-oriented interface
 * @export
 * @class TransactionAdminApi
 * @extends {BaseAPI}
 */
export class TransactionAdminApi extends BaseAPI implements TransactionAdminApiInterface {
    /**
     * 
     * @summary Gets list of Kyc requests in requestet state
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionAdminApi
     */
    public apiV1AdminTransactionsChargesGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return TransactionAdminApiFp(this.configuration).apiV1AdminTransactionsChargesGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancells a charge
     * @param {string} transactionCode 
     * @param {CancelChargeCommand} [cancelChargeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionAdminApi
     */
    public apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode: string, cancelChargeCommand?: CancelChargeCommand, options?: AxiosRequestConfig) {
        return TransactionAdminApiFp(this.configuration).apiV1AdminTransactionsChargesTransactionCodeCancelPost(transactionCode, cancelChargeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

