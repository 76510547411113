/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AdditionalChargeType = {
    Other: 'Other',
    Cleaning: 'Cleaning',
    Consumables: 'Consumables',
    MissingAmenities: 'MissingAmenities',
    ExtraKilometers: 'ExtraKilometers',
    Fuel: 'Fuel',
    DamageFee: 'DamageFee',
    Damage: 'Damage'
} as const;

export type AdditionalChargeType = typeof AdditionalChargeType[keyof typeof AdditionalChargeType];



