/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Type defining the current transaction status.
 * @export
 * @enum {string}
 */

export const ChargePaymentStatus = {
    Accepted: 'Accepted',
    PartiallyRefunded: 'PartiallyRefunded',
    Refunded: 'Refunded'
} as const;

export type ChargePaymentStatus = typeof ChargePaymentStatus[keyof typeof ChargePaymentStatus];



