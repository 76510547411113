/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerCreateCommand } from '../models';
// @ts-ignore
import { CustomerQueryResult } from '../models';
// @ts-ignore
import { CustomerQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { IdAndGuidResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UserInfoUpdateCommand } from '../models';
/**
 * CustomersManagerApi - axios parameter creator
 * @export
 */
export const CustomersManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of customers matching search sequence.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1ManagerCustomersGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1ManagerCustomersGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/manager/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a customer by identifier provided.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerCustomersIdGet', 'id', id)
            const localVarPath = `/api/v1/manager/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the customer information.
         * @param {number} id 
         * @param {UserInfoUpdateCommand} [userInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersIdPut: async (id: number, userInfoUpdateCommand?: UserInfoUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ManagerCustomersIdPut', 'id', id)
            const localVarPath = `/api/v1/manager/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInfoUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new customer
         * @param {CustomerCreateCommand} [customerCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersPost: async (customerCreateCommand?: CustomerCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/manager/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersManagerApi - functional programming interface
 * @export
 */
export const CustomersManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of customers matching search sequence.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerCustomersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerCustomersGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a customer by identifier provided.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerCustomersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerCustomersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the customer information.
         * @param {number} id 
         * @param {UserInfoUpdateCommand} [userInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerCustomersIdPut(id: number, userInfoUpdateCommand?: UserInfoUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerCustomersIdPut(id, userInfoUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new customer
         * @param {CustomerCreateCommand} [customerCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ManagerCustomersPost(customerCreateCommand?: CustomerCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdAndGuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ManagerCustomersPost(customerCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersManagerApi - factory interface
 * @export
 */
export const CustomersManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersManagerApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of customers matching search sequence.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<CustomerQueryResultPaginatedResult> {
            return localVarFp.apiV1ManagerCustomersGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a customer by identifier provided.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersIdGet(id: number, options?: any): AxiosPromise<CustomerQueryResult> {
            return localVarFp.apiV1ManagerCustomersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the customer information.
         * @param {number} id 
         * @param {UserInfoUpdateCommand} [userInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersIdPut(id: number, userInfoUpdateCommand?: UserInfoUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ManagerCustomersIdPut(id, userInfoUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new customer
         * @param {CustomerCreateCommand} [customerCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ManagerCustomersPost(customerCreateCommand?: CustomerCreateCommand, options?: any): AxiosPromise<IdAndGuidResult> {
            return localVarFp.apiV1ManagerCustomersPost(customerCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersManagerApi - interface
 * @export
 * @interface CustomersManagerApi
 */
export interface CustomersManagerApiInterface {
    /**
     * 
     * @summary Gets a list of customers matching search sequence.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApiInterface
     */
    apiV1ManagerCustomersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<CustomerQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a customer by identifier provided.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApiInterface
     */
    apiV1ManagerCustomersIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<CustomerQueryResult>;

    /**
     * 
     * @summary Updates the customer information.
     * @param {number} id 
     * @param {UserInfoUpdateCommand} [userInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApiInterface
     */
    apiV1ManagerCustomersIdPut(id: number, userInfoUpdateCommand?: UserInfoUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates new customer
     * @param {CustomerCreateCommand} [customerCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApiInterface
     */
    apiV1ManagerCustomersPost(customerCreateCommand?: CustomerCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdAndGuidResult>;

}

/**
 * CustomersManagerApi - object-oriented interface
 * @export
 * @class CustomersManagerApi
 * @extends {BaseAPI}
 */
export class CustomersManagerApi extends BaseAPI implements CustomersManagerApiInterface {
    /**
     * 
     * @summary Gets a list of customers matching search sequence.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApi
     */
    public apiV1ManagerCustomersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return CustomersManagerApiFp(this.configuration).apiV1ManagerCustomersGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a customer by identifier provided.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApi
     */
    public apiV1ManagerCustomersIdGet(id: number, options?: AxiosRequestConfig) {
        return CustomersManagerApiFp(this.configuration).apiV1ManagerCustomersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the customer information.
     * @param {number} id 
     * @param {UserInfoUpdateCommand} [userInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApi
     */
    public apiV1ManagerCustomersIdPut(id: number, userInfoUpdateCommand?: UserInfoUpdateCommand, options?: AxiosRequestConfig) {
        return CustomersManagerApiFp(this.configuration).apiV1ManagerCustomersIdPut(id, userInfoUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new customer
     * @param {CustomerCreateCommand} [customerCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersManagerApi
     */
    public apiV1ManagerCustomersPost(customerCreateCommand?: CustomerCreateCommand, options?: AxiosRequestConfig) {
        return CustomersManagerApiFp(this.configuration).apiV1ManagerCustomersPost(customerCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

