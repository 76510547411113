/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetOperatorReviewTrackingRecordsQueryResult } from '../models';
// @ts-ignore
import { GetOperatorReviewsResultPaginatedResult } from '../models';
// @ts-ignore
import { GetOperatorReviewsStatisticsQueryResult } from '../models';
// @ts-ignore
import { GetRenterReviewTrackingRecordsQueryResult } from '../models';
// @ts-ignore
import { GetRenterReviewsResultPaginatedResult } from '../models';
// @ts-ignore
import { GetRenterReviewsStatisticsQueryResult } from '../models';
// @ts-ignore
import { LanguageCode } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ReviewStatus } from '../models';
/**
 * ReviewsAdminApi - axios parameter creator
 * @export
 */
export const ReviewsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the operator booking reviews.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {ReviewStatus} [status] 
         * @param {LanguageCode} [language] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsFromOperatorGet: async (pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminReviewsFromOperatorGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminReviewsFromOperatorGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/reviews/from-operator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['Language'] = language;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the renter booking reviews.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {ReviewStatus} [status] 
         * @param {LanguageCode} [language] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsFromRenterGet: async (pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminReviewsFromRenterGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminReviewsFromRenterGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/reviews/from-renter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['Language'] = language;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the operator booking review.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsOperatorDelete: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/operator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['ReferenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the operator review statistics.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsOperatorStatisticsGet: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/operator/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the operator review tracking records.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsOperatorTrackingRecordsGet: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/operator/tracking-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['ReferenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the renter booking review.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRenterDelete: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/renter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['ReferenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the renter review statistics.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRenterStatisticsGet: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/renter/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the renter review tracking records.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRenterTrackingRecordsGet: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/renter/tracking-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['ReferenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedules the leave a review operator emails based on the A/B test group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/reschedule-leave-a-review-operator-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedules the leave a review operator emails based on the A/B test group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reviews/reschedule-leave-a-review-renter-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewsAdminApi - functional programming interface
 * @export
 */
export const ReviewsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the operator booking reviews.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {ReviewStatus} [status] 
         * @param {LanguageCode} [language] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsFromOperatorGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRenterReviewsResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsFromOperatorGet(pageNumber, pageSize, status, language, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the renter booking reviews.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {ReviewStatus} [status] 
         * @param {LanguageCode} [language] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsFromRenterGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorReviewsResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsFromRenterGet(pageNumber, pageSize, status, language, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the operator booking review.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsOperatorDelete(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsOperatorDelete(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the operator review statistics.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsOperatorStatisticsGet(query?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRenterReviewsStatisticsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsOperatorStatisticsGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the operator review tracking records.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorReviewTrackingRecordsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the renter booking review.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsRenterDelete(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsRenterDelete(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the renter review statistics.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsRenterStatisticsGet(query?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorReviewsStatisticsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsRenterStatisticsGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the renter review tracking records.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRenterReviewTrackingRecordsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reschedules the leave a review operator emails based on the A/B test group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reschedules the leave a review operator emails based on the A/B test group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewsAdminApi - factory interface
 * @export
 */
export const ReviewsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewsAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the operator booking reviews.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {ReviewStatus} [status] 
         * @param {LanguageCode} [language] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsFromOperatorGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: any): AxiosPromise<GetRenterReviewsResultPaginatedResult> {
            return localVarFp.apiV1AdminReviewsFromOperatorGet(pageNumber, pageSize, status, language, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the renter booking reviews.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {ReviewStatus} [status] 
         * @param {LanguageCode} [language] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsFromRenterGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: any): AxiosPromise<GetOperatorReviewsResultPaginatedResult> {
            return localVarFp.apiV1AdminReviewsFromRenterGet(pageNumber, pageSize, status, language, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the operator booking review.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsOperatorDelete(referenceCode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReviewsOperatorDelete(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the operator review statistics.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsOperatorStatisticsGet(query?: object, options?: any): AxiosPromise<GetRenterReviewsStatisticsQueryResult> {
            return localVarFp.apiV1AdminReviewsOperatorStatisticsGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the operator review tracking records.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode?: string, options?: any): AxiosPromise<GetOperatorReviewTrackingRecordsQueryResult> {
            return localVarFp.apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the renter booking review.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRenterDelete(referenceCode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReviewsRenterDelete(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the renter review statistics.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRenterStatisticsGet(query?: object, options?: any): AxiosPromise<GetOperatorReviewsStatisticsQueryResult> {
            return localVarFp.apiV1AdminReviewsRenterStatisticsGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the renter review tracking records.
         * @param {string} [referenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode?: string, options?: any): AxiosPromise<GetRenterReviewTrackingRecordsQueryResult> {
            return localVarFp.apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedules the leave a review operator emails based on the A/B test group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedules the leave a review operator emails based on the A/B test group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewsAdminApi - interface
 * @export
 * @interface ReviewsAdminApi
 */
export interface ReviewsAdminApiInterface {
    /**
     * 
     * @summary Gets the operator booking reviews.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {ReviewStatus} [status] 
     * @param {LanguageCode} [language] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsFromOperatorGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: AxiosRequestConfig): AxiosPromise<GetRenterReviewsResultPaginatedResult>;

    /**
     * 
     * @summary Gets the renter booking reviews.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {ReviewStatus} [status] 
     * @param {LanguageCode} [language] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsFromRenterGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: AxiosRequestConfig): AxiosPromise<GetOperatorReviewsResultPaginatedResult>;

    /**
     * 
     * @summary Deletes the operator booking review.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsOperatorDelete(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the operator review statistics.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsOperatorStatisticsGet(query?: object, options?: AxiosRequestConfig): AxiosPromise<GetRenterReviewsStatisticsQueryResult>;

    /**
     * 
     * @summary Gets the operator review tracking records.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<GetOperatorReviewTrackingRecordsQueryResult>;

    /**
     * 
     * @summary Deletes the renter booking review.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsRenterDelete(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the renter review statistics.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsRenterStatisticsGet(query?: object, options?: AxiosRequestConfig): AxiosPromise<GetOperatorReviewsStatisticsQueryResult>;

    /**
     * 
     * @summary Gets the renter review tracking records.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<GetRenterReviewTrackingRecordsQueryResult>;

    /**
     * 
     * @summary Reschedules the leave a review operator emails based on the A/B test group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Reschedules the leave a review operator emails based on the A/B test group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApiInterface
     */
    apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ReviewsAdminApi - object-oriented interface
 * @export
 * @class ReviewsAdminApi
 * @extends {BaseAPI}
 */
export class ReviewsAdminApi extends BaseAPI implements ReviewsAdminApiInterface {
    /**
     * 
     * @summary Gets the operator booking reviews.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {ReviewStatus} [status] 
     * @param {LanguageCode} [language] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsFromOperatorGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsFromOperatorGet(pageNumber, pageSize, status, language, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the renter booking reviews.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {ReviewStatus} [status] 
     * @param {LanguageCode} [language] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsFromRenterGet(pageNumber: number, pageSize: number, status?: ReviewStatus, language?: LanguageCode, search?: string, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsFromRenterGet(pageNumber, pageSize, status, language, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the operator booking review.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsOperatorDelete(referenceCode?: string, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsOperatorDelete(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the operator review statistics.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsOperatorStatisticsGet(query?: object, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsOperatorStatisticsGet(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the operator review tracking records.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode?: string, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsOperatorTrackingRecordsGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the renter booking review.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsRenterDelete(referenceCode?: string, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsRenterDelete(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the renter review statistics.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsRenterStatisticsGet(query?: object, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsRenterStatisticsGet(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the renter review tracking records.
     * @param {string} [referenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode?: string, options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsRenterTrackingRecordsGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedules the leave a review operator emails based on the A/B test group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsRescheduleLeaveAReviewOperatorEmailsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedules the leave a review operator emails based on the A/B test group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsAdminApi
     */
    public apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options?: AxiosRequestConfig) {
        return ReviewsAdminApiFp(this.configuration).apiV1AdminReviewsRescheduleLeaveAReviewRenterEmailsPost(options).then((request) => request(this.axios, this.basePath));
    }
}

