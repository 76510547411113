/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddonCategory } from '../models';
// @ts-ignore
import { AddonLevel } from '../models';
// @ts-ignore
import { AddonsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { AddonsVatRatesQueryResultListResult } from '../models';
// @ts-ignore
import { FwcAddonType } from '../models';
// @ts-ignore
import { GetAddonQueryResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateAddonCommand } from '../models';
/**
 * AddonAdminApi - axios parameter creator
 * @export
 */
export const AddonAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a paginated list of addons.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {FwcAddonType} [typeFilter] 
         * @param {AddonCategory} [categoryFilter] 
         * @param {AddonLevel} [levelFilter] 
         * @param {boolean} [isPublishedFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsGet: async (pageNumber?: number, pageSize?: number, search?: string, typeFilter?: FwcAddonType, categoryFilter?: AddonCategory, levelFilter?: AddonLevel, isPublishedFilter?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/addons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (typeFilter !== undefined) {
                localVarQueryParameter['TypeFilter'] = typeFilter;
            }

            if (categoryFilter !== undefined) {
                localVarQueryParameter['CategoryFilter'] = categoryFilter;
            }

            if (levelFilter !== undefined) {
                localVarQueryParameter['LevelFilter'] = levelFilter;
            }

            if (isPublishedFilter !== undefined) {
                localVarQueryParameter['IsPublishedFilter'] = isPublishedFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a addon by its identifier.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminAddonsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/addons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the dynamic segment\'s main image
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsIdPicturePut: async (id: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminAddonsIdPicturePut', 'id', id)
            const localVarPath = `/api/v1/admin/addons/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the addon\'s info.
         * @param {number} id The addon identifier which is being updated.
         * @param {UpdateAddonCommand} [updateAddonCommand] The update addon command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsIdPut: async (id: number, updateAddonCommand?: UpdateAddonCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminAddonsIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/addons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddonCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsVatGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/addons/vat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddonAdminApi - functional programming interface
 * @export
 */
export const AddonAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddonAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of addons.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {FwcAddonType} [typeFilter] 
         * @param {AddonCategory} [categoryFilter] 
         * @param {AddonLevel} [levelFilter] 
         * @param {boolean} [isPublishedFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAddonsGet(pageNumber?: number, pageSize?: number, search?: string, typeFilter?: FwcAddonType, categoryFilter?: AddonCategory, levelFilter?: AddonLevel, isPublishedFilter?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddonsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAddonsGet(pageNumber, pageSize, search, typeFilter, categoryFilter, levelFilter, isPublishedFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a addon by its identifier.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAddonsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddonQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAddonsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the dynamic segment\'s main image
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAddonsIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAddonsIdPicturePut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the addon\'s info.
         * @param {number} id The addon identifier which is being updated.
         * @param {UpdateAddonCommand} [updateAddonCommand] The update addon command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAddonsIdPut(id: number, updateAddonCommand?: UpdateAddonCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAddonsIdPut(id, updateAddonCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAddonsVatGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddonsVatRatesQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAddonsVatGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddonAdminApi - factory interface
 * @export
 */
export const AddonAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddonAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of addons.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {FwcAddonType} [typeFilter] 
         * @param {AddonCategory} [categoryFilter] 
         * @param {AddonLevel} [levelFilter] 
         * @param {boolean} [isPublishedFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsGet(pageNumber?: number, pageSize?: number, search?: string, typeFilter?: FwcAddonType, categoryFilter?: AddonCategory, levelFilter?: AddonLevel, isPublishedFilter?: boolean, options?: any): AxiosPromise<AddonsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminAddonsGet(pageNumber, pageSize, search, typeFilter, categoryFilter, levelFilter, isPublishedFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a addon by its identifier.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsIdGet(id: number, options?: any): AxiosPromise<GetAddonQueryResult> {
            return localVarFp.apiV1AdminAddonsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the dynamic segment\'s main image
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsIdPicturePut(id: number, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminAddonsIdPicturePut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the addon\'s info.
         * @param {number} id The addon identifier which is being updated.
         * @param {UpdateAddonCommand} [updateAddonCommand] The update addon command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsIdPut(id: number, updateAddonCommand?: UpdateAddonCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminAddonsIdPut(id, updateAddonCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAddonsVatGet(options?: any): AxiosPromise<AddonsVatRatesQueryResultListResult> {
            return localVarFp.apiV1AdminAddonsVatGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddonAdminApi - interface
 * @export
 * @interface AddonAdminApi
 */
export interface AddonAdminApiInterface {
    /**
     * 
     * @summary Gets a paginated list of addons.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {FwcAddonType} [typeFilter] 
     * @param {AddonCategory} [categoryFilter] 
     * @param {AddonLevel} [levelFilter] 
     * @param {boolean} [isPublishedFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApiInterface
     */
    apiV1AdminAddonsGet(pageNumber?: number, pageSize?: number, search?: string, typeFilter?: FwcAddonType, categoryFilter?: AddonCategory, levelFilter?: AddonLevel, isPublishedFilter?: boolean, options?: AxiosRequestConfig): AxiosPromise<AddonsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a addon by its identifier.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApiInterface
     */
    apiV1AdminAddonsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<GetAddonQueryResult>;

    /**
     * 
     * @summary Changes the dynamic segment\'s main image
     * @param {number} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApiInterface
     */
    apiV1AdminAddonsIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the addon\'s info.
     * @param {number} id The addon identifier which is being updated.
     * @param {UpdateAddonCommand} [updateAddonCommand] The update addon command..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApiInterface
     */
    apiV1AdminAddonsIdPut(id: number, updateAddonCommand?: UpdateAddonCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApiInterface
     */
    apiV1AdminAddonsVatGet(options?: AxiosRequestConfig): AxiosPromise<AddonsVatRatesQueryResultListResult>;

}

/**
 * AddonAdminApi - object-oriented interface
 * @export
 * @class AddonAdminApi
 * @extends {BaseAPI}
 */
export class AddonAdminApi extends BaseAPI implements AddonAdminApiInterface {
    /**
     * 
     * @summary Gets a paginated list of addons.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {FwcAddonType} [typeFilter] 
     * @param {AddonCategory} [categoryFilter] 
     * @param {AddonLevel} [levelFilter] 
     * @param {boolean} [isPublishedFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApi
     */
    public apiV1AdminAddonsGet(pageNumber?: number, pageSize?: number, search?: string, typeFilter?: FwcAddonType, categoryFilter?: AddonCategory, levelFilter?: AddonLevel, isPublishedFilter?: boolean, options?: AxiosRequestConfig) {
        return AddonAdminApiFp(this.configuration).apiV1AdminAddonsGet(pageNumber, pageSize, search, typeFilter, categoryFilter, levelFilter, isPublishedFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a addon by its identifier.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApi
     */
    public apiV1AdminAddonsIdGet(id: number, options?: AxiosRequestConfig) {
        return AddonAdminApiFp(this.configuration).apiV1AdminAddonsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the dynamic segment\'s main image
     * @param {number} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApi
     */
    public apiV1AdminAddonsIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig) {
        return AddonAdminApiFp(this.configuration).apiV1AdminAddonsIdPicturePut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the addon\'s info.
     * @param {number} id The addon identifier which is being updated.
     * @param {UpdateAddonCommand} [updateAddonCommand] The update addon command..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApi
     */
    public apiV1AdminAddonsIdPut(id: number, updateAddonCommand?: UpdateAddonCommand, options?: AxiosRequestConfig) {
        return AddonAdminApiFp(this.configuration).apiV1AdminAddonsIdPut(id, updateAddonCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonAdminApi
     */
    public apiV1AdminAddonsVatGet(options?: AxiosRequestConfig) {
        return AddonAdminApiFp(this.configuration).apiV1AdminAddonsVatGet(options).then((request) => request(this.axios, this.basePath));
    }
}

