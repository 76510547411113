/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AlterationState = {
    NotSet: 'NotSet',
    Pending: 'Pending',
    Accepted: 'Accepted',
    Declined: 'Declined',
    Withdrawn: 'Withdrawn',
    Expired: 'Expired',
    Settled: 'Settled'
} as const;

export type AlterationState = typeof AlterationState[keyof typeof AlterationState];



