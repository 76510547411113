/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsBadHttRequestExceptionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/bad-htt-request-exception`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsEcomailUserViewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/ecomail-user-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsEnumsUploadToCloudPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/enums/upload-to-cloud`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsEnvironmentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {string} message The exception message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsExceptionMessageGet: async (message: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message' is not null or undefined
            assertParamExists('apiV1TestsExceptionMessageGet', 'message', message)
            const localVarPath = `/api/v1/tests/exception/{message}`
                .replace(`{${"message"}}`, encodeURIComponent(String(message)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {string} [body] The exception message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsExceptionPost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/exception`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsHangfireEnqueuePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/hangfire/enqueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsHangfireSchedulePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/hangfire/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsImportsFwcVacationsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/imports/fwc-vacations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a random localized mail string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsLocalizationMailerResourcesRandomGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/localization/mailer-resources/random`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a random localized mail string.
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsLocalizationQueryStringGet: async (culture?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/localization/query-string`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (culture !== undefined) {
                localVarQueryParameter['culture'] = culture;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a random localized shared string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsLocalizationSharedResourcesRandomGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/localization/shared-resources/random`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends out mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerAlterationPendingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/mailer/alteration-pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends out mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerCustomerBookingsPaymentcompletedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/mailer/customer-bookings-paymentcompleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [password] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerMockGet: async (password?: string, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/mailer/mock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerSendgridGet: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/mailer/sendgrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsManualDeleteDanglingJobsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/manual/delete-dangling-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendBookingrequestexpirationreminderV2Get: async (userEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/send_bookingrequestexpirationreminder_v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendCampiriReservationreservedGet: async (userEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/send_campiri_reservationreserved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendFlaggedmessagetocampiriGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/send_flaggedmessagetocampiri`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendInviteadmintocampiriGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/send_inviteadmintocampiri`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendLeaveareviewtorenterGet: async (userEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tests/send_leaveareviewtorenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSentryMessageGet: async (message: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message' is not null or undefined
            assertParamExists('apiV1TestsSentryMessageGet', 'message', message)
            const localVarPath = `/api/v1/tests/sentry/{message}`
                .replace(`{${"message"}}`, encodeURIComponent(String(message)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsBadHttRequestExceptionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsBadHttRequestExceptionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsEcomailUserViewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsEcomailUserViewGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsEnumsUploadToCloudPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsEnumsUploadToCloudPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsEnvironmentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsEnvironmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {string} message The exception message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsExceptionMessageGet(message: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsExceptionMessageGet(message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {string} [body] The exception message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsExceptionPost(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsExceptionPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsHangfireEnqueuePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsHangfireEnqueuePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsHangfireSchedulePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsHangfireSchedulePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsImportsFwcVacationsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsImportsFwcVacationsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a random localized mail string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsLocalizationMailerResourcesRandomGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsLocalizationMailerResourcesRandomGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a random localized mail string.
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsLocalizationQueryStringGet(culture?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsLocalizationQueryStringGet(culture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a random localized shared string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsLocalizationSharedResourcesRandomGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsLocalizationSharedResourcesRandomGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends out mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsMailerAlterationPendingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsMailerAlterationPendingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends out mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [password] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsMailerMockGet(password?: string, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsMailerMockGet(password, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsMailerSendgridGet(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsMailerSendgridGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsManualDeleteDanglingJobsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsManualDeleteDanglingJobsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsSendCampiriReservationreservedGet(userEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsSendCampiriReservationreservedGet(userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsSendFlaggedmessagetocampiriGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsSendFlaggedmessagetocampiriGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsSendInviteadmintocampiriGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsSendInviteadmintocampiriGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsSendLeaveareviewtorenterGet(userEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsSendLeaveareviewtorenterGet(userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestsSentryMessageGet(message: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestsSentryMessageGet(message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsBadHttRequestExceptionGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsBadHttRequestExceptionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsEcomailUserViewGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsEcomailUserViewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsEnumsUploadToCloudPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsEnumsUploadToCloudPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsEnvironmentGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsEnvironmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {string} message The exception message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsExceptionMessageGet(message: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsExceptionMessageGet(message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Raises an exception with custom message.
         * @param {string} [body] The exception message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsExceptionPost(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsExceptionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsHangfireEnqueuePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsHangfireEnqueuePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsHangfireSchedulePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsHangfireSchedulePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsImportsFwcVacationsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsImportsFwcVacationsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a random localized mail string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsLocalizationMailerResourcesRandomGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsLocalizationMailerResourcesRandomGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a random localized mail string.
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsLocalizationQueryStringGet(culture?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsLocalizationQueryStringGet(culture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a random localized shared string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsLocalizationSharedResourcesRandomGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsLocalizationSharedResourcesRandomGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends out mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerAlterationPendingGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsMailerAlterationPendingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends out mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [password] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerMockGet(password?: string, email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsMailerMockGet(password, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsMailerSendgridGet(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsMailerSendgridGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsManualDeleteDanglingJobsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsManualDeleteDanglingJobsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendCampiriReservationreservedGet(userEmail?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsSendCampiriReservationreservedGet(userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendFlaggedmessagetocampiriGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsSendFlaggedmessagetocampiriGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendInviteadmintocampiriGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsSendInviteadmintocampiriGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSendLeaveareviewtorenterGet(userEmail?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsSendLeaveareviewtorenterGet(userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestsSentryMessageGet(message: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestsSentryMessageGet(message, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - interface
 * @export
 * @interface TestApi
 */
export interface TestApiInterface {
    /**
     * 
     * @summary Raises an exception with custom message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsBadHttRequestExceptionGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsEcomailUserViewGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsEnumsUploadToCloudPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsEnvironmentGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Raises an exception with custom message.
     * @param {string} message The exception message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsExceptionMessageGet(message: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Raises an exception with custom message.
     * @param {string} [body] The exception message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsExceptionPost(body?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsHangfireEnqueuePost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsHangfireSchedulePost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsImportsFwcVacationsPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a random localized mail string.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsLocalizationMailerResourcesRandomGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a random localized mail string.
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsLocalizationQueryStringGet(culture?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a random localized shared string.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsLocalizationSharedResourcesRandomGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sends out mailer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsMailerAlterationPendingGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sends out mailer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [password] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsMailerMockGet(password?: string, email?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsMailerSendgridGet(email?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsManualDeleteDanglingJobsPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsSendCampiriReservationreservedGet(userEmail?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsSendFlaggedmessagetocampiriGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsSendInviteadmintocampiriGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsSendLeaveareviewtorenterGet(userEmail?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    apiV1TestsSentryMessageGet(message: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI implements TestApiInterface {
    /**
     * 
     * @summary Raises an exception with custom message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsBadHttRequestExceptionGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsBadHttRequestExceptionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsEcomailUserViewGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsEcomailUserViewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsEnumsUploadToCloudPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsEnumsUploadToCloudPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsEnvironmentGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsEnvironmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Raises an exception with custom message.
     * @param {string} message The exception message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsExceptionMessageGet(message: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsExceptionMessageGet(message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Raises an exception with custom message.
     * @param {string} [body] The exception message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsExceptionPost(body?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsExceptionPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsHangfireEnqueuePost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsHangfireEnqueuePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsHangfireSchedulePost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsHangfireSchedulePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsImportsFwcVacationsPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsImportsFwcVacationsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a random localized mail string.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsLocalizationMailerResourcesRandomGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsLocalizationMailerResourcesRandomGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a random localized mail string.
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsLocalizationQueryStringGet(culture?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsLocalizationQueryStringGet(culture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a random localized shared string.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsLocalizationSharedResourcesRandomGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsLocalizationSharedResourcesRandomGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends out mailer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsMailerAlterationPendingGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsMailerAlterationPendingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends out mailer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsMailerCustomerBookingsPaymentcompletedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [password] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsMailerMockGet(password?: string, email?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsMailerMockGet(password, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsMailerSendgridGet(email?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsMailerSendgridGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsManualDeleteDanglingJobsPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsManualDeleteDanglingJobsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsSendBookingrequestexpirationreminderV2Get(userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsSendCampiriReservationreservedGet(userEmail?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsSendCampiriReservationreservedGet(userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsSendFlaggedmessagetocampiriGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsSendFlaggedmessagetocampiriGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsSendInviteadmintocampiriGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsSendInviteadmintocampiriGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsSendLeaveareviewtorenterGet(userEmail?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsSendLeaveareviewtorenterGet(userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestsSentryMessageGet(message: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestsSentryMessageGet(message, options).then((request) => request(this.axios, this.basePath));
    }
}

