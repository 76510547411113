/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Vehicle toilet facility.
 * @export
 * @enum {string}
 */

export const ToiletOption = {
    NotSet: 'NotSet',
    Chemical: 'Chemical',
    Composting: 'Composting',
    Portable: 'Portable',
    NoToilet: 'NoToilet',
    Yes: 'Yes'
} as const;

export type ToiletOption = typeof ToiletOption[keyof typeof ToiletOption];



