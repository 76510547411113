/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptAlterationAsRenterCommand } from '../models';
// @ts-ignore
import { AlterationCalendarDaysQueryResultListResult } from '../models';
// @ts-ignore
import { AlterationState } from '../models';
// @ts-ignore
import { CreateAlterationAsRenterCommand } from '../models';
// @ts-ignore
import { CreateAlterationAsRenterCommandResult } from '../models';
// @ts-ignore
import { DeclineAlterationAsRenterCommand } from '../models';
// @ts-ignore
import { GetBookingAlterationsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { WithdrawAlterationAsRenterCommand } from '../models';
/**
 * AlterationMarketplaceApi - axios parameter creator
 * @export
 */
export const AlterationMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
         * @param {string} id 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet: async (id: string, start?: string, end?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/reservations/{id}/alterations/calendar_days`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdAlterationsGet: async (id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsIdAlterationsGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/reservations/{id}/alterations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsRenterCommand} [createAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdAlterationsPost: async (id: string, createAlterationAsRenterCommand?: CreateAlterationAsRenterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsIdAlterationsPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/reservations/{id}/alterations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAlterationAsRenterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsRenterCommand} [acceptAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut: async (reservationId: string, alterationId: number, acceptAlterationAsRenterCommand?: AcceptAlterationAsRenterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/marketplace/reservations/{reservationId}/alterations/{alterationId}/accept`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptAlterationAsRenterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Declines an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsRenterCommand} [declineAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut: async (reservationId: string, alterationId: number, declineAlterationAsRenterCommand?: DeclineAlterationAsRenterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/marketplace/reservations/{reservationId}/alterations/{alterationId}/decline`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(declineAlterationAsRenterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut: async (reservationId: string, alterationId: number, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/marketplace/reservations/{reservationId}/alterations/{alterationId}/dismiss-result-notification`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Withdraws an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsRenterCommand} [withdrawAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut: async (reservationId: string, alterationId: number, withdrawAlterationAsRenterCommand?: WithdrawAlterationAsRenterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut', 'reservationId', reservationId)
            // verify required parameter 'alterationId' is not null or undefined
            assertParamExists('apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut', 'alterationId', alterationId)
            const localVarPath = `/api/v1/marketplace/reservations/{reservationId}/alterations/{alterationId}/withdraw`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)))
                .replace(`{${"alterationId"}}`, encodeURIComponent(String(alterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawAlterationAsRenterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlterationMarketplaceApi - functional programming interface
 * @export
 */
export const AlterationMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlterationMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
         * @param {string} id 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlterationCalendarDaysQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBookingAlterationsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsRenterCommand} [createAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsIdAlterationsPost(id: string, createAlterationAsRenterCommand?: CreateAlterationAsRenterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAlterationAsRenterCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsIdAlterationsPost(id, createAlterationAsRenterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accepts an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsRenterCommand} [acceptAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsRenterCommand?: AcceptAlterationAsRenterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsRenterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Declines an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsRenterCommand} [declineAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsRenterCommand?: DeclineAlterationAsRenterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsRenterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Withdraws an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsRenterCommand} [withdrawAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsRenterCommand?: WithdrawAlterationAsRenterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsRenterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlterationMarketplaceApi - factory interface
 * @export
 */
export const AlterationMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlterationMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
         * @param {string} id 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: any): AxiosPromise<AlterationCalendarDaysQueryResultListResult> {
            return localVarFp.apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of all related alterations and available actions.
         * @param {string} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {AlterationState} [state] Optional alteration state to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: any): AxiosPromise<GetBookingAlterationsQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new reservation alteration request.
         * @param {string} id 
         * @param {CreateAlterationAsRenterCommand} [createAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsIdAlterationsPost(id: string, createAlterationAsRenterCommand?: CreateAlterationAsRenterCommand, options?: any): AxiosPromise<CreateAlterationAsRenterCommandResult> {
            return localVarFp.apiV1MarketplaceReservationsIdAlterationsPost(id, createAlterationAsRenterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {AcceptAlterationAsRenterCommand} [acceptAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsRenterCommand?: AcceptAlterationAsRenterCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsRenterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Declines an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {DeclineAlterationAsRenterCommand} [declineAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsRenterCommand?: DeclineAlterationAsRenterCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsRenterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks the result notification as seen.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Withdraws an active alteration request.
         * @param {string} reservationId 
         * @param {number} alterationId 
         * @param {WithdrawAlterationAsRenterCommand} [withdrawAlterationAsRenterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsRenterCommand?: WithdrawAlterationAsRenterCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsRenterCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlterationMarketplaceApi - interface
 * @export
 * @interface AlterationMarketplaceApi
 */
export interface AlterationMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
     * @param {string} id 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: AxiosRequestConfig): AxiosPromise<AlterationCalendarDaysQueryResultListResult>;

    /**
     * 
     * @summary Gets a list of all related alterations and available actions.
     * @param {string} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {AlterationState} [state] Optional alteration state to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig): AxiosPromise<GetBookingAlterationsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates a new reservation alteration request.
     * @param {string} id 
     * @param {CreateAlterationAsRenterCommand} [createAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsIdAlterationsPost(id: string, createAlterationAsRenterCommand?: CreateAlterationAsRenterCommand, options?: AxiosRequestConfig): AxiosPromise<CreateAlterationAsRenterCommandResult>;

    /**
     * 
     * @summary Accepts an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {AcceptAlterationAsRenterCommand} [acceptAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsRenterCommand?: AcceptAlterationAsRenterCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Declines an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {DeclineAlterationAsRenterCommand} [declineAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsRenterCommand?: DeclineAlterationAsRenterCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Marks the result notification as seen.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Withdraws an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {WithdrawAlterationAsRenterCommand} [withdrawAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApiInterface
     */
    apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsRenterCommand?: WithdrawAlterationAsRenterCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AlterationMarketplaceApi - object-oriented interface
 * @export
 * @class AlterationMarketplaceApi
 * @extends {BaseAPI}
 */
export class AlterationMarketplaceApi extends BaseAPI implements AlterationMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of calendar days (with pricings and availability) for given reservation and its linked listing.
     * @param {string} id 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id: string, start?: string, end?: string, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsIdAlterationsCalendarDaysGet(id, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of all related alterations and available actions.
     * @param {string} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {AlterationState} [state] Optional alteration state to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsIdAlterationsGet(id: string, pageOffset?: number, pageSize?: number, state?: AlterationState, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsIdAlterationsGet(id, pageOffset, pageSize, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new reservation alteration request.
     * @param {string} id 
     * @param {CreateAlterationAsRenterCommand} [createAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsIdAlterationsPost(id: string, createAlterationAsRenterCommand?: CreateAlterationAsRenterCommand, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsIdAlterationsPost(id, createAlterationAsRenterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {AcceptAlterationAsRenterCommand} [acceptAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId: string, alterationId: number, acceptAlterationAsRenterCommand?: AcceptAlterationAsRenterCommand, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdAcceptPut(reservationId, alterationId, acceptAlterationAsRenterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Declines an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {DeclineAlterationAsRenterCommand} [declineAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId: string, alterationId: number, declineAlterationAsRenterCommand?: DeclineAlterationAsRenterCommand, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDeclinePut(reservationId, alterationId, declineAlterationAsRenterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks the result notification as seen.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId: string, alterationId: number, body?: object, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdDismissResultNotificationPut(reservationId, alterationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Withdraws an active alteration request.
     * @param {string} reservationId 
     * @param {number} alterationId 
     * @param {WithdrawAlterationAsRenterCommand} [withdrawAlterationAsRenterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlterationMarketplaceApi
     */
    public apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId: string, alterationId: number, withdrawAlterationAsRenterCommand?: WithdrawAlterationAsRenterCommand, options?: AxiosRequestConfig) {
        return AlterationMarketplaceApiFp(this.configuration).apiV1MarketplaceReservationsReservationIdAlterationsAlterationIdWithdrawPut(reservationId, alterationId, withdrawAlterationAsRenterCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

