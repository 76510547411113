/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const StationDeliveryToAddressType = {
    Free: 'Free',
    Charged: 'Charged',
    No: 'No'
} as const;

export type StationDeliveryToAddressType = typeof StationDeliveryToAddressType[keyof typeof StationDeliveryToAddressType];



