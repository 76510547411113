/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleCategoryType = {
    ForP2p: 'ForP2p',
    ForCustomers: 'ForCustomers',
    GuidesAndTips: 'GuidesAndTips',
    Travel: 'Travel',
    Sustainability: 'Sustainability',
    Campiri: 'Campiri',
    Camps: 'Camps',
    Spain: 'Spain',
    Portugal: 'Portugal',
    FreewayCamperNews: 'FreewayCamperNews',
    CampingWithDog: 'CampingWithDog',
    CampingWithChildren: 'CampingWithChildren',
    WinterCamping: 'WinterCamping',
    CustomerStories: 'CustomerStories',
    CreativeCamping: 'CreativeCamping'
} as const;

export type ArticleCategoryType = typeof ArticleCategoryType[keyof typeof ArticleCategoryType];



