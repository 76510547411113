/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Checklist item types
 * @export
 * @enum {string}
 */

export const ChecklistItemType = {
    AdBlueFilled: 'AdBlueFilled',
    GasBombFull: 'GasBombFull',
    EmptyWasteWater: 'EmptyWasteWater',
    WaterTankRefilled: 'WaterTankRefilled',
    RegistrationCertificate: 'RegistrationCertificate',
    InsuranceCard: 'InsuranceCard',
    AccidentReport: 'AccidentReport',
    Tires: 'Tires',
    InteriorCleaned: 'InteriorCleaned',
    ExteriorCleaned: 'ExteriorCleaned',
    ExtraOil: 'ExtraOil',
    FirstAidKit: 'FirstAidKit',
    HighVisVests: 'HighVisVests',
    Triangle: 'Triangle',
    FireExtinguisher: 'FireExtinguisher',
    ParkingDisc: 'ParkingDisc',
    PowerCable: 'PowerCable',
    CableDrum: 'CableDrum',
    PowerPlugAdapters: 'PowerPlugAdapters',
    WaterHose: 'WaterHose',
    RampChocks: 'RampChocks',
    SweepSet: 'SweepSet',
    CampingTable: 'CampingTable',
    Chairs: 'Chairs',
    BedroomSheets: 'BedroomSheets',
    KitchenPackage: 'KitchenPackage',
    GreenCard: 'GreenCard'
} as const;

export type ChecklistItemType = typeof ChecklistItemType[keyof typeof ChecklistItemType];



