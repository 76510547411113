/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * FwcAdminApi - axios parameter creator
 * @export
 */
export const FwcAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Import FWC data.
         * @param {boolean} [onlyValidateImages] 
         * @param {boolean} [skipImagesImport] 
         * @param {File} [englishFile] 
         * @param {File} [germanFile] 
         * @param {File} [italianFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFwcImportBlogPost: async (onlyValidateImages?: boolean, skipImagesImport?: boolean, englishFile?: File, germanFile?: File, italianFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/fwc/import/blog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (onlyValidateImages !== undefined) {
                localVarQueryParameter['onlyValidateImages'] = onlyValidateImages;
            }

            if (skipImagesImport !== undefined) {
                localVarQueryParameter['skipImagesImport'] = skipImagesImport;
            }


            if (englishFile !== undefined) { 
                localVarFormParams.append('englishFile', englishFile as any);
            }
    
            if (germanFile !== undefined) { 
                localVarFormParams.append('germanFile', germanFile as any);
            }
    
            if (italianFile !== undefined) { 
                localVarFormParams.append('italianFile', italianFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import FWC data.
         * @param {boolean} [importOperators] 
         * @param {boolean} [importStations] 
         * @param {boolean} [importMakesAndModels] 
         * @param {boolean} [importProducts] 
         * @param {boolean} [importVehicles] 
         * @param {boolean} [importVouchers] 
         * @param {boolean} [importAmountLeftCoupons] 
         * @param {boolean} [importBookings] 
         * @param {boolean} [importAvailability] 
         * @param {boolean} [importPayments] 
         * @param {boolean} [importRefunds] 
         * @param {boolean} [importCico] 
         * @param {boolean} [runValidation] 
         * @param {string} [reservationIdsToImport] 
         * @param {string} [vacationIdsToImport] 
         * @param {File} [fileVehicles] 
         * @param {File} [fileVouchers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFwcImportPost: async (importOperators?: boolean, importStations?: boolean, importMakesAndModels?: boolean, importProducts?: boolean, importVehicles?: boolean, importVouchers?: boolean, importAmountLeftCoupons?: boolean, importBookings?: boolean, importAvailability?: boolean, importPayments?: boolean, importRefunds?: boolean, importCico?: boolean, runValidation?: boolean, reservationIdsToImport?: string, vacationIdsToImport?: string, fileVehicles?: File, fileVouchers?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/fwc/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (importOperators !== undefined) {
                localVarQueryParameter['importOperators'] = importOperators;
            }

            if (importStations !== undefined) {
                localVarQueryParameter['importStations'] = importStations;
            }

            if (importMakesAndModels !== undefined) {
                localVarQueryParameter['importMakesAndModels'] = importMakesAndModels;
            }

            if (importProducts !== undefined) {
                localVarQueryParameter['importProducts'] = importProducts;
            }

            if (importVehicles !== undefined) {
                localVarQueryParameter['importVehicles'] = importVehicles;
            }

            if (importVouchers !== undefined) {
                localVarQueryParameter['importVouchers'] = importVouchers;
            }

            if (importAmountLeftCoupons !== undefined) {
                localVarQueryParameter['importAmountLeftCoupons'] = importAmountLeftCoupons;
            }

            if (importBookings !== undefined) {
                localVarQueryParameter['importBookings'] = importBookings;
            }

            if (importAvailability !== undefined) {
                localVarQueryParameter['importAvailability'] = importAvailability;
            }

            if (importPayments !== undefined) {
                localVarQueryParameter['importPayments'] = importPayments;
            }

            if (importRefunds !== undefined) {
                localVarQueryParameter['importRefunds'] = importRefunds;
            }

            if (importCico !== undefined) {
                localVarQueryParameter['importCico'] = importCico;
            }

            if (runValidation !== undefined) {
                localVarQueryParameter['runValidation'] = runValidation;
            }

            if (reservationIdsToImport !== undefined) {
                localVarQueryParameter['reservationIdsToImport'] = reservationIdsToImport;
            }

            if (vacationIdsToImport !== undefined) {
                localVarQueryParameter['vacationIdsToImport'] = vacationIdsToImport;
            }


            if (fileVehicles !== undefined) { 
                localVarFormParams.append('fileVehicles', fileVehicles as any);
            }
    
            if (fileVouchers !== undefined) { 
                localVarFormParams.append('fileVouchers', fileVouchers as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FwcAdminApi - functional programming interface
 * @export
 */
export const FwcAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FwcAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Import FWC data.
         * @param {boolean} [onlyValidateImages] 
         * @param {boolean} [skipImagesImport] 
         * @param {File} [englishFile] 
         * @param {File} [germanFile] 
         * @param {File} [italianFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminFwcImportBlogPost(onlyValidateImages?: boolean, skipImagesImport?: boolean, englishFile?: File, germanFile?: File, italianFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminFwcImportBlogPost(onlyValidateImages, skipImagesImport, englishFile, germanFile, italianFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import FWC data.
         * @param {boolean} [importOperators] 
         * @param {boolean} [importStations] 
         * @param {boolean} [importMakesAndModels] 
         * @param {boolean} [importProducts] 
         * @param {boolean} [importVehicles] 
         * @param {boolean} [importVouchers] 
         * @param {boolean} [importAmountLeftCoupons] 
         * @param {boolean} [importBookings] 
         * @param {boolean} [importAvailability] 
         * @param {boolean} [importPayments] 
         * @param {boolean} [importRefunds] 
         * @param {boolean} [importCico] 
         * @param {boolean} [runValidation] 
         * @param {string} [reservationIdsToImport] 
         * @param {string} [vacationIdsToImport] 
         * @param {File} [fileVehicles] 
         * @param {File} [fileVouchers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminFwcImportPost(importOperators?: boolean, importStations?: boolean, importMakesAndModels?: boolean, importProducts?: boolean, importVehicles?: boolean, importVouchers?: boolean, importAmountLeftCoupons?: boolean, importBookings?: boolean, importAvailability?: boolean, importPayments?: boolean, importRefunds?: boolean, importCico?: boolean, runValidation?: boolean, reservationIdsToImport?: string, vacationIdsToImport?: string, fileVehicles?: File, fileVouchers?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminFwcImportPost(importOperators, importStations, importMakesAndModels, importProducts, importVehicles, importVouchers, importAmountLeftCoupons, importBookings, importAvailability, importPayments, importRefunds, importCico, runValidation, reservationIdsToImport, vacationIdsToImport, fileVehicles, fileVouchers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FwcAdminApi - factory interface
 * @export
 */
export const FwcAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FwcAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Import FWC data.
         * @param {boolean} [onlyValidateImages] 
         * @param {boolean} [skipImagesImport] 
         * @param {File} [englishFile] 
         * @param {File} [germanFile] 
         * @param {File} [italianFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFwcImportBlogPost(onlyValidateImages?: boolean, skipImagesImport?: boolean, englishFile?: File, germanFile?: File, italianFile?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminFwcImportBlogPost(onlyValidateImages, skipImagesImport, englishFile, germanFile, italianFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import FWC data.
         * @param {boolean} [importOperators] 
         * @param {boolean} [importStations] 
         * @param {boolean} [importMakesAndModels] 
         * @param {boolean} [importProducts] 
         * @param {boolean} [importVehicles] 
         * @param {boolean} [importVouchers] 
         * @param {boolean} [importAmountLeftCoupons] 
         * @param {boolean} [importBookings] 
         * @param {boolean} [importAvailability] 
         * @param {boolean} [importPayments] 
         * @param {boolean} [importRefunds] 
         * @param {boolean} [importCico] 
         * @param {boolean} [runValidation] 
         * @param {string} [reservationIdsToImport] 
         * @param {string} [vacationIdsToImport] 
         * @param {File} [fileVehicles] 
         * @param {File} [fileVouchers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFwcImportPost(importOperators?: boolean, importStations?: boolean, importMakesAndModels?: boolean, importProducts?: boolean, importVehicles?: boolean, importVouchers?: boolean, importAmountLeftCoupons?: boolean, importBookings?: boolean, importAvailability?: boolean, importPayments?: boolean, importRefunds?: boolean, importCico?: boolean, runValidation?: boolean, reservationIdsToImport?: string, vacationIdsToImport?: string, fileVehicles?: File, fileVouchers?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminFwcImportPost(importOperators, importStations, importMakesAndModels, importProducts, importVehicles, importVouchers, importAmountLeftCoupons, importBookings, importAvailability, importPayments, importRefunds, importCico, runValidation, reservationIdsToImport, vacationIdsToImport, fileVehicles, fileVouchers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FwcAdminApi - interface
 * @export
 * @interface FwcAdminApi
 */
export interface FwcAdminApiInterface {
    /**
     * 
     * @summary Import FWC data.
     * @param {boolean} [onlyValidateImages] 
     * @param {boolean} [skipImagesImport] 
     * @param {File} [englishFile] 
     * @param {File} [germanFile] 
     * @param {File} [italianFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FwcAdminApiInterface
     */
    apiV1AdminFwcImportBlogPost(onlyValidateImages?: boolean, skipImagesImport?: boolean, englishFile?: File, germanFile?: File, italianFile?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Import FWC data.
     * @param {boolean} [importOperators] 
     * @param {boolean} [importStations] 
     * @param {boolean} [importMakesAndModels] 
     * @param {boolean} [importProducts] 
     * @param {boolean} [importVehicles] 
     * @param {boolean} [importVouchers] 
     * @param {boolean} [importAmountLeftCoupons] 
     * @param {boolean} [importBookings] 
     * @param {boolean} [importAvailability] 
     * @param {boolean} [importPayments] 
     * @param {boolean} [importRefunds] 
     * @param {boolean} [importCico] 
     * @param {boolean} [runValidation] 
     * @param {string} [reservationIdsToImport] 
     * @param {string} [vacationIdsToImport] 
     * @param {File} [fileVehicles] 
     * @param {File} [fileVouchers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FwcAdminApiInterface
     */
    apiV1AdminFwcImportPost(importOperators?: boolean, importStations?: boolean, importMakesAndModels?: boolean, importProducts?: boolean, importVehicles?: boolean, importVouchers?: boolean, importAmountLeftCoupons?: boolean, importBookings?: boolean, importAvailability?: boolean, importPayments?: boolean, importRefunds?: boolean, importCico?: boolean, runValidation?: boolean, reservationIdsToImport?: string, vacationIdsToImport?: string, fileVehicles?: File, fileVouchers?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * FwcAdminApi - object-oriented interface
 * @export
 * @class FwcAdminApi
 * @extends {BaseAPI}
 */
export class FwcAdminApi extends BaseAPI implements FwcAdminApiInterface {
    /**
     * 
     * @summary Import FWC data.
     * @param {boolean} [onlyValidateImages] 
     * @param {boolean} [skipImagesImport] 
     * @param {File} [englishFile] 
     * @param {File} [germanFile] 
     * @param {File} [italianFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FwcAdminApi
     */
    public apiV1AdminFwcImportBlogPost(onlyValidateImages?: boolean, skipImagesImport?: boolean, englishFile?: File, germanFile?: File, italianFile?: File, options?: AxiosRequestConfig) {
        return FwcAdminApiFp(this.configuration).apiV1AdminFwcImportBlogPost(onlyValidateImages, skipImagesImport, englishFile, germanFile, italianFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import FWC data.
     * @param {boolean} [importOperators] 
     * @param {boolean} [importStations] 
     * @param {boolean} [importMakesAndModels] 
     * @param {boolean} [importProducts] 
     * @param {boolean} [importVehicles] 
     * @param {boolean} [importVouchers] 
     * @param {boolean} [importAmountLeftCoupons] 
     * @param {boolean} [importBookings] 
     * @param {boolean} [importAvailability] 
     * @param {boolean} [importPayments] 
     * @param {boolean} [importRefunds] 
     * @param {boolean} [importCico] 
     * @param {boolean} [runValidation] 
     * @param {string} [reservationIdsToImport] 
     * @param {string} [vacationIdsToImport] 
     * @param {File} [fileVehicles] 
     * @param {File} [fileVouchers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FwcAdminApi
     */
    public apiV1AdminFwcImportPost(importOperators?: boolean, importStations?: boolean, importMakesAndModels?: boolean, importProducts?: boolean, importVehicles?: boolean, importVouchers?: boolean, importAmountLeftCoupons?: boolean, importBookings?: boolean, importAvailability?: boolean, importPayments?: boolean, importRefunds?: boolean, importCico?: boolean, runValidation?: boolean, reservationIdsToImport?: string, vacationIdsToImport?: string, fileVehicles?: File, fileVouchers?: File, options?: AxiosRequestConfig) {
        return FwcAdminApiFp(this.configuration).apiV1AdminFwcImportPost(importOperators, importStations, importMakesAndModels, importProducts, importVehicles, importVouchers, importAmountLeftCoupons, importBookings, importAvailability, importPayments, importRefunds, importCico, runValidation, reservationIdsToImport, vacationIdsToImport, fileVehicles, fileVouchers, options).then((request) => request(this.axios, this.basePath));
    }
}

