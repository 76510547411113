/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminUserByIdQueryResult } from '../models';
// @ts-ignore
import { AdminUserChangePasswordCommand } from '../models';
// @ts-ignore
import { AdminUserChangePasswordCommandResult } from '../models';
// @ts-ignore
import { AdminUserCreateCommand } from '../models';
// @ts-ignore
import { AdminUserUpdateCommand } from '../models';
// @ts-ignore
import { AdminUserUpdateCommandResult } from '../models';
// @ts-ignore
import { CampiriFilterRole } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { MeUserProfileQueryResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UsersListQueryResultPaginatedResult } from '../models';
/**
 * UserAdminApi - axios parameter creator
 * @export
 */
export const UserAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the currently logged in user entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminMeUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/me/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of users.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {Array<CampiriFilterRole>} [roles] 
         * @param {boolean} [emailVerified] 
         * @param {boolean} [operatorApproved] 
         * @param {boolean} [isDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersGet: async (pageSize: number, pageNumber: number, search?: string, roles?: Array<CampiriFilterRole>, emailVerified?: boolean, operatorApproved?: boolean, isDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminUsersGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminUsersGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (roles) {
                localVarQueryParameter['Roles'] = roles;
            }

            if (emailVerified !== undefined) {
                localVarQueryParameter['EmailVerified'] = emailVerified;
            }

            if (operatorApproved !== undefined) {
                localVarQueryParameter['OperatorApproved'] = operatorApproved;
            }

            if (isDeleted !== undefined) {
                localVarQueryParameter['IsDeleted'] = isDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete or restore user
         * @param {string} guid 
         * @param {boolean} [isDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersGuidDeletePut: async (guid: string, isDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminUsersGuidDeletePut', 'guid', guid)
            const localVarPath = `/api/v1/admin/users/{guid}/delete`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleted !== undefined) {
                localVarQueryParameter['isDeleted'] = isDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user\'s info.
         * @param {number} id The user identifier which is being requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grants an operator role to the user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdGrantOperatorPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdGrantOperatorPost', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}/grant-operator`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grants an operator role to the user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdGrantSalesPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdGrantSalesPost', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}/grant-sales`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user\'s password.
         * @param {number} id The user identifier for whom the password is being changed.
         * @param {AdminUserChangePasswordCommand} [adminUserChangePasswordCommand] The change password command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdPasswordPut: async (id: number, adminUserChangePasswordCommand?: AdminUserChangePasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdPasswordPut', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserChangePasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user\'s profile picture and returns the model.
         * @param {number} id The user identifier for whom the picture will be uploaded.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdPicturePut: async (id: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdPicturePut', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user\'s info.
         * @param {number} id The user identifier which is being updated.
         * @param {AdminUserUpdateCommand} [adminUserUpdateCommand] The update user command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdPut: async (id: number, adminUserUpdateCommand?: AdminUserUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets receive bookings notifications property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdReceiveBookingsNotificationsPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdReceiveBookingsNotificationsPut', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}/receive-bookings-notifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets disable receiving IncompleteOnboardingReminder.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut: async (id: number, value?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}/receive-incomplete-onboarding-reminder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new user.
         * @param {AdminUserCreateCommand} [adminUserCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersPost: async (adminUserCreateCommand?: AdminUserCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAdminApi - functional programming interface
 * @export
 */
export const UserAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the currently logged in user entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminMeUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeUserProfileQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminMeUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of users.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {Array<CampiriFilterRole>} [roles] 
         * @param {boolean} [emailVerified] 
         * @param {boolean} [operatorApproved] 
         * @param {boolean} [isDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersGet(pageSize: number, pageNumber: number, search?: string, roles?: Array<CampiriFilterRole>, emailVerified?: boolean, operatorApproved?: boolean, isDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersGet(pageSize, pageNumber, search, roles, emailVerified, operatorApproved, isDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete or restore user
         * @param {string} guid 
         * @param {boolean} [isDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersGuidDeletePut(guid: string, isDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersGuidDeletePut(guid, isDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the user\'s info.
         * @param {number} id The user identifier which is being requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grants an operator role to the user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdGrantOperatorPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdGrantOperatorPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grants an operator role to the user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdGrantSalesPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdGrantSalesPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user\'s password.
         * @param {number} id The user identifier for whom the password is being changed.
         * @param {AdminUserChangePasswordCommand} [adminUserChangePasswordCommand] The change password command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdPasswordPut(id: number, adminUserChangePasswordCommand?: AdminUserChangePasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserChangePasswordCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdPasswordPut(id, adminUserChangePasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user\'s profile picture and returns the model.
         * @param {number} id The user identifier for whom the picture will be uploaded.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdPicturePut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user\'s info.
         * @param {number} id The user identifier which is being updated.
         * @param {AdminUserUpdateCommand} [adminUserUpdateCommand] The update user command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdPut(id: number, adminUserUpdateCommand?: AdminUserUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserUpdateCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdPut(id, adminUserUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets receive bookings notifications property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdReceiveBookingsNotificationsPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdReceiveBookingsNotificationsPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets disable receiving IncompleteOnboardingReminder.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id: number, value?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new user.
         * @param {AdminUserCreateCommand} [adminUserCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersPost(adminUserCreateCommand?: AdminUserCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersPost(adminUserCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAdminApi - factory interface
 * @export
 */
export const UserAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the currently logged in user entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminMeUsersGet(options?: any): AxiosPromise<MeUserProfileQueryResult> {
            return localVarFp.apiV1AdminMeUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of users.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {Array<CampiriFilterRole>} [roles] 
         * @param {boolean} [emailVerified] 
         * @param {boolean} [operatorApproved] 
         * @param {boolean} [isDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersGet(pageSize: number, pageNumber: number, search?: string, roles?: Array<CampiriFilterRole>, emailVerified?: boolean, operatorApproved?: boolean, isDeleted?: boolean, options?: any): AxiosPromise<UsersListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminUsersGet(pageSize, pageNumber, search, roles, emailVerified, operatorApproved, isDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete or restore user
         * @param {string} guid 
         * @param {boolean} [isDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersGuidDeletePut(guid: string, isDeleted?: boolean, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminUsersGuidDeletePut(guid, isDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user\'s info.
         * @param {number} id The user identifier which is being requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdGet(id: number, options?: any): AxiosPromise<AdminUserByIdQueryResult> {
            return localVarFp.apiV1AdminUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grants an operator role to the user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdGrantOperatorPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminUsersIdGrantOperatorPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grants an operator role to the user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdGrantSalesPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminUsersIdGrantSalesPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user\'s password.
         * @param {number} id The user identifier for whom the password is being changed.
         * @param {AdminUserChangePasswordCommand} [adminUserChangePasswordCommand] The change password command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdPasswordPut(id: number, adminUserChangePasswordCommand?: AdminUserChangePasswordCommand, options?: any): AxiosPromise<AdminUserChangePasswordCommandResult> {
            return localVarFp.apiV1AdminUsersIdPasswordPut(id, adminUserChangePasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user\'s profile picture and returns the model.
         * @param {number} id The user identifier for whom the picture will be uploaded.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdPicturePut(id: number, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminUsersIdPicturePut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user\'s info.
         * @param {number} id The user identifier which is being updated.
         * @param {AdminUserUpdateCommand} [adminUserUpdateCommand] The update user command..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdPut(id: number, adminUserUpdateCommand?: AdminUserUpdateCommand, options?: any): AxiosPromise<AdminUserUpdateCommandResult> {
            return localVarFp.apiV1AdminUsersIdPut(id, adminUserUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets receive bookings notifications property.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdReceiveBookingsNotificationsPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminUsersIdReceiveBookingsNotificationsPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets disable receiving IncompleteOnboardingReminder.
         * @param {number} id 
         * @param {boolean} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id: number, value?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new user.
         * @param {AdminUserCreateCommand} [adminUserCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersPost(adminUserCreateCommand?: AdminUserCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminUsersPost(adminUserCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAdminApi - interface
 * @export
 * @interface UserAdminApi
 */
export interface UserAdminApiInterface {
    /**
     * 
     * @summary Returns the currently logged in user entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminMeUsersGet(options?: AxiosRequestConfig): AxiosPromise<MeUserProfileQueryResult>;

    /**
     * 
     * @summary Gets a paginated list of users.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {Array<CampiriFilterRole>} [roles] 
     * @param {boolean} [emailVerified] 
     * @param {boolean} [operatorApproved] 
     * @param {boolean} [isDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersGet(pageSize: number, pageNumber: number, search?: string, roles?: Array<CampiriFilterRole>, emailVerified?: boolean, operatorApproved?: boolean, isDeleted?: boolean, options?: AxiosRequestConfig): AxiosPromise<UsersListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Delete or restore user
     * @param {string} guid 
     * @param {boolean} [isDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersGuidDeletePut(guid: string, isDeleted?: boolean, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Get the user\'s info.
     * @param {number} id The user identifier which is being requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<AdminUserByIdQueryResult>;

    /**
     * 
     * @summary Grants an operator role to the user.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdGrantOperatorPost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Grants an operator role to the user.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdGrantSalesPost(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the user\'s password.
     * @param {number} id The user identifier for whom the password is being changed.
     * @param {AdminUserChangePasswordCommand} [adminUserChangePasswordCommand] The change password command..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdPasswordPut(id: number, adminUserChangePasswordCommand?: AdminUserChangePasswordCommand, options?: AxiosRequestConfig): AxiosPromise<AdminUserChangePasswordCommandResult>;

    /**
     * 
     * @summary Updates the user\'s profile picture and returns the model.
     * @param {number} id The user identifier for whom the picture will be uploaded.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Updates the user\'s info.
     * @param {number} id The user identifier which is being updated.
     * @param {AdminUserUpdateCommand} [adminUserUpdateCommand] The update user command..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdPut(id: number, adminUserUpdateCommand?: AdminUserUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<AdminUserUpdateCommandResult>;

    /**
     * 
     * @summary Sets receive bookings notifications property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdReceiveBookingsNotificationsPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets disable receiving IncompleteOnboardingReminder.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id: number, value?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates new user.
     * @param {AdminUserCreateCommand} [adminUserCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApiInterface
     */
    apiV1AdminUsersPost(adminUserCreateCommand?: AdminUserCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * UserAdminApi - object-oriented interface
 * @export
 * @class UserAdminApi
 * @extends {BaseAPI}
 */
export class UserAdminApi extends BaseAPI implements UserAdminApiInterface {
    /**
     * 
     * @summary Returns the currently logged in user entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminMeUsersGet(options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminMeUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of users.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {Array<CampiriFilterRole>} [roles] 
     * @param {boolean} [emailVerified] 
     * @param {boolean} [operatorApproved] 
     * @param {boolean} [isDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersGet(pageSize: number, pageNumber: number, search?: string, roles?: Array<CampiriFilterRole>, emailVerified?: boolean, operatorApproved?: boolean, isDeleted?: boolean, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersGet(pageSize, pageNumber, search, roles, emailVerified, operatorApproved, isDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete or restore user
     * @param {string} guid 
     * @param {boolean} [isDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersGuidDeletePut(guid: string, isDeleted?: boolean, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersGuidDeletePut(guid, isDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user\'s info.
     * @param {number} id The user identifier which is being requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdGet(id: number, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grants an operator role to the user.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdGrantOperatorPost(id: number, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdGrantOperatorPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grants an operator role to the user.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdGrantSalesPost(id: number, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdGrantSalesPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user\'s password.
     * @param {number} id The user identifier for whom the password is being changed.
     * @param {AdminUserChangePasswordCommand} [adminUserChangePasswordCommand] The change password command..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdPasswordPut(id: number, adminUserChangePasswordCommand?: AdminUserChangePasswordCommand, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdPasswordPut(id, adminUserChangePasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user\'s profile picture and returns the model.
     * @param {number} id The user identifier for whom the picture will be uploaded.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdPicturePut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user\'s info.
     * @param {number} id The user identifier which is being updated.
     * @param {AdminUserUpdateCommand} [adminUserUpdateCommand] The update user command..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdPut(id: number, adminUserUpdateCommand?: AdminUserUpdateCommand, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdPut(id, adminUserUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets receive bookings notifications property.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdReceiveBookingsNotificationsPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdReceiveBookingsNotificationsPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets disable receiving IncompleteOnboardingReminder.
     * @param {number} id 
     * @param {boolean} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id: number, value?: boolean, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersIdReceiveIncompleteOnboardingReminderPut(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new user.
     * @param {AdminUserCreateCommand} [adminUserCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public apiV1AdminUsersPost(adminUserCreateCommand?: AdminUserCreateCommand, options?: AxiosRequestConfig) {
        return UserAdminApiFp(this.configuration).apiV1AdminUsersPost(adminUserCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

