/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ListingListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { PoolCreateCommand } from '../models';
// @ts-ignore
import { PoolUpdateCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { VehiclePoolProfileQueryResult } from '../models';
// @ts-ignore
import { VehiclePoolsListQueryResultPaginatedResult } from '../models';
/**
 * PoolAdminApi - axios parameter creator
 * @export
 */
export const PoolAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the pools.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminPoolsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminPoolsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns pool to product.
         * @param {number} id 
         * @param {string} [productGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdAssignToProductPut: async (id: number, productGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdAssignToProductPut', 'id', id)
            const localVarPath = `/api/v1/admin/pools/{id}/assign-to-product`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productGuid !== undefined) {
                localVarQueryParameter['productGuid'] = productGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdDelete', 'id', id)
            const localVarPath = `/api/v1/admin/pools/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a pool detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/pools/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of listings.
         * @param {number} id The pool identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdListingsGet: async (id: number, pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdListingsGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdListingsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdListingsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/pools/{id}/listings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {number} id The vehicle pool unique identifier.
         * @param {PoolUpdateCommand} [poolUpdateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdPut: async (id: number, poolUpdateCommand?: PoolUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPoolsIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/pools/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsPost: async (poolCreateCommand?: PoolCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolAdminApi - functional programming interface
 * @export
 */
export const PoolAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the pools.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclePoolsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns pool to product.
         * @param {number} id 
         * @param {string} [productGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsIdAssignToProductPut(id: number, productGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsIdAssignToProductPut(id, productGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a pool detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclePoolProfileQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of listings.
         * @param {number} id The pool identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsIdListingsGet(id: number, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsIdListingsGet(id, pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {number} id The vehicle pool unique identifier.
         * @param {PoolUpdateCommand} [poolUpdateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsIdPut(id: number, poolUpdateCommand?: PoolUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsIdPut(id, poolUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPoolsPost(poolCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoolAdminApi - factory interface
 * @export
 */
export const PoolAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the pools.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<VehiclePoolsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminPoolsGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns pool to product.
         * @param {number} id 
         * @param {string} [productGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdAssignToProductPut(id: number, productGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminPoolsIdAssignToProductPut(id, productGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing vehicle model entity based on ID.
         * @param {number} id The vehicle model unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminPoolsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a pool detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdGet(id: number, options?: any): AxiosPromise<VehiclePoolProfileQueryResult> {
            return localVarFp.apiV1AdminPoolsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of listings.
         * @param {number} id The pool identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdListingsGet(id: number, pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ListingListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminPoolsIdListingsGet(id, pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {number} id The vehicle pool unique identifier.
         * @param {PoolUpdateCommand} [poolUpdateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsIdPut(id: number, poolUpdateCommand?: PoolUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminPoolsIdPut(id, poolUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new vehicle pool entity from model provided.
         * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminPoolsPost(poolCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoolAdminApi - interface
 * @export
 * @interface PoolAdminApi
 */
export interface PoolAdminApiInterface {
    /**
     * 
     * @summary Gets the pools.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<VehiclePoolsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Assigns pool to product.
     * @param {number} id 
     * @param {string} [productGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsIdAssignToProductPut(id: number, productGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes an existing vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsIdDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a pool detailed information.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<VehiclePoolProfileQueryResult>;

    /**
     * 
     * @summary Gets a paginated list of listings.
     * @param {number} id The pool identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsIdListingsGet(id: number, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ListingListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates a new vehicle pool entity from model provided.
     * @param {number} id The vehicle pool unique identifier.
     * @param {PoolUpdateCommand} [poolUpdateCommand] The vehicle pool data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsIdPut(id: number, poolUpdateCommand?: PoolUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Creates a new vehicle pool entity from model provided.
     * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApiInterface
     */
    apiV1AdminPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * PoolAdminApi - object-oriented interface
 * @export
 * @class PoolAdminApi
 * @extends {BaseAPI}
 */
export class PoolAdminApi extends BaseAPI implements PoolAdminApiInterface {
    /**
     * 
     * @summary Gets the pools.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns pool to product.
     * @param {number} id 
     * @param {string} [productGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsIdAssignToProductPut(id: number, productGuid?: string, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsIdAssignToProductPut(id, productGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing vehicle model entity based on ID.
     * @param {number} id The vehicle model unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsIdDelete(id: number, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a pool detailed information.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsIdGet(id: number, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of listings.
     * @param {number} id The pool identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsIdListingsGet(id: number, pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsIdListingsGet(id, pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new vehicle pool entity from model provided.
     * @param {number} id The vehicle pool unique identifier.
     * @param {PoolUpdateCommand} [poolUpdateCommand] The vehicle pool data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsIdPut(id: number, poolUpdateCommand?: PoolUpdateCommand, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsIdPut(id, poolUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new vehicle pool entity from model provided.
     * @param {PoolCreateCommand} [poolCreateCommand] The vehicle pool data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolAdminApi
     */
    public apiV1AdminPoolsPost(poolCreateCommand?: PoolCreateCommand, options?: AxiosRequestConfig) {
        return PoolAdminApiFp(this.configuration).apiV1AdminPoolsPost(poolCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

