/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ListingWizardApproveCommandResult } from '../models';
// @ts-ignore
import { ListingWizardState } from '../models';
// @ts-ignore
import { ListingWizardsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ListingWizardAdminApi - axios parameter creator
 * @export
 */
export const ListingWizardAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Downloads the CSV file
         * @param {string} [search] 
         * @param {Array<ListingWizardState>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsCsvGet: async (search?: string, states?: Array<ListingWizardState>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/listings/wizards/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of opened listing wizards.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<ListingWizardState>} [states] 
         * @param {CountryCode} [country] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsGet: async (pageNumber: number, pageSize: number, search?: string, states?: Array<ListingWizardState>, country?: CountryCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminListingsWizardsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminListingsWizardsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/listings/wizards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approves the specific listing wizard.
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsListingWizardIdApprovePut: async (listingWizardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1AdminListingsWizardsListingWizardIdApprovePut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/admin/listings/wizards/{listingWizardId}/approve`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Declines the specific listing wizard
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsListingWizardIdDeclinePut: async (listingWizardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1AdminListingsWizardsListingWizardIdDeclinePut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/admin/listings/wizards/{listingWizardId}/decline`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the specific listing wizard
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsListingWizardIdDelete: async (listingWizardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1AdminListingsWizardsListingWizardIdDelete', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/admin/listings/wizards/{listingWizardId}`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingWizardAdminApi - functional programming interface
 * @export
 */
export const ListingWizardAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingWizardAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Downloads the CSV file
         * @param {string} [search] 
         * @param {Array<ListingWizardState>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsWizardsCsvGet(search?: string, states?: Array<ListingWizardState>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsWizardsCsvGet(search, states, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the list of opened listing wizards.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<ListingWizardState>} [states] 
         * @param {CountryCode} [country] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsWizardsGet(pageNumber: number, pageSize: number, search?: string, states?: Array<ListingWizardState>, country?: CountryCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingWizardsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsWizardsGet(pageNumber, pageSize, search, states, country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approves the specific listing wizard.
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingWizardApproveCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Declines the specific listing wizard
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the specific listing wizard
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingWizardAdminApi - factory interface
 * @export
 */
export const ListingWizardAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingWizardAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Downloads the CSV file
         * @param {string} [search] 
         * @param {Array<ListingWizardState>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsCsvGet(search?: string, states?: Array<ListingWizardState>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminListingsWizardsCsvGet(search, states, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of opened listing wizards.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<ListingWizardState>} [states] 
         * @param {CountryCode} [country] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsGet(pageNumber: number, pageSize: number, search?: string, states?: Array<ListingWizardState>, country?: CountryCode, options?: any): AxiosPromise<ListingWizardsQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminListingsWizardsGet(pageNumber, pageSize, search, states, country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approves the specific listing wizard.
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId: number, options?: any): AxiosPromise<ListingWizardApproveCommandResult> {
            return localVarFp.apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Declines the specific listing wizard
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId: number, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the specific listing wizard
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId: number, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingWizardAdminApi - interface
 * @export
 * @interface ListingWizardAdminApi
 */
export interface ListingWizardAdminApiInterface {
    /**
     * 
     * @summary Downloads the CSV file
     * @param {string} [search] 
     * @param {Array<ListingWizardState>} [states] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApiInterface
     */
    apiV1AdminListingsWizardsCsvGet(search?: string, states?: Array<ListingWizardState>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the list of opened listing wizards.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<ListingWizardState>} [states] 
     * @param {CountryCode} [country] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApiInterface
     */
    apiV1AdminListingsWizardsGet(pageNumber: number, pageSize: number, search?: string, states?: Array<ListingWizardState>, country?: CountryCode, options?: AxiosRequestConfig): AxiosPromise<ListingWizardsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Approves the specific listing wizard.
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApiInterface
     */
    apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId: number, options?: AxiosRequestConfig): AxiosPromise<ListingWizardApproveCommandResult>;

    /**
     * 
     * @summary Declines the specific listing wizard
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApiInterface
     */
    apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId: number, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Removes the specific listing wizard
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApiInterface
     */
    apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId: number, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * ListingWizardAdminApi - object-oriented interface
 * @export
 * @class ListingWizardAdminApi
 * @extends {BaseAPI}
 */
export class ListingWizardAdminApi extends BaseAPI implements ListingWizardAdminApiInterface {
    /**
     * 
     * @summary Downloads the CSV file
     * @param {string} [search] 
     * @param {Array<ListingWizardState>} [states] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApi
     */
    public apiV1AdminListingsWizardsCsvGet(search?: string, states?: Array<ListingWizardState>, options?: AxiosRequestConfig) {
        return ListingWizardAdminApiFp(this.configuration).apiV1AdminListingsWizardsCsvGet(search, states, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of opened listing wizards.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<ListingWizardState>} [states] 
     * @param {CountryCode} [country] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApi
     */
    public apiV1AdminListingsWizardsGet(pageNumber: number, pageSize: number, search?: string, states?: Array<ListingWizardState>, country?: CountryCode, options?: AxiosRequestConfig) {
        return ListingWizardAdminApiFp(this.configuration).apiV1AdminListingsWizardsGet(pageNumber, pageSize, search, states, country, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approves the specific listing wizard.
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApi
     */
    public apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId: number, options?: AxiosRequestConfig) {
        return ListingWizardAdminApiFp(this.configuration).apiV1AdminListingsWizardsListingWizardIdApprovePut(listingWizardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Declines the specific listing wizard
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApi
     */
    public apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId: number, options?: AxiosRequestConfig) {
        return ListingWizardAdminApiFp(this.configuration).apiV1AdminListingsWizardsListingWizardIdDeclinePut(listingWizardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the specific listing wizard
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardAdminApi
     */
    public apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId: number, options?: AxiosRequestConfig) {
        return ListingWizardAdminApiFp(this.configuration).apiV1AdminListingsWizardsListingWizardIdDelete(listingWizardId, options).then((request) => request(this.axios, this.basePath));
    }
}

