/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateProductCommand } from '../models';
// @ts-ignore
import { GetProductByGuidQueryResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ListProductsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ProductPictureEditCommand } from '../models';
// @ts-ignore
import { ProductPicturesSortCommand } from '../models';
// @ts-ignore
import { UpdateProductCommand } from '../models';
/**
 * ProductAdminApi - axios parameter creator
 * @export
 */
export const ProductAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets products.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminProductsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminProductsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the product.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidDelete: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidDelete', 'guid', guid)
            const localVarPath = `/api/v1/admin/products/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the product.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/admin/products/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the product picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesPictureGuidDelete: async (guid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPicturesPictureGuidDelete', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/admin/products/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits the product picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {ProductPictureEditCommand} [productPictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesPictureGuidPut: async (guid: string, pictureGuid: string, productPictureEditCommand?: ProductPictureEditCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPicturesPictureGuidPut', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPicturesPictureGuidPut', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/admin/products/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPictureEditCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the product picture and returns the model.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesPost: async (guid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPicturesPost', 'guid', guid)
            const localVarPath = `/api/v1/admin/products/{guid}/pictures`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorts the product pictures.
         * @param {string} guid 
         * @param {ProductPicturesSortCommand} [productPicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesSortPost: async (guid: string, productPicturesSortCommand?: ProductPicturesSortCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPicturesSortPost', 'guid', guid)
            const localVarPath = `/api/v1/admin/products/{guid}/pictures/sort`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPicturesSortCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the product.
         * @param {string} guid 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPut: async (guid: string, updateProductCommand?: UpdateProductCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1AdminProductsGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/admin/products/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the product.
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsPost: async (createProductCommand?: CreateProductCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAdminApi - functional programming interface
 * @export
 */
export const ProductAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets products.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProductsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the product.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidDelete(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidDelete(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the product.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductByGuidQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the product picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidPicturesPictureGuidDelete(guid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edits the product picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {ProductPictureEditCommand} [productPictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, productPictureEditCommand?: ProductPictureEditCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidPicturesPictureGuidPut(guid, pictureGuid, productPictureEditCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the product picture and returns the model.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidPicturesPost(guid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidPicturesPost(guid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sorts the product pictures.
         * @param {string} guid 
         * @param {ProductPicturesSortCommand} [productPicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidPicturesSortPost(guid: string, productPicturesSortCommand?: ProductPicturesSortCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidPicturesSortPost(guid, productPicturesSortCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the product.
         * @param {string} guid 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsGuidPut(guid: string, updateProductCommand?: UpdateProductCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsGuidPut(guid, updateProductCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the product.
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminProductsPost(createProductCommand?: CreateProductCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminProductsPost(createProductCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductAdminApi - factory interface
 * @export
 */
export const ProductAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets products.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ListProductsQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminProductsGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the product.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidDelete(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminProductsGuidDelete(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the product.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidGet(guid: string, options?: any): AxiosPromise<GetProductByGuidQueryResult> {
            return localVarFp.apiV1AdminProductsGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the product picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminProductsGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits the product picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {ProductPictureEditCommand} [productPictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, productPictureEditCommand?: ProductPictureEditCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminProductsGuidPicturesPictureGuidPut(guid, pictureGuid, productPictureEditCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the product picture and returns the model.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesPost(guid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1AdminProductsGuidPicturesPost(guid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorts the product pictures.
         * @param {string} guid 
         * @param {ProductPicturesSortCommand} [productPicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPicturesSortPost(guid: string, productPicturesSortCommand?: ProductPicturesSortCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminProductsGuidPicturesSortPost(guid, productPicturesSortCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the product.
         * @param {string} guid 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsGuidPut(guid: string, updateProductCommand?: UpdateProductCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminProductsGuidPut(guid, updateProductCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the product.
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminProductsPost(createProductCommand?: CreateProductCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminProductsPost(createProductCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductAdminApi - interface
 * @export
 * @interface ProductAdminApi
 */
export interface ProductAdminApiInterface {
    /**
     * 
     * @summary Gets products.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ListProductsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Deletes the product.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidDelete(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the product.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<GetProductByGuidQueryResult>;

    /**
     * 
     * @summary Removes the product picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Edits the product picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {ProductPictureEditCommand} [productPictureEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, productPictureEditCommand?: ProductPictureEditCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the product picture and returns the model.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidPicturesPost(guid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Sorts the product pictures.
     * @param {string} guid 
     * @param {ProductPicturesSortCommand} [productPicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidPicturesSortPost(guid: string, productPicturesSortCommand?: ProductPicturesSortCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the product.
     * @param {string} guid 
     * @param {UpdateProductCommand} [updateProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsGuidPut(guid: string, updateProductCommand?: UpdateProductCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates the product.
     * @param {CreateProductCommand} [createProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApiInterface
     */
    apiV1AdminProductsPost(createProductCommand?: CreateProductCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

}

/**
 * ProductAdminApi - object-oriented interface
 * @export
 * @class ProductAdminApi
 * @extends {BaseAPI}
 */
export class ProductAdminApi extends BaseAPI implements ProductAdminApiInterface {
    /**
     * 
     * @summary Gets products.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the product.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidDelete(guid: string, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidDelete(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the product.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidGet(guid: string, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the product picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits the product picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {ProductPictureEditCommand} [productPictureEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, productPictureEditCommand?: ProductPictureEditCommand, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidPicturesPictureGuidPut(guid, pictureGuid, productPictureEditCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the product picture and returns the model.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidPicturesPost(guid: string, file?: File, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidPicturesPost(guid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorts the product pictures.
     * @param {string} guid 
     * @param {ProductPicturesSortCommand} [productPicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidPicturesSortPost(guid: string, productPicturesSortCommand?: ProductPicturesSortCommand, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidPicturesSortPost(guid, productPicturesSortCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the product.
     * @param {string} guid 
     * @param {UpdateProductCommand} [updateProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsGuidPut(guid: string, updateProductCommand?: UpdateProductCommand, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsGuidPut(guid, updateProductCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the product.
     * @param {CreateProductCommand} [createProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminApi
     */
    public apiV1AdminProductsPost(createProductCommand?: CreateProductCommand, options?: AxiosRequestConfig) {
        return ProductAdminApiFp(this.configuration).apiV1AdminProductsPost(createProductCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

