/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ManufacturerListResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ManufacturerMarketplaceApi - axios parameter creator
 * @export
 */
export const ManufacturerMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceManufacturersGet: async (pageSize: number, pageNumber: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1MarketplaceManufacturersGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1MarketplaceManufacturersGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/marketplace/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerMarketplaceApi - functional programming interface
 * @export
 */
export const ManufacturerMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceManufacturersGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerMarketplaceApi - factory interface
 * @export
 */
export const ManufacturerMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of vehicle manufacturers.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: any): AxiosPromise<ManufacturerListResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceManufacturersGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerMarketplaceApi - interface
 * @export
 * @interface ManufacturerMarketplaceApi
 */
export interface ManufacturerMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerMarketplaceApiInterface
     */
    apiV1MarketplaceManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<ManufacturerListResultPaginatedResult>;

}

/**
 * ManufacturerMarketplaceApi - object-oriented interface
 * @export
 * @class ManufacturerMarketplaceApi
 * @extends {BaseAPI}
 */
export class ManufacturerMarketplaceApi extends BaseAPI implements ManufacturerMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of vehicle manufacturers.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerMarketplaceApi
     */
    public apiV1MarketplaceManufacturersGet(pageSize: number, pageNumber: number, search?: string, options?: AxiosRequestConfig) {
        return ManufacturerMarketplaceApiFp(this.configuration).apiV1MarketplaceManufacturersGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }
}

