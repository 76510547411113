/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const MailerType = {
    Campiri_Bookings_OpenInquiryCreated: 'Campiri_Bookings_OpenInquiryCreated',
    Customer_Bookings_OpenInquiryCreated: 'Customer_Bookings_OpenInquiryCreated',
    Campiri_Bookings_BookingCreated: 'Campiri_Bookings_BookingCreated',
    Customer_Bookings_BookingCreated: 'Customer_Bookings_BookingCreated',
    Campiri_Bookings_BookingCreatedNewUser: 'Campiri_Bookings_BookingCreatedNewUser',
    Customer_Bookings_BookingCreatedNewUser: 'Customer_Bookings_BookingCreatedNewUser',
    Campiri_Bookings_BookingCreatedInstantBookable: 'Campiri_Bookings_BookingCreatedInstantBookable',
    Customer_Bookings_BookingCreatedInstantBookable: 'Customer_Bookings_BookingCreatedInstantBookable',
    Campiri_Bookings_BookingCreatedInstantBookableNewUser: 'Campiri_Bookings_BookingCreatedInstantBookableNewUser',
    Customer_Bookings_BookingCreatedInstantBookableNewUser: 'Customer_Bookings_BookingCreatedInstantBookableNewUser',
    Campiri_Bookings_BookingRequestCreated: 'Campiri_Bookings_BookingRequestCreated',
    Customer_Bookings_BookingRequestCreated: 'Customer_Bookings_BookingRequestCreated',
    Customer_Bookings_BookingApproved: 'Customer_Bookings_BookingApproved',
    Customer_Bookings_BookingCanceled: 'Customer_Bookings_BookingCanceled',
    Customer_Bookings_BookingRejected: 'Customer_Bookings_BookingRejected',
    Customer_Bookings_BookingRejectedTermUnavailable: 'Customer_Bookings_BookingRejectedTermUnavailable',
    Campiri_Bookings_BookingWithdrawn: 'Campiri_Bookings_BookingWithdrawn',
    Customer_Bookings_BookingWithdrawn: 'Customer_Bookings_BookingWithdrawn',
    Customer_Bookings_BookingCounterOfferCreated: 'Customer_Bookings_BookingCounterOfferCreated',
    Customer_Bookings_BookingCounterOfferCreatedNewUser: 'Customer_Bookings_BookingCounterOfferCreatedNewUser',
    Campiri_Bookings_BookingOfferDeniedByUser: 'Campiri_Bookings_BookingOfferDeniedByUser',
    Customer_Bookings_BookingReserved: 'Customer_Bookings_BookingReserved',
    Campiri_Bookings_BookingRequestForPayment: 'Campiri_Bookings_BookingRequestForPayment',
    Customer_Bookings_PaymentCompleted: 'Customer_Bookings_PaymentCompleted',
    Customer_Bookings_PayRestReminder: 'Customer_Bookings_PayRestReminder',
    Customer_Bookings_BookingRequestExpiresSoon: 'Customer_Bookings_BookingRequestExpiresSoon',
    Customer_Bookings_BookingOfferExpiresSoon: 'Customer_Bookings_BookingOfferExpiresSoon',
    Campiri_Bookings_PayRestReminder: 'Campiri_Bookings_PayRestReminder',
    Operator_Bookings_RequestStatusChanged: 'Operator_Bookings_RequestStatusChanged',
    Campiri_Bookings_RequestStatusChanged: 'Campiri_Bookings_RequestStatusChanged',
    Campiri_Bookings_NoOperatorResponse: 'Campiri_Bookings_NoOperatorResponse',
    Campiri_Bookings_ReservationReserved: 'Campiri_Bookings_ReservationReserved',
    Campiri_Bookings_ReservationCancelled: 'Campiri_Bookings_ReservationCancelled',
    Operator_Bookings_ReservationReserved: 'Operator_Bookings_ReservationReserved',
    Operator_Bookings_ReservationCancelled: 'Operator_Bookings_ReservationCancelled',
    Customer_Bookings_BookingOfferCreated: 'Customer_Bookings_BookingOfferCreated',
    Customer_Bookings_BookingOfferCreatedNewUser: 'Customer_Bookings_BookingOfferCreatedNewUser',
    Operator_Bookings_PaymentCompleted: 'Operator_Bookings_PaymentCompleted',
    Campiri_Bookings_BookingCancellationProtectionCompleted: 'Campiri_Bookings_BookingCancellationProtectionCompleted',
    Operator_Bookings_OfferStatusChanged: 'Operator_Bookings_OfferStatusChanged',
    Campiri_Bookings_OfferStatusChanged: 'Campiri_Bookings_OfferStatusChanged',
    Campiri_Bookings_ReservationMissingUserInsuranceData: 'Campiri_Bookings_ReservationMissingUserInsuranceData',
    Campiri_Bookings_BookingCancellationProtectionCouldNotBeCreated: 'Campiri_Bookings_BookingCancellationProtectionCouldNotBeCreated',
    Campiri_Bookings_BookingSepaPaymentFailed: 'Campiri_Bookings_BookingSepaPaymentFailed',
    Customer_Bookings_BookingFwcAddonsUpdated: 'Customer_Bookings_BookingFwcAddonsUpdated',
    Customer_KYC_UploadDocumentsReminder: 'Customer_KYC_UploadDocumentsReminder',
    Customer_KYC_DocumentsUploaded: 'Customer_KYC_DocumentsUploaded',
    Customer_KYC_DocumentsApproved: 'Customer_KYC_DocumentsApproved',
    Customer_KYC_DocumentsDenied: 'Customer_KYC_DocumentsDenied',
    Campiri_KYC_RequestForReview: 'Campiri_KYC_RequestForReview',
    User_User_ForgotPassword: 'User_User_ForgotPassword',
    User_User_EmailChanged: 'User_User_EmailChanged',
    User_User_EmailVerification: 'User_User_EmailVerification',
    Campiri_Insurance_InsuranceCreated: 'Campiri_Insurance_InsuranceCreated',
    Operator_CICO_CheckInApproaching: 'Operator_CICO_CheckInApproaching',
    Customer_CICO_CheckInCompleted: 'Customer_CICO_CheckInCompleted',
    Operator_CICO_CheckOutApproaching: 'Operator_CICO_CheckOutApproaching',
    Operator_CICO_CheckOutCompleted: 'Operator_CICO_CheckOutCompleted',
    Customer_CICO_CheckInApproaching: 'Customer_CICO_CheckInApproaching',
    Campiri_CampersClub_Contacted: 'Campiri_CampersClub_Contacted',
    Customer_CampersClub_Contacted: 'Customer_CampersClub_Contacted',
    Campiri_P2P_Contacted: 'Campiri_P2P_Contacted',
    Customer_P2P_Contacted: 'Customer_P2P_Contacted',
    Campiri_ListingWizardOperator_Created: 'Campiri_ListingWizardOperator_Created',
    Customer_ListingWizard_Created: 'Customer_ListingWizard_Created',
    Campiri_ListingWizard_Submitted: 'Campiri_ListingWizard_Submitted',
    Customer_ListingWizard_Submitted: 'Customer_ListingWizard_Submitted',
    Customer_ListingWizard_Inactive: 'Customer_ListingWizard_Inactive',
    Customer_ListingWizard_Approved: 'Customer_ListingWizard_Approved',
    Customer_ListingWizard_Declined: 'Customer_ListingWizard_Declined',
    Customer_ConversationMessage_Created: 'Customer_ConversationMessage_Created',
    Campiri_Operator_Inactive: 'Campiri_Operator_Inactive',
    Campiri_FlaggedMessage_Created: 'Campiri_FlaggedMessage_Created',
    Campiri_Messaging_InviteAdmin: 'Campiri_Messaging_InviteAdmin',
    Customer_Tuito_Pending: 'Customer_Tuito_Pending',
    Customer_Reviews_LeaveReview: 'Customer_Reviews_LeaveReview',
    Operator_Reviews_LeaveReview: 'Operator_Reviews_LeaveReview',
    Operator_AlterationPending: 'Operator_AlterationPending',
    Operator_AlterationAccepted: 'Operator_AlterationAccepted',
    Operator_AlterationDeclined: 'Operator_AlterationDeclined',
    Operator_AlterationWithdrawn: 'Operator_AlterationWithdrawn',
    Operator_AlterationExpired: 'Operator_AlterationExpired',
    Customer_AlterationPending: 'Customer_AlterationPending',
    Customer_AlterationAccepted: 'Customer_AlterationAccepted',
    Customer_AlterationDeclined: 'Customer_AlterationDeclined',
    Customer_AlterationWithdrawn: 'Customer_AlterationWithdrawn',
    Customer_AlterationExpired: 'Customer_AlterationExpired',
    Campiri_AlterationCreated: 'Campiri_AlterationCreated',
    Campiri_AlterationAccepted: 'Campiri_AlterationAccepted',
    Campiri_Listings_RequestInstantBookable: 'Campiri_Listings_RequestInstantBookable',
    Operator_Payouts_MissingOnboardingReminder: 'Operator_Payouts_MissingOnboardingReminder',
    Operator_Payouts_IncompleteOnboardingReminder: 'Operator_Payouts_IncompleteOnboardingReminder',
    Campiri_Stripe_StripeLowBalance: 'Campiri_Stripe_StripeLowBalance',
    Campiri_PriceEditorImportDone: 'Campiri_PriceEditorImportDone'
} as const;

export type MailerType = typeof MailerType[keyof typeof MailerType];



