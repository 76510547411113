/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethodType = {
    NotSet: 'NotSet',
    CreditCard: 'CreditCard',
    Paypal: 'Paypal',
    Unzer: 'Unzer',
    BankTransferCommerzbank: 'BankTransferCommerzbank',
    BankTransferBPM: 'BankTransferBPM',
    Agent: 'Agent',
    Cash: 'Cash',
    Stripe: 'Stripe',
    BankTransferCommerzbankDeposits: 'BankTransferCommerzbankDeposits',
    Other: 'Other'
} as const;

export type PaymentMethodType = typeof PaymentMethodType[keyof typeof PaymentMethodType];



