/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AddonCategory = {
    NotSet: 'NotSet',
    Bestseller: 'Bestseller',
    Family: 'Family',
    Winter: 'Winter',
    Bike: 'Bike',
    ExtraMore: 'ExtraMore',
    Insurance: 'Insurance',
    Cleaning: 'Cleaning',
    Transfer: 'Transfer',
    ServiceMore: 'ServiceMore',
    KilometersPackages: 'KilometersPackages'
} as const;

export type AddonCategory = typeof AddonCategory[keyof typeof AddonCategory];



