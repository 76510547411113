/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FleetOperatorStationDetailQueryResult } from '../models';
// @ts-ignore
import { FleetOperatorStationStatus } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ListFleetOperatorStationsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { OperatorCreateFleetOperatorStationCommand } from '../models';
// @ts-ignore
import { OperatorUpdateFleetOperatorStationCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * OperatorOperatorApi - axios parameter creator
 * @export
 */
export const OperatorOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsGet: async (pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1OperatorOperatorsStationsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1OperatorOperatorsStationsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/operator/operators/stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new fleet operator station
         * @param {OperatorCreateFleetOperatorStationCommand} [operatorCreateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsPost: async (operatorCreateFleetOperatorStationCommand?: OperatorCreateFleetOperatorStationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/operators/stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorCreateFleetOperatorStationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get station detail
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsStationGuidGet: async (stationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationGuid' is not null or undefined
            assertParamExists('apiV1OperatorOperatorsStationsStationGuidGet', 'stationGuid', stationGuid)
            const localVarPath = `/api/v1/operator/operators/stations/{stationGuid}`
                .replace(`{${"stationGuid"}}`, encodeURIComponent(String(stationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates fleet operator station
         * @param {string} stationGuid The area guid
         * @param {OperatorUpdateFleetOperatorStationCommand} [operatorUpdateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsStationGuidPut: async (stationGuid: string, operatorUpdateFleetOperatorStationCommand?: OperatorUpdateFleetOperatorStationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationGuid' is not null or undefined
            assertParamExists('apiV1OperatorOperatorsStationsStationGuidPut', 'stationGuid', stationGuid)
            const localVarPath = `/api/v1/operator/operators/stations/{stationGuid}`
                .replace(`{${"stationGuid"}}`, encodeURIComponent(String(stationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorUpdateFleetOperatorStationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends the station for review
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsStationGuidSendForReviewPost: async (stationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationGuid' is not null or undefined
            assertParamExists('apiV1OperatorOperatorsStationsStationGuidSendForReviewPost', 'stationGuid', stationGuid)
            const localVarPath = `/api/v1/operator/operators/stations/{stationGuid}/send-for-review`
                .replace(`{${"stationGuid"}}`, encodeURIComponent(String(stationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorOperatorApi - functional programming interface
 * @export
 */
export const OperatorOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatorOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOperatorsStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOperatorsStationsGet(pageSize, pageNumber, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new fleet operator station
         * @param {OperatorCreateFleetOperatorStationCommand} [operatorCreateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand?: OperatorCreateFleetOperatorStationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get station detail
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOperatorsStationsStationGuidGet(stationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FleetOperatorStationDetailQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOperatorsStationsStationGuidGet(stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates fleet operator station
         * @param {string} stationGuid The area guid
         * @param {OperatorUpdateFleetOperatorStationCommand} [operatorUpdateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOperatorsStationsStationGuidPut(stationGuid: string, operatorUpdateFleetOperatorStationCommand?: OperatorUpdateFleetOperatorStationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOperatorsStationsStationGuidPut(stationGuid, operatorUpdateFleetOperatorStationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends the station for review
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatorOperatorApi - factory interface
 * @export
 */
export const OperatorOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatorOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: any): AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorOperatorsStationsGet(pageSize, pageNumber, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new fleet operator station
         * @param {OperatorCreateFleetOperatorStationCommand} [operatorCreateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand?: OperatorCreateFleetOperatorStationCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get station detail
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsStationGuidGet(stationGuid: string, options?: any): AxiosPromise<FleetOperatorStationDetailQueryResult> {
            return localVarFp.apiV1OperatorOperatorsStationsStationGuidGet(stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates fleet operator station
         * @param {string} stationGuid The area guid
         * @param {OperatorUpdateFleetOperatorStationCommand} [operatorUpdateFleetOperatorStationCommand] Station data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsStationGuidPut(stationGuid: string, operatorUpdateFleetOperatorStationCommand?: OperatorUpdateFleetOperatorStationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorOperatorsStationsStationGuidPut(stationGuid, operatorUpdateFleetOperatorStationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends the station for review
         * @param {string} stationGuid Station identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatorOperatorApi - interface
 * @export
 * @interface OperatorOperatorApi
 */
export interface OperatorOperatorApiInterface {
    /**
     * 
     * @summary Get stations of fleet operator
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {FleetOperatorStationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApiInterface
     */
    apiV1OperatorOperatorsStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig): AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Creates new fleet operator station
     * @param {OperatorCreateFleetOperatorStationCommand} [operatorCreateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApiInterface
     */
    apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand?: OperatorCreateFleetOperatorStationCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Get station detail
     * @param {string} stationGuid Station identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApiInterface
     */
    apiV1OperatorOperatorsStationsStationGuidGet(stationGuid: string, options?: AxiosRequestConfig): AxiosPromise<FleetOperatorStationDetailQueryResult>;

    /**
     * 
     * @summary Updates fleet operator station
     * @param {string} stationGuid The area guid
     * @param {OperatorUpdateFleetOperatorStationCommand} [operatorUpdateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApiInterface
     */
    apiV1OperatorOperatorsStationsStationGuidPut(stationGuid: string, operatorUpdateFleetOperatorStationCommand?: OperatorUpdateFleetOperatorStationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sends the station for review
     * @param {string} stationGuid Station identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApiInterface
     */
    apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OperatorOperatorApi - object-oriented interface
 * @export
 * @class OperatorOperatorApi
 * @extends {BaseAPI}
 */
export class OperatorOperatorApi extends BaseAPI implements OperatorOperatorApiInterface {
    /**
     * 
     * @summary Get stations of fleet operator
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {FleetOperatorStationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApi
     */
    public apiV1OperatorOperatorsStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig) {
        return OperatorOperatorApiFp(this.configuration).apiV1OperatorOperatorsStationsGet(pageSize, pageNumber, search, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new fleet operator station
     * @param {OperatorCreateFleetOperatorStationCommand} [operatorCreateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApi
     */
    public apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand?: OperatorCreateFleetOperatorStationCommand, options?: AxiosRequestConfig) {
        return OperatorOperatorApiFp(this.configuration).apiV1OperatorOperatorsStationsPost(operatorCreateFleetOperatorStationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get station detail
     * @param {string} stationGuid Station identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApi
     */
    public apiV1OperatorOperatorsStationsStationGuidGet(stationGuid: string, options?: AxiosRequestConfig) {
        return OperatorOperatorApiFp(this.configuration).apiV1OperatorOperatorsStationsStationGuidGet(stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates fleet operator station
     * @param {string} stationGuid The area guid
     * @param {OperatorUpdateFleetOperatorStationCommand} [operatorUpdateFleetOperatorStationCommand] Station data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApi
     */
    public apiV1OperatorOperatorsStationsStationGuidPut(stationGuid: string, operatorUpdateFleetOperatorStationCommand?: OperatorUpdateFleetOperatorStationCommand, options?: AxiosRequestConfig) {
        return OperatorOperatorApiFp(this.configuration).apiV1OperatorOperatorsStationsStationGuidPut(stationGuid, operatorUpdateFleetOperatorStationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends the station for review
     * @param {string} stationGuid Station identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorOperatorApi
     */
    public apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid: string, options?: AxiosRequestConfig) {
        return OperatorOperatorApiFp(this.configuration).apiV1OperatorOperatorsStationsStationGuidSendForReviewPost(stationGuid, options).then((request) => request(this.axios, this.basePath));
    }
}

