/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ListingBadgeType = {
    NotSet: 'NotSet',
    SpecialOffer_PromoOffer: 'SpecialOffer_PromoOffer',
    SpecialOffer_SpecialOffer: 'SpecialOffer_SpecialOffer',
    SpecialOffer_From3Days: 'SpecialOffer_From3Days',
    SpecialOffer_FirstMinute2024: 'SpecialOffer_FirstMinute2024',
    Top_HitOfTheMonth: 'Top_HitOfTheMonth',
    Top_TopRV: 'Top_TopRV',
    Recommendation_ForFamilies: 'Recommendation_ForFamilies',
    Recommendation_ForCouples: 'Recommendation_ForCouples',
    Recommendation_ForCyclist: 'Recommendation_ForCyclist',
    Recommendation_RecommendedByUs: 'Recommendation_RecommendedByUs',
    CarLevel_NewListing: 'CarLevel_NewListing',
    CarLevel_Build2022: 'CarLevel_Build2022',
    CarLevel_Build2023: 'CarLevel_Build2023',
    CarLevel_SuperiorEquipment: 'CarLevel_SuperiorEquipment',
    CarLevel_WinterEquipment: 'CarLevel_WinterEquipment',
    CarLevel_Egoe: 'CarLevel_Egoe',
    CarLevel_Bonus80EUR: 'CarLevel_Bonus80EUR',
    CarLevel_Build2024: 'CarLevel_Build2024'
} as const;

export type ListingBadgeType = typeof ListingBadgeType[keyof typeof ListingBadgeType];



