/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const HelpCenterArticleCategoryType = {
    HowToVideo: 'HowToVideo',
    Gas: 'Gas',
    Water: 'Water',
    Heating: 'Heating',
    Electricity: 'Electricity',
    TypicalErrorCodes: 'TypicalErrorCodes',
    Kitchen: 'Kitchen',
    SleepingAndPopUpRoof: 'SleepingAndPopUpRoof',
    Toilet: 'Toilet',
    CockpitAndRefuelling: 'CockpitAndRefuelling',
    WinterCamping: 'WinterCamping',
    DamagesAndAccidents: 'DamagesAndAccidents'
} as const;

export type HelpCenterArticleCategoryType = typeof HelpCenterArticleCategoryType[keyof typeof HelpCenterArticleCategoryType];



