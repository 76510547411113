/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Differentiates states of a Campiri.Booking.BookingRequest
 * @export
 * @enum {string}
 */

export const BookingRequestStatus = {
    Requested: 'Requested',
    Expired: 'Expired',
    Approved: 'Approved',
    Completed: 'Completed',
    Rejected: 'Rejected',
    CounterOfferMade: 'CounterOfferMade',
    Cancelled: 'Cancelled',
    Withdrawn: 'Withdrawn'
} as const;

export type BookingRequestStatus = typeof BookingRequestStatus[keyof typeof BookingRequestStatus];



