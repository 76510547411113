/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailerDtoPaginatedResult } from '../models';
// @ts-ignore
import { MailerType } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * NoticesAdminApi - axios parameter creator
 * @export
 */
export const NoticesAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the mailer culture options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNoticesCultureOptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/notices/culture-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the mailers
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNoticesMailersGet: async (pageSize?: number, pageNumber?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/notices/mailers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a mailer example preview containing Subject, Preheader, and Content.
         * @param {MailerType} mailerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNoticesMailersMailerTypeExampleGet: async (mailerType: MailerType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailerType' is not null or undefined
            assertParamExists('apiV1AdminNoticesMailersMailerTypeExampleGet', 'mailerType', mailerType)
            const localVarPath = `/api/v1/admin/notices/mailers/{mailerType}/example`
                .replace(`{${"mailerType"}}`, encodeURIComponent(String(mailerType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticesAdminApi - functional programming interface
 * @export
 */
export const NoticesAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticesAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the mailer culture options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNoticesCultureOptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNoticesCultureOptionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the mailers
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNoticesMailersGet(pageSize?: number, pageNumber?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailerDtoPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNoticesMailersGet(pageSize, pageNumber, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a mailer example preview containing Subject, Preheader, and Content.
         * @param {MailerType} mailerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType: MailerType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticesAdminApi - factory interface
 * @export
 */
export const NoticesAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticesAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the mailer culture options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNoticesCultureOptionsGet(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiV1AdminNoticesCultureOptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the mailers
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNoticesMailersGet(pageSize?: number, pageNumber?: number, search?: string, options?: any): AxiosPromise<MailerDtoPaginatedResult> {
            return localVarFp.apiV1AdminNoticesMailersGet(pageSize, pageNumber, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a mailer example preview containing Subject, Preheader, and Content.
         * @param {MailerType} mailerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType: MailerType, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticesAdminApi - interface
 * @export
 * @interface NoticesAdminApi
 */
export interface NoticesAdminApiInterface {
    /**
     * 
     * @summary Gets the mailer culture options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesAdminApiInterface
     */
    apiV1AdminNoticesCultureOptionsGet(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: string; }>;

    /**
     * 
     * @summary Gets the mailers
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesAdminApiInterface
     */
    apiV1AdminNoticesMailersGet(pageSize?: number, pageNumber?: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<MailerDtoPaginatedResult>;

    /**
     * 
     * @summary Returns a mailer example preview containing Subject, Preheader, and Content.
     * @param {MailerType} mailerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesAdminApiInterface
     */
    apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType: MailerType, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * NoticesAdminApi - object-oriented interface
 * @export
 * @class NoticesAdminApi
 * @extends {BaseAPI}
 */
export class NoticesAdminApi extends BaseAPI implements NoticesAdminApiInterface {
    /**
     * 
     * @summary Gets the mailer culture options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesAdminApi
     */
    public apiV1AdminNoticesCultureOptionsGet(options?: AxiosRequestConfig) {
        return NoticesAdminApiFp(this.configuration).apiV1AdminNoticesCultureOptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the mailers
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesAdminApi
     */
    public apiV1AdminNoticesMailersGet(pageSize?: number, pageNumber?: number, search?: string, options?: AxiosRequestConfig) {
        return NoticesAdminApiFp(this.configuration).apiV1AdminNoticesMailersGet(pageSize, pageNumber, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a mailer example preview containing Subject, Preheader, and Content.
     * @param {MailerType} mailerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesAdminApi
     */
    public apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType: MailerType, options?: AxiosRequestConfig) {
        return NoticesAdminApiFp(this.configuration).apiV1AdminNoticesMailersMailerTypeExampleGet(mailerType, options).then((request) => request(this.axios, this.basePath));
    }
}

