/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApprovedOrRejectedByOperatorExportQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingIntentsExportQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { BookingsByOperatorQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { ListedCarsByOperatorQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListedCarsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListingsAvailabilityCsvQuery } from '../models';
// @ts-ignore
import { OperatorsCarExportQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { PaymentsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { PublishState } from '../models';
// @ts-ignore
import { ReservationsExportQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { VehicleType } from '../models';
/**
 * ReportsAdminApi - axios parameter creator
 * @export
 */
export const ReportsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsApprovedOrRejectedByOperatorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/approved-or-rejected-by-operator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {string} [utcDateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsBookingsDetailsGet: async (utcDateFrom?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/bookings/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (utcDateFrom !== undefined) {
                localVarQueryParameter['UtcDateFrom'] = (utcDateFrom as any instanceof Date) ?
                    (utcDateFrom as any).toISOString() :
                    utcDateFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsIntentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles availability.
         * @param {ListingsAvailabilityCsvQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsListingsAvailabilityGet: async (query?: ListingsAvailabilityCsvQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/listings/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {PublishState} [publishState] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {CountryCode} [country] 
         * @param {VehicleType} [vehicleType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsListingsStateCsvGet: async (publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/listings-state/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (publishState !== undefined) {
                localVarQueryParameter['PublishState'] = publishState;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['VehicleType'] = vehicleType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report for listed vehicles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {PublishState} [publishState] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {CountryCode} [country] 
         * @param {VehicleType} [vehicleType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsListingsStateGet: async (pageNumber: number, pageSize: number, publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminReportsListingsStateGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminReportsListingsStateGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/reports/listings-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (publishState !== undefined) {
                localVarQueryParameter['PublishState'] = publishState;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['VehicleType'] = vehicleType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report of listed vehicles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorCarsExportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/operator/cars-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report file of operator bookings
         * @param {string} [search] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorGmvCsvGet: async (search?: string, from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/operator/gmv/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (until !== undefined) {
                localVarQueryParameter['Until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report of operator bookings
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorGmvGet: async (pageNumber: number, pageSize: number, search?: string, from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminReportsOperatorGmvGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminReportsOperatorGmvGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/reports/operator/gmv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (until !== undefined) {
                localVarQueryParameter['Until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {string} [search] 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorNumberOfCarsCsvGet: async (search?: string, asOf?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/operator/number-of-cars/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (asOf !== undefined) {
                localVarQueryParameter['AsOf'] = (asOf as any instanceof Date) ?
                    (asOf as any).toISOString() :
                    asOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report for listed vehicles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorNumberOfCarsGet: async (pageNumber: number, pageSize: number, search?: string, asOf?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminReportsOperatorNumberOfCarsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminReportsOperatorNumberOfCarsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/reports/operator/number-of-cars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (asOf !== undefined) {
                localVarQueryParameter['AsOf'] = (asOf as any instanceof Date) ?
                    (asOf as any).toISOString() :
                    asOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsPaymentsCsvGet: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/payments/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsPaymentsGet: async (pageNumber: number, pageSize: number, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminReportsPaymentsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminReportsPaymentsGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/reports/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsReservationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reports/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsAdminApi - functional programming interface
 * @export
 */
export const ReportsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsApprovedOrRejectedByOperatorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovedOrRejectedByOperatorExportQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsApprovedOrRejectedByOperatorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {string} [utcDateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsBookingsDetailsGet(utcDateFrom?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsBookingsDetailsGet(utcDateFrom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsIntentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingIntentsExportQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsIntentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles availability.
         * @param {ListingsAvailabilityCsvQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsListingsAvailabilityGet(query?: ListingsAvailabilityCsvQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsListingsAvailabilityGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {PublishState} [publishState] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {CountryCode} [country] 
         * @param {VehicleType} [vehicleType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsListingsStateCsvGet(publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsListingsStateCsvGet(publishState, dateFrom, dateTo, country, vehicleType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report for listed vehicles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {PublishState} [publishState] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {CountryCode} [country] 
         * @param {VehicleType} [vehicleType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsListingsStateGet(pageNumber: number, pageSize: number, publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListedCarsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsListingsStateGet(pageNumber, pageSize, publishState, dateFrom, dateTo, country, vehicleType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report of listed vehicles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsOperatorCarsExportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorsCarExportQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsOperatorCarsExportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report file of operator bookings
         * @param {string} [search] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsOperatorGmvCsvGet(search?: string, from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsOperatorGmvCsvGet(search, from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report of operator bookings
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsOperatorGmvGet(pageNumber: number, pageSize: number, search?: string, from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingsByOperatorQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsOperatorGmvGet(pageNumber, pageSize, search, from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {string} [search] 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsOperatorNumberOfCarsCsvGet(search?: string, asOf?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsOperatorNumberOfCarsCsvGet(search, asOf, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report for listed vehicles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber: number, pageSize: number, search?: string, asOf?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListedCarsByOperatorQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber, pageSize, search, asOf, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsPaymentsCsvGet(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsPaymentsCsvGet(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsPaymentsGet(pageNumber: number, pageSize: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsPaymentsGet(pageNumber, pageSize, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReportsReservationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationsExportQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReportsReservationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsAdminApi - factory interface
 * @export
 */
export const ReportsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsApprovedOrRejectedByOperatorGet(options?: any): AxiosPromise<ApprovedOrRejectedByOperatorExportQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsApprovedOrRejectedByOperatorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {string} [utcDateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsBookingsDetailsGet(utcDateFrom?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsBookingsDetailsGet(utcDateFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsIntentsGet(options?: any): AxiosPromise<BookingIntentsExportQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsIntentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles availability.
         * @param {ListingsAvailabilityCsvQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsListingsAvailabilityGet(query?: ListingsAvailabilityCsvQuery, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsListingsAvailabilityGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {PublishState} [publishState] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {CountryCode} [country] 
         * @param {VehicleType} [vehicleType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsListingsStateCsvGet(publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsListingsStateCsvGet(publishState, dateFrom, dateTo, country, vehicleType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report for listed vehicles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {PublishState} [publishState] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {CountryCode} [country] 
         * @param {VehicleType} [vehicleType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsListingsStateGet(pageNumber: number, pageSize: number, publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: any): AxiosPromise<ListedCarsQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsListingsStateGet(pageNumber, pageSize, publishState, dateFrom, dateTo, country, vehicleType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report of listed vehicles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorCarsExportGet(options?: any): AxiosPromise<OperatorsCarExportQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsOperatorCarsExportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report file of operator bookings
         * @param {string} [search] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorGmvCsvGet(search?: string, from?: string, until?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsOperatorGmvCsvGet(search, from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report of operator bookings
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorGmvGet(pageNumber: number, pageSize: number, search?: string, from?: string, until?: string, options?: any): AxiosPromise<BookingsByOperatorQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsOperatorGmvGet(pageNumber, pageSize, search, from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report file for listed vehicles.
         * @param {string} [search] 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorNumberOfCarsCsvGet(search?: string, asOf?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsOperatorNumberOfCarsCsvGet(search, asOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report for listed vehicles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber: number, pageSize: number, search?: string, asOf?: string, options?: any): AxiosPromise<ListedCarsByOperatorQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber, pageSize, search, asOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsPaymentsCsvGet(dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminReportsPaymentsCsvGet(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsPaymentsGet(pageNumber: number, pageSize: number, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<PaymentsQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsPaymentsGet(pageNumber, pageSize, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReportsReservationsGet(options?: any): AxiosPromise<ReservationsExportQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminReportsReservationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsAdminApi - interface
 * @export
 * @interface ReportsAdminApi
 */
export interface ReportsAdminApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsApprovedOrRejectedByOperatorGet(options?: AxiosRequestConfig): AxiosPromise<ApprovedOrRejectedByOperatorExportQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a report file for listed vehicles.
     * @param {string} [utcDateFrom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsBookingsDetailsGet(utcDateFrom?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsIntentsGet(options?: AxiosRequestConfig): AxiosPromise<BookingIntentsExportQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a report file for listed vehicles availability.
     * @param {ListingsAvailabilityCsvQuery} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsListingsAvailabilityGet(query?: ListingsAvailabilityCsvQuery, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a report file for listed vehicles.
     * @param {PublishState} [publishState] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {CountryCode} [country] 
     * @param {VehicleType} [vehicleType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsListingsStateCsvGet(publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a report for listed vehicles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {PublishState} [publishState] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {CountryCode} [country] 
     * @param {VehicleType} [vehicleType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsListingsStateGet(pageNumber: number, pageSize: number, publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: AxiosRequestConfig): AxiosPromise<ListedCarsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a report of listed vehicles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsOperatorCarsExportGet(options?: AxiosRequestConfig): AxiosPromise<OperatorsCarExportQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a report file of operator bookings
     * @param {string} [search] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsOperatorGmvCsvGet(search?: string, from?: string, until?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a report of operator bookings
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsOperatorGmvGet(pageNumber: number, pageSize: number, search?: string, from?: string, until?: string, options?: AxiosRequestConfig): AxiosPromise<BookingsByOperatorQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a report file for listed vehicles.
     * @param {string} [search] 
     * @param {string} [asOf] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsOperatorNumberOfCarsCsvGet(search?: string, asOf?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a report for listed vehicles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [asOf] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber: number, pageSize: number, search?: string, asOf?: string, options?: AxiosRequestConfig): AxiosPromise<ListedCarsByOperatorQueryResultPaginatedResult>;

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsPaymentsCsvGet(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsPaymentsGet(pageNumber: number, pageSize: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): AxiosPromise<PaymentsQueryResultPaginatedResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApiInterface
     */
    apiV1AdminReportsReservationsGet(options?: AxiosRequestConfig): AxiosPromise<ReservationsExportQueryResultPaginatedResult>;

}

/**
 * ReportsAdminApi - object-oriented interface
 * @export
 * @class ReportsAdminApi
 * @extends {BaseAPI}
 */
export class ReportsAdminApi extends BaseAPI implements ReportsAdminApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsApprovedOrRejectedByOperatorGet(options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsApprovedOrRejectedByOperatorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report file for listed vehicles.
     * @param {string} [utcDateFrom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsBookingsDetailsGet(utcDateFrom?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsBookingsDetailsGet(utcDateFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsIntentsGet(options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsIntentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report file for listed vehicles availability.
     * @param {ListingsAvailabilityCsvQuery} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsListingsAvailabilityGet(query?: ListingsAvailabilityCsvQuery, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsListingsAvailabilityGet(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report file for listed vehicles.
     * @param {PublishState} [publishState] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {CountryCode} [country] 
     * @param {VehicleType} [vehicleType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsListingsStateCsvGet(publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsListingsStateCsvGet(publishState, dateFrom, dateTo, country, vehicleType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report for listed vehicles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {PublishState} [publishState] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {CountryCode} [country] 
     * @param {VehicleType} [vehicleType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsListingsStateGet(pageNumber: number, pageSize: number, publishState?: PublishState, dateFrom?: string, dateTo?: string, country?: CountryCode, vehicleType?: VehicleType, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsListingsStateGet(pageNumber, pageSize, publishState, dateFrom, dateTo, country, vehicleType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report of listed vehicles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsOperatorCarsExportGet(options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsOperatorCarsExportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report file of operator bookings
     * @param {string} [search] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsOperatorGmvCsvGet(search?: string, from?: string, until?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsOperatorGmvCsvGet(search, from, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report of operator bookings
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsOperatorGmvGet(pageNumber: number, pageSize: number, search?: string, from?: string, until?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsOperatorGmvGet(pageNumber, pageSize, search, from, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report file for listed vehicles.
     * @param {string} [search] 
     * @param {string} [asOf] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsOperatorNumberOfCarsCsvGet(search?: string, asOf?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsOperatorNumberOfCarsCsvGet(search, asOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report for listed vehicles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [asOf] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber: number, pageSize: number, search?: string, asOf?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsOperatorNumberOfCarsGet(pageNumber, pageSize, search, asOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsPaymentsCsvGet(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsPaymentsCsvGet(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsPaymentsGet(pageNumber: number, pageSize: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsPaymentsGet(pageNumber, pageSize, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAdminApi
     */
    public apiV1AdminReportsReservationsGet(options?: AxiosRequestConfig) {
        return ReportsAdminApiFp(this.configuration).apiV1AdminReportsReservationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}

