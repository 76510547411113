/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FleetOperatorStationStatus } from '../models';
// @ts-ignore
import { ListFleetOperatorStationsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * StationOperatorApi - axios parameter creator
 * @export
 */
export const StationOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorStationsGet: async (pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1OperatorStationsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1OperatorStationsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/operator/stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StationOperatorApi - functional programming interface
 * @export
 */
export const StationOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StationOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorStationsGet(pageSize, pageNumber, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StationOperatorApi - factory interface
 * @export
 */
export const StationOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StationOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Get stations of fleet operator
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {FleetOperatorStationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: any): AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorStationsGet(pageSize, pageNumber, search, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StationOperatorApi - interface
 * @export
 * @interface StationOperatorApi
 */
export interface StationOperatorApiInterface {
    /**
     * 
     * @summary Get stations of fleet operator
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {FleetOperatorStationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationOperatorApiInterface
     */
    apiV1OperatorStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig): AxiosPromise<ListFleetOperatorStationsQueryResultPaginatedResult>;

}

/**
 * StationOperatorApi - object-oriented interface
 * @export
 * @class StationOperatorApi
 * @extends {BaseAPI}
 */
export class StationOperatorApi extends BaseAPI implements StationOperatorApiInterface {
    /**
     * 
     * @summary Get stations of fleet operator
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {FleetOperatorStationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationOperatorApi
     */
    public apiV1OperatorStationsGet(pageSize: number, pageNumber: number, search?: string, status?: FleetOperatorStationStatus, options?: AxiosRequestConfig) {
        return StationOperatorApiFp(this.configuration).apiV1OperatorStationsGet(pageSize, pageNumber, search, status, options).then((request) => request(this.axios, this.basePath));
    }
}

