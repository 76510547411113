/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ArticleCategoryType } from '../models';
// @ts-ignore
import { ArticlePlacementType } from '../models';
// @ts-ignore
import { ArticleQueryResult } from '../models';
// @ts-ignore
import { ArticlesByViewsQueryResult } from '../models';
// @ts-ignore
import { ArticlesListQueryResult } from '../models';
// @ts-ignore
import { ArticlesListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { MediaImageResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ArticleMarketplaceApi - axios parameter creator
 * @export
 */
export const ArticleMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of articles by placement type.
         * @param {ArticlePlacementType} [placement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesByPlaceGet: async (placement?: ArticlePlacementType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/blog/articles/by-place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (placement !== undefined) {
                localVarQueryParameter['placement'] = placement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of articles by popularity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesByViewsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/blog/articles/by-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageOffset 
         * @param {number} pageSize 
         * @param {ArticleCategoryType} [category] 
         * @param {Array<number>} [ids] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesGet: async (pageOffset: number, pageSize: number, category?: ArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageOffset' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogArticlesGet', 'pageOffset', pageOffset)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogArticlesGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/marketplace/blog/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (category !== undefined) {
                localVarQueryParameter['Category'] = category;
            }

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (guids) {
                localVarQueryParameter['Guids'] = guids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogArticlesIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/blog/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesIdPreviewGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogArticlesIdPreviewGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/blog/articles/{id}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of articles by popularity.
         * @param {number} id 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesIdSimilarGet: async (id: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogArticlesIdSimilarGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/blog/articles/{id}/similar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesPicturesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogArticlesPicturesIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/blog/articles/pictures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleMarketplaceApi - functional programming interface
 * @export
 */
export const ArticleMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of articles by placement type.
         * @param {ArticlePlacementType} [placement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesByPlaceGet(placement?: ArticlePlacementType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticlesListQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesByPlaceGet(placement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of articles by popularity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesByViewsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticlesByViewsQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesByViewsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageOffset 
         * @param {number} pageSize 
         * @param {ArticleCategoryType} [category] 
         * @param {Array<number>} [ids] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesGet(pageOffset: number, pageSize: number, category?: ArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesGet(pageOffset, pageSize, category, ids, guids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesIdPreviewGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesIdPreviewGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of articles by popularity.
         * @param {number} id 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesIdSimilarGet(id: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticlesListQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesIdSimilarGet(id, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogArticlesPicturesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogArticlesPicturesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleMarketplaceApi - factory interface
 * @export
 */
export const ArticleMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of articles by placement type.
         * @param {ArticlePlacementType} [placement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesByPlaceGet(placement?: ArticlePlacementType, options?: any): AxiosPromise<Array<ArticlesListQueryResult>> {
            return localVarFp.apiV1MarketplaceBlogArticlesByPlaceGet(placement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of articles by popularity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesByViewsGet(options?: any): AxiosPromise<Array<ArticlesByViewsQueryResult>> {
            return localVarFp.apiV1MarketplaceBlogArticlesByViewsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageOffset 
         * @param {number} pageSize 
         * @param {ArticleCategoryType} [category] 
         * @param {Array<number>} [ids] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesGet(pageOffset: number, pageSize: number, category?: ArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: any): AxiosPromise<ArticlesListQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceBlogArticlesGet(pageOffset, pageSize, category, ids, guids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesIdGet(id: number, options?: any): AxiosPromise<ArticleQueryResult> {
            return localVarFp.apiV1MarketplaceBlogArticlesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an article variation.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesIdPreviewGet(id: number, options?: any): AxiosPromise<ArticleQueryResult> {
            return localVarFp.apiV1MarketplaceBlogArticlesIdPreviewGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of articles by popularity.
         * @param {number} id 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesIdSimilarGet(id: number, size?: number, options?: any): AxiosPromise<Array<ArticlesListQueryResult>> {
            return localVarFp.apiV1MarketplaceBlogArticlesIdSimilarGet(id, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogArticlesPicturesIdGet(id: number, options?: any): AxiosPromise<MediaImageResponse> {
            return localVarFp.apiV1MarketplaceBlogArticlesPicturesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleMarketplaceApi - interface
 * @export
 * @interface ArticleMarketplaceApi
 */
export interface ArticleMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of articles by placement type.
     * @param {ArticlePlacementType} [placement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesByPlaceGet(placement?: ArticlePlacementType, options?: AxiosRequestConfig): AxiosPromise<Array<ArticlesListQueryResult>>;

    /**
     * 
     * @summary Gets a list of articles by popularity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesByViewsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ArticlesByViewsQueryResult>>;

    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageOffset 
     * @param {number} pageSize 
     * @param {ArticleCategoryType} [category] 
     * @param {Array<number>} [ids] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesGet(pageOffset: number, pageSize: number, category?: ArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ArticlesListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<ArticleQueryResult>;

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesIdPreviewGet(id: number, options?: AxiosRequestConfig): AxiosPromise<ArticleQueryResult>;

    /**
     * 
     * @summary Gets a list of articles by popularity.
     * @param {number} id 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesIdSimilarGet(id: number, size?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ArticlesListQueryResult>>;

    /**
     * 
     * @summary Gets the media picture by its identifier.
     * @param {number} id Media identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApiInterface
     */
    apiV1MarketplaceBlogArticlesPicturesIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<MediaImageResponse>;

}

/**
 * ArticleMarketplaceApi - object-oriented interface
 * @export
 * @class ArticleMarketplaceApi
 * @extends {BaseAPI}
 */
export class ArticleMarketplaceApi extends BaseAPI implements ArticleMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a list of articles by placement type.
     * @param {ArticlePlacementType} [placement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesByPlaceGet(placement?: ArticlePlacementType, options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesByPlaceGet(placement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of articles by popularity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesByViewsGet(options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesByViewsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageOffset 
     * @param {number} pageSize 
     * @param {ArticleCategoryType} [category] 
     * @param {Array<number>} [ids] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesGet(pageOffset: number, pageSize: number, category?: ArticleCategoryType, ids?: Array<number>, guids?: Array<string>, options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesGet(pageOffset, pageSize, category, ids, guids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesIdGet(id: number, options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an article variation.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesIdPreviewGet(id: number, options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesIdPreviewGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of articles by popularity.
     * @param {number} id 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesIdSimilarGet(id: number, size?: number, options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesIdSimilarGet(id, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the media picture by its identifier.
     * @param {number} id Media identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleMarketplaceApi
     */
    public apiV1MarketplaceBlogArticlesPicturesIdGet(id: number, options?: AxiosRequestConfig) {
        return ArticleMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogArticlesPicturesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

