/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InvestmentType } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { VehicleType } from '../models';
/**
 * ContactUsMarketplaceApi - axios parameter creator
 * @export
 */
export const ContactUsMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Notifies Campiri that a customer wants to contact them (CampersClub)
         * @param {InvestmentType} [investmentType] Long-term or short-term
         * @param {string} [firstName] First name
         * @param {string} [lastName] Last name
         * @param {string} [email] Email
         * @param {string} [phone] Phone
         * @param {string} [captcha] Captcha token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceContactUsCampersClubPost: async (investmentType?: InvestmentType, firstName?: string, lastName?: string, email?: string, phone?: string, captcha?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/contact-us/campers-club`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentType !== undefined) {
                localVarQueryParameter['investmentType'] = investmentType;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (captcha !== undefined) {
                localVarQueryParameter['captcha'] = captcha;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Notifies Campiri that a customer wants to contact them (P2P)
         * @param {VehicleType} [vehicleType] Vehicle type
         * @param {string} [brandAndModel] Brand and model
         * @param {number} [year] Year of manufacture
         * @param {string} [country] Country to rent in
         * @param {string} [city] City
         * @param {string} [fullName] Full name
         * @param {string} [email] Email
         * @param {string} [phone] Phone
         * @param {string} [web] Web
         * @param {string} [captcha] Captcha token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceContactUsP2pPost: async (vehicleType?: VehicleType, brandAndModel?: string, year?: number, country?: string, city?: string, fullName?: string, email?: string, phone?: string, web?: string, captcha?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/contact-us/p2p`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleType !== undefined) {
                localVarQueryParameter['vehicleType'] = vehicleType;
            }

            if (brandAndModel !== undefined) {
                localVarQueryParameter['brandAndModel'] = brandAndModel;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (web !== undefined) {
                localVarQueryParameter['web'] = web;
            }

            if (captcha !== undefined) {
                localVarQueryParameter['captcha'] = captcha;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactUsMarketplaceApi - functional programming interface
 * @export
 */
export const ContactUsMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactUsMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Notifies Campiri that a customer wants to contact them (CampersClub)
         * @param {InvestmentType} [investmentType] Long-term or short-term
         * @param {string} [firstName] First name
         * @param {string} [lastName] Last name
         * @param {string} [email] Email
         * @param {string} [phone] Phone
         * @param {string} [captcha] Captcha token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceContactUsCampersClubPost(investmentType?: InvestmentType, firstName?: string, lastName?: string, email?: string, phone?: string, captcha?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceContactUsCampersClubPost(investmentType, firstName, lastName, email, phone, captcha, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Notifies Campiri that a customer wants to contact them (P2P)
         * @param {VehicleType} [vehicleType] Vehicle type
         * @param {string} [brandAndModel] Brand and model
         * @param {number} [year] Year of manufacture
         * @param {string} [country] Country to rent in
         * @param {string} [city] City
         * @param {string} [fullName] Full name
         * @param {string} [email] Email
         * @param {string} [phone] Phone
         * @param {string} [web] Web
         * @param {string} [captcha] Captcha token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceContactUsP2pPost(vehicleType?: VehicleType, brandAndModel?: string, year?: number, country?: string, city?: string, fullName?: string, email?: string, phone?: string, web?: string, captcha?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceContactUsP2pPost(vehicleType, brandAndModel, year, country, city, fullName, email, phone, web, captcha, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactUsMarketplaceApi - factory interface
 * @export
 */
export const ContactUsMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactUsMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Notifies Campiri that a customer wants to contact them (CampersClub)
         * @param {InvestmentType} [investmentType] Long-term or short-term
         * @param {string} [firstName] First name
         * @param {string} [lastName] Last name
         * @param {string} [email] Email
         * @param {string} [phone] Phone
         * @param {string} [captcha] Captcha token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceContactUsCampersClubPost(investmentType?: InvestmentType, firstName?: string, lastName?: string, email?: string, phone?: string, captcha?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceContactUsCampersClubPost(investmentType, firstName, lastName, email, phone, captcha, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Notifies Campiri that a customer wants to contact them (P2P)
         * @param {VehicleType} [vehicleType] Vehicle type
         * @param {string} [brandAndModel] Brand and model
         * @param {number} [year] Year of manufacture
         * @param {string} [country] Country to rent in
         * @param {string} [city] City
         * @param {string} [fullName] Full name
         * @param {string} [email] Email
         * @param {string} [phone] Phone
         * @param {string} [web] Web
         * @param {string} [captcha] Captcha token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceContactUsP2pPost(vehicleType?: VehicleType, brandAndModel?: string, year?: number, country?: string, city?: string, fullName?: string, email?: string, phone?: string, web?: string, captcha?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceContactUsP2pPost(vehicleType, brandAndModel, year, country, city, fullName, email, phone, web, captcha, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactUsMarketplaceApi - interface
 * @export
 * @interface ContactUsMarketplaceApi
 */
export interface ContactUsMarketplaceApiInterface {
    /**
     * 
     * @summary Notifies Campiri that a customer wants to contact them (CampersClub)
     * @param {InvestmentType} [investmentType] Long-term or short-term
     * @param {string} [firstName] First name
     * @param {string} [lastName] Last name
     * @param {string} [email] Email
     * @param {string} [phone] Phone
     * @param {string} [captcha] Captcha token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactUsMarketplaceApiInterface
     */
    apiV1MarketplaceContactUsCampersClubPost(investmentType?: InvestmentType, firstName?: string, lastName?: string, email?: string, phone?: string, captcha?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Notifies Campiri that a customer wants to contact them (P2P)
     * @param {VehicleType} [vehicleType] Vehicle type
     * @param {string} [brandAndModel] Brand and model
     * @param {number} [year] Year of manufacture
     * @param {string} [country] Country to rent in
     * @param {string} [city] City
     * @param {string} [fullName] Full name
     * @param {string} [email] Email
     * @param {string} [phone] Phone
     * @param {string} [web] Web
     * @param {string} [captcha] Captcha token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactUsMarketplaceApiInterface
     */
    apiV1MarketplaceContactUsP2pPost(vehicleType?: VehicleType, brandAndModel?: string, year?: number, country?: string, city?: string, fullName?: string, email?: string, phone?: string, web?: string, captcha?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ContactUsMarketplaceApi - object-oriented interface
 * @export
 * @class ContactUsMarketplaceApi
 * @extends {BaseAPI}
 */
export class ContactUsMarketplaceApi extends BaseAPI implements ContactUsMarketplaceApiInterface {
    /**
     * 
     * @summary Notifies Campiri that a customer wants to contact them (CampersClub)
     * @param {InvestmentType} [investmentType] Long-term or short-term
     * @param {string} [firstName] First name
     * @param {string} [lastName] Last name
     * @param {string} [email] Email
     * @param {string} [phone] Phone
     * @param {string} [captcha] Captcha token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactUsMarketplaceApi
     */
    public apiV1MarketplaceContactUsCampersClubPost(investmentType?: InvestmentType, firstName?: string, lastName?: string, email?: string, phone?: string, captcha?: string, options?: AxiosRequestConfig) {
        return ContactUsMarketplaceApiFp(this.configuration).apiV1MarketplaceContactUsCampersClubPost(investmentType, firstName, lastName, email, phone, captcha, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Notifies Campiri that a customer wants to contact them (P2P)
     * @param {VehicleType} [vehicleType] Vehicle type
     * @param {string} [brandAndModel] Brand and model
     * @param {number} [year] Year of manufacture
     * @param {string} [country] Country to rent in
     * @param {string} [city] City
     * @param {string} [fullName] Full name
     * @param {string} [email] Email
     * @param {string} [phone] Phone
     * @param {string} [web] Web
     * @param {string} [captcha] Captcha token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactUsMarketplaceApi
     */
    public apiV1MarketplaceContactUsP2pPost(vehicleType?: VehicleType, brandAndModel?: string, year?: number, country?: string, city?: string, fullName?: string, email?: string, phone?: string, web?: string, captcha?: string, options?: AxiosRequestConfig) {
        return ContactUsMarketplaceApiFp(this.configuration).apiV1MarketplaceContactUsP2pPost(vehicleType, brandAndModel, year, country, city, fullName, email, phone, web, captcha, options).then((request) => request(this.axios, this.basePath));
    }
}

