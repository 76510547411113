/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * InvoiceMarketplaceApi - axios parameter creator
 * @export
 */
export const InvoiceMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the HTML template of the invoice.
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceInvoicesGuidHtmlGet: async (guid: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1MarketplaceInvoicesGuidHtmlGet', 'guid', guid)
            const localVarPath = `/api/v1/marketplace/invoices/{guid}/html`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the PDF file of the invoice.
         * @param {string} guid The giud of the invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceInvoicesGuidPdfGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1MarketplaceInvoicesGuidPdfGet', 'guid', guid)
            const localVarPath = `/api/v1/marketplace/invoices/{guid}/pdf`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceMarketplaceApi - functional programming interface
 * @export
 */
export const InvoiceMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the HTML template of the invoice.
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceInvoicesGuidHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceInvoicesGuidHtmlGet(guid, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the PDF file of the invoice.
         * @param {string} guid The giud of the invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceInvoicesGuidPdfGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceInvoicesGuidPdfGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceMarketplaceApi - factory interface
 * @export
 */
export const InvoiceMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the HTML template of the invoice.
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceInvoicesGuidHtmlGet(guid: string, accessToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceInvoicesGuidHtmlGet(guid, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the PDF file of the invoice.
         * @param {string} guid The giud of the invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceInvoicesGuidPdfGet(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceInvoicesGuidPdfGet(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceMarketplaceApi - interface
 * @export
 * @interface InvoiceMarketplaceApi
 */
export interface InvoiceMarketplaceApiInterface {
    /**
     * 
     * @summary Gets the HTML template of the invoice.
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceMarketplaceApiInterface
     */
    apiV1MarketplaceInvoicesGuidHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the PDF file of the invoice.
     * @param {string} guid The giud of the invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceMarketplaceApiInterface
     */
    apiV1MarketplaceInvoicesGuidPdfGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * InvoiceMarketplaceApi - object-oriented interface
 * @export
 * @class InvoiceMarketplaceApi
 * @extends {BaseAPI}
 */
export class InvoiceMarketplaceApi extends BaseAPI implements InvoiceMarketplaceApiInterface {
    /**
     * 
     * @summary Gets the HTML template of the invoice.
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceMarketplaceApi
     */
    public apiV1MarketplaceInvoicesGuidHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig) {
        return InvoiceMarketplaceApiFp(this.configuration).apiV1MarketplaceInvoicesGuidHtmlGet(guid, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the PDF file of the invoice.
     * @param {string} guid The giud of the invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceMarketplaceApi
     */
    public apiV1MarketplaceInvoicesGuidPdfGet(guid: string, options?: AxiosRequestConfig) {
        return InvoiceMarketplaceApiFp(this.configuration).apiV1MarketplaceInvoicesGuidPdfGet(guid, options).then((request) => request(this.axios, this.basePath));
    }
}

