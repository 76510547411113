/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const TireSize = {
    NotSet: 'NotSet',
    Size23565R16C: 'Size23565R16C',
    Size22575R16CP: 'Size22575R16CP',
    Size21570R15CP: 'Size21570R15CP',
    Size23555R17: 'Size23555R17',
    Size21565R16C: 'Size21565R16C',
    Size21560R17C: 'Size21560R17C',
    Size21560R17: 'Size21560R17',
    Size24545R19YXL: 'Size24545R19YXL',
    Size23565R16CP: 'Size23565R16CP',
    Size20565R16C: 'Size20565R16C',
    Size23575R16CP: 'Size23575R16CP'
} as const;

export type TireSize = typeof TireSize[keyof typeof TireSize];



