/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRenterReviewTrackingRecordCommand } from '../models';
// @ts-ignore
import { FillInRenterReviewCommand } from '../models';
// @ts-ignore
import { GetPendingRenterReviewsCountQueryResult } from '../models';
// @ts-ignore
import { GetRenterReviewResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ReviewsOperatorApi - axios parameter creator
 * @export
 */
export const ReviewsOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the booking review detail based on booking reference code.
         * @param {string} [referenceCode] The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsGet: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['referenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the number of pending reviews.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsPendingCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/reviews/pending/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Posts the booking review linked to booking by its reference code.
         * @param {FillInRenterReviewCommand} [fillInRenterReviewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsPut: async (fillInRenterReviewCommand?: FillInRenterReviewCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fillInRenterReviewCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Records a review event.
         * @param {CreateRenterReviewTrackingRecordCommand} [createRenterReviewTrackingRecordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsTrackingRecordsPost: async (createRenterReviewTrackingRecordCommand?: CreateRenterReviewTrackingRecordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/reviews/tracking-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRenterReviewTrackingRecordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Records a review event.
         * @param {string} [referenceCode] 
         * @param {number} [notificationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet: async (referenceCode?: string, notificationNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/reviews/tracking-records/tracking-pixel.jpg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['referenceCode'] = referenceCode;
            }

            if (notificationNumber !== undefined) {
                localVarQueryParameter['notificationNumber'] = notificationNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewsOperatorApi - functional programming interface
 * @export
 */
export const ReviewsOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewsOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the booking review detail based on booking reference code.
         * @param {string} [referenceCode] The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReviewsGet(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRenterReviewResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReviewsGet(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the number of pending reviews.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReviewsPendingCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPendingRenterReviewsCountQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReviewsPendingCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Posts the booking review linked to booking by its reference code.
         * @param {FillInRenterReviewCommand} [fillInRenterReviewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReviewsPut(fillInRenterReviewCommand?: FillInRenterReviewCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReviewsPut(fillInRenterReviewCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Records a review event.
         * @param {CreateRenterReviewTrackingRecordCommand} [createRenterReviewTrackingRecordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand?: CreateRenterReviewTrackingRecordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Records a review event.
         * @param {string} [referenceCode] 
         * @param {number} [notificationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode, notificationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewsOperatorApi - factory interface
 * @export
 */
export const ReviewsOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewsOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the booking review detail based on booking reference code.
         * @param {string} [referenceCode] The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsGet(referenceCode?: string, options?: any): AxiosPromise<GetRenterReviewResult> {
            return localVarFp.apiV1OperatorReviewsGet(referenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the number of pending reviews.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsPendingCountGet(options?: any): AxiosPromise<GetPendingRenterReviewsCountQueryResult> {
            return localVarFp.apiV1OperatorReviewsPendingCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Posts the booking review linked to booking by its reference code.
         * @param {FillInRenterReviewCommand} [fillInRenterReviewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsPut(fillInRenterReviewCommand?: FillInRenterReviewCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReviewsPut(fillInRenterReviewCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Records a review event.
         * @param {CreateRenterReviewTrackingRecordCommand} [createRenterReviewTrackingRecordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand?: CreateRenterReviewTrackingRecordCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Records a review event.
         * @param {string} [referenceCode] 
         * @param {number} [notificationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode, notificationNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewsOperatorApi - interface
 * @export
 * @interface ReviewsOperatorApi
 */
export interface ReviewsOperatorApiInterface {
    /**
     * 
     * @summary Gets the booking review detail based on booking reference code.
     * @param {string} [referenceCode] The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApiInterface
     */
    apiV1OperatorReviewsGet(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<GetRenterReviewResult>;

    /**
     * 
     * @summary Gets the number of pending reviews.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApiInterface
     */
    apiV1OperatorReviewsPendingCountGet(options?: AxiosRequestConfig): AxiosPromise<GetPendingRenterReviewsCountQueryResult>;

    /**
     * 
     * @summary Posts the booking review linked to booking by its reference code.
     * @param {FillInRenterReviewCommand} [fillInRenterReviewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApiInterface
     */
    apiV1OperatorReviewsPut(fillInRenterReviewCommand?: FillInRenterReviewCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Records a review event.
     * @param {CreateRenterReviewTrackingRecordCommand} [createRenterReviewTrackingRecordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApiInterface
     */
    apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand?: CreateRenterReviewTrackingRecordCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Records a review event.
     * @param {string} [referenceCode] 
     * @param {number} [notificationNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApiInterface
     */
    apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ReviewsOperatorApi - object-oriented interface
 * @export
 * @class ReviewsOperatorApi
 * @extends {BaseAPI}
 */
export class ReviewsOperatorApi extends BaseAPI implements ReviewsOperatorApiInterface {
    /**
     * 
     * @summary Gets the booking review detail based on booking reference code.
     * @param {string} [referenceCode] The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApi
     */
    public apiV1OperatorReviewsGet(referenceCode?: string, options?: AxiosRequestConfig) {
        return ReviewsOperatorApiFp(this.configuration).apiV1OperatorReviewsGet(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the number of pending reviews.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApi
     */
    public apiV1OperatorReviewsPendingCountGet(options?: AxiosRequestConfig) {
        return ReviewsOperatorApiFp(this.configuration).apiV1OperatorReviewsPendingCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Posts the booking review linked to booking by its reference code.
     * @param {FillInRenterReviewCommand} [fillInRenterReviewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApi
     */
    public apiV1OperatorReviewsPut(fillInRenterReviewCommand?: FillInRenterReviewCommand, options?: AxiosRequestConfig) {
        return ReviewsOperatorApiFp(this.configuration).apiV1OperatorReviewsPut(fillInRenterReviewCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Records a review event.
     * @param {CreateRenterReviewTrackingRecordCommand} [createRenterReviewTrackingRecordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApi
     */
    public apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand?: CreateRenterReviewTrackingRecordCommand, options?: AxiosRequestConfig) {
        return ReviewsOperatorApiFp(this.configuration).apiV1OperatorReviewsTrackingRecordsPost(createRenterReviewTrackingRecordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Records a review event.
     * @param {string} [referenceCode] 
     * @param {number} [notificationNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsOperatorApi
     */
    public apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode?: string, notificationNumber?: number, options?: AxiosRequestConfig) {
        return ReviewsOperatorApiFp(this.configuration).apiV1OperatorReviewsTrackingRecordsTrackingPixelJpgGet(referenceCode, notificationNumber, options).then((request) => request(this.axios, this.basePath));
    }
}

