/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddFwcBookingAddonsCommand } from '../models';
// @ts-ignore
import { BookingOfferOperatorCreateCommand } from '../models';
// @ts-ignore
import { BookingStatusQueryResult } from '../models';
// @ts-ignore
import { CalculateFwcBookingAddonsQuery } from '../models';
// @ts-ignore
import { CalculateFwcBookingAddonsQueryResult } from '../models';
// @ts-ignore
import { CalculateOperatorOfferCustomPriceQuery } from '../models';
// @ts-ignore
import { ListingRentalCalendarPriceCalculateQuery } from '../models';
// @ts-ignore
import { ListingRentalCalendarPriceCalculateQueryResult } from '../models';
// @ts-ignore
import { OperatorBookingItemQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { OperatorBookingOfferQueryResult } from '../models';
// @ts-ignore
import { OperatorBookingRequestQueryResult } from '../models';
// @ts-ignore
import { OperatorBookingReservationQueryResult } from '../models';
// @ts-ignore
import { OperatorBookingsCountQueryResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ReservationRegisterSecurityDepositRefundPaymentCommand } from '../models';
// @ts-ignore
import { SetSecurityDepositChargePaymentDetailsCommand } from '../models';
// @ts-ignore
import { SetSecurityDepositCustomerIbanCommand } from '../models';
// @ts-ignore
import { SetSecurityDepositRefundPaymentDetailsCommand } from '../models';
// @ts-ignore
import { UpdateBookingDropOffCommand } from '../models';
// @ts-ignore
import { UpdateBookingPickUpCommand } from '../models';
// @ts-ignore
import { UpdateBookingPickUpDropOffCommand } from '../models';
// @ts-ignore
import { UpdateBookingSecurityDepositCommand } from '../models';
/**
 * BookingOperatorApi - axios parameter creator
 * @export
 */
export const BookingOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of canceled bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsCanceledGet: async (pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/bookings/canceled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a count of bookings by its visibility status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/bookings/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {string} [vehicleGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdAssignVehiclePut: async (id: string, vehicleGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdAssignVehiclePut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/assign-vehicle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleGuid !== undefined) {
                localVarQueryParameter['vehicleGuid'] = vehicleGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate expected amount for addons selected.
         * @param {string} id The booking reference code.
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdCalculateAddonsPricePost: async (id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdCalculateAddonsPricePost', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/calculate-addons-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateFwcBookingAddonsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdCustomItemsGuidDelete: async (id: string, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdCustomItemsGuidDelete', 'id', id)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdCustomItemsGuidDelete', 'guid', guid)
            const localVarPath = `/api/v1/operator/bookings/{id}/custom-items/{guid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdCustomItemsPost: async (id: string, name?: string, price?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdCustomItemsPost', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/custom-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdDropOffPut: async (id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdDropOffPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/drop-off`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingDropOffCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the custom internal note of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [note] The internal note to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdNotePut: async (id: string, note?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdNotePut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the pick-up and drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdPickUpDropOffPut: async (id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdPickUpDropOffPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/pick-up-drop-off`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingPickUpDropOffCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the pick-up information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdPickUpPut: async (id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdPickUpPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/pick-up`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingPickUpCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes security deposit charge payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositChargePaymentDetailsCommand} [setSecurityDepositChargePaymentDetailsCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentChargePut: async (id: string, setSecurityDepositChargePaymentDetailsCommand?: SetSecurityDepositChargePaymentDetailsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentChargePut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/charge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSecurityDepositChargePaymentDetailsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the security deposit payment receipt picture for charge
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/charge-receipt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for charge
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut: async (id: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/charge-receipt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes security deposit charge payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositCustomerIbanCommand} [setSecurityDepositCustomerIbanCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut: async (id: string, setSecurityDepositCustomerIbanCommand?: SetSecurityDepositCustomerIbanCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/customer-iban`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSecurityDepositCustomerIbanCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for damage
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut: async (id: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/damage-receipt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes security deposit refund payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositRefundPaymentDetailsCommand} [setSecurityDepositRefundPaymentDetailsCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut: async (id: string, setSecurityDepositRefundPaymentDetailsCommand?: SetSecurityDepositRefundPaymentDetailsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/refund`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSecurityDepositRefundPaymentDetailsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the security deposit payment receipt picture for refund
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/refund-receipt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for refund
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut: async (id: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-payment/refund-receipt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the security deposit for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPut: async (id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositPut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingSecurityDepositCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the security deposit refunded
         * @param {string} id 
         * @param {ReservationRegisterSecurityDepositRefundPaymentCommand} [reservationRegisterSecurityDepositRefundPaymentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositRefundChargePut: async (id: string, reservationRegisterSecurityDepositRefundPaymentCommand?: ReservationRegisterSecurityDepositRefundPaymentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdSecurityDepositRefundChargePut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/security-deposit-refund/charge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationRegisterSecurityDepositRefundPaymentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdStateGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdStateGet', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdUnassignVehiclePut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorBookingsIdUnassignVehiclePut', 'id', id)
            const localVarPath = `/api/v1/operator/bookings/{id}/unassign-vehicle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsPastGet: async (pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/bookings/past`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of upcoming bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsUpcomingGet: async (pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/bookings/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (stationGuid !== undefined) {
                localVarQueryParameter['StationGuid'] = stationGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculates totals for custom pricing of offer.
         * @param {CalculateOperatorOfferCustomPriceQuery} [calculateOperatorOfferCustomPriceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersCalculateCustomPricePost: async (calculateOperatorOfferCustomPriceQuery?: CalculateOperatorOfferCustomPriceQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/offers/calculate-custom-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateOperatorOfferCustomPriceQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an offer price.
         * @param {number} [listingId] 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersCalculatePricePost: async (listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/offers/calculate-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (listingId !== undefined) {
                localVarQueryParameter['listingId'] = listingId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingRentalCalendarPriceCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels the booking offer.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersIdCancelPut: async (id: string, managerNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorOffersIdCancelPut', 'id', id)
            const localVarPath = `/api/v1/operator/offers/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerNote !== undefined) {
                localVarQueryParameter['managerNote'] = managerNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorOffersIdGet', 'id', id)
            const localVarPath = `/api/v1/operator/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new Booking offer
         * @param {BookingOfferOperatorCreateCommand} [bookingOfferOperatorCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersPost: async (bookingOfferOperatorCreateCommand?: BookingOfferOperatorCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingOfferOperatorCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the request state to approved.
         * @param {string} id The booking reference code identifier.
         * @param {string} [operatorNote] Note from operator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdApprovePost: async (id: string, operatorNote?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorRequestsIdApprovePost', 'id', id)
            const localVarPath = `/api/v1/operator/requests/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (operatorNote !== undefined) {
                localVarQueryParameter['operatorNote'] = operatorNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdExpirationPut: async (id: string, expiration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorRequestsIdExpirationPut', 'id', id)
            const localVarPath = `/api/v1/operator/requests/{id}/expiration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = (expiration as any instanceof Date) ?
                    (expiration as any).toISOString() :
                    expiration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorRequestsIdGet', 'id', id)
            const localVarPath = `/api/v1/operator/requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the request state to rejected.
         * @param {string} id The booking reference code identifier.
         * @param {string} [operatorNote] A note from operator.
         * @param {string} [reason] A reason for cancellation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdRejectPost: async (id: string, operatorNote?: string, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorRequestsIdRejectPost', 'id', id)
            const localVarPath = `/api/v1/operator/requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (operatorNote !== undefined) {
                localVarQueryParameter['operatorNote'] = operatorNote;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds reservation addons
         * @param {string} id 
         * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAddFwcAddonsPut: async (id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorReservationsIdAddFwcAddonsPut', 'id', id)
            const localVarPath = `/api/v1/operator/reservations/{id}/add-fwc-addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFwcBookingAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorReservationsIdGet', 'id', id)
            const localVarPath = `/api/v1/operator/reservations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingOperatorApi - functional programming interface
 * @export
 */
export const BookingOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of canceled bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsCanceledGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingItemQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsCanceledGet(pageOffset, pageSize, search, stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a count of bookings by its visibility status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingsCountQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {string} [vehicleGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdAssignVehiclePut(id, vehicleGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate expected amount for addons selected.
         * @param {string} id The booking reference code.
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateFwcBookingAddonsQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdCalculateAddonsPricePost(id, calculateFwcBookingAddonsQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdCustomItemsGuidDelete(id, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdCustomItemsPost(id: string, name?: string, price?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdCustomItemsPost(id, name, price, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdDropOffPut(id, updateBookingDropOffCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the custom internal note of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [note] The internal note to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdNotePut(id: string, note?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdNotePut(id, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the pick-up and drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdPickUpDropOffPut(id, updateBookingPickUpDropOffCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the pick-up information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdPickUpPut(id, updateBookingPickUpCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes security deposit charge payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositChargePaymentDetailsCommand} [setSecurityDepositChargePaymentDetailsCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id: string, setSecurityDepositChargePaymentDetailsCommand?: SetSecurityDepositChargePaymentDetailsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id, setSecurityDepositChargePaymentDetailsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the security deposit payment receipt picture for charge
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for charge
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes security deposit charge payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositCustomerIbanCommand} [setSecurityDepositCustomerIbanCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id: string, setSecurityDepositCustomerIbanCommand?: SetSecurityDepositCustomerIbanCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id, setSecurityDepositCustomerIbanCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for damage
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes security deposit refund payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositRefundPaymentDetailsCommand} [setSecurityDepositRefundPaymentDetailsCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id: string, setSecurityDepositRefundPaymentDetailsCommand?: SetSecurityDepositRefundPaymentDetailsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id, setSecurityDepositRefundPaymentDetailsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the security deposit payment receipt picture for refund
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for refund
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the security deposit for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositPut(id, updateBookingSecurityDepositCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the security deposit refunded
         * @param {string} id 
         * @param {ReservationRegisterSecurityDepositRefundPaymentCommand} [reservationRegisterSecurityDepositRefundPaymentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id: string, reservationRegisterSecurityDepositRefundPaymentCommand?: ReservationRegisterSecurityDepositRefundPaymentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id, reservationRegisterSecurityDepositRefundPaymentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdStateGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStatusQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdStateGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsIdUnassignVehiclePut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsIdUnassignVehiclePut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsPastGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingItemQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsPastGet(pageOffset, pageSize, search, stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of upcoming bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorBookingsUpcomingGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingItemQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorBookingsUpcomingGet(pageOffset, pageSize, search, stationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculates totals for custom pricing of offer.
         * @param {CalculateOperatorOfferCustomPriceQuery} [calculateOperatorOfferCustomPriceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery?: CalculateOperatorOfferCustomPriceQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an offer price.
         * @param {number} [listingId] 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOffersCalculatePricePost(listingId, listingRentalCalendarPriceCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels the booking offer.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOffersIdCancelPut(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOffersIdCancelPut(id, managerNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOffersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingOfferQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOffersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new Booking offer
         * @param {BookingOfferOperatorCreateCommand} [bookingOfferOperatorCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand?: BookingOfferOperatorCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the request state to approved.
         * @param {string} id The booking reference code identifier.
         * @param {string} [operatorNote] Note from operator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorRequestsIdApprovePost(id: string, operatorNote?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorRequestsIdApprovePost(id, operatorNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorRequestsIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorRequestsIdExpirationPut(id, expiration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorRequestsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingRequestQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the request state to rejected.
         * @param {string} id The booking reference code identifier.
         * @param {string} [operatorNote] A note from operator.
         * @param {string} [reason] A reason for cancellation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorRequestsIdRejectPost(id: string, operatorNote?: string, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorRequestsIdRejectPost(id, operatorNote, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds reservation addons
         * @param {string} id 
         * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsIdAddFwcAddonsPut(id, addFwcBookingAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorReservationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorBookingReservationQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorReservationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingOperatorApi - factory interface
 * @export
 */
export const BookingOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of canceled bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsCanceledGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: any): AxiosPromise<OperatorBookingItemQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorBookingsCanceledGet(pageOffset, pageSize, search, stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a count of bookings by its visibility status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsCountGet(options?: any): AxiosPromise<OperatorBookingsCountQueryResult> {
            return localVarFp.apiV1OperatorBookingsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {string} [vehicleGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdAssignVehiclePut(id, vehicleGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate expected amount for addons selected.
         * @param {string} id The booking reference code.
         * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: any): AxiosPromise<CalculateFwcBookingAddonsQueryResult> {
            return localVarFp.apiV1OperatorBookingsIdCalculateAddonsPricePost(id, calculateFwcBookingAddonsQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdCustomItemsGuidDelete(id, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a custom item to a booking.
         * @param {string} id 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdCustomItemsPost(id: string, name?: string, price?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdCustomItemsPost(id, name, price, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdDropOffPut(id, updateBookingDropOffCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the custom internal note of the booking.
         * @param {string} id The booking reference code.
         * @param {string} [note] The internal note to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdNotePut(id: string, note?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdNotePut(id, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the pick-up and drop-off information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdPickUpDropOffPut(id, updateBookingPickUpDropOffCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the pick-up information for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdPickUpPut(id, updateBookingPickUpCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes security deposit charge payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositChargePaymentDetailsCommand} [setSecurityDepositChargePaymentDetailsCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id: string, setSecurityDepositChargePaymentDetailsCommand?: SetSecurityDepositChargePaymentDetailsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id, setSecurityDepositChargePaymentDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the security deposit payment receipt picture for charge
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for charge
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes security deposit charge payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositCustomerIbanCommand} [setSecurityDepositCustomerIbanCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id: string, setSecurityDepositCustomerIbanCommand?: SetSecurityDepositCustomerIbanCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id, setSecurityDepositCustomerIbanCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for damage
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes security deposit refund payment details
         * @param {string} id The booking reference code.
         * @param {SetSecurityDepositRefundPaymentDetailsCommand} [setSecurityDepositRefundPaymentDetailsCommand] Command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id: string, setSecurityDepositRefundPaymentDetailsCommand?: SetSecurityDepositRefundPaymentDetailsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id, setSecurityDepositRefundPaymentDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the security deposit payment receipt picture for refund
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the security deposit payment receipt picture for refund
         * @param {string} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the security deposit for a booking.
         * @param {string} id The booking reference code.
         * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositPut(id, updateBookingSecurityDepositCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the security deposit refunded
         * @param {string} id 
         * @param {ReservationRegisterSecurityDepositRefundPaymentCommand} [reservationRegisterSecurityDepositRefundPaymentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id: string, reservationRegisterSecurityDepositRefundPaymentCommand?: ReservationRegisterSecurityDepositRefundPaymentCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id, reservationRegisterSecurityDepositRefundPaymentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking status information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdStateGet(id: string, options?: any): AxiosPromise<BookingStatusQueryResult> {
            return localVarFp.apiV1OperatorBookingsIdStateGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns booking to specific physical vehicle.
         * @param {string} id The booking reference code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsIdUnassignVehiclePut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorBookingsIdUnassignVehiclePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsPastGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: any): AxiosPromise<OperatorBookingItemQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorBookingsPastGet(pageOffset, pageSize, search, stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of upcoming bookings
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {string} [search] 
         * @param {string} [stationGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorBookingsUpcomingGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: any): AxiosPromise<OperatorBookingItemQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorBookingsUpcomingGet(pageOffset, pageSize, search, stationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculates totals for custom pricing of offer.
         * @param {CalculateOperatorOfferCustomPriceQuery} [calculateOperatorOfferCustomPriceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery?: CalculateOperatorOfferCustomPriceQuery, options?: any): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult> {
            return localVarFp.apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an offer price.
         * @param {number} [listingId] 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: any): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult> {
            return localVarFp.apiV1OperatorOffersCalculatePricePost(listingId, listingRentalCalendarPriceCalculateQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels the booking offer.
         * @param {string} id The booking reference code identifier.
         * @param {string} [managerNote] Optional manager note for customer.
         * @param {string} [reason] Reason for cancelling the booking.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersIdCancelPut(id: string, managerNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorOffersIdCancelPut(id, managerNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking offer information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersIdGet(id: string, options?: any): AxiosPromise<OperatorBookingOfferQueryResult> {
            return localVarFp.apiV1OperatorOffersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new Booking offer
         * @param {BookingOfferOperatorCreateCommand} [bookingOfferOperatorCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand?: BookingOfferOperatorCreateCommand, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the request state to approved.
         * @param {string} id The booking reference code identifier.
         * @param {string} [operatorNote] Note from operator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdApprovePost(id: string, operatorNote?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorRequestsIdApprovePost(id, operatorNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the booking\'s expiration date.
         * @param {string} id The booking reference code identifier.
         * @param {string} [expiration] New expiration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdExpirationPut(id: string, expiration?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorRequestsIdExpirationPut(id, expiration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking request information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdGet(id: string, options?: any): AxiosPromise<OperatorBookingRequestQueryResult> {
            return localVarFp.apiV1OperatorRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the request state to rejected.
         * @param {string} id The booking reference code identifier.
         * @param {string} [operatorNote] A note from operator.
         * @param {string} [reason] A reason for cancellation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorRequestsIdRejectPost(id: string, operatorNote?: string, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorRequestsIdRejectPost(id, operatorNote, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds reservation addons
         * @param {string} id 
         * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorReservationsIdAddFwcAddonsPut(id, addFwcBookingAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the booking reservation information.
         * @param {string} id The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorReservationsIdGet(id: string, options?: any): AxiosPromise<OperatorBookingReservationQueryResult> {
            return localVarFp.apiV1OperatorReservationsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingOperatorApi - interface
 * @export
 * @interface BookingOperatorApi
 */
export interface BookingOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of canceled bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsCanceledGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig): AxiosPromise<OperatorBookingItemQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a count of bookings by its visibility status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsCountGet(options?: AxiosRequestConfig): AxiosPromise<OperatorBookingsCountQueryResult>;

    /**
     * 
     * @summary Assigns booking to specific physical vehicle.
     * @param {string} id The booking reference code.
     * @param {string} [vehicleGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Calculate expected amount for addons selected.
     * @param {string} id The booking reference code.
     * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig): AxiosPromise<CalculateFwcBookingAddonsQueryResult>;

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {string} [name] 
     * @param {number} [price] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdCustomItemsPost(id: string, name?: string, price?: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the custom internal note of the booking.
     * @param {string} id The booking reference code.
     * @param {string} [note] The internal note to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdNotePut(id: string, note?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the pick-up and drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the pick-up information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes security deposit charge payment details
     * @param {string} id The booking reference code.
     * @param {SetSecurityDepositChargePaymentDetailsCommand} [setSecurityDepositChargePaymentDetailsCommand] Command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id: string, setSecurityDepositChargePaymentDetailsCommand?: SetSecurityDepositChargePaymentDetailsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes the security deposit payment receipt picture for charge
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the security deposit payment receipt picture for charge
     * @param {string} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Changes security deposit charge payment details
     * @param {string} id The booking reference code.
     * @param {SetSecurityDepositCustomerIbanCommand} [setSecurityDepositCustomerIbanCommand] Command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id: string, setSecurityDepositCustomerIbanCommand?: SetSecurityDepositCustomerIbanCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the security deposit payment receipt picture for damage
     * @param {string} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Changes security deposit refund payment details
     * @param {string} id The booking reference code.
     * @param {SetSecurityDepositRefundPaymentDetailsCommand} [setSecurityDepositRefundPaymentDetailsCommand] Command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id: string, setSecurityDepositRefundPaymentDetailsCommand?: SetSecurityDepositRefundPaymentDetailsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes the security deposit payment receipt picture for refund
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the security deposit payment receipt picture for refund
     * @param {string} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Updates the security deposit for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets the security deposit refunded
     * @param {string} id 
     * @param {ReservationRegisterSecurityDepositRefundPaymentCommand} [reservationRegisterSecurityDepositRefundPaymentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id: string, reservationRegisterSecurityDepositRefundPaymentCommand?: ReservationRegisterSecurityDepositRefundPaymentCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking status information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdStateGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingStatusQueryResult>;

    /**
     * 
     * @summary Assigns booking to specific physical vehicle.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsIdUnassignVehiclePut(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsPastGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig): AxiosPromise<OperatorBookingItemQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a list of upcoming bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorBookingsUpcomingGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig): AxiosPromise<OperatorBookingItemQueryResultPaginatedResult>;

    /**
     * 
     * @summary Calculates totals for custom pricing of offer.
     * @param {CalculateOperatorOfferCustomPriceQuery} [calculateOperatorOfferCustomPriceQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery?: CalculateOperatorOfferCustomPriceQuery, options?: AxiosRequestConfig): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>;

    /**
     * 
     * @summary Gets an offer price.
     * @param {number} [listingId] 
     * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>;

    /**
     * 
     * @summary Cancels the booking offer.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorOffersIdCancelPut(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking offer information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorOffersIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<OperatorBookingOfferQueryResult>;

    /**
     * 
     * @summary Creates new Booking offer
     * @param {BookingOfferOperatorCreateCommand} [bookingOfferOperatorCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand?: BookingOfferOperatorCreateCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Changes the request state to approved.
     * @param {string} id The booking reference code identifier.
     * @param {string} [operatorNote] Note from operator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorRequestsIdApprovePost(id: string, operatorNote?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Changes the booking\'s expiration date.
     * @param {string} id The booking reference code identifier.
     * @param {string} [expiration] New expiration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorRequestsIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking request information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorRequestsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<OperatorBookingRequestQueryResult>;

    /**
     * 
     * @summary Changes the request state to rejected.
     * @param {string} id The booking reference code identifier.
     * @param {string} [operatorNote] A note from operator.
     * @param {string} [reason] A reason for cancellation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorRequestsIdRejectPost(id: string, operatorNote?: string, reason?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Adds reservation addons
     * @param {string} id 
     * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApiInterface
     */
    apiV1OperatorReservationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<OperatorBookingReservationQueryResult>;

}

/**
 * BookingOperatorApi - object-oriented interface
 * @export
 * @class BookingOperatorApi
 * @extends {BaseAPI}
 */
export class BookingOperatorApi extends BaseAPI implements BookingOperatorApiInterface {
    /**
     * 
     * @summary Gets a list of canceled bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsCanceledGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsCanceledGet(pageOffset, pageSize, search, stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a count of bookings by its visibility status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsCountGet(options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns booking to specific physical vehicle.
     * @param {string} id The booking reference code.
     * @param {string} [vehicleGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdAssignVehiclePut(id: string, vehicleGuid?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdAssignVehiclePut(id, vehicleGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate expected amount for addons selected.
     * @param {string} id The booking reference code.
     * @param {CalculateFwcBookingAddonsQuery} [calculateFwcBookingAddonsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdCalculateAddonsPricePost(id: string, calculateFwcBookingAddonsQuery?: CalculateFwcBookingAddonsQuery, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdCalculateAddonsPricePost(id, calculateFwcBookingAddonsQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdCustomItemsGuidDelete(id: string, guid: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdCustomItemsGuidDelete(id, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a custom item to a booking.
     * @param {string} id 
     * @param {string} [name] 
     * @param {number} [price] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdCustomItemsPost(id: string, name?: string, price?: number, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdCustomItemsPost(id, name, price, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingDropOffCommand} [updateBookingDropOffCommand] The command object containing the updated drop-off information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdDropOffPut(id: string, updateBookingDropOffCommand?: UpdateBookingDropOffCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdDropOffPut(id, updateBookingDropOffCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the custom internal note of the booking.
     * @param {string} id The booking reference code.
     * @param {string} [note] The internal note to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdNotePut(id: string, note?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdNotePut(id, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the pick-up and drop-off information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpDropOffCommand} [updateBookingPickUpDropOffCommand] The command object containing the updated pick-up and drop-off information.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdPickUpDropOffPut(id: string, updateBookingPickUpDropOffCommand?: UpdateBookingPickUpDropOffCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdPickUpDropOffPut(id, updateBookingPickUpDropOffCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the pick-up information for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingPickUpCommand} [updateBookingPickUpCommand] The command object containing the updated pick-up information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdPickUpPut(id: string, updateBookingPickUpCommand?: UpdateBookingPickUpCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdPickUpPut(id, updateBookingPickUpCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes security deposit charge payment details
     * @param {string} id The booking reference code.
     * @param {SetSecurityDepositChargePaymentDetailsCommand} [setSecurityDepositChargePaymentDetailsCommand] Command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id: string, setSecurityDepositChargePaymentDetailsCommand?: SetSecurityDepositChargePaymentDetailsCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentChargePut(id, setSecurityDepositChargePaymentDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the security deposit payment receipt picture for charge
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the security deposit payment receipt picture for charge
     * @param {string} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id: string, file?: File, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentChargeReceiptPut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes security deposit charge payment details
     * @param {string} id The booking reference code.
     * @param {SetSecurityDepositCustomerIbanCommand} [setSecurityDepositCustomerIbanCommand] Command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id: string, setSecurityDepositCustomerIbanCommand?: SetSecurityDepositCustomerIbanCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentCustomerIbanPut(id, setSecurityDepositCustomerIbanCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the security deposit payment receipt picture for damage
     * @param {string} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id: string, file?: File, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentDamageReceiptPut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes security deposit refund payment details
     * @param {string} id The booking reference code.
     * @param {SetSecurityDepositRefundPaymentDetailsCommand} [setSecurityDepositRefundPaymentDetailsCommand] Command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id: string, setSecurityDepositRefundPaymentDetailsCommand?: SetSecurityDepositRefundPaymentDetailsCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentRefundPut(id, setSecurityDepositRefundPaymentDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the security deposit payment receipt picture for refund
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the security deposit payment receipt picture for refund
     * @param {string} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id: string, file?: File, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPaymentRefundReceiptPut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the security deposit for a booking.
     * @param {string} id The booking reference code.
     * @param {UpdateBookingSecurityDepositCommand} [updateBookingSecurityDepositCommand] The command object containing the updated security deposit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositPut(id: string, updateBookingSecurityDepositCommand?: UpdateBookingSecurityDepositCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositPut(id, updateBookingSecurityDepositCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the security deposit refunded
     * @param {string} id 
     * @param {ReservationRegisterSecurityDepositRefundPaymentCommand} [reservationRegisterSecurityDepositRefundPaymentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id: string, reservationRegisterSecurityDepositRefundPaymentCommand?: ReservationRegisterSecurityDepositRefundPaymentCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdSecurityDepositRefundChargePut(id, reservationRegisterSecurityDepositRefundPaymentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking status information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdStateGet(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdStateGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns booking to specific physical vehicle.
     * @param {string} id The booking reference code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsIdUnassignVehiclePut(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsIdUnassignVehiclePut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsPastGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsPastGet(pageOffset, pageSize, search, stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of upcoming bookings
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {string} [search] 
     * @param {string} [stationGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorBookingsUpcomingGet(pageOffset?: number, pageSize?: number, search?: string, stationGuid?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorBookingsUpcomingGet(pageOffset, pageSize, search, stationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculates totals for custom pricing of offer.
     * @param {CalculateOperatorOfferCustomPriceQuery} [calculateOperatorOfferCustomPriceQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery?: CalculateOperatorOfferCustomPriceQuery, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorOffersCalculateCustomPricePost(calculateOperatorOfferCustomPriceQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an offer price.
     * @param {number} [listingId] 
     * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorOffersCalculatePricePost(listingId?: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorOffersCalculatePricePost(listingId, listingRentalCalendarPriceCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels the booking offer.
     * @param {string} id The booking reference code identifier.
     * @param {string} [managerNote] Optional manager note for customer.
     * @param {string} [reason] Reason for cancelling the booking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorOffersIdCancelPut(id: string, managerNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorOffersIdCancelPut(id, managerNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking offer information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorOffersIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorOffersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new Booking offer
     * @param {BookingOfferOperatorCreateCommand} [bookingOfferOperatorCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand?: BookingOfferOperatorCreateCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorOffersPost(bookingOfferOperatorCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the request state to approved.
     * @param {string} id The booking reference code identifier.
     * @param {string} [operatorNote] Note from operator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorRequestsIdApprovePost(id: string, operatorNote?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorRequestsIdApprovePost(id, operatorNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the booking\'s expiration date.
     * @param {string} id The booking reference code identifier.
     * @param {string} [expiration] New expiration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorRequestsIdExpirationPut(id: string, expiration?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorRequestsIdExpirationPut(id, expiration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking request information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorRequestsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the request state to rejected.
     * @param {string} id The booking reference code identifier.
     * @param {string} [operatorNote] A note from operator.
     * @param {string} [reason] A reason for cancellation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorRequestsIdRejectPost(id: string, operatorNote?: string, reason?: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorRequestsIdRejectPost(id, operatorNote, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds reservation addons
     * @param {string} id 
     * @param {AddFwcBookingAddonsCommand} [addFwcBookingAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorReservationsIdAddFwcAddonsPut(id: string, addFwcBookingAddonsCommand?: AddFwcBookingAddonsCommand, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorReservationsIdAddFwcAddonsPut(id, addFwcBookingAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the booking reservation information.
     * @param {string} id The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingOperatorApi
     */
    public apiV1OperatorReservationsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingOperatorApiFp(this.configuration).apiV1OperatorReservationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

