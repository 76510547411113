/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CalendarPriceListingListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { VehicleRentalCalendarPriceAddCommand } from '../models';
/**
 * CalendarPriceOperatorApi - axios parameter creator
 * @export
 */
export const CalendarPriceOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [filterFrom] 
         * @param {string} [filterUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdCalendarPricesGet: async (id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdCalendarPricesGet', 'id', id)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdCalendarPricesGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdCalendarPricesGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/operator/listings/{id}/calendar-prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['FilterFrom'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString() :
                    filterFrom;
            }

            if (filterUntil !== undefined) {
                localVarQueryParameter['FilterUntil'] = (filterUntil as any instanceof Date) ?
                    (filterUntil as any).toISOString() :
                    filterUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] The command parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdCalendarPricesPut: async (id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OperatorListingsIdCalendarPricesPut', 'id', id)
            const localVarPath = `/api/v1/operator/listings/{id}/calendar-prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleRentalCalendarPriceAddCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarPriceOperatorApi - functional programming interface
 * @export
 */
export const CalendarPriceOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarPriceOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [filterFrom] 
         * @param {string} [filterUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarPriceListingListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsIdCalendarPricesGet(id, pageSize, pageNumber, filterFrom, filterUntil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] The command parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsIdCalendarPricesPut(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsIdCalendarPricesPut(id, vehicleRentalCalendarPriceAddCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarPriceOperatorApi - factory interface
 * @export
 */
export const CalendarPriceOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarPriceOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets listing calendar prices.
         * @param {number} id The listing identifier.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [filterFrom] 
         * @param {string} [filterUntil] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: any): AxiosPromise<CalendarPriceListingListQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsIdCalendarPricesGet(id, pageSize, pageNumber, filterFrom, filterUntil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a listing calendar price.
         * @param {number} id The listing identifier.
         * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] The command parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsIdCalendarPricesPut(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorListingsIdCalendarPricesPut(id, vehicleRentalCalendarPriceAddCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarPriceOperatorApi - interface
 * @export
 * @interface CalendarPriceOperatorApi
 */
export interface CalendarPriceOperatorApiInterface {
    /**
     * 
     * @summary Gets listing calendar prices.
     * @param {number} id The listing identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [filterFrom] 
     * @param {string} [filterUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarPriceOperatorApiInterface
     */
    apiV1OperatorListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: AxiosRequestConfig): AxiosPromise<CalendarPriceListingListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Adds a listing calendar price.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] The command parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarPriceOperatorApiInterface
     */
    apiV1OperatorListingsIdCalendarPricesPut(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

}

/**
 * CalendarPriceOperatorApi - object-oriented interface
 * @export
 * @class CalendarPriceOperatorApi
 * @extends {BaseAPI}
 */
export class CalendarPriceOperatorApi extends BaseAPI implements CalendarPriceOperatorApiInterface {
    /**
     * 
     * @summary Gets listing calendar prices.
     * @param {number} id The listing identifier.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [filterFrom] 
     * @param {string} [filterUntil] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarPriceOperatorApi
     */
    public apiV1OperatorListingsIdCalendarPricesGet(id: number, pageSize: number, pageNumber: number, filterFrom?: string, filterUntil?: string, options?: AxiosRequestConfig) {
        return CalendarPriceOperatorApiFp(this.configuration).apiV1OperatorListingsIdCalendarPricesGet(id, pageSize, pageNumber, filterFrom, filterUntil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a listing calendar price.
     * @param {number} id The listing identifier.
     * @param {VehicleRentalCalendarPriceAddCommand} [vehicleRentalCalendarPriceAddCommand] The command parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarPriceOperatorApi
     */
    public apiV1OperatorListingsIdCalendarPricesPut(id: number, vehicleRentalCalendarPriceAddCommand?: VehicleRentalCalendarPriceAddCommand, options?: AxiosRequestConfig) {
        return CalendarPriceOperatorApiFp(this.configuration).apiV1OperatorListingsIdCalendarPricesPut(id, vehicleRentalCalendarPriceAddCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

