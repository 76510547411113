/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ArticleCategoryType } from '../models';
// @ts-ignore
import { BlogCategoryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * BlogCategoryMarketplaceApi - axios parameter creator
 * @export
 */
export const BlogCategoryMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a category by type.
         * @param {ArticleCategoryType} categoryType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogCategoriesCategoryTypeGet: async (categoryType: ArticleCategoryType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryType' is not null or undefined
            assertParamExists('apiV1MarketplaceBlogCategoriesCategoryTypeGet', 'categoryType', categoryType)
            const localVarPath = `/api/v1/marketplace/blog/categories/{categoryType}`
                .replace(`{${"categoryType"}}`, encodeURIComponent(String(categoryType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of categories.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogCategoriesGet: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/blog/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogCategoryMarketplaceApi - functional programming interface
 * @export
 */
export const BlogCategoryMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogCategoryMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a category by type.
         * @param {ArticleCategoryType} categoryType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType: ArticleCategoryType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogCategoryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of categories.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceBlogCategoriesGet(query?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlogCategoryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceBlogCategoriesGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlogCategoryMarketplaceApi - factory interface
 * @export
 */
export const BlogCategoryMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogCategoryMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a category by type.
         * @param {ArticleCategoryType} categoryType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType: ArticleCategoryType, options?: any): AxiosPromise<BlogCategoryResult> {
            return localVarFp.apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of categories.
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceBlogCategoriesGet(query?: object, options?: any): AxiosPromise<Array<BlogCategoryResult>> {
            return localVarFp.apiV1MarketplaceBlogCategoriesGet(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlogCategoryMarketplaceApi - interface
 * @export
 * @interface BlogCategoryMarketplaceApi
 */
export interface BlogCategoryMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a category by type.
     * @param {ArticleCategoryType} categoryType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogCategoryMarketplaceApiInterface
     */
    apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType: ArticleCategoryType, options?: AxiosRequestConfig): AxiosPromise<BlogCategoryResult>;

    /**
     * 
     * @summary Gets a list of categories.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogCategoryMarketplaceApiInterface
     */
    apiV1MarketplaceBlogCategoriesGet(query?: object, options?: AxiosRequestConfig): AxiosPromise<Array<BlogCategoryResult>>;

}

/**
 * BlogCategoryMarketplaceApi - object-oriented interface
 * @export
 * @class BlogCategoryMarketplaceApi
 * @extends {BaseAPI}
 */
export class BlogCategoryMarketplaceApi extends BaseAPI implements BlogCategoryMarketplaceApiInterface {
    /**
     * 
     * @summary Gets a category by type.
     * @param {ArticleCategoryType} categoryType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogCategoryMarketplaceApi
     */
    public apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType: ArticleCategoryType, options?: AxiosRequestConfig) {
        return BlogCategoryMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogCategoriesCategoryTypeGet(categoryType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of categories.
     * @param {object} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogCategoryMarketplaceApi
     */
    public apiV1MarketplaceBlogCategoriesGet(query?: object, options?: AxiosRequestConfig) {
        return BlogCategoryMarketplaceApiFp(this.configuration).apiV1MarketplaceBlogCategoriesGet(query, options).then((request) => request(this.axios, this.basePath));
    }
}

