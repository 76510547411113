/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const GlobalPriceChangeFilterType = {
    NotSet: 'NotSet',
    Manual: 'Manual',
    Dynamic: 'Dynamic',
    All: 'All'
} as const;

export type GlobalPriceChangeFilterType = typeof GlobalPriceChangeFilterType[keyof typeof GlobalPriceChangeFilterType];



