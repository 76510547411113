/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GlobalPriceChangeByIdQueryResult } from '../models';
// @ts-ignore
import { GlobalPriceChangeCreateCommand } from '../models';
// @ts-ignore
import { GlobalPriceChangeListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { GlobalPriceChangeLogsQueryResult } from '../models';
// @ts-ignore
import { GlobalPriceChangeOverrideCreateCommand } from '../models';
// @ts-ignore
import { GlobalPriceChangeOverrideUpdateCommand } from '../models';
// @ts-ignore
import { GlobalPriceChangeState } from '../models';
// @ts-ignore
import { GlobalPriceChangeUpdateCommand } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * GlobalPriceChangeAdminApi - axios parameter creator
 * @export
 */
export const GlobalPriceChangeAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of global price changes.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [overrideAll] 
         * @param {string} [search] 
         * @param {string} [authorSearch] 
         * @param {GlobalPriceChangeState} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeGet: async (pageSize: number, pageNumber: number, overrideAll?: boolean, search?: string, authorSearch?: string, state?: GlobalPriceChangeState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/global-price-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (overrideAll !== undefined) {
                localVarQueryParameter['OverrideAll'] = overrideAll;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (authorSearch !== undefined) {
                localVarQueryParameter['AuthorSearch'] = authorSearch;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeIdDelete', 'id', id)
            const localVarPath = `/api/v1/admin/global-price-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/global-price-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdLogsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeIdLogsGet', 'id', id)
            const localVarPath = `/api/v1/admin/global-price-change/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing global price change with override all prop.
         * @param {number} id The global price change unique identifier.
         * @param {GlobalPriceChangeOverrideUpdateCommand} [globalPriceChangeOverrideUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdOverridePut: async (id: number, globalPriceChangeOverrideUpdateCommand?: GlobalPriceChangeOverrideUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeIdOverridePut', 'id', id)
            const localVarPath = `/api/v1/admin/global-price-change/{id}/override`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalPriceChangeOverrideUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing global price change
         * @param {number} id The global price change unique identifier.
         * @param {GlobalPriceChangeUpdateCommand} [globalPriceChangeUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdPut: async (id: number, globalPriceChangeUpdateCommand?: GlobalPriceChangeUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminGlobalPriceChangeIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/global-price-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalPriceChangeUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new global price change with override all prop.
         * @param {GlobalPriceChangeOverrideCreateCommand} [globalPriceChangeOverrideCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeOverridePost: async (globalPriceChangeOverrideCreateCommand?: GlobalPriceChangeOverrideCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/global-price-change/override`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalPriceChangeOverrideCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new global price change.
         * @param {GlobalPriceChangeCreateCommand} [globalPriceChangeCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangePost: async (globalPriceChangeCreateCommand?: GlobalPriceChangeCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/global-price-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalPriceChangeCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalPriceChangeAdminApi - functional programming interface
 * @export
 */
export const GlobalPriceChangeAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalPriceChangeAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of global price changes.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [overrideAll] 
         * @param {string} [search] 
         * @param {string} [authorSearch] 
         * @param {GlobalPriceChangeState} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeGet(pageSize: number, pageNumber: number, overrideAll?: boolean, search?: string, authorSearch?: string, state?: GlobalPriceChangeState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalPriceChangeListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeGet(pageSize, pageNumber, overrideAll, search, authorSearch, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalPriceChangeByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeIdLogsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalPriceChangeLogsQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeIdLogsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an existing global price change with override all prop.
         * @param {number} id The global price change unique identifier.
         * @param {GlobalPriceChangeOverrideUpdateCommand} [globalPriceChangeOverrideUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeIdOverridePut(id: number, globalPriceChangeOverrideUpdateCommand?: GlobalPriceChangeOverrideUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeIdOverridePut(id, globalPriceChangeOverrideUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an existing global price change
         * @param {number} id The global price change unique identifier.
         * @param {GlobalPriceChangeUpdateCommand} [globalPriceChangeUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeIdPut(id: number, globalPriceChangeUpdateCommand?: GlobalPriceChangeUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeIdPut(id, globalPriceChangeUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new global price change with override all prop.
         * @param {GlobalPriceChangeOverrideCreateCommand} [globalPriceChangeOverrideCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand?: GlobalPriceChangeOverrideCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new global price change.
         * @param {GlobalPriceChangeCreateCommand} [globalPriceChangeCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand?: GlobalPriceChangeCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalPriceChangeAdminApi - factory interface
 * @export
 */
export const GlobalPriceChangeAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalPriceChangeAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of global price changes.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [overrideAll] 
         * @param {string} [search] 
         * @param {string} [authorSearch] 
         * @param {GlobalPriceChangeState} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeGet(pageSize: number, pageNumber: number, overrideAll?: boolean, search?: string, authorSearch?: string, state?: GlobalPriceChangeState, options?: any): AxiosPromise<GlobalPriceChangeListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminGlobalPriceChangeGet(pageSize, pageNumber, overrideAll, search, authorSearch, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminGlobalPriceChangeIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdGet(id: number, options?: any): AxiosPromise<GlobalPriceChangeByIdQueryResult> {
            return localVarFp.apiV1AdminGlobalPriceChangeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the global price change entity based on its identifier.
         * @param {number} id The global price change unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdLogsGet(id: number, options?: any): AxiosPromise<Array<GlobalPriceChangeLogsQueryResult>> {
            return localVarFp.apiV1AdminGlobalPriceChangeIdLogsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing global price change with override all prop.
         * @param {number} id The global price change unique identifier.
         * @param {GlobalPriceChangeOverrideUpdateCommand} [globalPriceChangeOverrideUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdOverridePut(id: number, globalPriceChangeOverrideUpdateCommand?: GlobalPriceChangeOverrideUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminGlobalPriceChangeIdOverridePut(id, globalPriceChangeOverrideUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing global price change
         * @param {number} id The global price change unique identifier.
         * @param {GlobalPriceChangeUpdateCommand} [globalPriceChangeUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeIdPut(id: number, globalPriceChangeUpdateCommand?: GlobalPriceChangeUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminGlobalPriceChangeIdPut(id, globalPriceChangeUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new global price change with override all prop.
         * @param {GlobalPriceChangeOverrideCreateCommand} [globalPriceChangeOverrideCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand?: GlobalPriceChangeOverrideCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new global price change.
         * @param {GlobalPriceChangeCreateCommand} [globalPriceChangeCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand?: GlobalPriceChangeCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalPriceChangeAdminApi - interface
 * @export
 * @interface GlobalPriceChangeAdminApi
 */
export interface GlobalPriceChangeAdminApiInterface {
    /**
     * 
     * @summary Gets a list of global price changes.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {boolean} [overrideAll] 
     * @param {string} [search] 
     * @param {string} [authorSearch] 
     * @param {GlobalPriceChangeState} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeGet(pageSize: number, pageNumber: number, overrideAll?: boolean, search?: string, authorSearch?: string, state?: GlobalPriceChangeState, options?: AxiosRequestConfig): AxiosPromise<GlobalPriceChangeListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Deletes an existing global price change entity based on its identifier.
     * @param {number} id The global price change unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeIdDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the global price change entity based on its identifier.
     * @param {number} id The global price change unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<GlobalPriceChangeByIdQueryResult>;

    /**
     * 
     * @summary Gets the global price change entity based on its identifier.
     * @param {number} id The global price change unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeIdLogsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Array<GlobalPriceChangeLogsQueryResult>>;

    /**
     * 
     * @summary Updates an existing global price change with override all prop.
     * @param {number} id The global price change unique identifier.
     * @param {GlobalPriceChangeOverrideUpdateCommand} [globalPriceChangeOverrideUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeIdOverridePut(id: number, globalPriceChangeOverrideUpdateCommand?: GlobalPriceChangeOverrideUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates an existing global price change
     * @param {number} id The global price change unique identifier.
     * @param {GlobalPriceChangeUpdateCommand} [globalPriceChangeUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeIdPut(id: number, globalPriceChangeUpdateCommand?: GlobalPriceChangeUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new global price change with override all prop.
     * @param {GlobalPriceChangeOverrideCreateCommand} [globalPriceChangeOverrideCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand?: GlobalPriceChangeOverrideCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Creates a new global price change.
     * @param {GlobalPriceChangeCreateCommand} [globalPriceChangeCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApiInterface
     */
    apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand?: GlobalPriceChangeCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * GlobalPriceChangeAdminApi - object-oriented interface
 * @export
 * @class GlobalPriceChangeAdminApi
 * @extends {BaseAPI}
 */
export class GlobalPriceChangeAdminApi extends BaseAPI implements GlobalPriceChangeAdminApiInterface {
    /**
     * 
     * @summary Gets a list of global price changes.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {boolean} [overrideAll] 
     * @param {string} [search] 
     * @param {string} [authorSearch] 
     * @param {GlobalPriceChangeState} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeGet(pageSize: number, pageNumber: number, overrideAll?: boolean, search?: string, authorSearch?: string, state?: GlobalPriceChangeState, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeGet(pageSize, pageNumber, overrideAll, search, authorSearch, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing global price change entity based on its identifier.
     * @param {number} id The global price change unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeIdDelete(id: number, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the global price change entity based on its identifier.
     * @param {number} id The global price change unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeIdGet(id: number, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the global price change entity based on its identifier.
     * @param {number} id The global price change unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeIdLogsGet(id: number, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeIdLogsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing global price change with override all prop.
     * @param {number} id The global price change unique identifier.
     * @param {GlobalPriceChangeOverrideUpdateCommand} [globalPriceChangeOverrideUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeIdOverridePut(id: number, globalPriceChangeOverrideUpdateCommand?: GlobalPriceChangeOverrideUpdateCommand, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeIdOverridePut(id, globalPriceChangeOverrideUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing global price change
     * @param {number} id The global price change unique identifier.
     * @param {GlobalPriceChangeUpdateCommand} [globalPriceChangeUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeIdPut(id: number, globalPriceChangeUpdateCommand?: GlobalPriceChangeUpdateCommand, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeIdPut(id, globalPriceChangeUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new global price change with override all prop.
     * @param {GlobalPriceChangeOverrideCreateCommand} [globalPriceChangeOverrideCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand?: GlobalPriceChangeOverrideCreateCommand, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangeOverridePost(globalPriceChangeOverrideCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new global price change.
     * @param {GlobalPriceChangeCreateCommand} [globalPriceChangeCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPriceChangeAdminApi
     */
    public apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand?: GlobalPriceChangeCreateCommand, options?: AxiosRequestConfig) {
        return GlobalPriceChangeAdminApiFp(this.configuration).apiV1AdminGlobalPriceChangePost(globalPriceChangeCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

