/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ListCalendarDaysQuery } from '../models';
// @ts-ignore
import { ListCalendarDaysQueryResultListResult } from '../models';
// @ts-ignore
import { ListingDetailQueryResult } from '../models';
// @ts-ignore
import { ListingPublicOfferSearchQuery } from '../models';
// @ts-ignore
import { ListingRentalCalendarPriceCalculateQuery } from '../models';
// @ts-ignore
import { ListingRentalCalendarPriceCalculateQueryResult } from '../models';
// @ts-ignore
import { ListingSearchQuery } from '../models';
// @ts-ignore
import { ListingSearchQueryResult } from '../models';
// @ts-ignore
import { ListingSearchQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListingSlugsQueryResultListResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ListingMarketplaceApi - axios parameter creator
 * @export
 */
export const ListingMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the marketplace featured listings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsFeaturedPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/listings/featured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a listing price.
         * @param {number} id 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsIdCalculatePricePost: async (id: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceListingsIdCalculatePricePost', 'id', id)
            const localVarPath = `/api/v1/marketplace/listings/{id}/calculate-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingRentalCalendarPriceCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of calendar days with their availability and base price.
         * @param {number} id 
         * @param {ListCalendarDaysQuery} [listCalendarDaysQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsIdCalendarDaysPost: async (id: number, listCalendarDaysQuery?: ListCalendarDaysQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceListingsIdCalendarDaysPost', 'id', id)
            const localVarPath = `/api/v1/marketplace/listings/{id}/calendar-days`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCalendarDaysQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a listing detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MarketplaceListingsIdGet', 'id', id)
            const localVarPath = `/api/v1/marketplace/listings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches the marketplace listings special offers for public.
         * @param {ListingPublicOfferSearchQuery} [listingPublicOfferSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsPublicOffersSearchPost: async (listingPublicOfferSearchQuery?: ListingPublicOfferSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/listings/public-offers-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingPublicOfferSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches the marketplace listings filtered by provided parameters.
         * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsSearchPost: async (listingSearchQuery?: ListingSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/listings/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches the marketplace listings in a specific segment filtered by provided parameters.
         * @param {string} segment The segment (category, experience, region) to be searched.
         * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsSegmentPost: async (segment: string, listingSearchQuery?: ListingSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'segment' is not null or undefined
            assertParamExists('apiV1MarketplaceListingsSegmentPost', 'segment', segment)
            const localVarPath = `/api/v1/marketplace/listings/{segment}`
                .replace(`{${"segment"}}`, encodeURIComponent(String(segment)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of caravan slugs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsSlugsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/listings/slugs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingMarketplaceApi - functional programming interface
 * @export
 */
export const ListingMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the marketplace featured listings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsFeaturedPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListingSearchQueryResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsFeaturedPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a listing price.
         * @param {number} id 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsIdCalculatePricePost(id: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsIdCalculatePricePost(id, listingRentalCalendarPriceCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of calendar days with their availability and base price.
         * @param {number} id 
         * @param {ListCalendarDaysQuery} [listCalendarDaysQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsIdCalendarDaysPost(id: number, listCalendarDaysQuery?: ListCalendarDaysQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCalendarDaysQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsIdCalendarDaysPost(id, listCalendarDaysQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a listing detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingDetailQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches the marketplace listings special offers for public.
         * @param {ListingPublicOfferSearchQuery} [listingPublicOfferSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery?: ListingPublicOfferSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingSearchQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches the marketplace listings filtered by provided parameters.
         * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsSearchPost(listingSearchQuery?: ListingSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingSearchQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsSearchPost(listingSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches the marketplace listings in a specific segment filtered by provided parameters.
         * @param {string} segment The segment (category, experience, region) to be searched.
         * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsSegmentPost(segment: string, listingSearchQuery?: ListingSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingSearchQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsSegmentPost(segment, listingSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of caravan slugs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceListingsSlugsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingSlugsQueryResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceListingsSlugsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingMarketplaceApi - factory interface
 * @export
 */
export const ListingMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the marketplace featured listings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsFeaturedPost(options?: any): AxiosPromise<Array<ListingSearchQueryResult>> {
            return localVarFp.apiV1MarketplaceListingsFeaturedPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a listing price.
         * @param {number} id 
         * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsIdCalculatePricePost(id: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: any): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult> {
            return localVarFp.apiV1MarketplaceListingsIdCalculatePricePost(id, listingRentalCalendarPriceCalculateQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of calendar days with their availability and base price.
         * @param {number} id 
         * @param {ListCalendarDaysQuery} [listCalendarDaysQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsIdCalendarDaysPost(id: number, listCalendarDaysQuery?: ListCalendarDaysQuery, options?: any): AxiosPromise<ListCalendarDaysQueryResultListResult> {
            return localVarFp.apiV1MarketplaceListingsIdCalendarDaysPost(id, listCalendarDaysQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a listing detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsIdGet(id: number, options?: any): AxiosPromise<ListingDetailQueryResult> {
            return localVarFp.apiV1MarketplaceListingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches the marketplace listings special offers for public.
         * @param {ListingPublicOfferSearchQuery} [listingPublicOfferSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery?: ListingPublicOfferSearchQuery, options?: any): AxiosPromise<ListingSearchQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches the marketplace listings filtered by provided parameters.
         * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsSearchPost(listingSearchQuery?: ListingSearchQuery, options?: any): AxiosPromise<ListingSearchQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceListingsSearchPost(listingSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches the marketplace listings in a specific segment filtered by provided parameters.
         * @param {string} segment The segment (category, experience, region) to be searched.
         * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsSegmentPost(segment: string, listingSearchQuery?: ListingSearchQuery, options?: any): AxiosPromise<ListingSearchQueryResultPaginatedResult> {
            return localVarFp.apiV1MarketplaceListingsSegmentPost(segment, listingSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of caravan slugs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceListingsSlugsGet(options?: any): AxiosPromise<ListingSlugsQueryResultListResult> {
            return localVarFp.apiV1MarketplaceListingsSlugsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingMarketplaceApi - interface
 * @export
 * @interface ListingMarketplaceApi
 */
export interface ListingMarketplaceApiInterface {
    /**
     * 
     * @summary Returns the marketplace featured listings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsFeaturedPost(options?: AxiosRequestConfig): AxiosPromise<Array<ListingSearchQueryResult>>;

    /**
     * 
     * @summary Gets a listing price.
     * @param {number} id 
     * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsIdCalculatePricePost(id: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig): AxiosPromise<ListingRentalCalendarPriceCalculateQueryResult>;

    /**
     * 
     * @summary Gets a list of calendar days with their availability and base price.
     * @param {number} id 
     * @param {ListCalendarDaysQuery} [listCalendarDaysQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsIdCalendarDaysPost(id: number, listCalendarDaysQuery?: ListCalendarDaysQuery, options?: AxiosRequestConfig): AxiosPromise<ListCalendarDaysQueryResultListResult>;

    /**
     * 
     * @summary Gets a listing detailed information.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<ListingDetailQueryResult>;

    /**
     * 
     * @summary Searches the marketplace listings special offers for public.
     * @param {ListingPublicOfferSearchQuery} [listingPublicOfferSearchQuery] The listing filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery?: ListingPublicOfferSearchQuery, options?: AxiosRequestConfig): AxiosPromise<ListingSearchQueryResultPaginatedResult>;

    /**
     * 
     * @summary Searches the marketplace listings filtered by provided parameters.
     * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsSearchPost(listingSearchQuery?: ListingSearchQuery, options?: AxiosRequestConfig): AxiosPromise<ListingSearchQueryResultPaginatedResult>;

    /**
     * 
     * @summary Searches the marketplace listings in a specific segment filtered by provided parameters.
     * @param {string} segment The segment (category, experience, region) to be searched.
     * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsSegmentPost(segment: string, listingSearchQuery?: ListingSearchQuery, options?: AxiosRequestConfig): AxiosPromise<ListingSearchQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a list of caravan slugs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApiInterface
     */
    apiV1MarketplaceListingsSlugsGet(options?: AxiosRequestConfig): AxiosPromise<ListingSlugsQueryResultListResult>;

}

/**
 * ListingMarketplaceApi - object-oriented interface
 * @export
 * @class ListingMarketplaceApi
 * @extends {BaseAPI}
 */
export class ListingMarketplaceApi extends BaseAPI implements ListingMarketplaceApiInterface {
    /**
     * 
     * @summary Returns the marketplace featured listings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsFeaturedPost(options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsFeaturedPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a listing price.
     * @param {number} id 
     * @param {ListingRentalCalendarPriceCalculateQuery} [listingRentalCalendarPriceCalculateQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsIdCalculatePricePost(id: number, listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery, options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsIdCalculatePricePost(id, listingRentalCalendarPriceCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of calendar days with their availability and base price.
     * @param {number} id 
     * @param {ListCalendarDaysQuery} [listCalendarDaysQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsIdCalendarDaysPost(id: number, listCalendarDaysQuery?: ListCalendarDaysQuery, options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsIdCalendarDaysPost(id, listCalendarDaysQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a listing detailed information.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsIdGet(id: number, options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches the marketplace listings special offers for public.
     * @param {ListingPublicOfferSearchQuery} [listingPublicOfferSearchQuery] The listing filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery?: ListingPublicOfferSearchQuery, options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsPublicOffersSearchPost(listingPublicOfferSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches the marketplace listings filtered by provided parameters.
     * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsSearchPost(listingSearchQuery?: ListingSearchQuery, options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsSearchPost(listingSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches the marketplace listings in a specific segment filtered by provided parameters.
     * @param {string} segment The segment (category, experience, region) to be searched.
     * @param {ListingSearchQuery} [listingSearchQuery] The listing filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsSegmentPost(segment: string, listingSearchQuery?: ListingSearchQuery, options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsSegmentPost(segment, listingSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of caravan slugs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingMarketplaceApi
     */
    public apiV1MarketplaceListingsSlugsGet(options?: AxiosRequestConfig) {
        return ListingMarketplaceApiFp(this.configuration).apiV1MarketplaceListingsSlugsGet(options).then((request) => request(this.axios, this.basePath));
    }
}

