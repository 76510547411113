/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LanguageCode } from '../models';
// @ts-ignore
import { MediaImageResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * MediaAdminApi - axios parameter creator
 * @export
 */
export const MediaAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminMediaPicturesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminMediaPicturesIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/media/pictures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the media picture to storage and returns its GUID identifier.
         * @param {string} [description] The picture description.
         * @param {LanguageCode} [languageCode] The picture description language.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminMediaPicturesPost: async (description?: string, languageCode?: LanguageCode, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/media/pictures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaAdminApi - functional programming interface
 * @export
 */
export const MediaAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminMediaPicturesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminMediaPicturesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the media picture to storage and returns its GUID identifier.
         * @param {string} [description] The picture description.
         * @param {LanguageCode} [languageCode] The picture description language.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminMediaPicturesPost(description?: string, languageCode?: LanguageCode, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminMediaPicturesPost(description, languageCode, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaAdminApi - factory interface
 * @export
 */
export const MediaAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the media picture by its identifier.
         * @param {number} id Media identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminMediaPicturesIdGet(id: number, options?: any): AxiosPromise<MediaImageResponse> {
            return localVarFp.apiV1AdminMediaPicturesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the media picture to storage and returns its GUID identifier.
         * @param {string} [description] The picture description.
         * @param {LanguageCode} [languageCode] The picture description language.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminMediaPicturesPost(description?: string, languageCode?: LanguageCode, file?: File, options?: any): AxiosPromise<MediaImageResponse> {
            return localVarFp.apiV1AdminMediaPicturesPost(description, languageCode, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaAdminApi - interface
 * @export
 * @interface MediaAdminApi
 */
export interface MediaAdminApiInterface {
    /**
     * 
     * @summary Gets the media picture by its identifier.
     * @param {number} id Media identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaAdminApiInterface
     */
    apiV1AdminMediaPicturesIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<MediaImageResponse>;

    /**
     * 
     * @summary Uploads the media picture to storage and returns its GUID identifier.
     * @param {string} [description] The picture description.
     * @param {LanguageCode} [languageCode] The picture description language.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaAdminApiInterface
     */
    apiV1AdminMediaPicturesPost(description?: string, languageCode?: LanguageCode, file?: File, options?: AxiosRequestConfig): AxiosPromise<MediaImageResponse>;

}

/**
 * MediaAdminApi - object-oriented interface
 * @export
 * @class MediaAdminApi
 * @extends {BaseAPI}
 */
export class MediaAdminApi extends BaseAPI implements MediaAdminApiInterface {
    /**
     * 
     * @summary Gets the media picture by its identifier.
     * @param {number} id Media identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaAdminApi
     */
    public apiV1AdminMediaPicturesIdGet(id: number, options?: AxiosRequestConfig) {
        return MediaAdminApiFp(this.configuration).apiV1AdminMediaPicturesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the media picture to storage and returns its GUID identifier.
     * @param {string} [description] The picture description.
     * @param {LanguageCode} [languageCode] The picture description language.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaAdminApi
     */
    public apiV1AdminMediaPicturesPost(description?: string, languageCode?: LanguageCode, file?: File, options?: AxiosRequestConfig) {
        return MediaAdminApiFp(this.configuration).apiV1AdminMediaPicturesPost(description, languageCode, file, options).then((request) => request(this.axios, this.basePath));
    }
}

