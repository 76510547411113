/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CICOChecklistResult } from '../models';
// @ts-ignore
import { CheckInRequiredVehicleCheckPictureResultListResult } from '../models';
// @ts-ignore
import { CheckInResult } from '../models';
// @ts-ignore
import { CheckInVehicleResultListResult } from '../models';
// @ts-ignore
import { CicoProtocolDocumentFileResult } from '../models';
// @ts-ignore
import { CompleteCheckInCommand } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { SetCheckInChecklistItemsCommand } from '../models';
// @ts-ignore
import { SetCheckInHandoverCommand } from '../models';
// @ts-ignore
import { SetCheckInKycRequestCommand } from '../models';
// @ts-ignore
import { SummaryCheckInCommand } from '../models';
// @ts-ignore
import { UpdateCheckInDataCommand } from '../models';
// @ts-ignore
import { UpdateCheckInNoteCommand } from '../models';
// @ts-ignore
import { UpdateCheckInProtocolSignedCommand } from '../models';
// @ts-ignore
import { UpdateFwcCheckInAddonsCommand } from '../models';
// @ts-ignore
import { VehicleCheckPictureType } from '../models';
/**
 * CheckInOperatorApi - axios parameter creator
 * @export
 */
export const CheckInOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes check-in billing picture.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidBillingReceiptDelete: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidBillingReceiptDelete', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/billing-receipt`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads check-in billing picture.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidBillingReceiptPut: async (guid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidBillingReceiptPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/billing-receipt`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the checklist for a check-in
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidChecklistGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidChecklistGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/checklist`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in\'s checklist data.
         * @param {string} guid 
         * @param {SetCheckInChecklistItemsCommand} [setCheckInChecklistItemsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidChecklistPut: async (guid: string, setCheckInChecklistItemsCommand?: SetCheckInChecklistItemsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidChecklistPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/checklist`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCheckInChecklistItemsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets check-in\'s state
         * @param {string} guid Guid of the check-in
         * @param {CompleteCheckInCommand} [completeCheckInCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidCompletePost: async (guid: string, completeCheckInCommand?: CompleteCheckInCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidCompletePost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/complete`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeCheckInCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns a vehicle damage to the check in
         * @param {string} guid Guid of the check-in
         * @param {string} [damageGuid] Vehicle damage global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidDamagesPost: async (guid: string, damageGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidDamagesPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/damages`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (damageGuid !== undefined) {
                localVarQueryParameter['damageGuid'] = damageGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the drop off unavailabilities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidDropoffUnavailabilityGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidDropoffUnavailabilityGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/dropoff-unavailability`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the check in addons
         * @param {string} guid 
         * @param {UpdateFwcCheckInAddonsCommand} [updateFwcCheckInAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidFwcAddonsPut: async (guid: string, updateFwcCheckInAddonsCommand?: UpdateFwcCheckInAddonsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidFwcAddonsPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/fwc-addons`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFwcCheckInAddonsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the check-in data
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in\'s handover data.
         * @param {string} guid Guid of the check-in.
         * @param {SetCheckInHandoverCommand} [setCheckInHandoverCommand] Handover data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidHandoverPut: async (guid: string, setCheckInHandoverCommand?: SetCheckInHandoverCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidHandoverPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/handover`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCheckInHandoverCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the check-in related KYC requests.
         * @param {string} guid 
         * @param {SetCheckInKycRequestCommand} [setCheckInKycRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidKycRequestPut: async (guid: string, setCheckInKycRequestCommand?: SetCheckInKycRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidKycRequestPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/kyc-request`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCheckInKycRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in note
         * @param {string} guid Guid of the check-in.
         * @param {UpdateCheckInNoteCommand} [updateCheckInNoteCommand] Check-in note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidNotePut: async (guid: string, updateCheckInNoteCommand?: UpdateCheckInNoteCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidNotePut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/note`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckInNoteCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes check-in vehicle\'s picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPicturesPictureGuidDelete: async (guid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPicturesPictureGuidDelete', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates check-in vehicle\'s picture.
         * @param {string} guid Guid of the check-in
         * @param {string} pictureGuid 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPicturesPictureGuidPut: async (guid: string, pictureGuid: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPicturesPictureGuidPut', 'guid', guid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPicturesPictureGuidPut', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads check-in vehicle\'s picture.
         * @param {string} guid Guid of the check-in
         * @param {VehicleCheckPictureType} type Picture type
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPicturesTypePost: async (guid: string, type: VehicleCheckPictureType, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPicturesTypePost', 'guid', guid)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPicturesTypePost', 'type', type)
            const localVarPath = `/api/v1/operator/check-in/{guid}/pictures/{type}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes check-in protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolDocumentDelete: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidProtocolDocumentDelete', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/protocol/document`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns check-in protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolDocumentGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidProtocolDocumentGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/protocol/document`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads check-in signed protocol document.
         * @param {string} guid Guid of the check-in
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolDocumentPut: async (guid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidProtocolDocumentPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/protocol/document`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidProtocolGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/protocol`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolHtmlGet: async (guid: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidProtocolHtmlGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/protocol/html`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets check-in\'s protocol signed.
         * @param {string} guid 
         * @param {UpdateCheckInProtocolSignedCommand} [updateCheckInProtocolSignedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolSignedPut: async (guid: string, updateCheckInProtocolSignedCommand?: UpdateCheckInProtocolSignedCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidProtocolSignedPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/protocol/signed`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckInProtocolSignedCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in.
         * @param {string} guid Guid of the check-in.
         * @param {UpdateCheckInDataCommand} [updateCheckInDataCommand] Check-in data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPut: async (guid: string, updateCheckInDataCommand?: UpdateCheckInDataCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckInDataCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the required check in vehicle check pictures
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidRequiredPicturesGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidRequiredPicturesGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/required-pictures`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the check-in\'s billings data.
         * @param {string} guid Guid of the check-in
         * @param {SummaryCheckInCommand} [summaryCheckInCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidSummaryPut: async (guid: string, summaryCheckInCommand?: SummaryCheckInCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidSummaryPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/summary`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(summaryCheckInCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns a vehicle to a check-in
         * @param {string} guid Guid of the check-in
         * @param {string} [vehicleGuid] Vehicle global identifier
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidVehiclesAssignPost: async (guid: string, vehicleGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidVehiclesAssignPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/vehicles/assign`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleGuid !== undefined) {
                localVarQueryParameter['vehicleGuid'] = vehicleGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of available vehicles for given check-in
         * @param {string} guid Guid of the check-in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidVehiclesGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorCheckInGuidVehiclesGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/check-in/{guid}/vehicles`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initializes a check-in
         * @param {string} [referenceCode] The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInPost: async (referenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/check-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceCode !== undefined) {
                localVarQueryParameter['referenceCode'] = referenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckInOperatorApi - functional programming interface
 * @export
 */
export const CheckInOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckInOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes check-in billing picture.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidBillingReceiptDelete(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidBillingReceiptDelete(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads check-in billing picture.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidBillingReceiptPut(guid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidBillingReceiptPut(guid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the checklist for a check-in
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidChecklistGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CICOChecklistResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidChecklistGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in\'s checklist data.
         * @param {string} guid 
         * @param {SetCheckInChecklistItemsCommand} [setCheckInChecklistItemsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidChecklistPut(guid: string, setCheckInChecklistItemsCommand?: SetCheckInChecklistItemsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidChecklistPut(guid, setCheckInChecklistItemsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets check-in\'s state
         * @param {string} guid Guid of the check-in
         * @param {CompleteCheckInCommand} [completeCheckInCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidCompletePost(guid: string, completeCheckInCommand?: CompleteCheckInCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidCompletePost(guid, completeCheckInCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns a vehicle damage to the check in
         * @param {string} guid Guid of the check-in
         * @param {string} [damageGuid] Vehicle damage global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidDamagesPost(guid: string, damageGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidDamagesPost(guid, damageGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the drop off unavailabilities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the check in addons
         * @param {string} guid 
         * @param {UpdateFwcCheckInAddonsCommand} [updateFwcCheckInAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidFwcAddonsPut(guid: string, updateFwcCheckInAddonsCommand?: UpdateFwcCheckInAddonsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidFwcAddonsPut(guid, updateFwcCheckInAddonsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the check-in data
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckInResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in\'s handover data.
         * @param {string} guid Guid of the check-in.
         * @param {SetCheckInHandoverCommand} [setCheckInHandoverCommand] Handover data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidHandoverPut(guid: string, setCheckInHandoverCommand?: SetCheckInHandoverCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidHandoverPut(guid, setCheckInHandoverCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the check-in related KYC requests.
         * @param {string} guid 
         * @param {SetCheckInKycRequestCommand} [setCheckInKycRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidKycRequestPut(guid: string, setCheckInKycRequestCommand?: SetCheckInKycRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidKycRequestPut(guid, setCheckInKycRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in note
         * @param {string} guid Guid of the check-in.
         * @param {UpdateCheckInNoteCommand} [updateCheckInNoteCommand] Check-in note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidNotePut(guid: string, updateCheckInNoteCommand?: UpdateCheckInNoteCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidNotePut(guid, updateCheckInNoteCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes check-in vehicle\'s picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates check-in vehicle\'s picture.
         * @param {string} guid Guid of the check-in
         * @param {string} pictureGuid 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid, pictureGuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads check-in vehicle\'s picture.
         * @param {string} guid Guid of the check-in
         * @param {VehicleCheckPictureType} type Picture type
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidPicturesTypePost(guid, type, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes check-in protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidProtocolDocumentDelete(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidProtocolDocumentDelete(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns check-in protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidProtocolDocumentGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CicoProtocolDocumentFileResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidProtocolDocumentGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads check-in signed protocol document.
         * @param {string} guid Guid of the check-in
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidProtocolDocumentPut(guid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidProtocolDocumentPut(guid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidProtocolGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidProtocolGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidProtocolHtmlGet(guid, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets check-in\'s protocol signed.
         * @param {string} guid 
         * @param {UpdateCheckInProtocolSignedCommand} [updateCheckInProtocolSignedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidProtocolSignedPut(guid: string, updateCheckInProtocolSignedCommand?: UpdateCheckInProtocolSignedCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidProtocolSignedPut(guid, updateCheckInProtocolSignedCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in.
         * @param {string} guid Guid of the check-in.
         * @param {UpdateCheckInDataCommand} [updateCheckInDataCommand] Check-in data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidPut(guid: string, updateCheckInDataCommand?: UpdateCheckInDataCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidPut(guid, updateCheckInDataCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the required check in vehicle check pictures
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidRequiredPicturesGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckInRequiredVehicleCheckPictureResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidRequiredPicturesGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the check-in\'s billings data.
         * @param {string} guid Guid of the check-in
         * @param {SummaryCheckInCommand} [summaryCheckInCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidSummaryPut(guid: string, summaryCheckInCommand?: SummaryCheckInCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidSummaryPut(guid, summaryCheckInCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns a vehicle to a check-in
         * @param {string} guid Guid of the check-in
         * @param {string} [vehicleGuid] Vehicle global identifier
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidVehiclesAssignPost(guid: string, vehicleGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidVehiclesAssignPost(guid, vehicleGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of available vehicles for given check-in
         * @param {string} guid Guid of the check-in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInGuidVehiclesGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckInVehicleResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInGuidVehiclesGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initializes a check-in
         * @param {string} [referenceCode] The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorCheckInPost(referenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorCheckInPost(referenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckInOperatorApi - factory interface
 * @export
 */
export const CheckInOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckInOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes check-in billing picture.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidBillingReceiptDelete(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidBillingReceiptDelete(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads check-in billing picture.
         * @param {string} guid 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidBillingReceiptPut(guid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckInGuidBillingReceiptPut(guid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the checklist for a check-in
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidChecklistGet(guid: string, options?: any): AxiosPromise<CICOChecklistResult> {
            return localVarFp.apiV1OperatorCheckInGuidChecklistGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in\'s checklist data.
         * @param {string} guid 
         * @param {SetCheckInChecklistItemsCommand} [setCheckInChecklistItemsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidChecklistPut(guid: string, setCheckInChecklistItemsCommand?: SetCheckInChecklistItemsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidChecklistPut(guid, setCheckInChecklistItemsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets check-in\'s state
         * @param {string} guid Guid of the check-in
         * @param {CompleteCheckInCommand} [completeCheckInCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidCompletePost(guid: string, completeCheckInCommand?: CompleteCheckInCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidCompletePost(guid, completeCheckInCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns a vehicle damage to the check in
         * @param {string} guid Guid of the check-in
         * @param {string} [damageGuid] Vehicle damage global identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidDamagesPost(guid: string, damageGuid?: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorCheckInGuidDamagesPost(guid, damageGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the drop off unavailabilities.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the check in addons
         * @param {string} guid 
         * @param {UpdateFwcCheckInAddonsCommand} [updateFwcCheckInAddonsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidFwcAddonsPut(guid: string, updateFwcCheckInAddonsCommand?: UpdateFwcCheckInAddonsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidFwcAddonsPut(guid, updateFwcCheckInAddonsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the check-in data
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidGet(guid: string, options?: any): AxiosPromise<CheckInResult> {
            return localVarFp.apiV1OperatorCheckInGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in\'s handover data.
         * @param {string} guid Guid of the check-in.
         * @param {SetCheckInHandoverCommand} [setCheckInHandoverCommand] Handover data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidHandoverPut(guid: string, setCheckInHandoverCommand?: SetCheckInHandoverCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidHandoverPut(guid, setCheckInHandoverCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the check-in related KYC requests.
         * @param {string} guid 
         * @param {SetCheckInKycRequestCommand} [setCheckInKycRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidKycRequestPut(guid: string, setCheckInKycRequestCommand?: SetCheckInKycRequestCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidKycRequestPut(guid, setCheckInKycRequestCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in note
         * @param {string} guid Guid of the check-in.
         * @param {UpdateCheckInNoteCommand} [updateCheckInNoteCommand] Check-in note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidNotePut(guid: string, updateCheckInNoteCommand?: UpdateCheckInNoteCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidNotePut(guid, updateCheckInNoteCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes check-in vehicle\'s picture.
         * @param {string} guid 
         * @param {string} pictureGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates check-in vehicle\'s picture.
         * @param {string} guid Guid of the check-in
         * @param {string} pictureGuid 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid, pictureGuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads check-in vehicle\'s picture.
         * @param {string} guid Guid of the check-in
         * @param {VehicleCheckPictureType} type Picture type
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckInGuidPicturesTypePost(guid, type, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes check-in protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolDocumentDelete(guid: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorCheckInGuidProtocolDocumentDelete(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns check-in protocol document.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolDocumentGet(guid: string, options?: any): AxiosPromise<CicoProtocolDocumentFileResult> {
            return localVarFp.apiV1OperatorCheckInGuidProtocolDocumentGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads check-in signed protocol document.
         * @param {string} guid Guid of the check-in
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolDocumentPut(guid: string, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidProtocolDocumentPut(guid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolGet(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidProtocolGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the check in protocol
         * @param {string} guid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidProtocolHtmlGet(guid, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets check-in\'s protocol signed.
         * @param {string} guid 
         * @param {UpdateCheckInProtocolSignedCommand} [updateCheckInProtocolSignedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidProtocolSignedPut(guid: string, updateCheckInProtocolSignedCommand?: UpdateCheckInProtocolSignedCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidProtocolSignedPut(guid, updateCheckInProtocolSignedCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in.
         * @param {string} guid Guid of the check-in.
         * @param {UpdateCheckInDataCommand} [updateCheckInDataCommand] Check-in data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidPut(guid: string, updateCheckInDataCommand?: UpdateCheckInDataCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidPut(guid, updateCheckInDataCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the required check in vehicle check pictures
         * @param {string} guid The check-in\&#39;s guid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidRequiredPicturesGet(guid: string, options?: any): AxiosPromise<CheckInRequiredVehicleCheckPictureResultListResult> {
            return localVarFp.apiV1OperatorCheckInGuidRequiredPicturesGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the check-in\'s billings data.
         * @param {string} guid Guid of the check-in
         * @param {SummaryCheckInCommand} [summaryCheckInCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidSummaryPut(guid: string, summaryCheckInCommand?: SummaryCheckInCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidSummaryPut(guid, summaryCheckInCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns a vehicle to a check-in
         * @param {string} guid Guid of the check-in
         * @param {string} [vehicleGuid] Vehicle global identifier
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidVehiclesAssignPost(guid: string, vehicleGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorCheckInGuidVehiclesAssignPost(guid, vehicleGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of available vehicles for given check-in
         * @param {string} guid Guid of the check-in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInGuidVehiclesGet(guid: string, options?: any): AxiosPromise<CheckInVehicleResultListResult> {
            return localVarFp.apiV1OperatorCheckInGuidVehiclesGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initializes a check-in
         * @param {string} [referenceCode] The booking reference code identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorCheckInPost(referenceCode?: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorCheckInPost(referenceCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckInOperatorApi - interface
 * @export
 * @interface CheckInOperatorApi
 */
export interface CheckInOperatorApiInterface {
    /**
     * 
     * @summary Deletes check-in billing picture.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidBillingReceiptDelete(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads check-in billing picture.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidBillingReceiptPut(guid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Returns the checklist for a check-in
     * @param {string} guid The check-in\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidChecklistGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CICOChecklistResult>;

    /**
     * 
     * @summary Updates the check-in\'s checklist data.
     * @param {string} guid 
     * @param {SetCheckInChecklistItemsCommand} [setCheckInChecklistItemsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidChecklistPut(guid: string, setCheckInChecklistItemsCommand?: SetCheckInChecklistItemsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets check-in\'s state
     * @param {string} guid Guid of the check-in
     * @param {CompleteCheckInCommand} [completeCheckInCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidCompletePost(guid: string, completeCheckInCommand?: CompleteCheckInCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Assigns a vehicle damage to the check in
     * @param {string} guid Guid of the check-in
     * @param {string} [damageGuid] Vehicle damage global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidDamagesPost(guid: string, damageGuid?: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Returns all the drop off unavailabilities.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @summary Update the check in addons
     * @param {string} guid 
     * @param {UpdateFwcCheckInAddonsCommand} [updateFwcCheckInAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidFwcAddonsPut(guid: string, updateFwcCheckInAddonsCommand?: UpdateFwcCheckInAddonsCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Returns all the check-in data
     * @param {string} guid The check-in\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CheckInResult>;

    /**
     * 
     * @summary Updates the check-in\'s handover data.
     * @param {string} guid Guid of the check-in.
     * @param {SetCheckInHandoverCommand} [setCheckInHandoverCommand] Handover data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidHandoverPut(guid: string, setCheckInHandoverCommand?: SetCheckInHandoverCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets the check-in related KYC requests.
     * @param {string} guid 
     * @param {SetCheckInKycRequestCommand} [setCheckInKycRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidKycRequestPut(guid: string, setCheckInKycRequestCommand?: SetCheckInKycRequestCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the check-in note
     * @param {string} guid Guid of the check-in.
     * @param {UpdateCheckInNoteCommand} [updateCheckInNoteCommand] Check-in note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidNotePut(guid: string, updateCheckInNoteCommand?: UpdateCheckInNoteCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Removes check-in vehicle\'s picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Updates check-in vehicle\'s picture.
     * @param {string} guid Guid of the check-in
     * @param {string} pictureGuid 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Uploads check-in vehicle\'s picture.
     * @param {string} guid Guid of the check-in
     * @param {VehicleCheckPictureType} type Picture type
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Deletes check-in protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidProtocolDocumentDelete(guid: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Returns check-in protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidProtocolDocumentGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CicoProtocolDocumentFileResult>;

    /**
     * 
     * @summary Uploads check-in signed protocol document.
     * @param {string} guid Guid of the check-in
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidProtocolDocumentPut(guid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the check in protocol
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidProtocolGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets the check in protocol
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sets check-in\'s protocol signed.
     * @param {string} guid 
     * @param {UpdateCheckInProtocolSignedCommand} [updateCheckInProtocolSignedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidProtocolSignedPut(guid: string, updateCheckInProtocolSignedCommand?: UpdateCheckInProtocolSignedCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the check-in.
     * @param {string} guid Guid of the check-in.
     * @param {UpdateCheckInDataCommand} [updateCheckInDataCommand] Check-in data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidPut(guid: string, updateCheckInDataCommand?: UpdateCheckInDataCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Returns the required check in vehicle check pictures
     * @param {string} guid The check-in\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidRequiredPicturesGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CheckInRequiredVehicleCheckPictureResultListResult>;

    /**
     * 
     * @summary Updates the check-in\'s billings data.
     * @param {string} guid Guid of the check-in
     * @param {SummaryCheckInCommand} [summaryCheckInCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidSummaryPut(guid: string, summaryCheckInCommand?: SummaryCheckInCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Assigns a vehicle to a check-in
     * @param {string} guid Guid of the check-in
     * @param {string} [vehicleGuid] Vehicle global identifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidVehiclesAssignPost(guid: string, vehicleGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a list of available vehicles for given check-in
     * @param {string} guid Guid of the check-in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInGuidVehiclesGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<CheckInVehicleResultListResult>;

    /**
     * 
     * @summary Initializes a check-in
     * @param {string} [referenceCode] The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApiInterface
     */
    apiV1OperatorCheckInPost(referenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

}

/**
 * CheckInOperatorApi - object-oriented interface
 * @export
 * @class CheckInOperatorApi
 * @extends {BaseAPI}
 */
export class CheckInOperatorApi extends BaseAPI implements CheckInOperatorApiInterface {
    /**
     * 
     * @summary Deletes check-in billing picture.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidBillingReceiptDelete(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidBillingReceiptDelete(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads check-in billing picture.
     * @param {string} guid 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidBillingReceiptPut(guid: string, file?: File, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidBillingReceiptPut(guid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the checklist for a check-in
     * @param {string} guid The check-in\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidChecklistGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidChecklistGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in\'s checklist data.
     * @param {string} guid 
     * @param {SetCheckInChecklistItemsCommand} [setCheckInChecklistItemsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidChecklistPut(guid: string, setCheckInChecklistItemsCommand?: SetCheckInChecklistItemsCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidChecklistPut(guid, setCheckInChecklistItemsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets check-in\'s state
     * @param {string} guid Guid of the check-in
     * @param {CompleteCheckInCommand} [completeCheckInCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidCompletePost(guid: string, completeCheckInCommand?: CompleteCheckInCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidCompletePost(guid, completeCheckInCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns a vehicle damage to the check in
     * @param {string} guid Guid of the check-in
     * @param {string} [damageGuid] Vehicle damage global identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidDamagesPost(guid: string, damageGuid?: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidDamagesPost(guid, damageGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the drop off unavailabilities.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidDropoffUnavailabilityGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the check in addons
     * @param {string} guid 
     * @param {UpdateFwcCheckInAddonsCommand} [updateFwcCheckInAddonsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidFwcAddonsPut(guid: string, updateFwcCheckInAddonsCommand?: UpdateFwcCheckInAddonsCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidFwcAddonsPut(guid, updateFwcCheckInAddonsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the check-in data
     * @param {string} guid The check-in\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in\'s handover data.
     * @param {string} guid Guid of the check-in.
     * @param {SetCheckInHandoverCommand} [setCheckInHandoverCommand] Handover data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidHandoverPut(guid: string, setCheckInHandoverCommand?: SetCheckInHandoverCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidHandoverPut(guid, setCheckInHandoverCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the check-in related KYC requests.
     * @param {string} guid 
     * @param {SetCheckInKycRequestCommand} [setCheckInKycRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidKycRequestPut(guid: string, setCheckInKycRequestCommand?: SetCheckInKycRequestCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidKycRequestPut(guid, setCheckInKycRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in note
     * @param {string} guid Guid of the check-in.
     * @param {UpdateCheckInNoteCommand} [updateCheckInNoteCommand] Check-in note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidNotePut(guid: string, updateCheckInNoteCommand?: UpdateCheckInNoteCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidNotePut(guid, updateCheckInNoteCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes check-in vehicle\'s picture.
     * @param {string} guid 
     * @param {string} pictureGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidPicturesPictureGuidDelete(guid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates check-in vehicle\'s picture.
     * @param {string} guid Guid of the check-in
     * @param {string} pictureGuid 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid: string, pictureGuid: string, body?: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidPicturesPictureGuidPut(guid, pictureGuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads check-in vehicle\'s picture.
     * @param {string} guid Guid of the check-in
     * @param {VehicleCheckPictureType} type Picture type
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidPicturesTypePost(guid: string, type: VehicleCheckPictureType, file?: File, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidPicturesTypePost(guid, type, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes check-in protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidProtocolDocumentDelete(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidProtocolDocumentDelete(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns check-in protocol document.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidProtocolDocumentGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidProtocolDocumentGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads check-in signed protocol document.
     * @param {string} guid Guid of the check-in
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidProtocolDocumentPut(guid: string, file?: File, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidProtocolDocumentPut(guid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the check in protocol
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidProtocolGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidProtocolGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the check in protocol
     * @param {string} guid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidProtocolHtmlGet(guid: string, accessToken?: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidProtocolHtmlGet(guid, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets check-in\'s protocol signed.
     * @param {string} guid 
     * @param {UpdateCheckInProtocolSignedCommand} [updateCheckInProtocolSignedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidProtocolSignedPut(guid: string, updateCheckInProtocolSignedCommand?: UpdateCheckInProtocolSignedCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidProtocolSignedPut(guid, updateCheckInProtocolSignedCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in.
     * @param {string} guid Guid of the check-in.
     * @param {UpdateCheckInDataCommand} [updateCheckInDataCommand] Check-in data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidPut(guid: string, updateCheckInDataCommand?: UpdateCheckInDataCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidPut(guid, updateCheckInDataCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the required check in vehicle check pictures
     * @param {string} guid The check-in\&#39;s guid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidRequiredPicturesGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidRequiredPicturesGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the check-in\'s billings data.
     * @param {string} guid Guid of the check-in
     * @param {SummaryCheckInCommand} [summaryCheckInCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidSummaryPut(guid: string, summaryCheckInCommand?: SummaryCheckInCommand, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidSummaryPut(guid, summaryCheckInCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns a vehicle to a check-in
     * @param {string} guid Guid of the check-in
     * @param {string} [vehicleGuid] Vehicle global identifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidVehiclesAssignPost(guid: string, vehicleGuid?: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidVehiclesAssignPost(guid, vehicleGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of available vehicles for given check-in
     * @param {string} guid Guid of the check-in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInGuidVehiclesGet(guid: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInGuidVehiclesGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initializes a check-in
     * @param {string} [referenceCode] The booking reference code identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOperatorApi
     */
    public apiV1OperatorCheckInPost(referenceCode?: string, options?: AxiosRequestConfig) {
        return CheckInOperatorApiFp(this.configuration).apiV1OperatorCheckInPost(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }
}

