/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateKycRequestCommandResult } from '../models';
// @ts-ignore
import { DocumentSide } from '../models';
// @ts-ignore
import { DocumentType } from '../models';
// @ts-ignore
import { KycRequestQueryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateAndApproveKycRequestCommand } from '../models';
/**
 * KycOperatorApi - axios parameter creator
 * @export
 */
export const KycOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the document from kyc request.
         * @param {string} guid 
         * @param {string} documentGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsDocumentGuidDelete: async (guid: string, documentGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidDocumentsDocumentGuidDelete', 'guid', guid)
            // verify required parameter 'documentGuid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidDocumentsDocumentGuidDelete', 'documentGuid', documentGuid)
            const localVarPath = `/api/v1/operator/kyc/{guid}/documents/{documentGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"documentGuid"}}`, encodeURIComponent(String(documentGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {string} documentGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsDocumentGuidGet: async (guid: string, documentGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidDocumentsDocumentGuidGet', 'guid', guid)
            // verify required parameter 'documentGuid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidDocumentsDocumentGuidGet', 'documentGuid', documentGuid)
            const localVarPath = `/api/v1/operator/kyc/{guid}/documents/{documentGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"documentGuid"}}`, encodeURIComponent(String(documentGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {DocumentType} [type] The type of document.
         * @param {DocumentSide} [side] The document side.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsGet: async (guid: string, type?: DocumentType, side?: DocumentSide, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidDocumentsGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/kyc/{guid}/documents`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (side !== undefined) {
                localVarQueryParameter['side'] = side;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads document to the request.
         * @param {string} guid 
         * @param {DocumentSide} [documentSide] 
         * @param {DocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsPut: async (guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidDocumentsPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/kyc/{guid}/documents`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentSide !== undefined) {
                localVarQueryParameter['documentSide'] = documentSide;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets kyc request by id.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/kyc/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the document from kyc request.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidVerifyDocumentsPost: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycGuidVerifyDocumentsPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/kyc/{guid}/verify-documents`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets latest kyc request of user.
         * @param {string} [userGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycLatestGet: async (userGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/kyc/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userGuid !== undefined) {
                localVarQueryParameter['userGuid'] = userGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates kyc request
         * @param {string} [userGuid] 
         * @param {string} [bookingReferenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycPost: async (userGuid?: string, bookingReferenceCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/kyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userGuid !== undefined) {
                localVarQueryParameter['userGuid'] = userGuid;
            }

            if (bookingReferenceCode !== undefined) {
                localVarQueryParameter['bookingReferenceCode'] = bookingReferenceCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates Kyc request applicant data, then approves the request.
         * @param {string} guid 
         * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycRequestGuidApprovePut: async (guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorKycRequestGuidApprovePut', 'guid', guid)
            const localVarPath = `/api/v1/operator/kyc/request/{guid}/approve`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAndApproveKycRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KycOperatorApi - functional programming interface
 * @export
 */
export const KycOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KycOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the document from kyc request.
         * @param {string} guid 
         * @param {string} documentGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid: string, documentGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid, documentGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {string} documentGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid: string, documentGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid, documentGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {DocumentType} [type] The type of document.
         * @param {DocumentSide} [side] The document side.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycGuidDocumentsGet(guid, type, side, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads document to the request.
         * @param {string} guid 
         * @param {DocumentSide} [documentSide] 
         * @param {DocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycGuidDocumentsPut(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycGuidDocumentsPut(guid, documentSide, documentType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets kyc request by id.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRequestQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the document from kyc request.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycGuidVerifyDocumentsPost(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycGuidVerifyDocumentsPost(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets latest kyc request of user.
         * @param {string} [userGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycLatestGet(userGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRequestQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycLatestGet(userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates kyc request
         * @param {string} [userGuid] 
         * @param {string} [bookingReferenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycPost(userGuid?: string, bookingReferenceCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateKycRequestCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycPost(userGuid, bookingReferenceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates Kyc request applicant data, then approves the request.
         * @param {string} guid 
         * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorKycRequestGuidApprovePut(guid, updateAndApproveKycRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KycOperatorApi - factory interface
 * @export
 */
export const KycOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KycOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the document from kyc request.
         * @param {string} guid 
         * @param {string} documentGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid: string, documentGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid, documentGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {string} documentGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid: string, documentGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid, documentGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a KYC uploaded document.
         * @param {string} guid 
         * @param {DocumentType} [type] The type of document.
         * @param {DocumentSide} [side] The document side.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorKycGuidDocumentsGet(guid, type, side, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads document to the request.
         * @param {string} guid 
         * @param {DocumentSide} [documentSide] 
         * @param {DocumentType} [documentType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidDocumentsPut(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorKycGuidDocumentsPut(guid, documentSide, documentType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets kyc request by id.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidGet(guid: string, options?: any): AxiosPromise<KycRequestQueryResult> {
            return localVarFp.apiV1OperatorKycGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the document from kyc request.
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycGuidVerifyDocumentsPost(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorKycGuidVerifyDocumentsPost(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets latest kyc request of user.
         * @param {string} [userGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycLatestGet(userGuid?: string, options?: any): AxiosPromise<KycRequestQueryResult> {
            return localVarFp.apiV1OperatorKycLatestGet(userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates kyc request
         * @param {string} [userGuid] 
         * @param {string} [bookingReferenceCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycPost(userGuid?: string, bookingReferenceCode?: string, options?: any): AxiosPromise<CreateKycRequestCommandResult> {
            return localVarFp.apiV1OperatorKycPost(userGuid, bookingReferenceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates Kyc request applicant data, then approves the request.
         * @param {string} guid 
         * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorKycRequestGuidApprovePut(guid, updateAndApproveKycRequestCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KycOperatorApi - interface
 * @export
 * @interface KycOperatorApi
 */
export interface KycOperatorApiInterface {
    /**
     * 
     * @summary Deletes the document from kyc request.
     * @param {string} guid 
     * @param {string} documentGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid: string, documentGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a KYC uploaded document.
     * @param {string} guid 
     * @param {string} documentGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid: string, documentGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a KYC uploaded document.
     * @param {string} guid 
     * @param {DocumentType} [type] The type of document.
     * @param {DocumentSide} [side] The document side.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads document to the request.
     * @param {string} guid 
     * @param {DocumentSide} [documentSide] 
     * @param {DocumentType} [documentType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycGuidDocumentsPut(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets kyc request by id.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<KycRequestQueryResult>;

    /**
     * 
     * @summary Deletes the document from kyc request.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycGuidVerifyDocumentsPost(guid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets latest kyc request of user.
     * @param {string} [userGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycLatestGet(userGuid?: string, options?: AxiosRequestConfig): AxiosPromise<KycRequestQueryResult>;

    /**
     * 
     * @summary Creates kyc request
     * @param {string} [userGuid] 
     * @param {string} [bookingReferenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycPost(userGuid?: string, bookingReferenceCode?: string, options?: AxiosRequestConfig): AxiosPromise<CreateKycRequestCommandResult>;

    /**
     * 
     * @summary Updates Kyc request applicant data, then approves the request.
     * @param {string} guid 
     * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApiInterface
     */
    apiV1OperatorKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * KycOperatorApi - object-oriented interface
 * @export
 * @class KycOperatorApi
 * @extends {BaseAPI}
 */
export class KycOperatorApi extends BaseAPI implements KycOperatorApiInterface {
    /**
     * 
     * @summary Deletes the document from kyc request.
     * @param {string} guid 
     * @param {string} documentGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid: string, documentGuid: string, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycGuidDocumentsDocumentGuidDelete(guid, documentGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a KYC uploaded document.
     * @param {string} guid 
     * @param {string} documentGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid: string, documentGuid: string, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycGuidDocumentsDocumentGuidGet(guid, documentGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a KYC uploaded document.
     * @param {string} guid 
     * @param {DocumentType} [type] The type of document.
     * @param {DocumentSide} [side] The document side.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycGuidDocumentsGet(guid: string, type?: DocumentType, side?: DocumentSide, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycGuidDocumentsGet(guid, type, side, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads document to the request.
     * @param {string} guid 
     * @param {DocumentSide} [documentSide] 
     * @param {DocumentType} [documentType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycGuidDocumentsPut(guid: string, documentSide?: DocumentSide, documentType?: DocumentType, file?: File, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycGuidDocumentsPut(guid, documentSide, documentType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets kyc request by id.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycGuidGet(guid: string, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the document from kyc request.
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycGuidVerifyDocumentsPost(guid: string, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycGuidVerifyDocumentsPost(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets latest kyc request of user.
     * @param {string} [userGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycLatestGet(userGuid?: string, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycLatestGet(userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates kyc request
     * @param {string} [userGuid] 
     * @param {string} [bookingReferenceCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycPost(userGuid?: string, bookingReferenceCode?: string, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycPost(userGuid, bookingReferenceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates Kyc request applicant data, then approves the request.
     * @param {string} guid 
     * @param {UpdateAndApproveKycRequestCommand} [updateAndApproveKycRequestCommand] Data of the request to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycOperatorApi
     */
    public apiV1OperatorKycRequestGuidApprovePut(guid: string, updateAndApproveKycRequestCommand?: UpdateAndApproveKycRequestCommand, options?: AxiosRequestConfig) {
        return KycOperatorApiFp(this.configuration).apiV1OperatorKycRequestGuidApprovePut(guid, updateAndApproveKycRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

