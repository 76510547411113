/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryCode } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { PromoCampaignCreateCommand } from '../models';
// @ts-ignore
import { PromoCampaignSetStatusCommand } from '../models';
// @ts-ignore
import { PromoCampaignStatsResult } from '../models';
// @ts-ignore
import { PromoCampaignStatus } from '../models';
// @ts-ignore
import { PromoCampaignUpdateCommand } from '../models';
// @ts-ignore
import { PromoCampaignsByIdQueryResult } from '../models';
// @ts-ignore
import { PromoCampaignsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { UpdatePromoCampaignPromoCodeDeactivatedCommand } from '../models';
/**
 * PromoCampaignAdminApi - axios parameter creator
 * @export
 */
export const PromoCampaignAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a paginated list of promo campaigns.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {PromoCampaignStatus} [status] 
         * @param {Array<CountryCode>} [regions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsGet: async (pageSize: number, pageNumber: number, search?: string, status?: PromoCampaignStatus, regions?: Array<CountryCode>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsGet', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/admin/promo-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (regions) {
                localVarQueryParameter['Regions'] = regions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a promo campaign detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/promo-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the promo code\'s deactivated value.
         * @param {number} id 
         * @param {string} code 
         * @param {UpdatePromoCampaignPromoCodeDeactivatedCommand} [updatePromoCampaignPromoCodeDeactivatedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut: async (id: number, code: string, updatePromoCampaignPromoCodeDeactivatedCommand?: UpdatePromoCampaignPromoCodeDeactivatedCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut', 'code', code)
            const localVarPath = `/api/v1/admin/promo-campaigns/{id}/promo-codes/{code}/deactivated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePromoCampaignPromoCodeDeactivatedCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the promo campaign.
         * @param {number} id 
         * @param {PromoCampaignUpdateCommand} [promoCampaignUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdPut: async (id: number, promoCampaignUpdateCommand?: PromoCampaignUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/promo-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoCampaignUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets stats for a promo campaign.
         * @param {number} id 
         * @param {string} [from] Reservation created &gt;&#x3D; from
         * @param {string} [to] 
         * @param {string} [compareWithFrom] Compare with created &gt;&#x3D; from
         * @param {string} [compareWithTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdStatsGet: async (id: number, from?: string, to?: string, compareWithFrom?: string, compareWithTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsIdStatsGet', 'id', id)
            const localVarPath = `/api/v1/admin/promo-campaigns/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (compareWithFrom !== undefined) {
                localVarQueryParameter['CompareWithFrom'] = (compareWithFrom as any instanceof Date) ?
                    (compareWithFrom as any).toISOString() :
                    compareWithFrom;
            }

            if (compareWithTo !== undefined) {
                localVarQueryParameter['CompareWithTo'] = (compareWithTo as any instanceof Date) ?
                    (compareWithTo as any).toISOString() :
                    compareWithTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the promo campaign status.
         * @param {number} id 
         * @param {PromoCampaignSetStatusCommand} [promoCampaignSetStatusCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdStatusPut: async (id: number, promoCampaignSetStatusCommand?: PromoCampaignSetStatusCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsIdStatusPut', 'id', id)
            const localVarPath = `/api/v1/admin/promo-campaigns/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoCampaignSetStatusCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new promo campaign.
         * @param {PromoCampaignCreateCommand} [promoCampaignCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsPost: async (promoCampaignCreateCommand?: PromoCampaignCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/promo-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoCampaignCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the promo code.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsPromoCodesCodeGet: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiV1AdminPromoCampaignsPromoCodesCodeGet', 'code', code)
            const localVarPath = `/api/v1/admin/promo-campaigns/promo-codes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromoCampaignAdminApi - functional programming interface
 * @export
 */
export const PromoCampaignAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromoCampaignAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of promo campaigns.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {PromoCampaignStatus} [status] 
         * @param {Array<CountryCode>} [regions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsGet(pageSize: number, pageNumber: number, search?: string, status?: PromoCampaignStatus, regions?: Array<CountryCode>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCampaignsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsGet(pageSize, pageNumber, search, status, regions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a promo campaign detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCampaignsByIdQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the promo code\'s deactivated value.
         * @param {number} id 
         * @param {string} code 
         * @param {UpdatePromoCampaignPromoCodeDeactivatedCommand} [updatePromoCampaignPromoCodeDeactivatedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id: number, code: string, updatePromoCampaignPromoCodeDeactivatedCommand?: UpdatePromoCampaignPromoCodeDeactivatedCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id, code, updatePromoCampaignPromoCodeDeactivatedCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the promo campaign.
         * @param {number} id 
         * @param {PromoCampaignUpdateCommand} [promoCampaignUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsIdPut(id: number, promoCampaignUpdateCommand?: PromoCampaignUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsIdPut(id, promoCampaignUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets stats for a promo campaign.
         * @param {number} id 
         * @param {string} [from] Reservation created &gt;&#x3D; from
         * @param {string} [to] 
         * @param {string} [compareWithFrom] Compare with created &gt;&#x3D; from
         * @param {string} [compareWithTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsIdStatsGet(id: number, from?: string, to?: string, compareWithFrom?: string, compareWithTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCampaignStatsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsIdStatsGet(id, from, to, compareWithFrom, compareWithTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the promo campaign status.
         * @param {number} id 
         * @param {PromoCampaignSetStatusCommand} [promoCampaignSetStatusCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsIdStatusPut(id: number, promoCampaignSetStatusCommand?: PromoCampaignSetStatusCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsIdStatusPut(id, promoCampaignSetStatusCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new promo campaign.
         * @param {PromoCampaignCreateCommand} [promoCampaignCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand?: PromoCampaignCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the promo code.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminPromoCampaignsPromoCodesCodeGet(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminPromoCampaignsPromoCodesCodeGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromoCampaignAdminApi - factory interface
 * @export
 */
export const PromoCampaignAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromoCampaignAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a paginated list of promo campaigns.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {PromoCampaignStatus} [status] 
         * @param {Array<CountryCode>} [regions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsGet(pageSize: number, pageNumber: number, search?: string, status?: PromoCampaignStatus, regions?: Array<CountryCode>, options?: any): AxiosPromise<PromoCampaignsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminPromoCampaignsGet(pageSize, pageNumber, search, status, regions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a promo campaign detailed information.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdGet(id: number, options?: any): AxiosPromise<PromoCampaignsByIdQueryResult> {
            return localVarFp.apiV1AdminPromoCampaignsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the promo code\'s deactivated value.
         * @param {number} id 
         * @param {string} code 
         * @param {UpdatePromoCampaignPromoCodeDeactivatedCommand} [updatePromoCampaignPromoCodeDeactivatedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id: number, code: string, updatePromoCampaignPromoCodeDeactivatedCommand?: UpdatePromoCampaignPromoCodeDeactivatedCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id, code, updatePromoCampaignPromoCodeDeactivatedCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the promo campaign.
         * @param {number} id 
         * @param {PromoCampaignUpdateCommand} [promoCampaignUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdPut(id: number, promoCampaignUpdateCommand?: PromoCampaignUpdateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminPromoCampaignsIdPut(id, promoCampaignUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets stats for a promo campaign.
         * @param {number} id 
         * @param {string} [from] Reservation created &gt;&#x3D; from
         * @param {string} [to] 
         * @param {string} [compareWithFrom] Compare with created &gt;&#x3D; from
         * @param {string} [compareWithTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdStatsGet(id: number, from?: string, to?: string, compareWithFrom?: string, compareWithTo?: string, options?: any): AxiosPromise<PromoCampaignStatsResult> {
            return localVarFp.apiV1AdminPromoCampaignsIdStatsGet(id, from, to, compareWithFrom, compareWithTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the promo campaign status.
         * @param {number} id 
         * @param {PromoCampaignSetStatusCommand} [promoCampaignSetStatusCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsIdStatusPut(id: number, promoCampaignSetStatusCommand?: PromoCampaignSetStatusCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminPromoCampaignsIdStatusPut(id, promoCampaignSetStatusCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new promo campaign.
         * @param {PromoCampaignCreateCommand} [promoCampaignCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand?: PromoCampaignCreateCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the promo code.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminPromoCampaignsPromoCodesCodeGet(code: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1AdminPromoCampaignsPromoCodesCodeGet(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromoCampaignAdminApi - interface
 * @export
 * @interface PromoCampaignAdminApi
 */
export interface PromoCampaignAdminApiInterface {
    /**
     * 
     * @summary Gets a paginated list of promo campaigns.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {PromoCampaignStatus} [status] 
     * @param {Array<CountryCode>} [regions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsGet(pageSize: number, pageNumber: number, search?: string, status?: PromoCampaignStatus, regions?: Array<CountryCode>, options?: AxiosRequestConfig): AxiosPromise<PromoCampaignsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets a promo campaign detailed information.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<PromoCampaignsByIdQueryResult>;

    /**
     * 
     * @summary Sets the promo code\'s deactivated value.
     * @param {number} id 
     * @param {string} code 
     * @param {UpdatePromoCampaignPromoCodeDeactivatedCommand} [updatePromoCampaignPromoCodeDeactivatedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id: number, code: string, updatePromoCampaignPromoCodeDeactivatedCommand?: UpdatePromoCampaignPromoCodeDeactivatedCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the promo campaign.
     * @param {number} id 
     * @param {PromoCampaignUpdateCommand} [promoCampaignUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsIdPut(id: number, promoCampaignUpdateCommand?: PromoCampaignUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets stats for a promo campaign.
     * @param {number} id 
     * @param {string} [from] Reservation created &gt;&#x3D; from
     * @param {string} [to] 
     * @param {string} [compareWithFrom] Compare with created &gt;&#x3D; from
     * @param {string} [compareWithTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsIdStatsGet(id: number, from?: string, to?: string, compareWithFrom?: string, compareWithTo?: string, options?: AxiosRequestConfig): AxiosPromise<PromoCampaignStatsResult>;

    /**
     * 
     * @summary Sets the promo campaign status.
     * @param {number} id 
     * @param {PromoCampaignSetStatusCommand} [promoCampaignSetStatusCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsIdStatusPut(id: number, promoCampaignSetStatusCommand?: PromoCampaignSetStatusCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Creates new promo campaign.
     * @param {PromoCampaignCreateCommand} [promoCampaignCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand?: PromoCampaignCreateCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets the promo code.
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApiInterface
     */
    apiV1AdminPromoCampaignsPromoCodesCodeGet(code: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

}

/**
 * PromoCampaignAdminApi - object-oriented interface
 * @export
 * @class PromoCampaignAdminApi
 * @extends {BaseAPI}
 */
export class PromoCampaignAdminApi extends BaseAPI implements PromoCampaignAdminApiInterface {
    /**
     * 
     * @summary Gets a paginated list of promo campaigns.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {PromoCampaignStatus} [status] 
     * @param {Array<CountryCode>} [regions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsGet(pageSize: number, pageNumber: number, search?: string, status?: PromoCampaignStatus, regions?: Array<CountryCode>, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsGet(pageSize, pageNumber, search, status, regions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a promo campaign detailed information.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsIdGet(id: number, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the promo code\'s deactivated value.
     * @param {number} id 
     * @param {string} code 
     * @param {UpdatePromoCampaignPromoCodeDeactivatedCommand} [updatePromoCampaignPromoCodeDeactivatedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id: number, code: string, updatePromoCampaignPromoCodeDeactivatedCommand?: UpdatePromoCampaignPromoCodeDeactivatedCommand, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsIdPromoCodesCodeDeactivatedPut(id, code, updatePromoCampaignPromoCodeDeactivatedCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the promo campaign.
     * @param {number} id 
     * @param {PromoCampaignUpdateCommand} [promoCampaignUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsIdPut(id: number, promoCampaignUpdateCommand?: PromoCampaignUpdateCommand, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsIdPut(id, promoCampaignUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets stats for a promo campaign.
     * @param {number} id 
     * @param {string} [from] Reservation created &gt;&#x3D; from
     * @param {string} [to] 
     * @param {string} [compareWithFrom] Compare with created &gt;&#x3D; from
     * @param {string} [compareWithTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsIdStatsGet(id: number, from?: string, to?: string, compareWithFrom?: string, compareWithTo?: string, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsIdStatsGet(id, from, to, compareWithFrom, compareWithTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the promo campaign status.
     * @param {number} id 
     * @param {PromoCampaignSetStatusCommand} [promoCampaignSetStatusCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsIdStatusPut(id: number, promoCampaignSetStatusCommand?: PromoCampaignSetStatusCommand, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsIdStatusPut(id, promoCampaignSetStatusCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new promo campaign.
     * @param {PromoCampaignCreateCommand} [promoCampaignCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand?: PromoCampaignCreateCommand, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsPost(promoCampaignCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the promo code.
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCampaignAdminApi
     */
    public apiV1AdminPromoCampaignsPromoCodesCodeGet(code: string, options?: AxiosRequestConfig) {
        return PromoCampaignAdminApiFp(this.configuration).apiV1AdminPromoCampaignsPromoCodesCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }
}

