/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateHelpCenterArticleCommand } from '../models';
// @ts-ignore
import { CreateHelpCenterArticleVariationCommand } from '../models';
// @ts-ignore
import { CreateHelpCenterArticleVariationWithArticleCommand } from '../models';
// @ts-ignore
import { HelpCenterArticleCategoryType } from '../models';
// @ts-ignore
import { HelpCenterArticleProductType } from '../models';
// @ts-ignore
import { HelpCenterArticleState } from '../models';
// @ts-ignore
import { HelpCenterArticleVariationQueryResult } from '../models';
// @ts-ignore
import { HelpCenterArticleVariationsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { LanguageCode } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateHelpCenterArticleCommand } from '../models';
// @ts-ignore
import { UpdateHelpCenterArticleVariationCommand } from '../models';
/**
 * HelpCenterArticleAdminApi - axios parameter creator
 * @export
 */
export const HelpCenterArticleAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateHelpCenterArticleVariationWithArticleCommand} [createHelpCenterArticleVariationWithArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesCreateWithVariationPost: async (createHelpCenterArticleVariationWithArticleCommand?: CreateHelpCenterArticleVariationWithArticleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/help-center/articles/create-with-variation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHelpCenterArticleVariationWithArticleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<LanguageCode>} [languages] 
         * @param {HelpCenterArticleState} [state] 
         * @param {Array<HelpCenterArticleProductType>} [productTypes] 
         * @param {Array<HelpCenterArticleCategoryType>} [categories] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesGet: async (pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: HelpCenterArticleState, productTypes?: Array<HelpCenterArticleProductType>, categories?: Array<HelpCenterArticleCategoryType>, guids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/help-center/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (languages) {
                localVarQueryParameter['Languages'] = languages;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }

            if (productTypes) {
                localVarQueryParameter['ProductTypes'] = productTypes;
            }

            if (categories) {
                localVarQueryParameter['Categories'] = categories;
            }

            if (guids) {
                localVarQueryParameter['Guids'] = guids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the article.
         * @param {number} id 
         * @param {UpdateHelpCenterArticleCommand} [updateHelpCenterArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdPut: async (id: number, updateHelpCenterArticleCommand?: UpdateHelpCenterArticleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/help-center/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHelpCenterArticleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguageGet: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguageGet', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguageGet', 'language', language)
            const localVarPath = `/api/v1/admin/help-center/articles/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut', 'language', language)
            const localVarPath = `/api/v1/admin/help-center/articles/{id}/variations/{language}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateHelpCenterArticleVariationCommand} [updateHelpCenterArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguagePut: async (id: number, language: LanguageCode, updateHelpCenterArticleVariationCommand?: UpdateHelpCenterArticleVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguagePut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguagePut', 'language', language)
            const localVarPath = `/api/v1/admin/help-center/articles/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHelpCenterArticleVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unpublishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut', 'language', language)
            const localVarPath = `/api/v1/admin/help-center/articles/{id}/variations/{language}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new article variation.
         * @param {number} id 
         * @param {CreateHelpCenterArticleVariationCommand} [createHelpCenterArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsPost: async (id: number, createHelpCenterArticleVariationCommand?: CreateHelpCenterArticleVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminHelpCenterArticlesIdVariationsPost', 'id', id)
            const localVarPath = `/api/v1/admin/help-center/articles/{id}/variations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHelpCenterArticleVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new article.
         * @param {CreateHelpCenterArticleCommand} [createHelpCenterArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesPost: async (createHelpCenterArticleCommand?: CreateHelpCenterArticleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/help-center/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHelpCenterArticleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpCenterArticleAdminApi - functional programming interface
 * @export
 */
export const HelpCenterArticleAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpCenterArticleAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateHelpCenterArticleVariationWithArticleCommand} [createHelpCenterArticleVariationWithArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand?: CreateHelpCenterArticleVariationWithArticleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<LanguageCode>} [languages] 
         * @param {HelpCenterArticleState} [state] 
         * @param {Array<HelpCenterArticleProductType>} [productTypes] 
         * @param {Array<HelpCenterArticleCategoryType>} [categories] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: HelpCenterArticleState, productTypes?: Array<HelpCenterArticleProductType>, categories?: Array<HelpCenterArticleCategoryType>, guids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCenterArticleVariationsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesGet(pageNumber, pageSize, search, languages, state, productTypes, categories, guids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the article.
         * @param {number} id 
         * @param {UpdateHelpCenterArticleCommand} [updateHelpCenterArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesIdPut(id: number, updateHelpCenterArticleCommand?: UpdateHelpCenterArticleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesIdPut(id, updateHelpCenterArticleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelpCenterArticleVariationQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateHelpCenterArticleVariationCommand} [updateHelpCenterArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateHelpCenterArticleVariationCommand?: UpdateHelpCenterArticleVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id, language, updateHelpCenterArticleVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unpublishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new article variation.
         * @param {number} id 
         * @param {CreateHelpCenterArticleVariationCommand} [createHelpCenterArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesIdVariationsPost(id: number, createHelpCenterArticleVariationCommand?: CreateHelpCenterArticleVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesIdVariationsPost(id, createHelpCenterArticleVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new article.
         * @param {CreateHelpCenterArticleCommand} [createHelpCenterArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand?: CreateHelpCenterArticleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpCenterArticleAdminApi - factory interface
 * @export
 */
export const HelpCenterArticleAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpCenterArticleAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateHelpCenterArticleVariationWithArticleCommand} [createHelpCenterArticleVariationWithArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand?: CreateHelpCenterArticleVariationWithArticleCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<LanguageCode>} [languages] 
         * @param {HelpCenterArticleState} [state] 
         * @param {Array<HelpCenterArticleProductType>} [productTypes] 
         * @param {Array<HelpCenterArticleCategoryType>} [categories] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: HelpCenterArticleState, productTypes?: Array<HelpCenterArticleProductType>, categories?: Array<HelpCenterArticleCategoryType>, guids?: Array<string>, options?: any): AxiosPromise<HelpCenterArticleVariationsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminHelpCenterArticlesGet(pageNumber, pageSize, search, languages, state, productTypes, categories, guids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the article.
         * @param {number} id 
         * @param {UpdateHelpCenterArticleCommand} [updateHelpCenterArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdPut(id: number, updateHelpCenterArticleCommand?: UpdateHelpCenterArticleCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminHelpCenterArticlesIdPut(id, updateHelpCenterArticleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: any): AxiosPromise<HelpCenterArticleVariationQueryResult> {
            return localVarFp.apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateHelpCenterArticleVariationCommand} [updateHelpCenterArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateHelpCenterArticleVariationCommand?: UpdateHelpCenterArticleVariationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id, language, updateHelpCenterArticleVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unpublishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new article variation.
         * @param {number} id 
         * @param {CreateHelpCenterArticleVariationCommand} [createHelpCenterArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesIdVariationsPost(id: number, createHelpCenterArticleVariationCommand?: CreateHelpCenterArticleVariationCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminHelpCenterArticlesIdVariationsPost(id, createHelpCenterArticleVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new article.
         * @param {CreateHelpCenterArticleCommand} [createHelpCenterArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand?: CreateHelpCenterArticleCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpCenterArticleAdminApi - interface
 * @export
 * @interface HelpCenterArticleAdminApi
 */
export interface HelpCenterArticleAdminApiInterface {
    /**
     * 
     * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
     * @param {CreateHelpCenterArticleVariationWithArticleCommand} [createHelpCenterArticleVariationWithArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand?: CreateHelpCenterArticleVariationWithArticleCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<LanguageCode>} [languages] 
     * @param {HelpCenterArticleState} [state] 
     * @param {Array<HelpCenterArticleProductType>} [productTypes] 
     * @param {Array<HelpCenterArticleCategoryType>} [categories] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: HelpCenterArticleState, productTypes?: Array<HelpCenterArticleProductType>, categories?: Array<HelpCenterArticleCategoryType>, guids?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<HelpCenterArticleVariationsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Updates the article.
     * @param {number} id 
     * @param {UpdateHelpCenterArticleCommand} [updateHelpCenterArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesIdPut(id: number, updateHelpCenterArticleCommand?: UpdateHelpCenterArticleCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<HelpCenterArticleVariationQueryResult>;

    /**
     * 
     * @summary Publishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateHelpCenterArticleVariationCommand} [updateHelpCenterArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateHelpCenterArticleVariationCommand?: UpdateHelpCenterArticleVariationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Unpublishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new article variation.
     * @param {number} id 
     * @param {CreateHelpCenterArticleVariationCommand} [createHelpCenterArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesIdVariationsPost(id: number, createHelpCenterArticleVariationCommand?: CreateHelpCenterArticleVariationCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Creates a new article.
     * @param {CreateHelpCenterArticleCommand} [createHelpCenterArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApiInterface
     */
    apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand?: CreateHelpCenterArticleCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * HelpCenterArticleAdminApi - object-oriented interface
 * @export
 * @class HelpCenterArticleAdminApi
 * @extends {BaseAPI}
 */
export class HelpCenterArticleAdminApi extends BaseAPI implements HelpCenterArticleAdminApiInterface {
    /**
     * 
     * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
     * @param {CreateHelpCenterArticleVariationWithArticleCommand} [createHelpCenterArticleVariationWithArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand?: CreateHelpCenterArticleVariationWithArticleCommand, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesCreateWithVariationPost(createHelpCenterArticleVariationWithArticleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<LanguageCode>} [languages] 
     * @param {HelpCenterArticleState} [state] 
     * @param {Array<HelpCenterArticleProductType>} [productTypes] 
     * @param {Array<HelpCenterArticleCategoryType>} [categories] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: HelpCenterArticleState, productTypes?: Array<HelpCenterArticleProductType>, categories?: Array<HelpCenterArticleCategoryType>, guids?: Array<string>, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesGet(pageNumber, pageSize, search, languages, state, productTypes, categories, guids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the article.
     * @param {number} id 
     * @param {UpdateHelpCenterArticleCommand} [updateHelpCenterArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesIdPut(id: number, updateHelpCenterArticleCommand?: UpdateHelpCenterArticleCommand, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesIdPut(id, updateHelpCenterArticleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesIdVariationsLanguageGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesIdVariationsLanguagePublishPut(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateHelpCenterArticleVariationCommand} [updateHelpCenterArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateHelpCenterArticleVariationCommand?: UpdateHelpCenterArticleVariationCommand, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesIdVariationsLanguagePut(id, language, updateHelpCenterArticleVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unpublishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesIdVariationsLanguageUnpublishPut(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new article variation.
     * @param {number} id 
     * @param {CreateHelpCenterArticleVariationCommand} [createHelpCenterArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesIdVariationsPost(id: number, createHelpCenterArticleVariationCommand?: CreateHelpCenterArticleVariationCommand, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesIdVariationsPost(id, createHelpCenterArticleVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new article.
     * @param {CreateHelpCenterArticleCommand} [createHelpCenterArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpCenterArticleAdminApi
     */
    public apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand?: CreateHelpCenterArticleCommand, options?: AxiosRequestConfig) {
        return HelpCenterArticleAdminApiFp(this.configuration).apiV1AdminHelpCenterArticlesPost(createHelpCenterArticleCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

