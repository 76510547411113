/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateSubscriptionStatusCommand } from '../models';
/**
 * EcomailWebhookApi - axios parameter creator
 * @export
 */
export const EcomailWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary The ecomail subscribe/unsubscribe event is triggered  https://support.ecomail.cz/cs/articles/2241902-webhook-zmena-stavu-kontaktu
         * @param {string} [token] 
         * @param {UpdateSubscriptionStatusCommand} [updateSubscriptionStatusCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EcomailWebhooksSubscriptionChangedPost: async (token?: string, updateSubscriptionStatusCommand?: UpdateSubscriptionStatusCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ecomail-webhooks/subscription-changed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionStatusCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EcomailWebhookApi - functional programming interface
 * @export
 */
export const EcomailWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EcomailWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary The ecomail subscribe/unsubscribe event is triggered  https://support.ecomail.cz/cs/articles/2241902-webhook-zmena-stavu-kontaktu
         * @param {string} [token] 
         * @param {UpdateSubscriptionStatusCommand} [updateSubscriptionStatusCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EcomailWebhooksSubscriptionChangedPost(token?: string, updateSubscriptionStatusCommand?: UpdateSubscriptionStatusCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EcomailWebhooksSubscriptionChangedPost(token, updateSubscriptionStatusCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EcomailWebhookApi - factory interface
 * @export
 */
export const EcomailWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EcomailWebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary The ecomail subscribe/unsubscribe event is triggered  https://support.ecomail.cz/cs/articles/2241902-webhook-zmena-stavu-kontaktu
         * @param {string} [token] 
         * @param {UpdateSubscriptionStatusCommand} [updateSubscriptionStatusCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EcomailWebhooksSubscriptionChangedPost(token?: string, updateSubscriptionStatusCommand?: UpdateSubscriptionStatusCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1EcomailWebhooksSubscriptionChangedPost(token, updateSubscriptionStatusCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EcomailWebhookApi - interface
 * @export
 * @interface EcomailWebhookApi
 */
export interface EcomailWebhookApiInterface {
    /**
     * 
     * @summary The ecomail subscribe/unsubscribe event is triggered  https://support.ecomail.cz/cs/articles/2241902-webhook-zmena-stavu-kontaktu
     * @param {string} [token] 
     * @param {UpdateSubscriptionStatusCommand} [updateSubscriptionStatusCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EcomailWebhookApiInterface
     */
    apiV1EcomailWebhooksSubscriptionChangedPost(token?: string, updateSubscriptionStatusCommand?: UpdateSubscriptionStatusCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * EcomailWebhookApi - object-oriented interface
 * @export
 * @class EcomailWebhookApi
 * @extends {BaseAPI}
 */
export class EcomailWebhookApi extends BaseAPI implements EcomailWebhookApiInterface {
    /**
     * 
     * @summary The ecomail subscribe/unsubscribe event is triggered  https://support.ecomail.cz/cs/articles/2241902-webhook-zmena-stavu-kontaktu
     * @param {string} [token] 
     * @param {UpdateSubscriptionStatusCommand} [updateSubscriptionStatusCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EcomailWebhookApi
     */
    public apiV1EcomailWebhooksSubscriptionChangedPost(token?: string, updateSubscriptionStatusCommand?: UpdateSubscriptionStatusCommand, options?: AxiosRequestConfig) {
        return EcomailWebhookApiFp(this.configuration).apiV1EcomailWebhooksSubscriptionChangedPost(token, updateSubscriptionStatusCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

