/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The unpublished reason types.
 * @export
 * @enum {string}
 */

export const UnpublishedReasons = {
    NotSet: 'NotSet',
    Temporary_Repairs: 'Temporary_Repairs',
    Temporary_CampiriWebChanges: 'Temporary_CampiriWebChanges',
    Temporary_WaitingForInsurance: 'Temporary_WaitingForInsurance',
    Permanent_CarSold: 'Permanent_CarSold',
    Permanent_CarSoldDontWantToRentAnymore: 'Permanent_CarSoldDontWantToRentAnymore',
    Permnanet_DontWantToRentAnymore: 'Permnanet_DontWantToRentAnymore',
    Permanent_BuyingNewCar: 'Permanent_BuyingNewCar',
    Permanent_UnhappyWithCampiri: 'Permanent_UnhappyWithCampiri',
    Permanent_NoBookings: 'Permanent_NoBookings'
} as const;

export type UnpublishedReasons = typeof UnpublishedReasons[keyof typeof UnpublishedReasons];



