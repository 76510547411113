/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * A message type determines of which type is the message.  <br>      A type of the message can also indicate what content of the message is. Whether it is an attachment or regular      text message.  
 * @export
 * @enum {string}
 */

export const MessageType = {
    Text: 'Text',
    Attachments: 'Attachments',
    Activity: 'Activity',
    System: 'System'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];



