/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateDynamicSegmentCommand } from '../models';
// @ts-ignore
import { CreateDynamicSegmentVariationCommand } from '../models';
// @ts-ignore
import { CreateDynamicSegmentWithVariationCommand } from '../models';
// @ts-ignore
import { CreateDynamicSegmentWithVariationIdsResult } from '../models';
// @ts-ignore
import { DynamicSegmentListResultPaginatedResult } from '../models';
// @ts-ignore
import { DynamicSegmentListingsResult } from '../models';
// @ts-ignore
import { DynamicSegmentResult } from '../models';
// @ts-ignore
import { DynamicSegmentVariationListResultPaginatedResult } from '../models';
// @ts-ignore
import { DynamicSegmentVariationResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { LanguageCode } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateDynamicSegmentCommand } from '../models';
// @ts-ignore
import { UpdateDynamicSegmentVariationCommand } from '../models';
// @ts-ignore
import { UpdateDynamicSegmentVariationPublishedCommand } from '../models';
/**
 * DynamicSegmentAdminApi - axios parameter creator
 * @export
 */
export const DynamicSegmentAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates dynamic segment and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateDynamicSegmentWithVariationCommand} [createDynamicSegmentWithVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsCreateWithVariationPost: async (createDynamicSegmentWithVariationCommand?: CreateDynamicSegmentWithVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/dynamic-segments/create-with-variation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDynamicSegmentWithVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dynamic segments
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/dynamic-segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a dynamic segment by id
         * @param {number} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdDelete: async (id: number, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdDelete', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a dynamic segment by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdGet', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdListingsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdListingsGet', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/listings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the dynamic segment\'s main image
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdPicturePut: async (id: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdPicturePut', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a dynamic segment
         * @param {number} id 
         * @param {UpdateDynamicSegmentCommand} [updateDynamicSegmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdPut: async (id: number, updateDynamicSegmentCommand?: UpdateDynamicSegmentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDynamicSegmentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dynamic segment\'s variations
         * @param {number} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsGet: async (id: number, pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsGet', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/variations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes dynamic segment\'s variation by language
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguageDelete: async (id: number, language: LanguageCode, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguageDelete', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguageDelete', 'language', language)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a dynamic segment\'s variation by language
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguageGet: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguageGet', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguageGet', 'language', language)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publishes/unpublishes the variation
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateDynamicSegmentVariationPublishedCommand} [updateDynamicSegmentVariationPublishedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut: async (id: number, language: LanguageCode, updateDynamicSegmentVariationPublishedCommand?: UpdateDynamicSegmentVariationPublishedCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut', 'language', language)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/variations/{language}/published`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDynamicSegmentVariationPublishedCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a dynamic segment\'s variation
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateDynamicSegmentVariationCommand} [updateDynamicSegmentVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguagePut: async (id: number, language: LanguageCode, updateDynamicSegmentVariationCommand?: UpdateDynamicSegmentVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguagePut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsLanguagePut', 'language', language)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDynamicSegmentVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a dynamic segment\'s variation
         * @param {number} id 
         * @param {CreateDynamicSegmentVariationCommand} [createDynamicSegmentVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsPost: async (id: number, createDynamicSegmentVariationCommand?: CreateDynamicSegmentVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminDynamicSegmentsIdVariationsPost', 'id', id)
            const localVarPath = `/api/v1/admin/dynamic-segments/{id}/variations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDynamicSegmentVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new dynamic segment
         * @param {CreateDynamicSegmentCommand} [createDynamicSegmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsPost: async (createDynamicSegmentCommand?: CreateDynamicSegmentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/dynamic-segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDynamicSegmentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the variations
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {number} [dynamicSegmentId] 
         * @param {string} [search] Fulltext search
         * @param {LanguageCode} [language] Language filter
         * @param {boolean} [searchableOnly] Return only searchable variations
         * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsVariationsGet: async (pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/dynamic-segments/variations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (dynamicSegmentId !== undefined) {
                localVarQueryParameter['DynamicSegmentId'] = dynamicSegmentId;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (language !== undefined) {
                localVarQueryParameter['Language'] = language;
            }

            if (searchableOnly !== undefined) {
                localVarQueryParameter['SearchableOnly'] = searchableOnly;
            }

            if (isIndexed !== undefined) {
                localVarQueryParameter['IsIndexed'] = isIndexed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicSegmentAdminApi - functional programming interface
 * @export
 */
export const DynamicSegmentAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DynamicSegmentAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates dynamic segment and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateDynamicSegmentWithVariationCommand} [createDynamicSegmentWithVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand?: CreateDynamicSegmentWithVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDynamicSegmentWithVariationIdsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets dynamic segments
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a dynamic segment by id
         * @param {number} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdDelete(id: number, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdDelete(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a dynamic segment by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdListingsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentListingsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdListingsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the dynamic segment\'s main image
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdPicturePut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a dynamic segment
         * @param {number} id 
         * @param {UpdateDynamicSegmentCommand} [updateDynamicSegmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdPut(id: number, updateDynamicSegmentCommand?: UpdateDynamicSegmentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdPut(id, updateDynamicSegmentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets dynamic segment\'s variations
         * @param {number} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdVariationsGet(id: number, pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentVariationListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdVariationsGet(id, pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes dynamic segment\'s variation by language
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id: number, language: LanguageCode, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id, language, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a dynamic segment\'s variation by language
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentVariationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publishes/unpublishes the variation
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateDynamicSegmentVariationPublishedCommand} [updateDynamicSegmentVariationPublishedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id: number, language: LanguageCode, updateDynamicSegmentVariationPublishedCommand?: UpdateDynamicSegmentVariationPublishedCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id, language, updateDynamicSegmentVariationPublishedCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a dynamic segment\'s variation
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateDynamicSegmentVariationCommand} [updateDynamicSegmentVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id: number, language: LanguageCode, updateDynamicSegmentVariationCommand?: UpdateDynamicSegmentVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id, language, updateDynamicSegmentVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a dynamic segment\'s variation
         * @param {number} id 
         * @param {CreateDynamicSegmentVariationCommand} [createDynamicSegmentVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsIdVariationsPost(id: number, createDynamicSegmentVariationCommand?: CreateDynamicSegmentVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsIdVariationsPost(id, createDynamicSegmentVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new dynamic segment
         * @param {CreateDynamicSegmentCommand} [createDynamicSegmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand?: CreateDynamicSegmentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the variations
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {number} [dynamicSegmentId] 
         * @param {string} [search] Fulltext search
         * @param {LanguageCode} [language] Language filter
         * @param {boolean} [searchableOnly] Return only searchable variations
         * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicSegmentVariationListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDynamicSegmentsVariationsGet(pageOffset, pageSize, dynamicSegmentId, search, language, searchableOnly, isIndexed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DynamicSegmentAdminApi - factory interface
 * @export
 */
export const DynamicSegmentAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DynamicSegmentAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates dynamic segment and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateDynamicSegmentWithVariationCommand} [createDynamicSegmentWithVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand?: CreateDynamicSegmentWithVariationCommand, options?: any): AxiosPromise<CreateDynamicSegmentWithVariationIdsResult> {
            return localVarFp.apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dynamic segments
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<DynamicSegmentListResultPaginatedResult> {
            return localVarFp.apiV1AdminDynamicSegmentsGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a dynamic segment by id
         * @param {number} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdDelete(id: number, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminDynamicSegmentsIdDelete(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a dynamic segment by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdGet(id: number, options?: any): AxiosPromise<DynamicSegmentResult> {
            return localVarFp.apiV1AdminDynamicSegmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdListingsGet(id: number, options?: any): AxiosPromise<DynamicSegmentListingsResult> {
            return localVarFp.apiV1AdminDynamicSegmentsIdListingsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the dynamic segment\'s main image
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdPicturePut(id: number, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminDynamicSegmentsIdPicturePut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a dynamic segment
         * @param {number} id 
         * @param {UpdateDynamicSegmentCommand} [updateDynamicSegmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdPut(id: number, updateDynamicSegmentCommand?: UpdateDynamicSegmentCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminDynamicSegmentsIdPut(id, updateDynamicSegmentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dynamic segment\'s variations
         * @param {number} id 
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsGet(id: number, pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<DynamicSegmentVariationListResultPaginatedResult> {
            return localVarFp.apiV1AdminDynamicSegmentsIdVariationsGet(id, pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes dynamic segment\'s variation by language
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id: number, language: LanguageCode, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id, language, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a dynamic segment\'s variation by language
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: any): AxiosPromise<DynamicSegmentVariationResult> {
            return localVarFp.apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publishes/unpublishes the variation
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateDynamicSegmentVariationPublishedCommand} [updateDynamicSegmentVariationPublishedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id: number, language: LanguageCode, updateDynamicSegmentVariationPublishedCommand?: UpdateDynamicSegmentVariationPublishedCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id, language, updateDynamicSegmentVariationPublishedCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a dynamic segment\'s variation
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateDynamicSegmentVariationCommand} [updateDynamicSegmentVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id: number, language: LanguageCode, updateDynamicSegmentVariationCommand?: UpdateDynamicSegmentVariationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id, language, updateDynamicSegmentVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a dynamic segment\'s variation
         * @param {number} id 
         * @param {CreateDynamicSegmentVariationCommand} [createDynamicSegmentVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsIdVariationsPost(id: number, createDynamicSegmentVariationCommand?: CreateDynamicSegmentVariationCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminDynamicSegmentsIdVariationsPost(id, createDynamicSegmentVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new dynamic segment
         * @param {CreateDynamicSegmentCommand} [createDynamicSegmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand?: CreateDynamicSegmentCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the variations
         * @param {number} [pageOffset] Items to be offset in pagination.
         * @param {number} [pageSize] Page size.
         * @param {number} [dynamicSegmentId] 
         * @param {string} [search] Fulltext search
         * @param {LanguageCode} [language] Language filter
         * @param {boolean} [searchableOnly] Return only searchable variations
         * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: any): AxiosPromise<DynamicSegmentVariationListResultPaginatedResult> {
            return localVarFp.apiV1AdminDynamicSegmentsVariationsGet(pageOffset, pageSize, dynamicSegmentId, search, language, searchableOnly, isIndexed, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DynamicSegmentAdminApi - interface
 * @export
 * @interface DynamicSegmentAdminApi
 */
export interface DynamicSegmentAdminApiInterface {
    /**
     * 
     * @summary Creates dynamic segment and variation  This is a temporary endpoint triggering two commands.
     * @param {CreateDynamicSegmentWithVariationCommand} [createDynamicSegmentWithVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand?: CreateDynamicSegmentWithVariationCommand, options?: AxiosRequestConfig): AxiosPromise<CreateDynamicSegmentWithVariationIdsResult>;

    /**
     * 
     * @summary Gets dynamic segments
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentListResultPaginatedResult>;

    /**
     * 
     * @summary Deletes a dynamic segment by id
     * @param {number} id 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdDelete(id: number, body?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a dynamic segment by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdGet(id: number, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentResult>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdListingsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentListingsResult>;

    /**
     * 
     * @summary Changes the dynamic segment\'s main image
     * @param {number} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a dynamic segment
     * @param {number} id 
     * @param {UpdateDynamicSegmentCommand} [updateDynamicSegmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdPut(id: number, updateDynamicSegmentCommand?: UpdateDynamicSegmentCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets dynamic segment\'s variations
     * @param {number} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdVariationsGet(id: number, pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentVariationListResultPaginatedResult>;

    /**
     * 
     * @summary Deletes dynamic segment\'s variation by language
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id: number, language: LanguageCode, body?: object, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets a dynamic segment\'s variation by language
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentVariationResult>;

    /**
     * 
     * @summary Publishes/unpublishes the variation
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateDynamicSegmentVariationPublishedCommand} [updateDynamicSegmentVariationPublishedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id: number, language: LanguageCode, updateDynamicSegmentVariationPublishedCommand?: UpdateDynamicSegmentVariationPublishedCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a dynamic segment\'s variation
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateDynamicSegmentVariationCommand} [updateDynamicSegmentVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id: number, language: LanguageCode, updateDynamicSegmentVariationCommand?: UpdateDynamicSegmentVariationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a dynamic segment\'s variation
     * @param {number} id 
     * @param {CreateDynamicSegmentVariationCommand} [createDynamicSegmentVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsIdVariationsPost(id: number, createDynamicSegmentVariationCommand?: CreateDynamicSegmentVariationCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Creates a new dynamic segment
     * @param {CreateDynamicSegmentCommand} [createDynamicSegmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand?: CreateDynamicSegmentCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets all the variations
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {number} [dynamicSegmentId] 
     * @param {string} [search] Fulltext search
     * @param {LanguageCode} [language] Language filter
     * @param {boolean} [searchableOnly] Return only searchable variations
     * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApiInterface
     */
    apiV1AdminDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: AxiosRequestConfig): AxiosPromise<DynamicSegmentVariationListResultPaginatedResult>;

}

/**
 * DynamicSegmentAdminApi - object-oriented interface
 * @export
 * @class DynamicSegmentAdminApi
 * @extends {BaseAPI}
 */
export class DynamicSegmentAdminApi extends BaseAPI implements DynamicSegmentAdminApiInterface {
    /**
     * 
     * @summary Creates dynamic segment and variation  This is a temporary endpoint triggering two commands.
     * @param {CreateDynamicSegmentWithVariationCommand} [createDynamicSegmentWithVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand?: CreateDynamicSegmentWithVariationCommand, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsCreateWithVariationPost(createDynamicSegmentWithVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dynamic segments
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a dynamic segment by id
     * @param {number} id 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdDelete(id: number, body?: object, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdDelete(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a dynamic segment by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdGet(id: number, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdListingsGet(id: number, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdListingsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the dynamic segment\'s main image
     * @param {number} id 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdPicturePut(id: number, file?: File, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdPicturePut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a dynamic segment
     * @param {number} id 
     * @param {UpdateDynamicSegmentCommand} [updateDynamicSegmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdPut(id: number, updateDynamicSegmentCommand?: UpdateDynamicSegmentCommand, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdPut(id, updateDynamicSegmentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dynamic segment\'s variations
     * @param {number} id 
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdVariationsGet(id: number, pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdVariationsGet(id, pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes dynamic segment\'s variation by language
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id: number, language: LanguageCode, body?: object, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdVariationsLanguageDelete(id, language, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a dynamic segment\'s variation by language
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdVariationsLanguageGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publishes/unpublishes the variation
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateDynamicSegmentVariationPublishedCommand} [updateDynamicSegmentVariationPublishedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id: number, language: LanguageCode, updateDynamicSegmentVariationPublishedCommand?: UpdateDynamicSegmentVariationPublishedCommand, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdVariationsLanguagePublishedPut(id, language, updateDynamicSegmentVariationPublishedCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a dynamic segment\'s variation
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateDynamicSegmentVariationCommand} [updateDynamicSegmentVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id: number, language: LanguageCode, updateDynamicSegmentVariationCommand?: UpdateDynamicSegmentVariationCommand, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdVariationsLanguagePut(id, language, updateDynamicSegmentVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a dynamic segment\'s variation
     * @param {number} id 
     * @param {CreateDynamicSegmentVariationCommand} [createDynamicSegmentVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsIdVariationsPost(id: number, createDynamicSegmentVariationCommand?: CreateDynamicSegmentVariationCommand, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsIdVariationsPost(id, createDynamicSegmentVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new dynamic segment
     * @param {CreateDynamicSegmentCommand} [createDynamicSegmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand?: CreateDynamicSegmentCommand, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsPost(createDynamicSegmentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the variations
     * @param {number} [pageOffset] Items to be offset in pagination.
     * @param {number} [pageSize] Page size.
     * @param {number} [dynamicSegmentId] 
     * @param {string} [search] Fulltext search
     * @param {LanguageCode} [language] Language filter
     * @param {boolean} [searchableOnly] Return only searchable variations
     * @param {boolean} [isIndexed] Optional filter for IsIndexed field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicSegmentAdminApi
     */
    public apiV1AdminDynamicSegmentsVariationsGet(pageOffset?: number, pageSize?: number, dynamicSegmentId?: number, search?: string, language?: LanguageCode, searchableOnly?: boolean, isIndexed?: boolean, options?: AxiosRequestConfig) {
        return DynamicSegmentAdminApiFp(this.configuration).apiV1AdminDynamicSegmentsVariationsGet(pageOffset, pageSize, dynamicSegmentId, search, language, searchableOnly, isIndexed, options).then((request) => request(this.axios, this.basePath));
    }
}

