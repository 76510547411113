/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Limits the listing selection to the specified vehicle parameters.
 * @export
 * @enum {string}
 */

export const VehicleWeightOption = {
    NotSet: 'NotSet',
    Limited3500: 'Limited3500',
    Unlimited: 'Unlimited'
} as const;

export type VehicleWeightOption = typeof VehicleWeightOption[keyof typeof VehicleWeightOption];



