/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerSignUpNewsLetterCommand } from '../models';
// @ts-ignore
import { CustomerUnsubscribeCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * NewsLetterMarketplaceApi - axios parameter creator
 * @export
 */
export const NewsLetterMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribes the user to the newsletter list.
         * @param {CustomerSignUpNewsLetterCommand} [customerSignUpNewsLetterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceNewsletterSubscribePost: async (customerSignUpNewsLetterCommand?: CustomerSignUpNewsLetterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/newsletter/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSignUpNewsLetterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unsubscribes the user to the newsletter list.
         * @param {CustomerUnsubscribeCommand} [customerUnsubscribeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceNewsletterUnsubscribePost: async (customerUnsubscribeCommand?: CustomerUnsubscribeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/newsletter/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerUnsubscribeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsLetterMarketplaceApi - functional programming interface
 * @export
 */
export const NewsLetterMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsLetterMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Subscribes the user to the newsletter list.
         * @param {CustomerSignUpNewsLetterCommand} [customerSignUpNewsLetterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand?: CustomerSignUpNewsLetterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unsubscribes the user to the newsletter list.
         * @param {CustomerUnsubscribeCommand} [customerUnsubscribeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand?: CustomerUnsubscribeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsLetterMarketplaceApi - factory interface
 * @export
 */
export const NewsLetterMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsLetterMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Subscribes the user to the newsletter list.
         * @param {CustomerSignUpNewsLetterCommand} [customerSignUpNewsLetterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand?: CustomerSignUpNewsLetterCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unsubscribes the user to the newsletter list.
         * @param {CustomerUnsubscribeCommand} [customerUnsubscribeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand?: CustomerUnsubscribeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsLetterMarketplaceApi - interface
 * @export
 * @interface NewsLetterMarketplaceApi
 */
export interface NewsLetterMarketplaceApiInterface {
    /**
     * 
     * @summary Subscribes the user to the newsletter list.
     * @param {CustomerSignUpNewsLetterCommand} [customerSignUpNewsLetterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsLetterMarketplaceApiInterface
     */
    apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand?: CustomerSignUpNewsLetterCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Unsubscribes the user to the newsletter list.
     * @param {CustomerUnsubscribeCommand} [customerUnsubscribeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsLetterMarketplaceApiInterface
     */
    apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand?: CustomerUnsubscribeCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * NewsLetterMarketplaceApi - object-oriented interface
 * @export
 * @class NewsLetterMarketplaceApi
 * @extends {BaseAPI}
 */
export class NewsLetterMarketplaceApi extends BaseAPI implements NewsLetterMarketplaceApiInterface {
    /**
     * 
     * @summary Subscribes the user to the newsletter list.
     * @param {CustomerSignUpNewsLetterCommand} [customerSignUpNewsLetterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsLetterMarketplaceApi
     */
    public apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand?: CustomerSignUpNewsLetterCommand, options?: AxiosRequestConfig) {
        return NewsLetterMarketplaceApiFp(this.configuration).apiV1MarketplaceNewsletterSubscribePost(customerSignUpNewsLetterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unsubscribes the user to the newsletter list.
     * @param {CustomerUnsubscribeCommand} [customerUnsubscribeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsLetterMarketplaceApi
     */
    public apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand?: CustomerUnsubscribeCommand, options?: AxiosRequestConfig) {
        return NewsLetterMarketplaceApiFp(this.configuration).apiV1MarketplaceNewsletterUnsubscribePost(customerUnsubscribeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

