/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OperatorPolicyType = {
    Return_LateAfterTime_Fixed: 'Return_LateAfterTime_Fixed',
    Return_LateAnotherDay_PerDay: 'Return_LateAnotherDay_PerDay',
    Vehicle_Dirty_Fixed: 'Vehicle_Dirty_Fixed',
    Vehicle_VeryDirty_Fixed: 'Vehicle_VeryDirty_Fixed',
    Vehicle_ExtraKilometersOverLimit_PerKilometer: 'Vehicle_ExtraKilometersOverLimit_PerKilometer',
    Vehicle_FuelTankNotFull_PerLiter: 'Vehicle_FuelTankNotFull_PerLiter',
    Vehicle_WasteTankNotEmptied_Fixed: 'Vehicle_WasteTankNotEmptied_Fixed',
    Vehicle_ToiletTankNotEmptied_Fixed: 'Vehicle_ToiletTankNotEmptied_Fixed',
    Vehicle_DirtyDishes_Fixed: 'Vehicle_DirtyDishes_Fixed',
    Damage_General_PerDamage: 'Damage_General_PerDamage',
    Damage_KnowinglyNotReportedDamages_Fixed: 'Damage_KnowinglyNotReportedDamages_Fixed',
    Damage_RenterParticipation_MinMax: 'Damage_RenterParticipation_MinMax',
    Losts_VehicleKeys_PerKey: 'Losts_VehicleKeys_PerKey',
    Losts_VehicleDocumentation_PerDocument: 'Losts_VehicleDocumentation_PerDocument',
    Other_UsageOfFirstAidKid_Fixed: 'Other_UsageOfFirstAidKid_Fixed',
    Other_UsageOfFireExtinguisher_Fixed: 'Other_UsageOfFireExtinguisher_Fixed',
    Other_UsageOfSpareTire_Fixed: 'Other_UsageOfSpareTire_Fixed',
    Other_PoliceFine_Fixed: 'Other_PoliceFine_Fixed',
    Other_UnauthorizedServiceVisit_Fixed: 'Other_UnauthorizedServiceVisit_Fixed',
    Other_Smoking_Fixed: 'Other_Smoking_Fixed',
    Other_Pets_Fixed: 'Other_Pets_Fixed'
} as const;

export type OperatorPolicyType = typeof OperatorPolicyType[keyof typeof OperatorPolicyType];



