/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ArticleState } from '../models';
// @ts-ignore
import { ArticleVariationQueryResult } from '../models';
// @ts-ignore
import { ArticleVariationsListQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { CreateArticleCommand } from '../models';
// @ts-ignore
import { CreateArticleVariationCommand } from '../models';
// @ts-ignore
import { CreateArticleVariationWithArticleCommand } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { LanguageCode } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateArticleCommand } from '../models';
// @ts-ignore
import { UpdateArticleVariationCommand } from '../models';
/**
 * ArticleAdminApi - axios parameter creator
 * @export
 */
export const ArticleAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateArticleVariationWithArticleCommand} [createArticleVariationWithArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesCreateWithVariationPost: async (createArticleVariationWithArticleCommand?: CreateArticleVariationWithArticleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/blog/articles/create-with-variation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleVariationWithArticleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<LanguageCode>} [languages] 
         * @param {ArticleState} [state] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesGet: async (pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: ArticleState, guids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/blog/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (languages) {
                localVarQueryParameter['Languages'] = languages;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }

            if (guids) {
                localVarQueryParameter['Guids'] = guids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the article.
         * @param {number} id 
         * @param {UpdateArticleCommand} [updateArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdPut: async (id: number, updateArticleCommand?: UpdateArticleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdPut', 'id', id)
            const localVarPath = `/api/v1/admin/blog/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArticleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguageGet: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguageGet', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguageGet', 'language', language)
            const localVarPath = `/api/v1/admin/blog/articles/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguagePublishPut: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguagePublishPut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguagePublishPut', 'language', language)
            const localVarPath = `/api/v1/admin/blog/articles/{id}/variations/{language}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateArticleVariationCommand} [updateArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguagePut: async (id: number, language: LanguageCode, updateArticleVariationCommand?: UpdateArticleVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguagePut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguagePut', 'language', language)
            const localVarPath = `/api/v1/admin/blog/articles/{id}/variations/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArticleVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unpublishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut: async (id: number, language: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut', 'language', language)
            const localVarPath = `/api/v1/admin/blog/articles/{id}/variations/{language}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new article variation.
         * @param {number} id 
         * @param {CreateArticleVariationCommand} [createArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsPost: async (id: number, createArticleVariationCommand?: CreateArticleVariationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminBlogArticlesIdVariationsPost', 'id', id)
            const localVarPath = `/api/v1/admin/blog/articles/{id}/variations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleVariationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new article.
         * @param {CreateArticleCommand} [createArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesPost: async (createArticleCommand?: CreateArticleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/blog/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleAdminApi - functional programming interface
 * @export
 */
export const ArticleAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateArticleVariationWithArticleCommand} [createArticleVariationWithArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand?: CreateArticleVariationWithArticleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<LanguageCode>} [languages] 
         * @param {ArticleState} [state] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: ArticleState, guids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleVariationsListQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesGet(pageNumber, pageSize, search, languages, state, guids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the article.
         * @param {number} id 
         * @param {UpdateArticleCommand} [updateArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesIdPut(id: number, updateArticleCommand?: UpdateArticleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesIdPut(id, updateArticleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleVariationQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesIdVariationsLanguageGet(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateArticleVariationCommand} [updateArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateArticleVariationCommand?: UpdateArticleVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesIdVariationsLanguagePut(id, language, updateArticleVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unpublishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new article variation.
         * @param {number} id 
         * @param {CreateArticleVariationCommand} [createArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesIdVariationsPost(id: number, createArticleVariationCommand?: CreateArticleVariationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesIdVariationsPost(id, createArticleVariationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new article.
         * @param {CreateArticleCommand} [createArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminBlogArticlesPost(createArticleCommand?: CreateArticleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminBlogArticlesPost(createArticleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleAdminApi - factory interface
 * @export
 */
export const ArticleAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
         * @param {CreateArticleVariationWithArticleCommand} [createArticleVariationWithArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand?: CreateArticleVariationWithArticleCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated list of articles.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {Array<LanguageCode>} [languages] 
         * @param {ArticleState} [state] 
         * @param {Array<string>} [guids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: ArticleState, guids?: Array<string>, options?: any): AxiosPromise<ArticleVariationsListQueryResultPaginatedResult> {
            return localVarFp.apiV1AdminBlogArticlesGet(pageNumber, pageSize, search, languages, state, guids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the article.
         * @param {number} id 
         * @param {UpdateArticleCommand} [updateArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdPut(id: number, updateArticleCommand?: UpdateArticleCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminBlogArticlesIdPut(id, updateArticleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: any): AxiosPromise<ArticleVariationQueryResult> {
            return localVarFp.apiV1AdminBlogArticlesIdVariationsLanguageGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an article.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {UpdateArticleVariationCommand} [updateArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateArticleVariationCommand?: UpdateArticleVariationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBlogArticlesIdVariationsLanguagePut(id, language, updateArticleVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unpublishes the article variation.
         * @param {number} id 
         * @param {LanguageCode} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new article variation.
         * @param {number} id 
         * @param {CreateArticleVariationCommand} [createArticleVariationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesIdVariationsPost(id: number, createArticleVariationCommand?: CreateArticleVariationCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminBlogArticlesIdVariationsPost(id, createArticleVariationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new article.
         * @param {CreateArticleCommand} [createArticleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminBlogArticlesPost(createArticleCommand?: CreateArticleCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1AdminBlogArticlesPost(createArticleCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleAdminApi - interface
 * @export
 * @interface ArticleAdminApi
 */
export interface ArticleAdminApiInterface {
    /**
     * 
     * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
     * @param {CreateArticleVariationWithArticleCommand} [createArticleVariationWithArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand?: CreateArticleVariationWithArticleCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<LanguageCode>} [languages] 
     * @param {ArticleState} [state] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: ArticleState, guids?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ArticleVariationsListQueryResultPaginatedResult>;

    /**
     * 
     * @summary Updates the article.
     * @param {number} id 
     * @param {UpdateArticleCommand} [updateArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesIdPut(id: number, updateArticleCommand?: UpdateArticleCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Gets an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<ArticleVariationQueryResult>;

    /**
     * 
     * @summary Publishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateArticleVariationCommand} [updateArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateArticleVariationCommand?: UpdateArticleVariationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Unpublishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new article variation.
     * @param {number} id 
     * @param {CreateArticleVariationCommand} [createArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesIdVariationsPost(id: number, createArticleVariationCommand?: CreateArticleVariationCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Creates a new article.
     * @param {CreateArticleCommand} [createArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApiInterface
     */
    apiV1AdminBlogArticlesPost(createArticleCommand?: CreateArticleCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

}

/**
 * ArticleAdminApi - object-oriented interface
 * @export
 * @class ArticleAdminApi
 * @extends {BaseAPI}
 */
export class ArticleAdminApi extends BaseAPI implements ArticleAdminApiInterface {
    /**
     * 
     * @summary Creates article and variation  This is a temporary endpoint triggering two commands.
     * @param {CreateArticleVariationWithArticleCommand} [createArticleVariationWithArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand?: CreateArticleVariationWithArticleCommand, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesCreateWithVariationPost(createArticleVariationWithArticleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated list of articles.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<LanguageCode>} [languages] 
     * @param {ArticleState} [state] 
     * @param {Array<string>} [guids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesGet(pageNumber: number, pageSize: number, search?: string, languages?: Array<LanguageCode>, state?: ArticleState, guids?: Array<string>, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesGet(pageNumber, pageSize, search, languages, state, guids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the article.
     * @param {number} id 
     * @param {UpdateArticleCommand} [updateArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesIdPut(id: number, updateArticleCommand?: UpdateArticleCommand, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesIdPut(id, updateArticleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesIdVariationsLanguageGet(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesIdVariationsLanguageGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesIdVariationsLanguagePublishPut(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an article.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {UpdateArticleVariationCommand} [updateArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesIdVariationsLanguagePut(id: number, language: LanguageCode, updateArticleVariationCommand?: UpdateArticleVariationCommand, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesIdVariationsLanguagePut(id, language, updateArticleVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unpublishes the article variation.
     * @param {number} id 
     * @param {LanguageCode} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id: number, language: LanguageCode, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesIdVariationsLanguageUnpublishPut(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new article variation.
     * @param {number} id 
     * @param {CreateArticleVariationCommand} [createArticleVariationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesIdVariationsPost(id: number, createArticleVariationCommand?: CreateArticleVariationCommand, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesIdVariationsPost(id, createArticleVariationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new article.
     * @param {CreateArticleCommand} [createArticleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdminApi
     */
    public apiV1AdminBlogArticlesPost(createArticleCommand?: CreateArticleCommand, options?: AxiosRequestConfig) {
        return ArticleAdminApiFp(this.configuration).apiV1AdminBlogArticlesPost(createArticleCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

