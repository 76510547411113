/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckInListResultPaginatedResult } from '../models';
// @ts-ignore
import { CheckInStatus } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * CheckInAdminApi - axios parameter creator
 * @export
 */
export const CheckInAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of check-ins by status
         * @param {CheckInStatus} status 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCheckInGet: async (status: CheckInStatus, pageNumber: number, pageSize: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('apiV1AdminCheckInGet', 'status', status)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1AdminCheckInGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1AdminCheckInGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/admin/check-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckInAdminApi - functional programming interface
 * @export
 */
export const CheckInAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckInAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of check-ins by status
         * @param {CheckInStatus} status 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCheckInGet(status: CheckInStatus, pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckInListResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCheckInGet(status, pageNumber, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckInAdminApi - factory interface
 * @export
 */
export const CheckInAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckInAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of check-ins by status
         * @param {CheckInStatus} status 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCheckInGet(status: CheckInStatus, pageNumber: number, pageSize: number, search?: string, options?: any): AxiosPromise<CheckInListResultPaginatedResult> {
            return localVarFp.apiV1AdminCheckInGet(status, pageNumber, pageSize, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckInAdminApi - interface
 * @export
 * @interface CheckInAdminApi
 */
export interface CheckInAdminApiInterface {
    /**
     * 
     * @summary Gets a list of check-ins by status
     * @param {CheckInStatus} status 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInAdminApiInterface
     */
    apiV1AdminCheckInGet(status: CheckInStatus, pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<CheckInListResultPaginatedResult>;

}

/**
 * CheckInAdminApi - object-oriented interface
 * @export
 * @class CheckInAdminApi
 * @extends {BaseAPI}
 */
export class CheckInAdminApi extends BaseAPI implements CheckInAdminApiInterface {
    /**
     * 
     * @summary Gets a list of check-ins by status
     * @param {CheckInStatus} status 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInAdminApi
     */
    public apiV1AdminCheckInGet(status: CheckInStatus, pageNumber: number, pageSize: number, search?: string, options?: AxiosRequestConfig) {
        return CheckInAdminApiFp(this.configuration).apiV1AdminCheckInGet(status, pageNumber, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }
}

