/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const StoveSize = {
    NotSet: 'NotSet',
    OneBurner: 'OneBurner',
    TwoBurner: 'TwoBurner',
    ThreeBurner: 'ThreeBurner',
    FoldoutKitchen: 'FoldoutKitchen',
    FoldoutStove: 'FoldoutStove'
} as const;

export type StoveSize = typeof StoveSize[keyof typeof StoveSize];



