/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddVehicleDamageCommand } from '../models';
// @ts-ignore
import { GetVehicleByGuidOperatorQueryResult } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { IdResult } from '../models';
// @ts-ignore
import { ListVehiclesByOperatorAndStationQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListVehiclesByOperatorQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RentalCalendarAvailabilityByGuidQueryResult } from '../models';
// @ts-ignore
import { UpdateVehicleDamageCommand } from '../models';
// @ts-ignore
import { VehicleCreateCommand } from '../models';
// @ts-ignore
import { VehicleCreateCommandResult } from '../models';
// @ts-ignore
import { VehicleCreateInPoolCommand } from '../models';
// @ts-ignore
import { VehicleCreateTransferCommand } from '../models';
// @ts-ignore
import { VehicleDamageByGuidQueryResult } from '../models';
// @ts-ignore
import { VehicleEventOverrideCommand } from '../models';
// @ts-ignore
import { VehicleEventUpdateCommand } from '../models';
// @ts-ignore
import { VehiclePictureEditCommand } from '../models';
// @ts-ignore
import { VehiclePicturesSortCommand } from '../models';
// @ts-ignore
import { VehicleStatus } from '../models';
// @ts-ignore
import { VehicleUpdateCommand } from '../models';
/**
 * VehicleOperatorApi - axios parameter creator
 * @export
 */
export const VehicleOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets vehicles.
         * @param {string} [stationGuid] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesByStationGet: async (stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/vehicles/by-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stationGuid !== undefined) {
                localVarQueryParameter['stationGuid'] = stationGuid;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesEventsEventGuidDelete: async (eventGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesEventsEventGuidDelete', 'eventGuid', eventGuid)
            const localVarPath = `/api/v1/operator/vehicles/events/{eventGuid}`
                .replace(`{${"eventGuid"}}`, encodeURIComponent(String(eventGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesEventsEventGuidGet: async (eventGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesEventsEventGuidGet', 'eventGuid', eventGuid)
            const localVarPath = `/api/v1/operator/vehicles/events/{eventGuid}`
                .replace(`{${"eventGuid"}}`, encodeURIComponent(String(eventGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesEventsEventGuidPut: async (eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesEventsEventGuidPut', 'eventGuid', eventGuid)
            const localVarPath = `/api/v1/operator/vehicles/events/{eventGuid}`
                .replace(`{${"eventGuid"}}`, encodeURIComponent(String(eventGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleEventUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicles.
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {Array<VehicleStatus>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGet: async (pageSize?: number, page?: number, search?: string, statuses?: Array<VehicleStatus>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid GUID of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidDelete: async (guid: string, damageGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidDelete', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidDelete', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/damages/{damageGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidGet: async (guid: string, damageGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidGet', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidGet', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/damages/{damageGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes an image from the vehicle damage object.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {string} pictureGuid Picture GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete: async (guid: string, damageGuid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete', 'damageGuid', damageGuid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/damages/{damageGuid}/pictures/{pictureGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add an image to the vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {File} [picture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost: async (guid: string, damageGuid: string, picture?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/damages/{damageGuid}/pictures`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (picture !== undefined) { 
                localVarFormParams.append('picture', picture as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidPut: async (guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPut', 'guid', guid)
            // verify required parameter 'damageGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesDamageGuidPut', 'damageGuid', damageGuid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/damages/{damageGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"damageGuid"}}`, encodeURIComponent(String(damageGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleDamageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesPost: async (guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidDamagesPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/damages`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVehicleDamageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleEventOverrideCommand} [vehicleEventOverrideCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidEventsPost: async (guid: string, vehicleEventOverrideCommand?: VehicleEventOverrideCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidEventsPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/events`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleEventOverrideCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidGet: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidGet', 'guid', guid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidPut: async (guid: string, vehicleUpdateCommand?: VehicleUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a vehicle for review (which can be done by admin).
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidSendForReviewPost: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidSendForReviewPost', 'guid', guid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/send-for-review`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidTransfersPut: async (guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesGuidTransfersPut', 'guid', guid)
            const localVarPath = `/api/v1/operator/vehicles/{guid}/transfers`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateTransferCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new vehicle entity from model provided.
         * @param {number} poolId The vehicle pool idnetifier.
         * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] The vehicle data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesPoolIdVehiclesPost: async (poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesPoolIdVehiclesPost', 'poolId', poolId)
            const localVarPath = `/api/v1/operator/vehicles/{poolId}/vehicles`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateInPoolCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a vehicle.
         * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesPost: async (vehicleCreateCommand?: VehicleCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the vehicle pool picture.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {string} pictureGuid THe picture identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete: async (vehicleGuid: string, pictureGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete', 'vehicleGuid', vehicleGuid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/vehicles/{vehicleGuid}/pictures/{pictureGuid}`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits the vehicle pool picture.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {string} pictureGuid THe picture identifier.
         * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut: async (vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut', 'vehicleGuid', vehicleGuid)
            // verify required parameter 'pictureGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut', 'pictureGuid', pictureGuid)
            const localVarPath = `/api/v1/operator/vehicles/{vehicleGuid}/pictures/{pictureGuid}`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)))
                .replace(`{${"pictureGuid"}}`, encodeURIComponent(String(pictureGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePictureEditCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the vehicle picture and returns the model.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesPost: async (vehicleGuid: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesVehicleGuidPicturesPost', 'vehicleGuid', vehicleGuid)
            const localVarPath = `/api/v1/operator/vehicles/{vehicleGuid}/pictures`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorts the vehicle pool pictures.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesSortPost: async (vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleGuid' is not null or undefined
            assertParamExists('apiV1OperatorVehiclesVehicleGuidPicturesSortPost', 'vehicleGuid', vehicleGuid)
            const localVarPath = `/api/v1/operator/vehicles/{vehicleGuid}/pictures/sort`
                .replace(`{${"vehicleGuid"}}`, encodeURIComponent(String(vehicleGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePicturesSortCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleOperatorApi - functional programming interface
 * @export
 */
export const VehicleOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets vehicles.
         * @param {string} [stationGuid] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVehiclesByOperatorAndStationQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesByStationGet(stationGuid, from, until, search, pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesEventsEventGuidGet(eventGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalCalendarAvailabilityByGuidQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesEventsEventGuidGet(eventGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesEventsEventGuidPut(eventGuid, vehicleEventUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicles.
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {Array<VehicleStatus>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGet(pageSize?: number, page?: number, search?: string, statuses?: Array<VehicleStatus>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVehiclesByOperatorQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGet(pageSize, page, search, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid GUID of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid, damageGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageByGuidQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid, damageGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes an image from the vehicle damage object.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {string} pictureGuid Picture GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid, damageGuid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add an image to the vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {File} [picture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid, damageGuid, picture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid, damageGuid, updateVehicleDamageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidDamagesPost(guid, addVehicleDamageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleEventOverrideCommand} [vehicleEventOverrideCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidEventsPost(guid: string, vehicleEventOverrideCommand?: VehicleEventOverrideCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidEventsPost(guid, vehicleEventOverrideCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidGet(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleByGuidOperatorQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidGet(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidPut(guid: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidPut(guid, vehicleUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends a vehicle for review (which can be done by admin).
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidSendForReviewPost(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidSendForReviewPost(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesGuidTransfersPut(guid, vehicleCreateTransferCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new vehicle entity from model provided.
         * @param {number} poolId The vehicle pool idnetifier.
         * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] The vehicle data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesPoolIdVehiclesPost(poolId, vehicleCreateInPoolCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a vehicle.
         * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesPost(vehicleCreateCommand?: VehicleCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleCreateCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesPost(vehicleCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the vehicle pool picture.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {string} pictureGuid THe picture identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edits the vehicle pool picture.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {string} pictureGuid THe picture identifier.
         * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid, pictureGuid, vehiclePictureEditCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the vehicle picture and returns the model.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sorts the vehicle pool pictures.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid, vehiclePicturesSortCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleOperatorApi - factory interface
 * @export
 */
export const VehicleOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets vehicles.
         * @param {string} [stationGuid] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [search] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: any): AxiosPromise<ListVehiclesByOperatorAndStationQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorVehiclesByStationGet(stationGuid, from, until, search, pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesEventsEventGuidGet(eventGuid: string, options?: any): AxiosPromise<RentalCalendarAvailabilityByGuidQueryResult> {
            return localVarFp.apiV1OperatorVehiclesEventsEventGuidGet(eventGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventGuid 
         * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorVehiclesEventsEventGuidPut(eventGuid, vehicleEventUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicles.
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {Array<VehicleStatus>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGet(pageSize?: number, page?: number, search?: string, statuses?: Array<VehicleStatus>, options?: any): AxiosPromise<ListVehiclesByOperatorQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorVehiclesGet(pageSize, page, search, statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid GUID of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid, damageGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: any): AxiosPromise<VehicleDamageByGuidQueryResult> {
            return localVarFp.apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid, damageGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes an image from the vehicle damage object.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {string} pictureGuid Picture GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid, damageGuid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add an image to the vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Damage GUID
         * @param {File} [picture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid, damageGuid, picture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {string} damageGuid Global identifier of the vehicle damage
         * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid, damageGuid, updateVehicleDamageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add vehicle damage.
         * @param {string} guid Global identifier of the vehicle
         * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorVehiclesGuidDamagesPost(guid, addVehicleDamageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleEventOverrideCommand} [vehicleEventOverrideCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidEventsPost(guid: string, vehicleEventOverrideCommand?: VehicleEventOverrideCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesGuidEventsPost(guid, vehicleEventOverrideCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidGet(guid: string, options?: any): AxiosPromise<GetVehicleByGuidOperatorQueryResult> {
            return localVarFp.apiV1OperatorVehiclesGuidGet(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the vehicle.
         * @param {string} guid Global identifier of the vehicle
         * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidPut(guid: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesGuidPut(guid, vehicleUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a vehicle for review (which can be done by admin).
         * @param {string} guid Global identifier of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidSendForReviewPost(guid: string, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1OperatorVehiclesGuidSendForReviewPost(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesGuidTransfersPut(guid, vehicleCreateTransferCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new vehicle entity from model provided.
         * @param {number} poolId The vehicle pool idnetifier.
         * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] The vehicle data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: any): AxiosPromise<IdResult> {
            return localVarFp.apiV1OperatorVehiclesPoolIdVehiclesPost(poolId, vehicleCreateInPoolCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a vehicle.
         * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesPost(vehicleCreateCommand?: VehicleCreateCommand, options?: any): AxiosPromise<VehicleCreateCommandResult> {
            return localVarFp.apiV1OperatorVehiclesPost(vehicleCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the vehicle pool picture.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {string} pictureGuid THe picture identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits the vehicle pool picture.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {string} pictureGuid THe picture identifier.
         * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid, pictureGuid, vehiclePictureEditCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the vehicle picture and returns the model.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorts the vehicle pool pictures.
         * @param {string} vehicleGuid The vehicle identifier.
         * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid, vehiclePicturesSortCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleOperatorApi - interface
 * @export
 * @interface VehicleOperatorApi
 */
export interface VehicleOperatorApiInterface {
    /**
     * 
     * @summary Gets vehicles.
     * @param {string} [stationGuid] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {string} [search] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<ListVehiclesByOperatorAndStationQueryResultPaginatedResult>;

    /**
     * 
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesEventsEventGuidGet(eventGuid: string, options?: AxiosRequestConfig): AxiosPromise<RentalCalendarAvailabilityByGuidQueryResult>;

    /**
     * 
     * @param {string} eventGuid 
     * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Gets vehicles.
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {string} [search] 
     * @param {Array<VehicleStatus>} [statuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGet(pageSize?: number, page?: number, search?: string, statuses?: Array<VehicleStatus>, options?: AxiosRequestConfig): AxiosPromise<ListVehiclesByOperatorQueryResultPaginatedResult>;

    /**
     * 
     * @summary Remove vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid GUID of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: AxiosRequestConfig): AxiosPromise<VehicleDamageByGuidQueryResult>;

    /**
     * 
     * @summary Removes an image from the vehicle damage object.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {string} pictureGuid Picture GUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Add an image to the vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {File} [picture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Update vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Add vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @param {string} guid 
     * @param {VehicleEventOverrideCommand} [vehicleEventOverrideCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidEventsPost(guid: string, vehicleEventOverrideCommand?: VehicleEventOverrideCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidGet(guid: string, options?: AxiosRequestConfig): AxiosPromise<GetVehicleByGuidOperatorQueryResult>;

    /**
     * 
     * @summary Updates the vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidPut(guid: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Sends a vehicle for review (which can be done by admin).
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidSendForReviewPost(guid: string, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @param {string} guid 
     * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new vehicle entity from model provided.
     * @param {number} poolId The vehicle pool idnetifier.
     * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] The vehicle data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: AxiosRequestConfig): AxiosPromise<IdResult>;

    /**
     * 
     * @summary Create a vehicle.
     * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesPost(vehicleCreateCommand?: VehicleCreateCommand, options?: AxiosRequestConfig): AxiosPromise<VehicleCreateCommandResult>;

    /**
     * 
     * @summary Removes the vehicle pool picture.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {string} pictureGuid THe picture identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Edits the vehicle pool picture.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {string} pictureGuid THe picture identifier.
     * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uploads the vehicle picture and returns the model.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Sorts the vehicle pool pictures.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApiInterface
     */
    apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * VehicleOperatorApi - object-oriented interface
 * @export
 * @class VehicleOperatorApi
 * @extends {BaseAPI}
 */
export class VehicleOperatorApi extends BaseAPI implements VehicleOperatorApiInterface {
    /**
     * 
     * @summary Gets vehicles.
     * @param {string} [stationGuid] 
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {string} [search] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesByStationGet(stationGuid?: string, from?: string, until?: string, search?: string, pageSize?: number, page?: number, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesByStationGet(stationGuid, from, until, search, pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesEventsEventGuidDelete(eventGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesEventsEventGuidGet(eventGuid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesEventsEventGuidGet(eventGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventGuid 
     * @param {VehicleEventUpdateCommand} [vehicleEventUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesEventsEventGuidPut(eventGuid: string, vehicleEventUpdateCommand?: VehicleEventUpdateCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesEventsEventGuidPut(eventGuid, vehicleEventUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicles.
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {string} [search] 
     * @param {Array<VehicleStatus>} [statuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGet(pageSize?: number, page?: number, search?: string, statuses?: Array<VehicleStatus>, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGet(pageSize, page, search, statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid GUID of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid: string, damageGuid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidDamagesDamageGuidDelete(guid, damageGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid: string, damageGuid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidDamagesDamageGuidGet(guid, damageGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes an image from the vehicle damage object.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {string} pictureGuid Picture GUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid: string, damageGuid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPictureGuidDelete(guid, damageGuid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add an image to the vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Damage GUID
     * @param {File} [picture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid: string, damageGuid: string, picture?: File, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidDamagesDamageGuidPicturesPost(guid, damageGuid, picture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {string} damageGuid Global identifier of the vehicle damage
     * @param {UpdateVehicleDamageCommand} [updateVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid: string, damageGuid: string, updateVehicleDamageCommand?: UpdateVehicleDamageCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidDamagesDamageGuidPut(guid, damageGuid, updateVehicleDamageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add vehicle damage.
     * @param {string} guid Global identifier of the vehicle
     * @param {AddVehicleDamageCommand} [addVehicleDamageCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidDamagesPost(guid: string, addVehicleDamageCommand?: AddVehicleDamageCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidDamagesPost(guid, addVehicleDamageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {VehicleEventOverrideCommand} [vehicleEventOverrideCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidEventsPost(guid: string, vehicleEventOverrideCommand?: VehicleEventOverrideCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidEventsPost(guid, vehicleEventOverrideCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidGet(guid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidGet(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the vehicle.
     * @param {string} guid Global identifier of the vehicle
     * @param {VehicleUpdateCommand} [vehicleUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidPut(guid: string, vehicleUpdateCommand?: VehicleUpdateCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidPut(guid, vehicleUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends a vehicle for review (which can be done by admin).
     * @param {string} guid Global identifier of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidSendForReviewPost(guid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidSendForReviewPost(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {VehicleCreateTransferCommand} [vehicleCreateTransferCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesGuidTransfersPut(guid: string, vehicleCreateTransferCommand?: VehicleCreateTransferCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesGuidTransfersPut(guid, vehicleCreateTransferCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new vehicle entity from model provided.
     * @param {number} poolId The vehicle pool idnetifier.
     * @param {VehicleCreateInPoolCommand} [vehicleCreateInPoolCommand] The vehicle data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesPoolIdVehiclesPost(poolId: number, vehicleCreateInPoolCommand?: VehicleCreateInPoolCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesPoolIdVehiclesPost(poolId, vehicleCreateInPoolCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a vehicle.
     * @param {VehicleCreateCommand} [vehicleCreateCommand] Add vehicle damage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesPost(vehicleCreateCommand?: VehicleCreateCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesPost(vehicleCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the vehicle pool picture.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {string} pictureGuid THe picture identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid: string, pictureGuid: string, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidDelete(vehicleGuid, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits the vehicle pool picture.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {string} pictureGuid THe picture identifier.
     * @param {VehiclePictureEditCommand} [vehiclePictureEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid: string, pictureGuid: string, vehiclePictureEditCommand?: VehiclePictureEditCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesVehicleGuidPicturesPictureGuidPut(vehicleGuid, pictureGuid, vehiclePictureEditCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the vehicle picture and returns the model.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid: string, file?: File, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesVehicleGuidPicturesPost(vehicleGuid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorts the vehicle pool pictures.
     * @param {string} vehicleGuid The vehicle identifier.
     * @param {VehiclePicturesSortCommand} [vehiclePicturesSortCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleOperatorApi
     */
    public apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid: string, vehiclePicturesSortCommand?: VehiclePicturesSortCommand, options?: AxiosRequestConfig) {
        return VehicleOperatorApiFp(this.configuration).apiV1OperatorVehiclesVehicleGuidPicturesSortPost(vehicleGuid, vehiclePicturesSortCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

