/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const HelpCenterArticleProductType = {
    VW_Bulli_T6_1_California_Ocean: 'VW_Bulli_T6_1_California_Ocean',
    Campervan600_VW_Grand_California: 'Campervan600_VW_Grand_California',
    MercedesMarcoPolo: 'MercedesMarcoPolo'
} as const;

export type HelpCenterArticleProductType = typeof HelpCenterArticleProductType[keyof typeof HelpCenterArticleProductType];



